import {
  INIT_PAYMENT,
  INIT_PAYMENT_FOR_QR,
  POLL_PAYMENT_STATUS,
  VERIFY_PAYMENT,
  FETCH_PAYMENT_METHODS,
  SAVE_PAYMENT_METHODS,
  SAVE_PAYMENT_INFORMATIONS,
  SAVE_POST_PAYMENT_DATA,
  REDIRECT_AFTER_PAYMENT,
  INIT_LAZY_PAY_PAYMENT,
  VERIFY_LAZY_PAY_OTP,
  SAVE_AVAILABLE_PAYMENT_METHODS,
  GET_AVAILABLE_PAYMENT_METHODS,
  CHECK_CARD_INFO,
  SET_CUSTOMER_SELECTED_PAYMENT_OPTION,
  PRE_PAYMENT_FOR_MDR_TRX,
  TOGGLE_PARTIAL_PAYMENT_MODE,
} from '../actionTypes';

export const initPayment = (data, router) => {
  return {
    type: INIT_PAYMENT,
    data,
    router,
  };
};

export const initPaymentForQR = (data, callback) => {
  return {
    type: INIT_PAYMENT_FOR_QR,
    data,
    callback,
  };
};

export const pollPayment = (data, router) => {
  return {
    type: POLL_PAYMENT_STATUS,
    data,
    router,
  };
};

export const verifyPayment = (data, router, storeInfo) => {
  return {
    type: VERIFY_PAYMENT,
    data,
    router,
    storeInfo,
  };
};

export const fetchPaymentMethods = (data) => {
  return {
    type: FETCH_PAYMENT_METHODS,
    data,
  };
};

export const savePaymentMethods = (data) => {
  return {
    type: SAVE_PAYMENT_METHODS,
    data,
  };
};

export const savePaymentInfo = (data) => {
  return {
    type: SAVE_PAYMENT_INFORMATIONS,
    data,
  };
};

export const savePostPaymentData = (data) => {
  return {
    type: SAVE_POST_PAYMENT_DATA,
    data,
  };
};

export const redirectAfterPayment = (orderDetails, router, storeInfo) => {
  return {
    type: REDIRECT_AFTER_PAYMENT,
    orderDetails,
    router,
    storeInfo,
  };
};

export const initLazyPayPayment = (data, router) => {
  return {
    type: INIT_LAZY_PAY_PAYMENT,
    data,
    router,
  };
};

export const verifyLazyPayOtp = (data, router) => {
  return {
    type: VERIFY_LAZY_PAY_OTP,
    data,
    router,
  };
};

// Payment Methods shown in Store Footer
export const saveAvailablePaymentMethods = (data) => {
  return {
    type: SAVE_AVAILABLE_PAYMENT_METHODS,
    data: data,
  };
};

export const getAvailablePaymentMethods = (data) => {
  return {
    type: GET_AVAILABLE_PAYMENT_METHODS,
    payload: data,
  };
};

export const checkCardInfo = (data, callback) => {
  return {
    type: CHECK_CARD_INFO,
    data,
    callback,
  };
};

export const setCustomerTransactionPaymentMethod = (data) => {
  return {
    type: SET_CUSTOMER_SELECTED_PAYMENT_OPTION,
    data,
  };
};

export const prePaymentCheckMDRTransactions = (data, callback) => {
  return {
    type: PRE_PAYMENT_FOR_MDR_TRX,
    data,
    callback,
  };
};

export const togglePartialPaymentMode = (data, callback, other) => {
  return {
    type: TOGGLE_PARTIAL_PAYMENT_MODE,
    data,
    callback,
    other,
  };
};

import store from '@/redux/store';

export const reference = {
  id: 24,
  name: 'template1',
  colors: {
    C1: '#000',
    C2: '#FFFF',
    C3: '#1F282A',
    C4: '#FFFF',
    C5: '#CFB095',
  },
  font: {
    body: {
      fontFamily: 'Fredoka',
      fontWeight: '400',
    },
    cta: {
      fontFamily: 'Fredoka',
      fontWeight: '500',
      borderRadius: '0',
    },
    heading: {
      fontFamily: 'Fredoka',
      fontWeight: '400',
    },
  },
  ordering: 0,
};

export const getFinalConfig = (x) => {
  // Reference to be read from Redux Store.

  /** Logic :
   *
   * 1. First check if widgetsReducer contains activeStyleTemplate set, if so then pick it from here. Generally in case of CFE it would be null, so then
   * 2. Proceed to pick from store theme.
   */

  const widgetData = store.getState().widgetsReducer;
  const storeThemes = store.getState().storeReducer?.store?.theme;

  let currentActiveStyle;

  if (widgetData.activeStyleTemplate) {
    currentActiveStyle = widgetData.activeStyleTemplate;
  } else {
    currentActiveStyle = storeThemes?.style_template;
  }

  const reference = currentActiveStyle;

  const finalObj = {
    heading: { ...reference?.font?.heading, color: reference?.colors?.C4 },
    body: { ...reference?.font?.body, color: reference?.colors?.C4 },
    buttonPrimary: {
      ...reference?.font?.cta,
      color: reference?.colors?.C3,
      backgroundColor: reference?.colors?.C2,
      backgroundHoverColor: reference?.colors?.C5,
    },
    buttonSecondary: {
      ...reference?.font?.cta,
      color: reference?.colors?.C2,
      borderColor: reference?.colors?.C2,
      backgroundColor: 'transparent',
    },
    backgroundColor: reference?.colors?.C1,
    nav: {
      color: reference?.colors?.C2,
    },
    borderColor: reference?.colors?.C2,
  };
  return finalObj;
};

export const getStyleForProps = (styleConfig) => {
  const themeConfig = getFinalConfig(reference);
  return themeConfig;
  if (!styleConfig) {
    return themeConfig;
  } else {
    return { ...themeConfig, ...styleConfig };
  }
};

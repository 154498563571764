/**
 *
 * @param {Array} items
 * @return {object}
 */
export const getTotalCartAmounts = (items) => {
  const initalAmounts = {
    pay_amount: 0,
    amount: 0,
  };
  try {
    /** Extracting the required data from the cart */
    return items?.reduce(
      (acc, curr) => {
        if (curr.item_id !== undefined && curr.actual_price) {
          if (
            curr.variants_count > 0 &&
            Array.isArray(curr.variants_selected) &&
            curr.variants_selected.length > 0
          ) {
            acc.amount += curr.variants_selected.reduce(
              (acc, curr) => acc + curr.price * curr.quantity,
              0
            );
          } else {
            acc.amount += curr.actual_price * curr.quantity;
          }
          acc.pay_amount += curr.amount;
        }
        return acc;
      },
      {
        pay_amount: 0,
        amount: 0,
      }
    );
  } catch (err) {
    console.log(err);
    return initalAmounts;
  }
};

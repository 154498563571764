import { getUrlParams } from '@/utils/getUrlParams';
import { IS_SERVER } from './checkRenderEnv';
import { PREVIEW_PARAMS } from './constants';

const checkIfThemePreview = () => {
  const { tid, tag_id, items_available, theme_page } =
    !IS_SERVER &&
    getUrlParams([
      PREVIEW_PARAMS.THEME_ID,
      PREVIEW_PARAMS.TAG_ID,
      PREVIEW_PARAMS.ITEMS_AVAILABLE,
      PREVIEW_PARAMS.THEME_PAGE,
    ]);
  return !!(tid && tag_id && items_available && theme_page);
};

export default checkIfThemePreview;

/**
 * This is the flat list component
 * Use cases : Used for the auto scroll pagination lists
 * WARNING: THIS COMPONENT SHOULD NOT BE CHANGED UNLESS ABSOLUTELY NECESSARY. IF CHANGES ARE NECESSARY, DISCUSS IT WITH THE TEAM PRIOR
 * @param {boolean} renderInsideFragment - this is used as most of our codebase is using the original section tag for wrapper,
 *                                but in the case of Middlebody 5 which uses a reusable styled component used in multiple places,
 *                                to style it's direct underlying children directly, therefore fragment is used for such case
 */
import React, { useCallback, useRef } from 'react';

const FlatList = ({
  isLoaderVisible,
  onScrollEnd,
  isNext,
  LoaderComponent = null,
  loaderComponentCount = 3,
  renderList,
  loaderProps = {},
  renderInsideFragment = false,
}) => {
  const observer = useRef();

  const lastStoreElementRef = useCallback(
    (node) => {
      if (isLoaderVisible) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          onScrollEnd?.();
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    [isLoaderVisible, onScrollEnd, isNext]
  );

  const _RenderChildren = () => (
    <>
      {/* List */}
      {renderList()}

      {/* Loader component */}
      {isLoaderVisible && LoaderComponent && (
        <>
          {new Array(loaderComponentCount).fill(0).map((el, index) => (
            <LoaderComponent {...loaderProps} key={`${index}-loaderComponent`} />
          ))}
        </>
      )}

      {/* On Scroll end div */}
      {isNext === true && !isLoaderVisible && (
        <div className="show-more" ref={lastStoreElementRef}></div>
      )}
    </>
  );

  return renderInsideFragment ? (
    _RenderChildren()
  ) : (
    <section>{_RenderChildren()}</section>
  );
};

export default FlatList;

/* eslint-disable no-unused-vars */
export enum BUTTON_SIZE_TYPE {
    SMALL =  'small',
    MEDIUM = 'medium',
    LARGE ='large',
}

export const BUTTON_TYPE = {
    SOLID: 'solid',
    OUTLINE: 'outline',
    TEXTUAL: 'textual'  
}

export const BUTTON_VARIANT = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SECONDARY2: 'secondary-2',
    SECONDARYFILLED: 'secondaryFilled',
    SECONDARYFILLED2: 'secondaryFilled-2',
    TERTIARY: 'tertiary'
}

export const TRANSPARENT = 'transparent'
import { takeEvery, put } from 'redux-saga/effects';
import { ENDPOINT } from './../../config/endpoints';
import apiCall from './../../services/api';
import { checkIfObjectExists } from '@/utils/checkIfObjectExists';

import {
  FETCH_SIMILAR_CATALOG_ITEMS,
  FETCH_CATALOG_ITEMS_BY_CATEGORY_ID,
  SEARCH_PRODUCT_ITEMS,
  FETCH_CATALOG_ITEM_BY_ID,
  FETCH_CATALOG_ITEMS_BASICS_BY_STOREID,
  FETCH_COLLECTION_CATALOG_ITEM_BY_ID,
  GET_CUSTOMER_REVIEW_SETTINGS,
  PUBLISH_REVIEW,
  VERIFY_BUYER_FOR_ITEM,
  GET_FILTERED_ITEMS_OPTIONS,
  ADD_NOTIFY_PRODUCT_INQUIRY,
} from './../actionTypes';

import {
  saveCatalogsListBasedOnCategories,
  setLoaderState,
  saveSimilarCatalogItems,
  saveSearchedProductItems,
  saveCatalogItemById,
  saveDetailedItemById,
  setVariantModalVisibility,
  saveCatalogItemsBasicsByStoreId,
  saveCollectionCatalogItems,
  saveCustomerReviewSettings,
  saveFilteredOptions,
  saveFilteredItems,
  setFilterLoader,
} from './../actions';
import store from '../store';
import { toastNotifyWarning } from '@/components/Common/Toast';
import { getUniqueArrayOfObject } from '@/utils/getUniqueArrayOfObject';

function* fetchCatalogItemsBasicsByStoreId(actions) {
  yield put(setLoaderState(true));

  try {
    const catalogResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.CATALOG.getCatalogItemsBasicsByStoreId(
        actions.data.store_id,
        actions.data.category_type
      ),
    });
    if (catalogResponse && catalogResponse.data.status) {
      yield put(setLoaderState(false));
      let catalogItems = {};

      if (actions.data.category_type) {
        if (Array.isArray(catalogResponse.data?.tags_items)) {
          catalogItems = catalogResponse.data?.tags_items?.reduce((acc, curr) => {
            acc[curr.tag.id] = curr.store_items;
            return acc;
          }, {});
        }
      } else {
        if (Array.isArray(catalogResponse.data?.store_items)) {
          catalogItems = catalogResponse.data?.store_items?.reduce((acc, curr) => {
            acc[curr.category.id] = curr.items;
            return acc;
          }, {});
        }
      }

      yield put(saveCatalogItemsBasicsByStoreId(catalogItems));
    }
  } catch (err) {
    console.log(err);
    yield put(setLoaderState(false));
  }
}

function* fetchCatalogsByCategoryId(actions) {
  try {
    yield put(setLoaderState(true));
    const catalogResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.CATALOG.getCatalogItemsBasicsByCategoryId(
        actions.data.store_id,
        actions.data.category_id,
        actions.data.category_type,
        actions.data.page_no
      ),
    });
    if (catalogResponse && catalogResponse.data.status) {
      yield put(
        saveCatalogsListBasedOnCategories({
          category_id: actions.data.category_id,
          items: catalogResponse.data.items || [],
          isNextPage: catalogResponse.data.next_page,
          isPaginated: actions.data.page_no >= 1,
        })
      );
      actions.callback &&
        actions.callback(catalogResponse.data.status, catalogResponse.data.next_page);
      yield put(setLoaderState(false));
    } else {
      actions.callback && actions.callback(false);
    }
  } catch (err) {
    actions.callback && actions.callback(false);
    yield put(setLoaderState(false));
  }
}

function* fetchSimilarCatalogItems(actions) {
  try {
    yield put(setLoaderState(true));
    const catalogResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.CATALOG.getSimilarItems(
        actions.data.store_id,
        actions.data.category_id,
        actions.data.item_id
      ),
    });
    if (catalogResponse && catalogResponse.data.status) {
      yield put(setLoaderState(false));
      yield put(
        saveSimilarCatalogItems({
          suggestions: catalogResponse.data.suggested_items,
          similar: catalogResponse.data.similar_items,
        })
      );
    }
  } catch (err) {
    console.log(err);
    yield put(setLoaderState(false));
  }
}

function* searchProductItems(actions) {
  try {
    if (actions.data.page === 1) {
      yield put(saveSearchedProductItems({ items: [], isNext: 0, page: 1 }));
    }

    yield put(setLoaderState(true));
    const catalogResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.CATALOG.SEARCH_CATALOG_ITEM,
      data: actions.data,
    });

    if (catalogResponse && catalogResponse.data.status) {
      yield put(setLoaderState(false));
      actions.callback && actions.callback();
      yield put(
        saveSearchedProductItems({
          items: catalogResponse.data.items,
          isNext: catalogResponse.data.next,
          page: actions.data.page,
        })
      );
    }
  } catch (error) {
    console.log(error);
    yield put(setLoaderState(false));
  }
}

function* fetchCatalogItemById(actions) {
  try {
    yield put(setLoaderState(true));
    const catalogResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.CATALOG.getItemById(
        actions.data.store_id,
        actions.data.item_id,
        actions.data.src
      ),
      parseToJson: true,
    });

    if (catalogResponse && catalogResponse.data.status) {
      yield put(setLoaderState(false));
      if (actions.isVariantData) {
        yield put(saveDetailedItemById(catalogResponse?.data?.data));
        yield put(setVariantModalVisibility(true));
        actions.cb && actions.cb(catalogResponse?.data?.data);
      } else {
        actions.cb && actions.cb(catalogResponse?.data?.data);
        yield put(saveCatalogItemById(catalogResponse?.data?.data));
      }
    }
  } catch (error) {
    console.log(error);
    yield put(setLoaderState(false));
  }
}

function* fetchAllCollectionCategoryData(actions) {
  try {
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.CATALOG.getAllCollectionCategoryData({
        storeId: actions.data.storeId,
        collectionId: actions.data.collectionId,
        categoryId: actions.data.categoryId,
      }),
    });
    if (response?.data?.status) {
      const parsedData = JSON.parse(response?.data?.data);
      // const collectionData = response?.data?.items || [];
      yield put(
        saveCollectionCatalogItems({
          items: parsedData,
          category_id: actions.data.categoryId,
          collection_id: actions.data.collectionId,
        })
      );

      actions?.callback && actions.callback();
    }
  } catch (err) {
    console.log(err);
  }
}

function* getCustomerReviewSettings(actions) {
  const isConfigAlreadyFetched = checkIfObjectExists(
    store?.getState()?.catalogReducer?.reviewSettings
  );
  if (isConfigAlreadyFetched) {
    return;
  }

  try {
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.CATALOG.getCustomerReviewSettings(actions.data),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      yield put(saveCustomerReviewSettings(response?.data?.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* publishReview(actions) {
  try {
    if (!actions.data.review_rating[0].phone) {
      const userInfo = store.getState().userReducer?.data;
      actions.data.review_rating[0].user_id = userInfo.user_id;
      actions.data.review_rating[0].email = userInfo.email;
    }
    const response = yield apiCall({
      method: 'PUT',
      data: actions.data,
      url: ENDPOINT.CATALOG.publishReview,
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      actions.callback && actions.callback();
    }
  } catch (err) {
    console.log(err);
  }
}

function* verifyBuyerForItem(actions) {
  try {
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.CATALOG.verifyBuyerForItem(
        actions.data.item_id,
        actions.data.phone,
        actions.data.user_id || '',
        actions.data.store_id || ''
      ),
      parseToJson: true,
    });
    if (response && response?.data?.status) {
      actions.callback(response?.data?.data);
    }
  } catch (err) {
    console.log(err);
  }
}

function* fetchFilteredItemsOptions(actions) {
  yield put(setFilterLoader(true));
  /** return unique array on based on name property */
  const uniqueFitlerPayload = getUniqueArrayOfObject(actions.data?.filters ?? [], 'name');
  actions.data.filters = uniqueFitlerPayload;
  try {
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.CATALOG.getFilteredItemsOptions,
      data: actions.data,
      parseToJson: true,
    });
    if (response && response?.status) {
      const lastStoredItems = store.getState().catalogReducer.filteredItems;
      yield put(saveFilteredOptions(response.data?.data?.filters));
      yield put(saveFilteredItems(response.data?.data?.items, lastStoredItems));
      actions?.callback &&
        actions?.callback(response.data?.data?.status, response.data?.data?.is_next_page);
    }
  } catch (err) {
    actions?.callback && actions?.callback(false);
    console.log(err);
  } finally {
    yield put(setFilterLoader(false));
  }
}

function* addNotifyProductInquiry(actions) {
  yield put(setFilterLoader(true));
  try {
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.CATALOG.getNotifyProductInquiryUrl,
      data: actions.data,
    });

    if (response && response?.data?.status) {
      toastNotifyWarning("We'll inform you when the product is back in stock");
    } else {
      toastNotifyWarning(
        'We already have that email registered. We will inform you when the product is back in stock'
      );
    }
    actions?.callback && actions?.callback();
  } catch (err) {
    console.log('error is', err);
  } finally {
    yield put(setFilterLoader(false));
  }
}

export default function* root() {
  yield takeEvery(
    FETCH_CATALOG_ITEMS_BASICS_BY_STOREID,
    fetchCatalogItemsBasicsByStoreId
  );
  yield takeEvery(FETCH_SIMILAR_CATALOG_ITEMS, fetchSimilarCatalogItems);
  yield takeEvery(FETCH_CATALOG_ITEMS_BY_CATEGORY_ID, fetchCatalogsByCategoryId);
  yield takeEvery(SEARCH_PRODUCT_ITEMS, searchProductItems);
  yield takeEvery(FETCH_CATALOG_ITEM_BY_ID, fetchCatalogItemById);
  yield takeEvery(FETCH_COLLECTION_CATALOG_ITEM_BY_ID, fetchAllCollectionCategoryData);
  yield takeEvery(GET_CUSTOMER_REVIEW_SETTINGS, getCustomerReviewSettings);
  yield takeEvery(PUBLISH_REVIEW, publishReview);
  yield takeEvery(VERIFY_BUYER_FOR_ITEM, verifyBuyerForItem);
  yield takeEvery(GET_FILTERED_ITEMS_OPTIONS, fetchFilteredItemsOptions);
  yield takeEvery(ADD_NOTIFY_PRODUCT_INQUIRY, addNotifyProductInquiry);
}

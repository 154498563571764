import config from './index';

export const ENDPOINT = {
  STORE: {
    getStoreBySubDomain: (subdomain) =>
      `${config.API_BASE_URL}/dotk/customer/getStoreBySubDomain/${subdomain}`,
    getStoreBySubDomainWithToken: (subdomain) =>
      `${config.API_BASE_URL}/dotk/vm1/premium/getStoreBySubDomain/${subdomain}`,
    getStoreBasicDetails: (subdomain) =>
      `${config.API_BASE_URL}/dotk/vo1/settings/storeBasicDetailsByDomain/${subdomain}`,
    getThemeById: (id, storeId) =>
      `${config.API_BASE_URL}/dotk/themes/getTheme/${id}?store_id=${storeId}`,
    getDummyProductsByThemeTagId: (themeTagId) =>
      `${config.API_BASE_URL}/dotk/vo1/products/sampleCategoryItems/${themeTagId}`,
    getFeatureLocks: (storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/premium/featureLocks/${storeId}`,
    addThemeWidget: `${config.API_BASE_URL}/dotk/vm1/premium/widgets`,
    updateThemeWidget: `${config.API_BASE_URL}/dotk/vm1/premium/widget`,
    getB2bRfqInputFields: (store_id) =>
      `${config.API_BASE_URL}/dotk/vc1/settings/storeRfqConfigs/${store_id}`,
  },
  GLOBAL_SETTING: {
    getFontsData: `${config.API_BASE_URL}/dotk/vm1/premium/defaultStyles`,
    updateGlobalStyle: `${config.API_BASE_URL}/dotk/vm1/premium/storeStyle`,
    updateFontData: `${config.API_BASE_URL}/dotk/vm1/premium/customFontsStyle`,
    updateCustomTag: `${config.API_BASE_URL}/dotk/vm1/products/tagList`,
    getVariantData: (storeId) =>
      `${config.API_BASE_URL}/dotk/vm1/products/itemsOptionsByStore/${storeId}`,
  },
  SEO: {
    getCustomSeoDetails: (storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/marketing/getCustomSeoDetails/${storeId}`,
    getAdvanceCustomSeoDetails: (storeId, type, typeId) =>
      `${config.API_BASE_URL}/dotk/vo1/marketing/advancedCustomSeo/${storeId}?type=${type}&type_id=${typeId}`,
  },
  AUTH: {
    AUTHENTICATE_USER: `${config.API_BASE_URL}/dotk/vc1/user/authenticate`,
    CREATE_USER_ADDRESS: `${config.API_BASE_URL}/dotk/vc1/user/address/create`,
    generateOTP: (phone, queryParams) =>
      `${
        config.API_BASE_URL
      }/dotk/vo1/user/login/${phone}?source=digital_showroom&domain=${
        window.location.href
      }${queryParams || ''}`,
    verifyOTP: (phone, otp, storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/user/verifyOtp?phone=${phone}&otp=${otp}&store_id=${storeId}`,
    generateOTPforEmail: `${config.API_BASE_URL}/dotk/vo1/user/login/v2/email`,
    verifyOTPforEmail: (email, otp, storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/user/verifyOtp?email=${email}&otp=${otp}&store_id=${storeId}`,
    verifyOTPLess: () => `${config.API_BASE_URL}/dotk/vo1/user/verifyOtplessTokenCfe`,
    GENERATE_OTP_MERCHANTS: `${config.API_BASE_URL}/dotk/vm1/user/generateOtp`,
    VERIFY_MERCHANT_ACTION_BY_OTP: `${config.API_BASE_URL}/dotk/vm1/settings/verifyPublishWebsite`,
    getUserAddressList: (userId, storeId) =>
      `${config.API_BASE_URL}/dotk/vc1/user/address/getAllByUser/${userId}?store_id=${storeId}`,
    getTruecallerDetails: (requestNonce, storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/user/verifyLoginViaTrueCaller/${requestNonce}?store_id=${storeId}`,
    LOGIN_WITH_GMAIL: `${config.API_BASE_URL}/dotk/vo1/user/directLogin`,
  },
  CATALOG: {
    getCatalogItemsBasicsByStoreId: (storeId, categoryType) =>
      `${config.API_BASE_URL}/dotk/catalog/getItemsBasicDetailsByStoreId/${storeId}?category_type=${categoryType}`,
    getCategories: (storeId) =>
      `${config.API_BASE_URL}/dotk/catalog/getCategories/${storeId}`,
    getCatalogItemsBasicsByCategoryId: (storeId, categoryId, categoryType, pageNo) =>
      // category_id -> product-tag id if product tag related info required. categoryType -> 0 if category showcase, 1 if product showcase
      `${
        config.API_BASE_URL
      }/dotk/catalog/getItemsBasicDetails/${storeId}?category_id=${categoryId}&category_type=${categoryType}&page_no=${
        pageNo || 0
      }`,
    // adding src params, will be reverted later
    getItemById: (storeId, itemId, src = '') =>
      `${config.API_BASE_URL}/dotk/vo1/products/item?store_id=${storeId}&item_id=${itemId}&src=${src}`,
    getSimilarItems: (storeId, categoryId, itemId) =>
      `${config.API_BASE_URL}/dotk/catalog/getCategoryItems?store_id=${storeId}&category_id=${categoryId}&item_id=${itemId}`,
    SEARCH_CATALOG_ITEM: `${config.API_BASE_URL}/dotk/catalog/searchItems`,
    getAllCollectionsData: (storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/catalogue/collectionCategoryData/${storeId}`,
    getAllCollectionCategoryData: ({ storeId, collectionId, categoryId }) =>
      `${config.API_BASE_URL}/dotk/vo1/catalogue/collectionCategoryItemsData?store_id=${storeId}&collection_id=${collectionId}&category_id=${categoryId}`,
    getCustomerReviewSettings: (store_id) =>
      `${config.API_BASE_URL}/dotk/vo1/settings/reviewConfig/${store_id}`,
    publishReview: `${config.API_BASE_URL}/dotk/vc1/products/publishReview`,
    verifyBuyerForItem: (item_id, phone, user_id, store_id) =>
      `${config.API_BASE_URL}/dotk/vc1/orders/verifyBuyerForItemV2?id=${item_id}&phone=${phone}&user_id=${user_id}&store_id=${store_id}`,
    getFilteredItemsOptions: `${config.API_BASE_URL}/dotk/vo1/products/getFilteredItemsAndSubFilters`,
    getNotifyProductInquiryUrl: `${config.API_BASE_URL}/dotk/vo1/products/notifyMeItemInfo`,
  },
  COMMON: {
    getFooterPaymentModesList: (storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/paymentOptions/storeDetails/${storeId}`,
  },
  CART: {
    VALIDATE_CART: `${config.API_BASE_URL}/dotk/order/validateCart`,
    getCartById: (id) => `${config.API_BASE_URL}/dotk/vo1/orders/getOrderCartById/${id}`,
    SET_CART: `${config.API_BASE_URL}/dotk/vo1/orders/setOrderCart`,
  },
  USER: {
    isOrderServiceableCheck: (sourcePincode, destinationPincode, storeId) =>
      `${config.API_BASE_URL}/dotk/vc1/delivery/isOrderServiceable?pickup_pincode=${sourcePincode}&delivery_pincode=${destinationPincode}&store_id=${storeId}`,
    getCityStateByPinCode: (pincode) =>
      `${config.API_BASE_URL}/dotk/vc1/user/getCityStateByPinCode/${pincode}`,
    getCountriesList: () => `${config.API_BASE_URL}/dotk/vc1/user/countries`,
    deleteUserAccount: () => `${config.API_BASE_URL}/dotk/vc1/settings/deleteAccount`,
  },
  ORDER: {
    CREATE_ORDER: `${config.API_BASE_URL}/dotk/vc1/orders/createNewOrder`,
    getOrderFromHash: (hash, storeId) => {
      let plug = `${config.API_BASE_URL}/dotk/order/getOrderFromHash/${hash}`;
      if (storeId) plug += `?store_id=${storeId}`;
      return plug;
    },
    getLoggedInOrderFromHash: ({ order_hash, phone, storeId }) =>
      `${config.API_BASE_URL}/dotk/vc1/orders/getOrderFromHash/${order_hash}?phone=${phone}&storeid=${storeId}`,
    getCustomerOrders: (storeId, phone, userId, pageNum, status) =>
      `${config.API_BASE_URL}/dotk/vc1/orders/getCustomerOrders?id=${storeId}&phone=${phone}&user_id=${userId}&page=${pageNum}&status=${status}`,
    getOrderDetailsByID: (orderId) =>
      `${config.API_BASE_URL}/dotk/vc1/orders/getOrderDetails/${orderId}`,
    getStoreOrder: (hash) =>
      `${config.API_BASE_URL}/dotk/vc1/orders/getStoreOrder/${hash}`,
    updateOrderStateByHash: (orderHash) =>
      `${config.API_BASE_URL}/dotk/vc1/orders/updateOrderState/${orderHash}`,
    getGstInvoice: (orderId, storeId) =>
      `${config.API_BASE_URL}/dotk/vc1/orders/generateGstInvoice/${orderId}?store_id=${storeId}`,
    cancelOrder: () => `${config.API_BASE_URL}/dotk/vc1/orders/cancel`,
  },
  PAYMENT: {
    INIT_PAYMENT: `${config.API_BASE_URL}/dotk/vc1/orders/initiatePayment`,
    INIT_LAZY_PAY_PAYMENT: `${config.API_BASE_URL}/dotk/vc1/settings/initiateLazyPayPayment`,
    verifyPayment: (txnID) =>
      `${config.API_BASE_URL}/dotk/vc1/orders/verifyPaymentStatus/${txnID}`,
    pollPaymentStatus: (txnID) =>
      `${config.API_BASE_URL}/dotk/vc1/orders/pollPaymentStatus/${txnID}`,
    fetchPaymentModes: (storeId, hash) =>
      `${config.API_BASE_URL}/dotk/merchant/getCustomerPaymentOptions/${storeId}?hash=${hash}`,
    fetchAvailablePaymentModes: (storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/settings/getStorePaymentOptions/${storeId}`,
    VERIFY_OTP_FOR_LAZY_PAY: `${config.OLD_API_BASE_URL}/payment/v2/lazyPay/otp`,
    checkCardInfo: (cardNo) => `${config.API_BASE_URL}/dotk/vo1/user/cardInfo/${cardNo}`,
    MDR_TRX_CHARGES: `${config.API_BASE_URL}/dotk/vo1/orders/mdrTransactionCharges`,
    TOGGLE_PARTIAL_PAYMENT: `${config.API_BASE_URL}/dotk/vc1/orders/informPartialPayment`,
  },
  LOGS: {
    PUSH_EVENT_LOG: `${config.API_BASE_URL}/dotanalytics/push/pushEventLog`,
    UPLOAD_IMAGE_TO_S3: `${config.API_BASE_URL}/dotk/vm1/settings/uploadMediaToS3`,
    UPLOAD_MEDIA_TO_S3: `${config.API_BASE_URL}/dotk/vm1/settings/uploadToS3`, // This is used to upload videos currently, don't remove
  },
  SPOTLIGHT: {
    BUSINESS_LIST: `${config.API_BASE_URL}/dotk/merchant/getbusinessList`,
    cityList: (latitude, longitude) =>
      `${config.API_BASE_URL}/dotk/city/getList?latitude=${latitude}&longitude=${longitude}`,
    storesList: (latitude, longitude, cityId, pageNo, categoryId) =>
      `${config.API_BASE_URL}/dotk/city/getStoreList?latitude=${latitude}&longitude=${longitude}&city_id=${cityId}&page=${pageNo}&category_id=${categoryId}`,
    storeSearch: (latitude, longitude, cityId, pageNo, searchText) =>
      `${config.API_BASE_URL}/dotk/city/store/search?latitude=${latitude}&longitude=${longitude}&city_id=${cityId}&page=${pageNo}&search_string=${searchText}`,
  },
  PROMO: {
    getCouponsListByStoreId: (storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/promo/getActiveCustomerCoupons/${storeId}`,
    VERIFY_COUPON: `${config.API_BASE_URL}/dotk/vo1/promo/verifyCoupon`,
    getPaymentCouponsListByStoreId: (storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/settings/getPaymentOffers/${storeId}`,
    VERIFY_PAYMENT_OFFER: `${config.API_BASE_URL}/dotk/vo1/settings/verifyPaymentOffer`,
    GET_EVALUATED_ACTIVE_CUSTOMER_COUPONS: `${config.API_BASE_URL}/dotk/vo1/marketing/getEvaluatedActiveCustomerCoupons`,

    // new APIs
    GET_CART_PAGE_PROMOS: `${config.API_BASE_URL}/dotk/vo1/marketing/cartPromos`,
    getAllPromos: (storeId, userId = 0) =>
      `${config.API_BASE_URL}/dotk/vo1/marketing/promos/${storeId}/${userId}`,
    getPromoDataById: (promoId, storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/marketing/promoInfo/${promoId}/${storeId}`,
    GET_PROMOS_BY_TYPE_AND_TYPE_ID: `${config.API_BASE_URL}/dotk/vo1/marketing/promosByCataloguetype`,
    GET_PROMO_ASSOCIATED_IDS: `${config.API_BASE_URL}/dotk/vo1/marketing/promoApplicableDetails`,
    GET_PROMO_ASSOCIATED_PRODUCTS_BY_TYPE_AND_TYPE_IDS: `${config.API_BASE_URL}/dotk/vo1/products/byCatalogueType`,
    GET_REQUIRED_BOGO_PRODUCTS_IN_CART: `${config.API_BASE_URL}/dotk/vo1/marketing/offerPageCartitems`,
    GET_SATISFIED_BOGO_PROMOS: `${config.API_BASE_URL}/dotk/vo1/marketing/triggeredPromos`,
    APPLY_COUPON: `${config.API_BASE_URL}/dotk/vc1/orders/applyPromo`,
  },
  ADDITIONAL_PAGES: {
    GET_ADDITIONAL_PAGE: `${config.API_BASE_URL}/dotk/vo1/additionalPage/getPageBySlug`,
    getNavigationList: (storeId, isPublished) =>
      `${config.API_BASE_URL}/dotk/vo1/additionalPage/getPages?store_id=${storeId}&is_published=${isPublished}`,
    getStaticWidgetDetails: (widgetId) =>
      `${config.API_BASE_URL}/dotk/vo1/premium/widgetDetails/${widgetId}`,
    getDraftWidgets: (storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/premium/newEcommWidgets/${storeId}`,
    discardDraftedWidgets: (storeId) =>
      `${config.API_BASE_URL}/dotk/vm1/premium/discardDraft`,
  },
  STORE_POLICIES: {
    fetchStorePolicies: (storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/settings/allPolicyPages/${storeId}`,
  },
  LEAD_GENERATION: {
    updateCustomerLead: `${config.API_BASE_URL}/dotk/vo1/user/updateCustomerLead`,
    getStoreActiveLeadGenTemplate: (storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/settings/getStoreActiveLeadGenTemplate/${storeId}`,
  },
  EMI: {
    GET_EMI_OPTIONS_FOR_CARD: `${config.API_BASE_URL}/dotk/vo1/settings/getEmiOptionsSpecificToCard`,
    GET_ALL_EMI_OPTIONS: `${config.API_BASE_URL}/dotk/vo1/settings/getAllEmiOptionsInfo`,
    SET_EMI_OPTION: `${config.API_BASE_URL}/dotk/vo1/orders/emiOption`,
  },
  DELIVERY: {
    GET_ITEMS_DELIVERY_CHARGE: `${config.API_BASE_URL}/dotk/vo1/settings/getItemsBasedDeliveryCharges`,
    GET_SERVICABLE_ADDRESS_LIST: `${config.API_BASE_URL}/dotk/vc1/settings/serviceabilityOfAddressIds`,
    GET_ESTIMATED_DELIVERY_TIME: `${config.API_BASE_URL}/dotk/vo1/products/pincodeBasedEstimatedDelivery`,
  },
  WIDGETS: {
    getWidgetThemeContent: `${config.API_BASE_URL}/dotk/vo1/premium/newThemeContent/2`,
    addWidget: `${config.API_BASE_URL}/dotk/vm1/premium/widgets`,
    updateWidget: `${config.API_BASE_URL}/dotk/vm1/premium/widget`,
    submitForm: `${config.API_BASE_URL}/dotk/vo1/premium/submitFormData`,
    saveStyleTemplate: `${config.API_BASE_URL}/dotk/vm1/premium/storeStyleTemplateId`,
    addMediaToStore: `${config.API_BASE_URL}/dotk/vm1/premium/storeThemeMedia`,
    updateMediaToStore: `${config.API_BASE_URL}/dotk/vm1/premium/storeThemeMedia`,
    deleteMediaFromStore: (mediaId) =>
      `${config.API_BASE_URL}/dotk/vm1/premium/storeThemeMedia/${mediaId}`,
    uploadMediaToS3: `${config.API_BASE_URL}/dotk/vm1/media/uploadMediaToS3`,
    getActiveThemeContent: `${config.API_BASE_URL}/dotk/vo1/premium/theme/2?theme_category=2`,
    addEditTheme: `${config.API_BASE_URL}/dotk/vo1/premium/theme`,
    fetchTheme: (themeId) =>
      `${config.API_BASE_URL}/dotk/vo1/premium/theme/${themeId}?theme_category=2`,
    publishTheme: `${config.API_BASE_URL}/dotk/vo1/premium/publishTheme`,
    setStoreWebsiteStatus: `${config.API_BASE_URL}/dotk/vm1/premium/storePremiumFlag`,
  },
  BRAND_WEBSITE: {
    getPreviewTheme: (id) =>
      `${config.API_BASE_URL}/dotk/vm1/premium/theme/${id}?theme_category=2`,
  },
  EXIT_GATING: {
    getExitGatingData: (storeId) =>
      `${config.API_BASE_URL}/dotk/vo1/marketing/exitGating/${storeId}`,
  },
};

import { WYSIWYG, CodeEditor } from '@dotpe/kui/WYSIWYG';
import { ChangeEvent, FunctionComponent, useState } from 'react';
import styles from './jsonFormBuilder.module.scss';
import { set, get } from 'lodash';
import { JsonFormBuilderArray } from './JsonFormBuilderArray';
import { InfoIcon } from '@/assets/svgExports/InfoIcon';
import Tooltip from '@/components/Common/Tooltip';
import { ColorPicker } from './ColorPicker';
import React from 'react';

interface JsonFormBuilderProps {
  widgetId: string;
  config: Record<string, any>;
  widgetProps: Record<string, any>;
  onChange?: (
    values: Record<string, any>,
    e?: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  ancestor?: string;
}

const parseValues = (values: Record<string, any>) => {
  const newValues: Record<string, any> = {};
  Object.entries(values).forEach(([k, v]) => {
    set(newValues, k.split('.'), v);
  });
  return values;
};

const getValue = (values, key) => {
  const field = get(values, key);
  if (field?.type) {
    return field.value;
  } else {
    return field;
  }
};

export const JsonFormBuilder: FunctionComponent<JsonFormBuilderProps> = (props) => {
  const { config = {}, widgetProps = {}, ancestor = '' } = props;
  const [values, setValues] = useState<Record<string, any>>({});

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, type } = e.target;
    let value: string | boolean | number = e.target.value;
    if (type === 'checkbox') {
      value = (e.target as HTMLInputElement).checked;
    }
    if (type === 'number') {
      value = value ? parseInt(value as string) : 0;
    }
    const newValues = { ...values };
    set(newValues, name, value);
    const parsedValues = parseValues(newValues);
    props.onChange && props.onChange(parsedValues, e);
    setValues(newValues);
  };

  const handleChangeValues = (
    path: string,
    changedValues: Record<string, any>,
    e?: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const newValues = { ...values };
    set(newValues, path, changedValues);
    setValues(newValues);
    const parsedValues = parseValues(newValues);
    props.onChange(parsedValues, e);
  };

  return (
    <div key={props.widgetId}>
      {Object.entries(config).map(([k, v]) => {
        const thisKey = `${ancestor ? `${ancestor}.` : ''}${k}`;
        const inputId = `widget-prop-${thisKey}`;
        const inputProps = {
          name: thisKey,
          key: thisKey,
          id: inputId,
          onChange: handleChange,
        };
        const fallbackValue = Array.isArray(v) ? v[0] : getValue(config, k);
        const value = getValue(widgetProps, k);
        let inputComponent: React.JSX.Element = null;
        const inputType = v.type || typeof v;
        if (inputType === 'string') {
          switch (v) {
            case 'wysiwyg':
              inputComponent = <WYSIWYG {...inputProps} value={value} />;
              break;
            case 'wysiwyg-minimal':
              inputComponent = <WYSIWYG {...inputProps} value={value} />;
              break;

            case 'html':
              inputComponent = (
                <CodeEditor
                  {...inputProps}
                  value={value}
                  language="html"
                  height="20rem"
                  size="mini"
                />
              );
              break;
            case 'json':
              inputComponent = (
                <CodeEditor
                  {...inputProps}
                  value={value || '{}'}
                  language="json"
                  height="20rem"
                  size="mini"
                />
              );
              break;

            default:
              inputComponent = (
                <input
                  {...inputProps}
                  pattern={v.pattern}
                  value={value || fallbackValue}
                />
              );
              break;
          }
        }
        if (inputType === 'color') {
          inputComponent = <ColorPicker {...inputProps} value={value} />;
        }
        if (inputType === 'number') {
          inputComponent = <input {...inputProps} value={value} type="number" />;
        }
        if (inputType === 'boolean') {
          inputComponent = <input {...inputProps} checked={value} type="checkbox" />;
        }
        if (Array.isArray(v)) {
          if (typeof v[0] === 'string' || typeof v[0] === 'number') {
            inputComponent = (
              <select {...inputProps} value={value || fallbackValue}>
                {v.map((value, index) => (
                  <option key={`${thisKey}-${value}-${index}`} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            );
          } else if (Object.keys(v).length > 0) {
            // object
            const obj = v[0];
            inputComponent = (
              <JsonFormBuilderArray
                widgetId={props.widgetId}
                config={obj}
                widgetProps={widgetProps}
                onChangeValues={handleChangeValues}
                ancestor={`${thisKey}`}
              />
            );
          }
        }

        return (
          inputComponent && (
            <div className={styles.widgetFormGroup} key={inputId}>
              <label htmlFor={inputId} className={styles.widgetFormLabel}>
                {v.label || k}
                {v.hint && (
                  <Tooltip content={v.hint} position="top">
                    <InfoIcon size={12} />
                  </Tooltip>
                )}
              </label>
              {inputComponent}
            </div>
          )
        );
      })}
    </div>
  );
};

import { getSizeAccType } from 'src/components/DesignSystem/Widgets2.0/Header/components/getSizeAccType';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { useGetDeviceType } from './useGetDeviceType';
import { useState } from 'react';
import { useNavConfigs } from './useNavConfigs';

export function useNavlinkStyling(props = {} as any) {
  const { isScrollable = false, parentHoverIndex = -1, isHeaderHovered = true } = props;
  const { deviceType } = useGetDeviceType();
  const isMobile = deviceType === 'mobile';
  const [hoverIndex, setHoverIndex] = useState(null);
  const { getWidgetSSRState } = useWidgetDndContextData();
  const { activeHeaderData } = getWidgetSSRState();

  const globalClass = activeHeaderData?.headerPropsConfig?.globalClass || {};

  const { currentClass } = useNavConfigs(isScrollable, isHeaderHovered);

  function getHoverTextColor() {
    const type = currentClass?.base_config?.defaulthoverType;
    switch (type) {
      case 'Underline':
        return currentClass?.base_config?.defaultUnderlineTextColor;
      case 'Background Box':
        return currentClass?.base_config?.defaultBoxTextColor;
      case 'None':
        return currentClass?.base_config?.defaultNavLinksColor;
    }
  }

  function getNavElementStyle(
    index: number = 0,
    parentIndex = -1,
    isHoverEnabled = true
  ) {
    const noParentSet = parentHoverIndex === -1;
    const isParentHovered = parentIndex === parentHoverIndex;
    const isHovered =
      isHoverEnabled && (noParentSet || isParentHovered) && hoverIndex === index;

    const textColor = isHovered
      ? getHoverTextColor()
      : currentClass?.base_config?.defaultNavLinksColor;

    return {
      fontSize: getSizeAccType(
        globalClass?.staticClass?.base_config?.defaultTextFontSize,
        isMobile
          ? globalClass?.staticClass?.base_config?.defaultFontSizeTypeMobile
          : globalClass?.staticClass?.base_config?.defaultFontSizeType
      ),
      fontWeight: isHovered
        ? currentClass?.base_config?.defaultTextFontWeightOnHover
        : globalClass?.staticClass?.base_config?.defaultTextFontWeight,
      color: textColor || '#000',
      borderColor: (textColor || '#111C36') + '1F',

      textTransform:
        globalClass?.staticClass?.base_config?.defaultTextDecoration || 'capitalize',
      ...(isHovered && currentClass?.base_config?.defaulthoverType === 'Background Box'
        ? {
            width: 'max-content',
            backgroundColor: currentClass?.base_config?.defaultBoxBackgroundColor,
          }
        : {}),
    };
  }

  const onLinkHoverEnter = (index) => {
    setHoverIndex(index);
  };

  const onLinkHoverLeave = () => {
    setHoverIndex(null);
  };

  return { onLinkHoverLeave, onLinkHoverEnter, getNavElementStyle, hoverIndex };
}

import { useState, useEffect } from 'react';
import { debounce } from 'lodash';

function getWindowDimensions() {
  if (typeof window !== 'undefined') {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
      isDesktop: width > 768,
    };
  } else {
    return {
      width: 500,
      height: 500,
      isDesktop: false,
    };
  }
}

function getDocumentDimensions() {
  if (typeof window !== 'undefined') {
    const { clientWidth: width, clientHeight: height } = document.documentElement;
    return {
      width,
      height,
      isDesktop: width > 768,
    };
  } else {
    return {
      width: 500,
      height: 500,
      isDesktop: false,
    };
  }
}

export default function useWindowDimensions(
  props = { useDocumentDimensions: false, debounceDuration: 300 }
) {
  const { useDocumentDimensions, debounceDuration } = props;
  const [windowDimensions, setWindowDimensions] = useState(
    useDocumentDimensions ? getDocumentDimensions() : getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(
        useDocumentDimensions ? getDocumentDimensions() : getWindowDimensions()
      );
    }

    const debounceResize = debounce(handleResize, debounceDuration);

    window.addEventListener('resize', debounceResize);
    return () => {
      window.removeEventListener('resize', debounceResize);
      debounceResize.cancel();
    };
  }, []);

  return windowDimensions;
}

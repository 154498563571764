import classNames from 'classnames';
import styles from './shopComponent.module.scss';
import { Option } from './DropdownOption';
import { useState } from 'react';
import NestedLinksPopper from './NestedLinks';
import router from 'next/router';
import { PATH, getRoute } from 'src/utils/routes';
import { isPLPRequiredSystem } from 'src/utils/isPLPRequiredSystem';
import { useSSRSelector } from 'src/redux';
import { useEffect } from 'react';
import { useNavlinkStyling } from 'src/hooks/useNavlinkStyling';

export const NavigationDropdown = ({
  isNested = false,
  top,
  optionsList,
  isHeaderHovered,
  setShowDropdownIndex = null,
  isScroll,
  ...props
}) => {
  const store = useSSRSelector((state) => state.storeReducer.store);

  const visibleOptionList = optionsList.filter((item) => item.showOnHeader);
  const { isShopComponent = false, categories = {} } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);

  function handleMouseAction(target, index) {
    setCurrentIndex(index);
    if (visibleOptionList?.[index]?.type === 'dropdown') {
      setAnchorEl(target);
    } else {
      anchorEl && setAnchorEl(null);
    }
  }

  const subNavItemsConfig = useNavlinkStyling({
    isScrollable: isScroll,
  });

  useEffect(() => {
    !isHeaderHovered && setShowDropdownIndex && setShowDropdownIndex(-1);
  }, [isHeaderHovered]);

  if (!optionsList?.length) {
    return null;
  }

  function handleDropdownNavClick(data) {
    if (isShopComponent) {
      const currentCategory = categories?.find((val) => val?.name === data?.name);
      if (
        typeof currentCategory?.id === 'number' ||
        typeof currentCategory?.id === 'string' ||
        typeof data?.id === 'number' ||
        typeof data?.id === 'string'
      ) {
        router.push(
          getRoute(
            `${
              isPLPRequiredSystem(store?.theme?.theme_class) ? PATH.SHOP : PATH.PRODUCT
            }?cid=${currentCategory?.id || data?.id}`,
            store?.store_info?.domain
          ),
          undefined,
          { shallow: true }
        );
      } else {
        data?.onClick();
      }
    } else {
      props?.onNavItemClick(data?.redirectionLink, data?.redirectNewPage);
    }
  }

  return (
    <>
      <div
        style={{
          top: `${top}px`,
          color: '#000',
          ...(props.dropDownStyle || {}),
          ...(isNested ? { position: 'static', transform: 'none' } : {}),
        }}
        className={classNames(
          props.wrapperClass,
          'tw-z-10 ',
          !props.fromMobileMenuDrawer
            ? classNames('tw-relative  ', styles['cfe-dropdown-nav'])
            : ' tw-overflow-y-auto tw-p-0 [&>div>p]:tw-text-[13px] [&>div>p]:tw-font-semibold [&>div>p]:tw-text-newBlack [&>div]:!tw-border-l-0 [&>div]:!tw-pl-0'
        )}
      >
        <div
          style={{ maxHeight: '360px' }}
          className={classNames(
            !props.fromMobileMenuDrawer
              ? classNames(styles['cfe-dropdown-nav__items'], 'tw-py-[20px]')
              : 'tw-mt-[22.5px]'
          )}
        >
          {visibleOptionList.map((option, idx, renderArray) => (
            <Option
              key={idx}
              option={option}
              idx={idx}
              renderArray={renderArray}
              isShopComponent={isShopComponent}
              customItemConfigs={props?.customItemConfigs}
              fromMobileMenuDrawer={props?.fromMobileMenuDrawer}
              handleMouseAction={(target) => handleMouseAction(target, idx)}
              isSubNavListActive={
                idx === currentIndex &&
                visibleOptionList?.[currentIndex]?.type === 'dropdown'
              }
              onNavItemClick={handleDropdownNavClick}
            />
          ))}
        </div>
        {!!anchorEl && visibleOptionList?.[currentIndex]?.option?.length ? (
          <NestedLinksPopper
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
              setCurrentIndex(-1);
            }}
          >
            <NavigationDropdown
              {...props}
              top={top}
              optionsList={visibleOptionList?.[currentIndex]?.option}
              isNested={true}
              isHeaderHovered={isHeaderHovered}
              customItemConfigs={subNavItemsConfig}
              isScroll={isScroll}
            />
          </NestedLinksPopper>
        ) : null}
      </div>
    </>
  );
};

export function WidgetOutlineHtmlIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clip-path="url(#clip0_1261_3337)">
        <path
          d="M7.05 12.45L3.6 9L7.05 5.55L6 4.5L1.5 9L6 13.5L7.05 12.45ZM10.95 12.45L14.4 9L10.95 5.55L12 4.5L16.5 9L12 13.5L10.95 12.45Z"
          fill="#222222"
        />
      </g>
      <defs>
        <clipPath id="clip0_1261_3337">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

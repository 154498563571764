export const IMAGE_DATA = {
    isVisible: true,
    imageUrl: ''
}

export const IMAGE_TYPE = [
    {
      text: 'All equal',
      value: 'equal',
    },
    {
        text: 'Original',
        value: 'original',
      },
  ];
import { getDefaultConfig } from 'src/components/WidgetMaker/utils/common';
import {
  CARD_APPEARANCE_DATA,
  HEADING_ALIGNMENT,
  HOVER_EFFECT_DROPDOWN,
  ICON_ALIGNMENT,
  ICON_POSITION,
  MULTI_COLUMN_ICON_DEFAULT_VALUE,
  TEXT_SIZE_DROPDOWN,
} from './MultiColumnStaticValues';
import {
  DESCRIPTION_POSITION,
  SUBSCRIPTION_NAMES,
  TEXT_CASE,
  TEXT_THICKNESS,
} from '@/constants/builderConstants';
import { getCardShare } from '../../components/RowItemImageText';

export const CARD_SHAPE_DROPDOWN = [
  {
    value: 'square',
    renderData: () => getCardShare('Square (1:1)', 'ic-square'),
  },
  {
    value: 'portraitTall',
    renderData: () => getCardShare('Portrait tall (2:3)', 'ic-PortraitTall'),
  },
  {
    value: 'portraitShort',
    renderData: () => getCardShare('Portrait short (3:4)', 'ic-PortraitShort'),
  },
  {
    value: 'landscape',
    renderData: () => getCardShare('Landscape (3:2)', 'ic-landscape'),
  },
  {
    value: 'landscapeWide',
    renderData: () => getCardShare('Landscape (4:3)', 'ic-landscape2'),
  },
  {
    value: 'circle',
    renderData: () => getCardShare('Circle (1:1)', 'ic-circle'),
  },
  {
    value: 'blob',
    renderData: () => getCardShare('Blob (1:1)', 'ic-blob'),
  },
  {
    value: 'arch',
    renderData: () => getCardShare('Arch (3:4)', 'ic-arch'),
  },
];

export const DEFAULT_MULTI_COLUMN_ICON_LAYOUT = {
  type: 'icon',
  heading: '<h2>Section title<h2>',
  headingCharCount: 0,
  description: '<p>Use this section to explain a set of product features.(Optional)<p>',
  descriptionCharCount: 0,
  descriptionPosition: 'below',
  textAlign: 'left',
  colors: {
    selectedColorSchemeIndex: 0,
    colorSchemes: [
      {
        background: '#ffffff',
        text: '#000000',
        isDefault: true,
      },
      {
        background: '#000000',
        text: '#ffffff',
        isDefault: false,
      },
    ],
  },
  numColumnsDesktop: 4,
  numColumnsMobile: 2,
  columnGap: 20,
  appearance: 'standard',
  backgroundColor: '#FFFFFF',
  borderColor: '#E2E4E7',
  mobilePaddingY: 40,
  paddingY: 70,
  marginTop: 0,
  marginBottom: 0,
};

export const DEFAULT_MULTI_COLUMN_ICON_CARD = {
  isShow: true,
  iconPosition: 'top',
  iconAlignment: 'top',
  iconSizeDesktop: 60,
  iconSizeMobile: 36,
  showTitle: true,
  showDescription: true,
  textAlignment: 'left',
  contentAlignment: 'left',
  titleSizeDesktop: 'large',
  titleSizeMobile: 'large',
  descriptionSizeDesktop: 'small',
  descriptionSizeMobile: 'small',
  titleTextCase: 'none',
  descriptionTextCase: 'none',
  titleColor: '#111C36',
  descriptionColor: '#555D70',
  titleThicness: 'medium',
  descriptionThicness: 'regular',
  buttonConfig: [
    getDefaultConfig(
      'productCardButton',
      '#FFFFFF',
      '#111C36',
      'Button',
      'textual',
      'widgetBtnUnderlineRightWrapper2',
      'Underline right',
      false
    ),
  ],
  multiColumnData: [
    {
      ...MULTI_COLUMN_ICON_DEFAULT_VALUE,
    },
    {
      ...MULTI_COLUMN_ICON_DEFAULT_VALUE,
    },
    {
      ...MULTI_COLUMN_ICON_DEFAULT_VALUE,
    },
    {
      ...MULTI_COLUMN_ICON_DEFAULT_VALUE,
    },
  ],
};

export const DEFAULT_MULTI_COLUMN_ICON_IMAGE = {
  isShow: true,
  imageShape: 'square',
  hoverType: 'zoomIn',
  showTitle: true,
  showDescription: true,
  textAlignment: 'left',
  contentAlignment: 'left',
  aspectRatio: '1:1',
  titleSizeDesktop: 'large',
  titleSizeMobile: 'medium',
  descriptionSizeDesktop: 'small',
  descriptionSizeMobile: 'small',
  titleTextCase: 'none',
  descriptionTextCase: 'none',
  titleColor: '#111C36',
  descriptionColor: '#555D70',
  titleThicness: 'medium',
  descriptionThicness: 'regular',
  buttonConfig: [
    getDefaultConfig('multiColumnButton', '#FFFFFF', '#111C36', 'Explore services'),
  ],
  multiColumnData: [
    {
      ...MULTI_COLUMN_ICON_DEFAULT_VALUE,
    },
    {
      ...MULTI_COLUMN_ICON_DEFAULT_VALUE,
    },
    {
      ...MULTI_COLUMN_ICON_DEFAULT_VALUE,
    },
    {
      ...MULTI_COLUMN_ICON_DEFAULT_VALUE,
    },
  ],
};

export const _MULTI_COLUMN_CONFIG = (isIconType) => {
  const allSubscriptions = [SUBSCRIPTION_NAMES.ELITE, SUBSCRIPTION_NAMES.PRO];
  if (isIconType) {
    allSubscriptions.push(SUBSCRIPTION_NAMES.ESSENTIAL);
  }
  return {
    sectionContent: {
      sectionHeading: 'Section content',
      description: '',
      tooltipContent: '',
      subscription: allSubscriptions,
      builder: [
        {
          forType: 'layout',
          componentLabel: 'Heading',
          element: 'inputEditor',
          accessKey: ['heading'],
          charCountKey: 'headingCharCount',
          placeholder: 'Enter title',
          maxLength: 100,
        },
        {
          forType: 'layout',
          componentLabel: 'Description',
          element: 'inputEditor',
          placeholder: 'Enter description',
          charCountKey: 'descriptionCharCount',
          accessKey: ['description'],
          textColor: '#808080',
          maxLength: 100,
        },
        {
          forType: 'layout',
          componentLabel: 'Description position',
          element: 'multiselect',
          accessKey: ['descriptionPosition'],
          componentData: DESCRIPTION_POSITION,
        },
        {
          forType: 'layout',
          componentLabel: 'Heading alignment',
          element: 'multiselect',
          accessKey: ['textAlign'],
          componentData: HEADING_ALIGNMENT,
        },
        {
          forType: 'layout',
          componentLabel: 'Section colour',
          editLabel: 'Edit colour',
          uniqueKey: 'selectionColors',
          element: 'colorsCombination',
          accessKey: ['colors'],
          subscription: [SUBSCRIPTION_NAMES.ELITE, SUBSCRIPTION_NAMES.PRO],
        },
      ],
    },
    layoutColumns: {
      sectionHeading: 'Layout',
      description: '',
      tooltipContent: '',
      subscription: [SUBSCRIPTION_NAMES.ELITE, SUBSCRIPTION_NAMES.PRO],
      builder: [
        {
          forType: 'layout',
          componentLabel: 'Number of columns desktop',
          element: 'slider',
          accessKey: ['numColumnsDesktop'],
          min: 2,
          max: 8,
          suffix: '',
          step: 1,
        },
        {
          forType: 'layout',
          componentLabel: 'Number of columns mobile',
          element: 'slider',
          accessKey: ['numColumnsMobile'],
          min: 1,
          max: 3,
          suffix: '',
          step: 1,
        },
        {
          forType: 'layout',
          componentLabel: 'Column gap',
          element: 'slider',
          accessKey: ['columnGap'],
          min: 0,
          max: 48,
          suffix: 'px',
          step: 1,
        },
        {
          forType: 'layout',
          componentLabel: 'Appearance',
          element: 'multiselect',
          accessKey: ['appearance'],
          componentData: CARD_APPEARANCE_DATA,
        },
        {
          element: 'colorDetails',
          componentLabel: 'Colours',
          forType: 'layout',
          conditionalRender: true,
          isVisible: (activeWidgetProps) =>
            activeWidgetProps?.listPresentation?.appearance === 'card',
          colorsData: [
            {
              forType: 'layout',
              text: 'backgroundColor',
              displayVal: 'Background colour',
              accessKey: ['backgroundColor'],
              shouldUpdateData: false,
              data: [],
            },
            {
              forType: 'layout',
              text: 'borderColor',
              displayVal: 'Border colour',
              accessKey: ['borderColor'],
              shouldUpdateData: false,
              data: [],
            },
          ],
        },
      ],
    },
    iconData: {
      sectionHeading: 'Icon',
      description: '',
      tooltipContent: '',
      isShow: true,
      subscription: allSubscriptions,
      builder: [
        {
          forType: 'card',
          componentLabel: 'Icon position',
          element: 'multiselect',
          accessKey: ['iconPosition'],
          componentData: ICON_POSITION,
        },
        {
          forType: 'card',
          componentLabel: 'Icon alignment',
          element: 'multiselect',
          renderConditional: true,
          isVisible: (activeWidgetProps) =>
            activeWidgetProps?.cardFrame?.iconPosition === 'left',
          accessKey: ['iconAlignment'],
          componentData: ICON_ALIGNMENT,
        },
        {
          forType: 'card',
          componentLabel: 'Icon size - Desktop',
          element: 'slider',
          accessKey: ['iconSizeDesktop'],
          subscription: [SUBSCRIPTION_NAMES.ELITE, SUBSCRIPTION_NAMES.PRO],
          min: 30,
          max: 120,
          suffix: 'px',
          step: 1,
        },
        {
          forType: 'card',
          componentLabel: 'Icon size - Mobile',
          element: 'slider',
          subscription: [SUBSCRIPTION_NAMES.ELITE, SUBSCRIPTION_NAMES.PRO],
          accessKey: ['iconSizeMobile'],
          min: 16,
          max: 100,
          suffix: 'px',
          step: 1,
        },
      ],
    },
    imageData: {
      sectionHeading: 'Image',
      description: '',
      tooltipContent: '',
      isShow: true,
      subscription: allSubscriptions,
      builder: [
        {
          forType: 'card',
          element: 'dropdown',
          componentLabel: 'Image shape',
          accessKey: ['imageShape'],
          dropdownData: CARD_SHAPE_DROPDOWN,
          customChildRender: true,
          placeholder: '',
        },
        {
          forType: 'card',
          element: 'dropdown',
          componentLabel: 'Image hover interaction',
          accessKey: ['hoverType'],
          dropdownData: HOVER_EFFECT_DROPDOWN,
          customChildRender: false,
          placeholder: '',
        },
      ],
    },
    textConfig: {
      sectionHeading: 'Text',
      description: '',
      tooltipContent: '',
      subscription: [SUBSCRIPTION_NAMES.ELITE, SUBSCRIPTION_NAMES.PRO],
      builder: [
        {
          forType: 'card',
          element: 'checkbox',
          componentLabel: 'Show title',
          accessKey: ['showTitle'],
        },
        {
          forType: 'card',
          element: 'checkbox',
          componentLabel: 'Show description',
          accessKey: ['showDescription'],
        },
        {
          forType: 'card',
          componentLabel: 'Text alignment',
          element: 'multiselect',
          accessKey: ['textAlignment'],
          componentData: HEADING_ALIGNMENT,
        },
        {
          forType: 'card',
          componentLabel: 'Content alignment',
          element: 'multiselect',
          accessKey: ['contentAlignment'],
          componentData: HEADING_ALIGNMENT,
        },
        {
          forType: 'card',
          element: 'dropdown',
          componentLabel: 'Title size - Desktop',
          accessKey: ['titleSizeDesktop'],
          dropdownData: TEXT_SIZE_DROPDOWN,
          customChildRender: false,
          placeholder: '',
        },
        {
          forType: 'card',
          element: 'dropdown',
          componentLabel: 'Title size - Mobile',
          accessKey: ['titleSizeMobile'],
          dropdownData: TEXT_SIZE_DROPDOWN,
          customChildRender: false,
          placeholder: '',
        },
        {
          forType: 'card',
          element: 'dropdown',
          componentLabel: 'Description size - Desktop',
          accessKey: ['descriptionSizeDesktop'],
          dropdownData: TEXT_SIZE_DROPDOWN,
          customChildRender: false,
          placeholder: '',
        },
        {
          forType: 'card',
          element: 'dropdown',
          componentLabel: 'Description size - Mobile',
          accessKey: ['descriptionSizeMobile'],
          dropdownData: TEXT_SIZE_DROPDOWN,
          customChildRender: false,
          placeholder: '',
        },
        {
          componentLabel: 'Advanced settings',
          componentSubLabel: 'Customise Text casing, content thickness, colour.',
          element: 'customModal',
          modalHeading: 'Advance settings',
          componentData: [
            {
              forType: 'card',
              componentLabel: 'Title text case',
              element: 'multiselect',
              accessKey: ['titleTextCase'],
              componentData: TEXT_CASE,
            },
            {
              forType: 'card',
              componentLabel: 'Description text case',
              element: 'multiselect',
              accessKey: ['descriptionTextCase'],
              componentData: TEXT_CASE,
            },
            {
              element: 'separator',
            },
            {
              element: 'colorDetails',
              componentLabel: 'Colours',
              forType: 'card',
              colorsData: [
                {
                  forType: 'card',
                  text: 'titleColor',
                  displayVal: 'Title',
                  accessKey: ['titleColor'],
                  shouldUpdateData: false,
                  data: [],
                },
                {
                  forType: 'card',
                  text: 'descriptionColor',
                  displayVal: 'Description',
                  accessKey: ['descriptionColor'],
                  shouldUpdateData: false,
                  data: [],
                },
              ],
            },
            {
              element: 'separator',
            },
            {
              forType: 'card',
              element: 'dropdown',
              componentLabel: 'Title thickness',
              accessKey: ['titleThicness'],
              dropdownData: TEXT_THICKNESS,
              customChildRender: false,
              placeholder: '',
            },
            {
              forType: 'card',
              element: 'dropdown',
              componentLabel: 'Description thickness',
              accessKey: ['descriptionThicness'],
              dropdownData: TEXT_THICKNESS,
              customChildRender: false,
              placeholder: '',
            },
          ],
        },
      ],
    },
    multiColumnButton: {
      subscription: [SUBSCRIPTION_NAMES.ELITE],
      builder: [
        {
          forType: 'card',
          element: 'button',
          showColorCombination: true,
          keepSameColor: false,
          showRedirectionField: false,
          showRedirectionMsg: true,
          showDeleteIcon: false,
          accessKey: ['buttonConfig'],
          subscription: [SUBSCRIPTION_NAMES.ELITE],
        },
      ],
    },
    sectionSetting: {
      sectionHeading: 'Section settings',
      subscription: [SUBSCRIPTION_NAMES.ELITE, SUBSCRIPTION_NAMES.PRO],
      builder: [
        {
          forType: 'layout',
          componentLabel: 'Desktop padding top and bottom',
          element: 'slider',
          accessKey: ['paddingY'],
          min: 0,
          max: 150,
          suffix: 'px',
          step: 1,
        },
        {
          forType: 'layout',
          componentLabel: 'Mobile padding top and bottom',
          element: 'slider',
          accessKey: ['mobilePaddingY'],
          min: 0,
          max: 100,
          suffix: 'px',
          step: 1,
        },
        {
          forType: 'layout',
          componentLabel: 'Margin top',
          element: 'slider',
          accessKey: ['marginTop'],
          min: 0,
          max: 100,
          suffix: 'px',
          step: 1,
        },
        {
          forType: 'layout',
          componentLabel: 'Margin bottom',
          element: 'slider',
          accessKey: ['marginBottom'],
          min: 0,
          max: 100,
          suffix: 'px',
          step: 1,
        },
      ],
    },
  };
};

export const MULTICOLUMN_WIDGET_LIST_CONFIG = {
  config: {
    keyName: 'multiColumnData',
    defaultData: MULTI_COLUMN_ICON_DEFAULT_VALUE,
    subWidgetType: 'multiColumn',
    title: 'Multicolumn',
    addBtnTitle: 'Add column',
    accessKeyPath: 'cardFrame.multiColumnData',
  },
  childData: {
    srcKey: '',
    thumbnailkey: '',
    inlineTitleKey: 'title',
  },
};

import { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';
import styles from './colorPicker.module.scss';
import { Popover } from '@mui/material';
import { Button } from '@dotpe/kui';
import cn from 'classnames';

interface ColorPickerProps {
  name: string;
  id?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  customStyle?: Record<string, string>;
  onColorSubmit?: (e: ChangeEvent<HTMLInputElement>, hexValue?: string) => void;
  showColorPicker?: boolean;
  setShowColorPicker?: (val: boolean) => void;
  children?: any;
}
export const ColorPicker: FunctionComponent<ColorPickerProps> = (props) => {
  const { customStyle, showColorPicker, setShowColorPicker } = props;
  const [value, setValue] = useState(props.value);
  const [background, setBackground] = useState(props.value);
  const [openPicker, setOpenPicker] = useState(false);
  const targetRef = useRef<HTMLInputElement>(null);
  const colorPreviewRef = useRef<HTMLDivElement>(null);
  const { valueToHex } = useColorPicker(background, setBackground);

  useEffect(() => {
    if (showColorPicker) setOpenPicker(showColorPicker);
  }, [showColorPicker]);

  useEffect(() => {
    let e = new Event('change');
    e = { ...e, target: targetRef.current };
    props.onChange && props.onChange(e as unknown as ChangeEvent<HTMLInputElement>);
  }, [value]);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handlePickerToggle = () => {
    setOpenPicker(!openPicker);
    setShowColorPicker?.(!openPicker);
  };

  const handleSave = () => {
    const hexString = valueToHex();
    let e = new Event('change');
    e = { ...e, target: targetRef.current };
    props?.onColorSubmit(e as unknown as ChangeEvent<HTMLInputElement>, hexString);
    setValue(background);
    handlePickerToggle();
  };

  return (
    <div className="colorPickerContainer" id={props.id}>
      <input hidden name={props.name} value={value} ref={targetRef} readOnly />
      <div
        className={cn(styles.backgroundPreview, 'jsonFormBuilder-input')}
        style={{ background: value, ...customStyle, position: 'relative' }}
        onClick={handlePickerToggle}
        ref={colorPreviewRef}
      >
        {props.children}
      </div>

      <Popover
        classes={{
          paper: styles.colorPickerPopover,
        }}
        open={openPicker}
        anchorEl={colorPreviewRef.current}
        onClose={handlePickerToggle}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <ReactColorPicker
          style={{ maxWidth: '400px', overflow: 'scroll' }}
          hideControls={false}
          value={background}
          className="test"
          onChange={setBackground}
        />
        <Button variant="primary" size="s" onClick={handleSave}>
          Select
        </Button>
      </Popover>
    </div>
  );
};

import { IS_SERVER } from './checkRenderEnv';

const TRIGGER_KEY = 'triggerPurchaseConversionEvent';

export const setPurchaseTrigger = () => {
  !IS_SERVER && localStorage.setItem(TRIGGER_KEY, true);
};

export const shouldTrrigerPurchaseEvent = () => {
  return !IS_SERVER && !!JSON.parse(localStorage.getItem(TRIGGER_KEY));
};

export const unsetPurchaseTrigger = () => {
  localStorage.removeItem(TRIGGER_KEY);
};

/**
 * function below here are used to add or remove purchase data to dataLayer
 * which can be used to track purcahse event.
 * dataLayer - an object used by Google Tag Manager and gtag.js to pass information to tags
 */

export function removeExistingPurchaseEventFromDataLayer(orderId) {
  if (!IS_SERVER && orderId) {
    window.dataLayer = window.dataLayer || [];
    const purchaseEventIndex = window?.dataLayer?.find((el) => el.event === 'purchase');
    if (purchaseEventIndex !== -1) {
      window.dataLayer.splice(purchaseEventIndex, 1);
    }
  }
}

export function pushPurchaseEventToDataLayer(data, orderId) {
  if (!IS_SERVER && orderId && window.dataLayer) {
    removeExistingPurchaseEventFromDataLayer(orderId);
    window.dataLayer.push(data);
  }
}

import {
  SAVE_PAYMENT_METHODS,
  SAVE_PAYMENT_INFORMATIONS,
  SAVE_POST_PAYMENT_DATA,
  SAVE_AVAILABLE_PAYMENT_METHODS,
  SET_CUSTOMER_SELECTED_PAYMENT_OPTION,
} from '../actionTypes';

const initialState = {
  methods: [],
  availablePaymentMethods: [],
  downPaymentMethods: [],
  otherMethods: [],
  paymentInfo: {},
  postPayment: {},
  selectedPaymentOptionForMobilePayment: {},
  min_emi_amount: null,
  partial_payment_amount: null,
};

const PaymentMethodsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_PAYMENT_METHODS:
      return {
        ...state,
        methods: action.data.payment_modes || [],
        downPaymentMethods: action.data.down_payment_modes || [],
        unselectablePaymentOptions: action.data?.grayed_out_payment_options || [],
        otherMethods: action.data.others || [],
        paymentOptionsWithTxnCharge: action.data.payment_options_with_txn_charge || {},
        min_emi_amount: action.data.min_emi_amount ?? null,
        partial_payment_amount: action.data.partial_payment_amount || null,
      };
    case SAVE_PAYMENT_INFORMATIONS:
      return { ...state, paymentInfo: action.data };
    case SAVE_POST_PAYMENT_DATA:
      return { ...state, postPayment: action.data };
    case SAVE_AVAILABLE_PAYMENT_METHODS:
      return {
        ...state,
        availablePaymentMethods: action.data,
      };
    case SET_CUSTOMER_SELECTED_PAYMENT_OPTION:
      return {
        ...state,
        selectedPaymentOptionForMobilePayment: action.data,
      };
    default:
      return state;
  }
};

export default PaymentMethodsReducer;

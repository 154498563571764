export const isPLPRequiredSystem = (themeVar) => {
  switch (themeVar) {
    case 1:
      return true;
    case 2:
      return true;
    case 3:
      return false;
    case 4:
      return false;
    default:
      return true;
  }
};

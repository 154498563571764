/**
 * @author Rahul Gahalaut
 * we are getting owner's phone number and email address in encrypted form from now on.
 * This util function would help us to decrypt that data, by using the SECRET_KEY( DECRYPTION_KEY here) porvided by Backend team.
 */
import config from 'src/config';
import crypto from 'crypto';

const unpad = (buffer) => {
  const paddingLength = buffer[buffer.length - 1];
  return buffer.slice(0, buffer.length - paddingLength);
};

const generateSha512Hash = (secretKey) => {
  const hash = crypto.createHash('sha512').update(secretKey).digest('hex');
  return hash.slice(0, 16); // First 16 characters of the hash
};

export const getDecryptedContactInfo = (encryptedInfo) => {
  const secretKey = config.DECRYPTION_KEY;
  const [enc, iv] = encryptedInfo.split(':');
  const encBuffer = Buffer.from(enc, 'base64');
  const ivBuffer = Buffer.from(iv, 'base64');

  // Generate 16-byte key using SHA-512
  const key = generateSha512Hash(secretKey);

  // Create decipher
  const decipher = crypto.createDecipheriv('aes-128-cbc', key, ivBuffer);
  decipher.setAutoPadding(false); // Disable automatic padding removal

  let decrypted = decipher.update(encBuffer);
  decrypted = Buffer.concat([decrypted, decipher.final()]);

  // Remove padding manually
  return unpad(decrypted).toString();
};

/**
 * given function is used to disable IP address enabled domains from accessing
 * the underlying APIs. This is a work in progress, created to
 * understand why IP addresses are being part of getStoreBySubDomain
 * in form of request host.
 * if IP address exist in host we stop the server calls all together
 * @param {string} hostName
 * @return {boolean}
 */

const IP_REGEX =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gm;

export const checkForIpAddress = (hostName) => {
  if (hostName) {
    hostName = hostName.split('/')[0];
    if (hostName.includes(':')) {
      hostName = hostName.split(':')[0];
    }
    return hostName.match(IP_REGEX) || false;
  }
  return false;
};

import { takeEvery, put } from 'redux-saga/effects';
import { ENDPOINT } from '../../config/endpoints';
import apiCall from '../../services/api';
import { GET_CART_BY_ID, SET_CART, VALIDATE_CART, SET_B2B_CART } from '../actionTypes';
import {
  setNotAcceptingOrdersModalVisibility,
  saveCartValidationData,
  saveCart,
  toggleSetCartLoader,
  updateCartId,
  saveCreateList,
  setGlobalLoader,
  togglePromoRemovalAlertModal,
  saveLatestSyncedCart,
  // getCartById,
} from './../actions';
import { getCartItemsWithGst } from '@/utils/getCartItemsWithGst';
import { setModifiedDeliveryInfo } from '@/utils/setModifiedDeliveryInfo';
import { getCurrentOrderType } from '@/utils/getCurrentOrderType';
import { validateItemProperties } from '@/utils/validateItemProperties';
import { cartItemsSplit } from '@/utils/cartItemsSplit';
import { returnUniqueValuesFromArray } from '@/utils/returnUniqueValuesFromArray';
import { getTotalCartAmounts } from '@/utils/getTotalCartAmounts';
import store from './../store';
import { toastNotifyError } from 'src/components/Common/Toast';

function* validateCart(actions) {
  try {
    const { items } = validateItemProperties(actions.data.items);
    actions.data.items = items;
    const payload = Object.assign({}, actions.data);
    const userData = store.getState().userReducer;
    payload['user_phone'] = userData.data.phone;
    payload['phone'] = userData.data.phone;
    payload['user_email'] = userData.data.email;
    payload['email'] = userData.data.email;
    payload['user_id'] = userData.data.user_id;
    const cartResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.CART.VALIDATE_CART,
      data: payload,
    });
    if (cartResponse && cartResponse.data.status) {
      /** Check if data is modified */
      if (cartResponse.data.is_cart_modified) {
        yield put(saveCartValidationData(cartResponse.data));
        /** Now if cart in modified So => Update cart */
        const cartData = store.getState().cartReducer;

        /** append id to the cart items */
        const newCartItems = cartResponse?.data?.items?.map((el) => {
          el.id = el.item_id;

          /** check variant selected is present, if present than add variant count in item */
          if (el.variants_selected && Array.isArray(el.variants_selected)) {
            el.variants_count = el?.variants_selected?.length;
          }

          return el;
        });

        // Calculate new pay_amount amount
        const amount_values = getTotalCartAmounts(cartResponse?.data?.items);
        yield put(
          saveCart({
            ...cartData,
            items: newCartItems || [],
            pay_amount: amount_values.pay_amount,
            amount: amount_values.amount,
          })
        );
      } else if (cartResponse.data.is_store_closed) {
        /** Check is the store is closed */
        yield put(setNotAcceptingOrdersModalVisibility(true));
      }
      actions.callback && actions.callback(cartResponse.data);
    }
  } catch (err) {
    console.log(err);
  }
}

function* setCart(actions) {
  try {
    yield put(setGlobalLoader(true));
    yield put(toggleSetCartLoader(true));
    const payload = Object.assign({}, actions.data);
    const userData = store.getState().userReducer;
    const latestSyncedCart = store.getState().commonReducer?.latestSyncedCart;

    const { createList: staleIncompleteListItems } = store.getState().commonReducer;
    const { services } = store.getState().storeReducer.store;
    payload['user_phone'] = userData.data.phone;
    payload['phone'] = userData.data.phone;
    payload['user_email'] = userData.data.email;
    payload['email'] = userData.data.email;
    payload['user_id'] = userData.data.user_id;
    payload['order_type'] = getCurrentOrderType(payload.order_type);

    const { inCompleteItems, completeItems, catalogItems } = cartItemsSplit(
      payload.items
    );

    // store incompatible data into savecreatelist
    yield put(
      saveCreateList(
        returnUniqueValuesFromArray([...staleIncompleteListItems, ...inCompleteItems])
      )
    );
    yield put(saveCart({ items: [...catalogItems, ...completeItems] }));
    payload.items = [...catalogItems, ...completeItems];

    /**
     * if mdr_flag is 1 remove buyer_pay_value from payload
     * This is done to avoid considering buyer_pay_value as the final amount
     * if it is sent as payload in setOrderCart api.
     * This condition is only for mdr_flag === 1
     * Additionally, buyer_pay_value is manipulated in createNewOrder, and made equal to the
     * pay_amount of the selected payment method.
     */
    if (services?.mdr_flag && payload?.buyer_pay_value) delete payload.buyer_pay_value;

    payload.items = getCartItemsWithGst(payload.items);
    payload.delivery_info = setModifiedDeliveryInfo(payload);

    if (!payload?.store_id || !payload || !Object.keys(payload).length) return;
    const cartResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.CART.SET_CART,
      data: payload,
      parseToJson: true,
    });
    if (cartResponse && cartResponse.data.status) {
      // is_coupon_removed - would tell us if an earlier applied coupon has been removed.
      if (
        !cartResponse.data.data?.force_update_cart?.is_coupon_removed ||
        !latestSyncedCart
      ) {
        if (!payload.cart_id || payload.cart_id !== cartResponse.data.data.cart_id)
          yield put(updateCartId(cartResponse.data.data.cart_id));
        actions.callback
          ? actions.callback(cartResponse.data.data)
          : yield put(saveCart(cartResponse.data.data));
        yield put(saveLatestSyncedCart(cartResponse.data.data));
        yield put(
          togglePromoRemovalAlertModal({
            show: false,
            cartDataAfterOfferRemoval: null,
          })
        );
      } else {
        yield put(
          togglePromoRemovalAlertModal({
            show: true,
            cartDataAfterOfferRemoval: cartResponse.data.data,
          })
        );
      }
    }
  } catch (err) {
    toastNotifyError(err?.data?.message || 'Something went wrong!');
  } finally {
    yield put(toggleSetCartLoader(false));
    yield put(setGlobalLoader(false));
  }
}

function* fetchCartById(actions) {
  try {
    const cartResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.CART.getCartById(actions.data),
      parseToJson: true,
    });
    if (cartResponse && cartResponse.data.status) {
      yield put(saveCart(cartResponse.data.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* setB2bCart(actions) {
  try {
    const payload = Object.assign({}, actions.data);
    const cartResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.CART.SET_CART,
      data: payload,
      parseToJson: true,
    });
    if (cartResponse && cartResponse.data.status) {
      if (!payload.cart_id || payload.cart_id !== cartResponse.data.data.cart_id)
        yield put(updateCartId(cartResponse.data.data.cart_id));

      actions.callback
        ? actions.callback(cartResponse.data.data)
        : yield put(saveCart(cartResponse.data.data));
    }
  } catch (err) {
    console.log(err);
  } finally {
    yield put(toggleSetCartLoader(false));
  }
}

export default function* root() {
  yield takeEvery(VALIDATE_CART, validateCart);
  yield takeEvery(SET_CART, setCart);
  yield takeEvery(SET_B2B_CART, setB2bCart);
  yield takeEvery(GET_CART_BY_ID, fetchCartById);
}

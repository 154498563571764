import { Dispatch } from 'react';
import { IWidgetDndInitialState, IWidgetDndActions, TypeSetWidgets } from '../types';
import { WIDGET_DND_ACTION_TYPES } from './actionTypes';
import { ISnackBarConfig } from 'src/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Snackbar/Snackbar';

export const INITIAL_WIDGET_DND_CONTEXT_ACTIONS: IWidgetDndActions = {
  setWidgets: () => {},
  setActiveWidget: () => {},
  setHasUnsavedChanges: () => {},
  setSidePanelState: () => {},
  setIndexToAddWidgetAtFromMainSection: () => {},
  setActiveHeaderData: () => {},
  setActiveFooterData: () => {},
  setIsHeaderFooterPropertyActive: () => {},
  setWidgetDeleteModalOpen: () => {},
  setWidgetIdToDelete: () => {},
  setShowPreview: () => {},
  setOpenPostPublishModal: () => {},
  setActiveItem: () => {},
  togglePreviewDevice: () => {},
  setWidgetSettingsSectionState: () => {},
  footerWidgetSection: () => {},
  setBuilderFlag: () => {},
  setGlobalSetting: () => {},
  setGlobalStyle: () => {},
  setCustomTag: () => {},
  setVariantData: () => {},
  setReelsOutlineData: () => {},
  updateMySubscriptionType: () => {},
  setSnackbarConfig: () => {},
  setSelectedWidgetSessionState: () => {},
};

interface IWidgetContextActions {
  widgetContextState: IWidgetDndInitialState;
  widgetContextDispatch: Dispatch<any>;
  setWidgetSSRState: any;
}

function generatePayload<X = any, Y = any>(state: X, parameter: Y) {
  let payload = null;
  if (typeof parameter === 'function') {
    payload = parameter(state);
  } else {
    payload = parameter;
  }

  return payload;
}

export const useWidgetDndContextActions = (
  argues: IWidgetContextActions
): IWidgetDndActions => {
  const { widgetContextState, widgetContextDispatch } = argues;

  function setWidgets(parameter: TypeSetWidgets) {
    const payload = generatePayload(widgetContextState.widgets, parameter);

    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.UPDATE_WIDGETS,
      payload,
    });
  }

  function setSnackbarConfig(parameter: ISnackBarConfig) {
    const payload = generatePayload(widgetContextState.widgets, parameter);

    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_SNACKBAR_CONFIG,
      payload,
    });
  }

  function setActiveWidget(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_ACTIVE_WIDGET,
      payload,
    });
  }

  function setBuilderFlag(payload: boolean) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_BUILDER_FLAG,
      payload,
    });
  }

  function setWidgetSettingsSectionState(parameter: TypeSetWidgets) {
    const payload = generatePayload(
      widgetContextState.widgetSettingsSectionState,
      parameter
    );
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_WIDGET_SETTINGS_SECTION_STATE,
      payload,
    });
  }

  function setHasUnsavedChanges(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.PAGE_UPDATE_STATUS,
      payload,
    });
  }

  function footerWidgetSection(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.FOOTER_WIDGET_SECTION,
      payload,
    });
  }

  function setSidePanelState(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.UPDATE_SIDE_PANEL_STATE,
      payload,
    });
  }

  function setIndexToAddWidgetAtFromMainSection(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.ADD_WIDGET_INDEX,
      payload,
    });
  }

  function setActiveHeaderData(parameter) {
    const payload = generatePayload(widgetContextState.activeHeaderData, parameter);
    argues.setWidgetSSRState((state) => {
      const newState = { ...state, activeHeaderData: payload };
      return newState;
    });

    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_ACTIVE_HEADER_DATA,
      payload,
    });
  }

  function setActiveFooterData(parameter) {
    const payload = generatePayload(widgetContextState.activeFooterData, parameter);
    argues.setWidgetSSRState((state) => {
      const newState = { ...state, activeFooterData: payload };
      return newState;
    });

    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_ACTIVE_FOOTER_DATA,
      payload,
    });
  }

  function setIsHeaderFooterPropertyActive(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_HEADER_FOOTER_PROPERTY_ACTIVE_STATUS,
      payload,
    });
  }

  function setWidgetDeleteModalOpen(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_WIDGET_DELETE_MODAL_STATUS,
      payload,
    });
  }

  function setWidgetIdToDelete(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_WIDGET_ID_TO_DELETE,
      payload,
    });
  }

  function setShowPreview(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_SHOW_PREVIEW,
      payload,
    });
  }

  function togglePreviewDevice(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.TOGGLE_PREVIEW_DEVICE,
      payload,
    });
  }

  function setOpenPostPublishModal(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_POST_PUBLISH_MODAL_STATUS,
      payload,
    });
  }

  function setActiveItem(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_ACTIVE_ITEM,
      payload,
    });
  }

  function setGlobalSetting(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_GLOBAL_SETTING,
      payload,
    });
  }

  function setGlobalStyle(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_GLOBAL_STYLE,
      payload,
    });
  }

  function setCustomTag(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_CUSTOM_TAG,
      payload,
    });
  }

  function setVariantData(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_VARIANT_DATA,
      payload,
    });
  }

  function setReelsOutlineData(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_REELS_OUTLINE_DATA,
      payload,
    });
  }

  function updateMySubscriptionType(updatedSubscription) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_MY_SUBSCRIPTION_TYPE,
      payload: updatedSubscription,
    });
  }

  function setSelectedWidgetSessionState(payload) {
    widgetContextDispatch({
      type: WIDGET_DND_ACTION_TYPES.SET_SELECTED_WIDGET_SESSION_STATE,
      payload,
    });
  }

  return {
    setWidgets,
    setActiveWidget,
    setHasUnsavedChanges,
    setSidePanelState,
    setIndexToAddWidgetAtFromMainSection,
    setActiveHeaderData,
    setActiveFooterData,
    setIsHeaderFooterPropertyActive,
    setWidgetDeleteModalOpen,
    setWidgetIdToDelete,
    setShowPreview,
    setOpenPostPublishModal,
    setActiveItem,
    togglePreviewDevice,
    setWidgetSettingsSectionState,
    setGlobalSetting,
    setGlobalStyle,
    footerWidgetSection,
    setBuilderFlag,
    setCustomTag,
    setSnackbarConfig,
    setVariantData,
    setReelsOutlineData,
    updateMySubscriptionType,
    setSelectedWidgetSessionState,
  };
};

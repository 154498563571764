export const CTAIconCart = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '16'}
    height={props.height || '16'}
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill={props.iconColor || '#000'}
      d="M1 1.467A.467.467 0 0 1 1.467 1h1.4a.47.47 0 0 1 .452.354l.378 1.513h10.837a.466.466 0 0 1 .457.558l-.933 4.666a.47.47 0 0 1-.434.375l-8.771.44.268 1.427h8.013a.467.467 0 0 1 0 .934h-8.4a.466.466 0 0 1-.459-.381L2.876 3.433l-.373-1.5H1.467A.467.467 0 0 1 1 1.467M3.895 3.8l.784 4.18 8.535-.428.75-3.752zm1.772 7.467a1.867 1.867 0 1 0 0 3.733 1.867 1.867 0 0 0 0-3.733m6.533 0a1.866 1.866 0 1 0 0 3.732 1.866 1.866 0 0 0 0-3.732m-6.533.933a.933.933 0 1 1 0 1.866.933.933 0 0 1 0-1.866m6.533 0a.933.933 0 1 1 0 1.866.933.933 0 0 1 0-1.866"
    ></path>
  </svg>
);

export const CTAIconPlus = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '17'}
    height={props.height || '17'}
    fill="none"
    viewBox="0 0 17 17"
  >
    <path fill="transparent" d="M.5.5h16v16H.5z"></path>
    <path
      fill={props.iconColor || '#111C36'}
      d="M9.249 7.751V2.5H7.75v5.251H2.5V9.25h5.251V14.5H9.25V9.249H14.5V7.75z"
    ></path>
  </svg>
);

export const CTAIconBag = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '17'}
    height={props.height || '17'}
    fill="none"
    viewBox="0 0 17 17"
  >
    <path
      stroke={props.iconColor || '#111C36'}
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m3.1 4.9 1.8-2.4h7.2l1.8 2.4m-10.8 0v8.4a1.2 1.2 0 0 0 1.2 1.2h8.4a1.2 1.2 0 0 0 1.2-1.2V4.9m-10.8 0h10.8m-3 2.4a2.4 2.4 0 0 1-4.8 0"
    ></path>
  </svg>
);

export const DiscountTagCircle = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '48'}
    height={props.height || '48'}
    fill="none"
    viewBox="0 0 48 48"
  >
    <path
      fill={props.bgColor || '#2CA861'}
      d="M15.973 2.59a4.77 4.77 0 0 0 4.274-1.146A5.59 5.59 0 0 1 24 0a5.58 5.58 0 0 1 3.753 1.445 4.77 4.77 0 0 0 4.274 1.145A5.58 5.58 0 0 1 36 3.215a5.58 5.58 0 0 1 2.528 3.128 4.77 4.77 0 0 0 3.13 3.129A5.58 5.58 0 0 1 44.784 12a5.58 5.58 0 0 1 .625 3.973 4.77 4.77 0 0 0 1.146 4.273A5.59 5.59 0 0 1 48 24c0 1.443-.547 2.76-1.445 3.753a4.77 4.77 0 0 0-1.145 4.274A5.58 5.58 0 0 1 44.785 36a5.58 5.58 0 0 1-3.128 2.527 4.77 4.77 0 0 0-3.129 3.13A5.58 5.58 0 0 1 36 44.785a5.58 5.58 0 0 1-3.973.625 4.77 4.77 0 0 0-4.274 1.145A5.58 5.58 0 0 1 24 48a5.58 5.58 0 0 1-3.753-1.445 4.77 4.77 0 0 0-4.274-1.145A5.58 5.58 0 0 1 12 44.785a5.58 5.58 0 0 1-2.527-3.128 4.77 4.77 0 0 0-3.13-3.129A5.58 5.58 0 0 1 3.215 36a5.58 5.58 0 0 1-.625-3.973 4.76 4.76 0 0 0-1.146-4.273A5.59 5.59 0 0 1 0 24c0-1.443.547-2.76 1.445-3.753a4.77 4.77 0 0 0 1.145-4.274A5.58 5.58 0 0 1 3.215 12a5.58 5.58 0 0 1 3.128-2.528 4.77 4.77 0 0 0 3.129-3.129A5.58 5.58 0 0 1 12 3.215a5.58 5.58 0 0 1 3.973-.625"
    ></path>
  </svg>
);

export const DiscountTagSquare = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '40'}
    height={props.height || '48'}
    fill="none"
    viewBox="0 0 40 48"
  >
    <path
      fill={props.bgColor || '#2CA861'}
      fillRule="evenodd"
      d="M40 0H0v47.871l2.73-2.738L5.58 48l2.867-2.867L11.306 48l2.85-2.867L17.021 48l2.85-2.867L22.739 48l2.858-2.867L28.464 48l2.85-2.867L34.181 48l2.858-2.867L39.906 48l.094-.095V0"
      clipRule="evenodd"
    ></path>
  </svg>
);

export const DiscountTagReverseArch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="48"
    fill="none"
    viewBox="0 0 36 48"
  >
    <path fill={props.bgColor || '#2CA861'} d="M36 0v48l-18.004-4.288L0 48V0z"></path>
  </svg>
);

export const DiscountTagMobileReverseArch = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '32'}
    height={props.height || '36'}
    fill="none"
    viewBox="0 0 32 36"
  >
    <path fill={props.bgColor || '#2CA861'} d="M32 0v36l-16-6-16 6V0z"></path>
  </svg>
);

import React from 'react';
import {
  CTAIconBag,
  CTAIconCart,
  CTAIconPlus,
  DiscountTagCircle,
  DiscountTagMobileReverseArch,
  DiscountTagReverseArch,
  DiscountTagSquare,
} from './assets/svgs';
import { hexToRgba } from '../../ModernTheme/utils/common';

export const getProductDetailsWrapperStyles = (config) => {
  const style = {};
  let className = '';

  switch (config?.contentAlignment) {
    case 'left':
      className += ' tw-items-start tw-text-left';
      break;
    case 'center':
      className += ' tw-items-center tw-text-center';
      break;
    case 'right':
      className += ' tw-items-end tw-text-right';
      break;
  }
  return { style, className };
};

export function getTextThickness(thicknessText) {
  let className = '';
  switch (thicknessText) {
    case 'thin':
      className += ' tw-font-light';
      break;
    case 'regular':
      className += ' tw-font-regular';
      break;
    case 'medium':
      className += ' tw-font-medium';
      break;
    case 'bold':
      className += ' tw-font-semibold';
      break;
    default:
      className += ' tw-font-regular';
      break;
  }
  return { className };
}

export const getProductNameStyles = (config) => {
  const style: React.CSSProperties = {};
  let className = '';

  switch (config?.productNameNumOfLine) {
    case 'one':
      className += ' tw-line-clamp-1';
      break;
    case 'two':
      className += ' tw-line-clamp-2';
      break;
  }

  switch (config?.productNameSize) {
    case 'extraSmall':
      style.fontSize = '12px';
      // style.lineHeight = '1';
      break;
    case 'small':
      style.fontSize = '14px';
      // style.lineHeight = '1';
      break;
    case 'medium':
      style.fontSize = '16px';
      // style.lineHeight = '1.2';
      break;
    case 'large':
      style.fontSize = '18px';
      // style.lineHeight = '1.2';
      break;
    case 'extraLarge':
      style.fontSize = '20px';
      // style.lineHeight = '1.2';
      break;
    default:
      style.fontSize = '16px';
      // style.lineHeight = '1.1';
      break;
  }

  const thicknessStyles = getTextThickness(config?.productNameThicness);
  className += thicknessStyles.className;

  return { style, className };
};

export const getProductDescriptionStyles = (config, isMobile) => {
  const style: React.CSSProperties = {};
  let className = '';

  switch (
    config?.[
      !isMobile
        ? 'productdescriptionDesktopNumOfLines'
        : 'productdescriptionMobileNumOfLines'
    ]
  ) {
    case 'one':
      className += ' tw-line-clamp-1';
      break;
    case 'two':
      className += ' tw-line-clamp-2';
      break;
  }

  switch (config?.productDescriptionSize) {
    case 'extraSmall':
      style.fontSize = '12px';
      // style.lineHeight = '1';
      break;
    case 'small':
      style.fontSize = '14px';
      // style.lineHeight = '1';
      break;
    case 'medium':
      style.fontSize = '16px';
      // style.lineHeight = '1.2';
      break;
    case 'large':
      style.fontSize = '18px';
      // style.lineHeight = '1.2';
      break;
    case 'extraLarge':
      style.fontSize = '20px';
      // style.lineHeight = '1.2';
      break;
    default:
      style.fontSize = '16px';
      // style.lineHeight = '1.1';
      break;
  }

  switch (config?.productDescriptionThicness) {
    case 'thin':
      style.fontWeight = '300';
      break;
    case 'regular':
      style.fontWeight = '400';
      break;
    case 'medium':
      style.fontWeight = '500';
      break;
    case 'bold':
      style.fontWeight = '700';
      break;
    default:
      style.fontWeight = '400';
      break;
  }

  return { style, className };
};

export const getProductPriceStyles = (config) => {
  const style: React.CSSProperties = {};
  const className = '';

  switch (config?.productPriceSize) {
    case 'extraSmall':
      style.fontSize = '12px';
      // style.lineHeight = '1';
      break;
    case 'small':
      style.fontSize = '14px';
      // style.lineHeight = '1';
      break;
    case 'medium':
      style.fontSize = '16px';
      // style.lineHeight = '1';
      break;
    case 'large':
      style.fontSize = '18px';
      // style.lineHeight = '1';
      break;
    case 'extraLarge':
      style.fontSize = '20px';
      // style.lineHeight = '1';
      break;
    default:
      style.fontSize = '16px';
      // style.lineHeight = '1';
      break;
  }

  switch (config?.productPriceThicness) {
    case 'thin':
      style.fontWeight = '300';
      break;
    case 'regular':
      style.fontWeight = '400';
      break;
    case 'medium':
      style.fontWeight = '500';
      break;
    case 'bold':
      style.fontWeight = '700';
      break;
    default:
      style.fontWeight = '400';
      break;
  }

  return { style, className };
};

export const getProductActualPriceStyles = (priceStyles) => {
  const style: React.CSSProperties = {};

  if (priceStyles.fontSize) {
    const sizeItem = +priceStyles.fontSize?.split('px')[0];
    style.fontSize = sizeItem - 1 + 'px';
  }

  return { style };
};

export const getCTAIcon = (config, isIconBasedCTA) => {
  if (!config?.iconInCta && !isIconBasedCTA) {
    return null;
  }

  switch (config?.iconType) {
    case 'cart':
      return CTAIconCart;
    case 'plus':
      return CTAIconPlus;
    case 'bag':
      return CTAIconBag;
    default:
      return null;
  }
};

export const getCTAWrapperStyles = (config) => {
  const style = {};
  let className = '';

  switch (config?.iconShape) {
    case 'square':
      className += ' !tw-rounded-none';
      break;
    case 'circular':
      className += ' !tw-rounded-[50%]';
      break;
    case 'rounded':
      className += ' !tw-rounded-[8px]';
      break;
  }

  return { style, className };
};

export const DISCOUNT_IMAGE_TAG = {
  REVERSE_ARCH: 'tag1',
  CIRCLE: 'tag2',
  SQUARE: 'tag3',
};

export const getDiscountTagImageComponent = (config, isMobile): any => {
  switch (config?.discountTag) {
    case DISCOUNT_IMAGE_TAG.REVERSE_ARCH: {
      const defaultProps = isMobile ? { height: 40, width: 34 } : {};
      return function TagImageComponent(props) {
        return !isMobile ? (
          <DiscountTagReverseArch {...defaultProps} {...props} />
        ) : (
          <DiscountTagMobileReverseArch {...defaultProps} {...props} />
        );
      };
    }
    case DISCOUNT_IMAGE_TAG.CIRCLE: {
      const defaultProps = isMobile
        ? { height: 40, width: 40 }
        : { width: 48, height: 48 };
      return function TagImageComponent(props) {
        return <DiscountTagCircle {...defaultProps} {...props} />;
      };
    }
    case DISCOUNT_IMAGE_TAG.SQUARE: {
      const defaultProps = isMobile
        ? { height: 40, width: 34 }
        : { width: 40, height: 48 };
      return function TagImageComponent(props) {
        return <DiscountTagSquare {...defaultProps} {...props} />;
      };
    }
    default:
      return null;
  }
};

export const BUTTON_ACTION = {
  ADD: 'add',
  INCREMENT: 'inc',
  DECREMENT: 'dec',
};

export const getVariantTextStyles = (config) => {
  const style: React.CSSProperties = {};

  switch (config?.variantSize) {
    case 'small':
      style.fontSize = '11px';
      style.lineHeight = '1';
      break;
    case 'medium':
      style.fontSize = '12px';
      style.lineHeight = '1';
      break;
    case 'large':
      style.fontSize = '13px';
      style.lineHeight = '1';
      break;
    default:
      style.fontSize = '12px';
      style.lineHeight = '1';
      break;
  }

  return { style };
};

export const getVariantSingularTextStyles = (
  config,
  { isColorVariant, isOneCharacterName, isTextOnly }
) => {
  const style: React.CSSProperties = {};
  if (isTextOnly && !isColorVariant) return { style };
  switch (config?.variantSize) {
    case 'extraSmall':
      style.height = !isColorVariant ? '16px' : undefined;
      style.width = isOneCharacterName ? '16px' : undefined;
      break;
    case 'small':
      style.height = !isColorVariant ? '18px' : '8px';
      style.width = isOneCharacterName ? '18px' : isColorVariant ? '8px' : undefined;
      break;
    case 'medium':
      style.height = !isColorVariant ? '20px' : '12px';
      style.width = isOneCharacterName ? '20px' : isColorVariant ? '12px' : undefined;
      break;
    case 'large':
      style.height = !isColorVariant ? '22px' : '16px';
      style.width = isOneCharacterName ? '22px' : isColorVariant ? '16px' : undefined;
      break;
    case 'extraLarge':
      style.height = !isColorVariant ? '22px' : undefined;
      style.width = isOneCharacterName ? '22px' : undefined;
      break;
    default:
      style.height = !isColorVariant ? '18px' : undefined;
      break;
  }

  return { style };
};

const DUMMY_IMAGE_URL = 'https://cdn.dotpe.in/longtail/store-items/default_image.png';
export const DUMMY_IMAGE_DATA = {
  image_id: 0,
  image_url: DUMMY_IMAGE_URL,
  thumbnail_url: DUMMY_IMAGE_URL,
  media_type: 1,
  status: 1,
};

export const getImagesToRender = (config, images) => {
  let sendImages = [];
  if (config?.imageType === 'single') {
    sendImages = images?.slice(0, 1) || [];
  }
  switch (config?.hoverType) {
    case 'zoomIn':
      sendImages = images?.slice(0, 1) || [];
      break;
    case 'nextImage':
      sendImages = images?.slice(0, 2) || [];
      break;
    default:
      sendImages = images || [];
      break;
  }
  if (!sendImages?.length) {
    sendImages = [DUMMY_IMAGE_DATA];
  }
  return sendImages;
};

export const getCartWrapperConfigs = (config) => {
  const isCardLayout = (config?.productAppearance || config?.appearance) === 'card';
  const isFullWidth = isCardLayout && config?.imageWidth === 'fullWidth';
  const style: React.CSSProperties = {
    background: isCardLayout ? config?.backgroundColor : 'transparent',
    borderColor: isCardLayout ? config?.borderColor : '#111C361F',
  };
  const className = isCardLayout ? 'tw-border-[1px] tw-border-solid' : '';

  return { style, className, isCardLayout, isFullWidth };
};

export const getWrapperStylesBasedOnGlobal = (cardWrapperConfigs, globalStyle) => {
  const isMarginedImgInCard =
    cardWrapperConfigs.isCardLayout && !cardWrapperConfigs.isFullWidth;
  const isFullWidthImgInCard =
    cardWrapperConfigs.isCardLayout && cardWrapperConfigs.isFullWidth;
  const imageCardStyle = globalStyle?.imageCards?.style;

  const addWrapperGlobalStyle = {
    style: {} as React.CSSProperties,
    className: '',
  };
  if (cardWrapperConfigs.isCardLayout) {
    const appliedBorderRadius = +imageCardStyle.borderRadius
      ?.split(' ')
      ?.find((str) => str.includes?.('px'))
      ?.split('px')[0];
    const calcBorderRadius = !isNaN(appliedBorderRadius) ? appliedBorderRadius : 0;
    if (isFullWidthImgInCard) {
      addWrapperGlobalStyle.className += ' removeGlobalOverride ';
      addWrapperGlobalStyle.style.borderRadius = `${calcBorderRadius ? calcBorderRadius + 1 : 0}px`;
    }
    if (isMarginedImgInCard) {
      addWrapperGlobalStyle.style = {
        ...addWrapperGlobalStyle.style,
        borderRadius: `${calcBorderRadius ? calcBorderRadius + 1 : 0}px`,
      };
    }
  }

  return { addWrapperGlobalStyle };
};

// For overlay based scenario
export const getCTAHoverAnimationClass = ({ buttonElement, isItemInCart, isMobile }) => {
  // Showing button on hover animation but only on desktop & for icon based CTA
  // const isIconBasedCTA = ['icon', 'iconFrontOfProductName'].includes(
  //   buttonElement?.config?.buttonType
  // );
  const isHoverAnimationChosen = buttonElement?.config?.buttonBehaviour === 'hover';
  if (!isHoverAnimationChosen) {
    return null;
  }
  if (isMobile) {
    return '[&_.addToCtaButtonForProduct]:tw-hidden';
  }
  if (isItemInCart) {
    return null;
  }
  return `[&:hover_.addToCtaButtonForProduct]:tw-pointer-events-auto [&:hover_.addToCtaButtonForProduct]:tw-opacity-[1] 
          [&_.addToCtaButtonForProduct]:tw-pointer-events-none [&_.addToCtaButtonForProduct]:tw-opacity-0 
          [&_.addToCtaButtonForProduct]:!tw-transition-all`;
};

export const getTagBorderRadius = (shape) => {
  let className: string = '';
  switch (shape) {
    case 'pill':
      className += ' tw-rounded-[20px] ';
      break;
    case 'rounded':
      className += ' tw-rounded-[4px] ';
      break;
  }
  return { className };
};

export const getTagsWrapperStyles = (config, tagsTintConfigs) => {
  let className: string = '';
  const style: React.CSSProperties = {};
  let ChildComponent = null;

  const borderRadiusStyle = getTagBorderRadius(config?.tagShape);
  className += borderRadiusStyle.className;

  if (config?.tagAppearance === 'default') {
    style.background = tagsTintConfigs?.tag_color;
    style.color = tagsTintConfigs?.text_color || '#fff';
    return { className, style, ChildComponent };
  }
  switch (config?.tagStyle) {
    case 'type1':
      className += 'tw-shadow-[0px_1px_1px_0px_#0000001F]';
      style.border = '0.3px solid #111C361F';
      style.background = tagsTintConfigs?.tag_color;
      style.color = tagsTintConfigs?.text_color || '#fff';
      break;
    case 'type2':
      style.border = '0.5px solid';
      style.borderColor = tagsTintConfigs?.tag_color || '#111C36';
      style.background = '#fff';
      style.color = tagsTintConfigs?.tag_color || '#111C36';
      break;
    case 'type3':
      style.background = tagsTintConfigs?.tag_color
        ? hexToRgba(tagsTintConfigs?.tag_color, 0.12)
        : '#fff';
      style.color = tagsTintConfigs?.tag_color || '#000';
      ChildComponent = (
        <div
          className={
            '!tw-color-[#fff] tw-absolute tw-inset-0 tw-z-[-1] tw-bg-[#fff] ' +
            borderRadiusStyle.className
          }
        />
      );
      break;
  }

  return { className, style, ChildComponent };
};

import {
  SAVE_STORE_INFO,
  FETCH_STORE_BY_SUBDOMAIN,
  GET_B2B_RFQ_INPUT_FIELDS,
  SAVE_B2B_RFQ_INPUT_FIELDS,
  TOGGLE_RFQ_FORM_MODAL,
  UPDATE_THEME_INFO,
} from '../actionTypes';

export const saveStoreInfo = (data) => {
  return {
    type: SAVE_STORE_INFO,
    data,
  };
};

export const fetchStoreInfoBySubDomain = (subdomain, router) => {
  return {
    type: FETCH_STORE_BY_SUBDOMAIN,
    router,
    subdomain,
  };
};

export const getB2bRfqInputFields = (callback, storeId) => {
  return {
    type: GET_B2B_RFQ_INPUT_FIELDS,
    callback,
    storeId,
  };
};

export const saveB2bRfqInputFields = (data) => {
  return {
    type: SAVE_B2B_RFQ_INPUT_FIELDS,
    data,
  };
};

export const toggleRfqFormModal = () => {
  return {
    type: TOGGLE_RFQ_FORM_MODAL,
  };
};

export const updateStoreTheme = (data) => {
  return {
    type: UPDATE_THEME_INFO,
    data,
  };
};

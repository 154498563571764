import Sortable from "sortablejs";

const WIDGET_GROUP_NAME = 'widgets';
const PAGE_BUILDER_WIDGET_HANDLE = '.drag-handle';

export const PAGE_BUILDER_CONFIG: Sortable.Options = {
  group: {
    name: WIDGET_GROUP_NAME,
    pull: 'clone',
    revertClone: true,
  },
  handle: PAGE_BUILDER_WIDGET_HANDLE,
  onAdd: (e) => console.log('onAdd', e),
  onChange: (e) => console.log('onChange', e),
  onChoose: (e) => console.log('onChoose', e),
  onClone: (e) => console.log('onClone', e),
  onDeselect: (e) => console.log('onDeselect', e),
  onEnd: (e) => console.log('onEnd', e),
  onFilter: (e) => console.log('onFilter', e),
  onMove: (e) => console.log('onMove', e),
  onRemove: (e) => console.log('onRemove', e),
  onSelect: (e) => console.log('onSelect', e),
  onSort: (e) => console.log('onSort', e),
  onSpill: (e) => console.log('onSpill', e),
  onStart: (e) => console.log('onStart', e),
  onUnchoose: (e) => console.log('onUnchoose', e),
  onUpdate: (e) => console.log('onUpdate', e),
}

export const WIDGET_LIST_CONFIG: Sortable.Options = {
  group: {
    name: WIDGET_GROUP_NAME,
    pull: 'clone',
    put: false,
  },
  sort: false,
}

export const PAGE_BUILDER_COLUMN_CONFIG: Sortable.Options = {
  group: {
    name: WIDGET_GROUP_NAME,
    // pull: 'clone',
    // put: (to) => to.el.children.length <= 1,
  },
  handle: PAGE_BUILDER_WIDGET_HANDLE,
}

export const JSON_FORM_ARRAY_CONFIG: Sortable.Options = {
  handle: '.dragHandle',
}

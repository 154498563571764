import React, { ReactNode, useEffect } from 'react';
import Slider from 'react-slick';
import SliderArrowBack from '@/assets/svgExports/SliderArrowBack';
import SliderArrowForward from '@/assets/svgExports/SliderArrowForward';
import useBasicSliderHook from '../../hooks/useBasicSliderHook';

interface BasicSliderProps {
  showArrows?: boolean;
  arrowColor?: string;
  arrowCommonClasses?: string;
  removeArrowDefault?: boolean;
  arrowLeftClasses?: string;
  arrowRightClasses?: string;
  customArrowLeft?: any;
  customArrowRight?: any;
  showDots?: boolean;
  children?: ReactNode | undefined;
  customSettings?: any;
  activeIndex?: number;
  customStyles?: string;
}

const BasicSlider: React.FC<BasicSliderProps> = ({
  showArrows = false,
  arrowColor = '#000000',
  removeArrowDefault = false,
  arrowCommonClasses = '',
  arrowLeftClasses = '',
  arrowRightClasses = '',
  customArrowLeft = null,
  customArrowRight = null,
  showDots = true,
  children = null,
  customSettings = {},
  activeIndex = -1,
  customStyles = '',
}) => {
  const { sliderRef, settings, onPrevClick, onNextClick, goToIndex, startAutoPlay } =
    useBasicSliderHook(customSettings);

  const deafultArrowClasses = !removeArrowDefault
    ? 'tw-absolute -tw-translate-y-1/2 tw-top-[50%] tw-cursor-pointer'
    : '';
  const defaultArrowLeftClasses = !removeArrowDefault ? 'tw-left-[-24px]' : '';
  const defaultArrowRightClasses = !removeArrowDefault ? 'tw-right-[-24px]' : '';

  // This effect enables the component to go to a perticular index.
  useEffect(() => {
    if (activeIndex !== -1) {
      goToIndex(activeIndex);
    } else {
      settings.autoplay && startAutoPlay();
    }
  }, [activeIndex]);

  return (
    <div className="tw-relative">
      {showArrows && (
        <span
          className={`${deafultArrowClasses} ${arrowCommonClasses} ${defaultArrowLeftClasses}  ${arrowLeftClasses}`}
          onClick={onPrevClick}
        >
          {customArrowLeft ? customArrowLeft : <SliderArrowBack color={arrowColor} />}
        </span>
      )}
      <Slider
        ref={(slider) => (sliderRef.current = slider)}
        {...settings}
        dots={showDots}
      >
        {children}
      </Slider>
      {showArrows && (
        <span
          className={`${deafultArrowClasses} ${arrowCommonClasses} ${defaultArrowRightClasses}  ${arrowRightClasses}`}
          onClick={onNextClick}
        >
          {customArrowLeft ? customArrowRight : <SliderArrowForward color={arrowColor} />}
        </span>
      )}
      {!!customStyles && <style>{customStyles}</style>}
    </div>
  );
};

export default BasicSlider;

import { takeEvery } from 'redux-saga/effects';
import { toastNotifyError } from '@/components/Common/Toast';
import apiCall from './../../services/api';
import {
  CHECK_IS_ORDER_LOCATION_SERVICEABLE,
  DELETE_USER_ACCOUNT,
  FETCH_CITY_AND_STATE_FROM_PINCODE,
  FETCH_COUNTRIES_LIST,
} from './../actionTypes';
import { ENDPOINT } from 'src/config/endpoints';

function* checkIfOrderLocationIsServiceable(actions) {
  try {
    const userResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.USER.isOrderServiceableCheck(
        actions.data.sourcePincode,
        actions.data.destinationPincode,
        actions.data.storeId
      ),
      parseToJson: true,
    });
    if (userResponse && userResponse.data.status) {
      actions.callback && actions.callback(userResponse.data.data);
    } else {
      actions.callback && actions.callback(false);
    }
  } catch (err) {
    toastNotifyError(err.data.message);
  }
}

function* getCityStateFromPincode(actions) {
  try {
    const userResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.USER.getCityStateByPinCode(actions.data),
      parseToJson: true,
    });
    if (userResponse && userResponse.data.status) {
      actions.callback && actions.callback(userResponse.data.data);
    } else {
      actions.callback && actions.callback(false);
    }
  } catch (err) {
    toastNotifyError(err.data.message);
  }
}

function* getCountriesList(actions) {
  try {
    const countriesResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.USER.getCountriesList(),
      parseToJson: true,
    });
    const resData = countriesResponse?.data?.data || {};
    const countriesList = resData.countries_code || [];
    const defaultCountry = resData.default_country || {};
    actions.callback && actions.callback(countriesList, defaultCountry);
  } catch (err) {
    toastNotifyError('Unable to get countries list!');
  }
}

function* deleteUserAccount(actions) {
  try {
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.USER.deleteUserAccount(),
      data: actions.data,
    });
    if (response && response?.data?.status) {
      actions?.callback();
    } else {
      toastNotifyError('Unable to delete account!');
    }
  } catch (err) {
    toastNotifyError('Unable to delete account!');
  }
}

export default function* root() {
  yield takeEvery(CHECK_IS_ORDER_LOCATION_SERVICEABLE, checkIfOrderLocationIsServiceable);
  yield takeEvery(FETCH_CITY_AND_STATE_FROM_PINCODE, getCityStateFromPincode);
  yield takeEvery(FETCH_COUNTRIES_LIST, getCountriesList);
  yield takeEvery(DELETE_USER_ACCOUNT, deleteUserAccount);
}

import {
  FETCH_COUPONS_LIST,
  SAVE_COUPONS_LIST,
  VERIFY_COUPON,
  FETCH_PAYMENT_OFFERS_LIST,
  SAVE_PAYMENT_OFFERS_LIST,
  VERIFY_PAYMENT_OFFER,
  FETCH_EVALUATED_COUPONS,
  GET_CART_PAGE_PROMOS,
  GET_ALL_PROMOS,
  TOGGLE_PROMO_DETAIL_MODAL,
  GET_PROMO_DATA_BY_ID,
  GET_PROMOS_BY_TYPE_AND_TYPE_ID,
  GET_PROMO_ASSOCIATED_IDS,
  GET_PROMO_ASSOCIATED_PRODUCTS_BY_TYPE_AND_TYPE_IDS,
  GET_BOGO_REQUIRED_PRODUCTS_IN_CART,
  GET_SATISFIED_BOGO_PROMOS,
  TOGGLE_PROMO_REMOVAL_ALERT_MODAL,
  APPLY_PROMO,
} from '../actionTypes';

export const getCouponsList = (data) => {
  return {
    type: FETCH_COUPONS_LIST,
    data,
  };
};

export const saveCouponsList = (data) => {
  return {
    type: SAVE_COUPONS_LIST,
    data,
  };
};

export const getPaymentOffersList = (data) => {
  return {
    type: FETCH_PAYMENT_OFFERS_LIST,
    data,
  };
};

export const savePaymentOffersList = (data) => {
  return {
    type: SAVE_PAYMENT_OFFERS_LIST,
    data,
  };
};

export const verifyCoupon = (data, callback) => {
  return {
    type: VERIFY_COUPON,
    data,
    callback,
  };
};

export const verifyPaymentOffer = (data, callback) => {
  return {
    type: VERIFY_PAYMENT_OFFER,
    data,
    callback,
  };
};

export const getEvaluatedCouponList = (data) => {
  return {
    type: FETCH_EVALUATED_COUPONS,
    data,
  };
};

export const getCartPagePromos = (data, callback) => {
  return {
    type: GET_CART_PAGE_PROMOS,
    data,
    callback,
  };
};

export const getAllPromos = (data, callback) => {
  return {
    type: GET_ALL_PROMOS,
    data,
    callback,
  };
};

export const togglePromoDetailModal = (data) => {
  return {
    type: TOGGLE_PROMO_DETAIL_MODAL,
    data,
  };
};

export const getPromoDataById = (data, callback) => {
  return {
    type: GET_PROMO_DATA_BY_ID,
    data,
    callback,
  };
};

export const getPromosByTypeAndTypeId = (data, callback) => {
  return {
    type: GET_PROMOS_BY_TYPE_AND_TYPE_ID,
    data,
    callback,
  };
};

export const getPromoAssociatedIds = (data, callback) => {
  return {
    type: GET_PROMO_ASSOCIATED_IDS,
    data,
    callback,
  };
};

export const getPromoAssociatedProductsByTypeAndTypeIds = (data, callback) => {
  return {
    type: GET_PROMO_ASSOCIATED_PRODUCTS_BY_TYPE_AND_TYPE_IDS,
    data,
    callback,
  };
};

export const getBogoRequiredProductsInCart = (data, callback) => {
  return {
    type: GET_BOGO_REQUIRED_PRODUCTS_IN_CART,
    data,
    callback,
  };
};

export const getSatisfiedBogoPromos = (data, callback) => {
  return {
    type: GET_SATISFIED_BOGO_PROMOS,
    data,
    callback,
  };
};

export const togglePromoRemovalAlertModal = (data) => {
  return {
    type: TOGGLE_PROMO_REMOVAL_ALERT_MODAL,
    data,
  };
};

export const applyPromo = (data, callback) => {
  return {
    type: APPLY_PROMO,
    data,
    callback,
  };
};

import {
  SAVE_CATALOG_ITEMS_BASICS_BY_STOREID,
  SAVE_PDP_DATA,
  FETCH_SIMILAR_CATALOG_ITEMS,
  SAVE_SIMILAR_CATALOG_ITEMS,
  SAVE_CATALOG_CATEGORIES,
  SEARCH_PRODUCT_ITEMS,
  SAVE_SEARCHED_PRODUCT_ITEMS,
  FETCH_CATALOG_ITEM_BY_ID,
  FETCH_CATALOG_ITEMS_BY_CATEGORY_ID,
  SAVE_CATALOG_ITEM_BY_ID,
  FETCH_CATALOG_ITEMS_BASICS_BY_STOREID,
  SAVE_CATALOGS_LIST_BASED_ON_CATEGORIES,
  SAVE_CATALOG_TAGS,
  SET_CATALOG_PRODUCT_FILTER,
  SAVE_COLLECTIONS,
  FETCH_COLLECTION_CATALOG_ITEM_BY_ID,
  SAVE_COLLECTION_CATALOG_ITEMS,
  RESET_CATALOGS_LIST_BASED_ON_CATEGORIES,
  GET_CUSTOMER_REVIEW_SETTINGS,
  SAVE_REVIEW_SETTINGS,
  PUBLISH_REVIEW,
  VERIFY_BUYER_FOR_ITEM,
  SAVE_FILTERED_ITEMS,
  SAVE_FILTERED_OPTIONS,
  RESET_FILTERED_ITEMS_OPTIONS,
  GET_FILTERED_ITEMS_OPTIONS,
  FILTERS_OPTION_APPLY_COUNTER,
  SET_FILTER_OPTION,
  RESET_FILTER_ITEMS,
  ADD_NOTIFY_PRODUCT_INQUIRY,
  SET_MB5_ALL_CATEGORY_PAGINATION_CONFIG,
  SET_MB5_SELECTED_CATEGORY_PAGINATION_CONFIG,
  SET_MB6_PAGINATION_CONFIG,
  SET_MB5_SORT_AND_FILTER_CONFIG,
  RESET_MB5_ALL_SELECTED_CATEGORIES_PAGINATION_CONFIG,
  RESET_CATALOG_ITEMS,
  OPEN_PRODUCT_PERSONALIZATION_POPUP,
  CLOSE_PRODUCT_PERSONALIZATION_POPUP,
  ADD_PRODUCT_PERSONALIZATION_DATA,
  ADD_TEXT_PRODUCT_PERSONALIZATION,
  INCREMENT_PRODUCT_PERSONALIZATION_DATA,
  DECREMENT_PRODUCT_PERSONALIZATION_DATA,
  EDIT_PRODUCT_PERSONALIZATIO_DATA,
  EDIT_CART_PRODUCT_ITEM,
  SET_PRODUCT_DATA_PERSONALISED,
  SET_PERSONALIZED_PRODUCT_CUSTOMIZATION_COUNT,
} from './../actionTypes';

export const fetchCatalogItemsBasicsByStoreId = (data) => {
  return {
    type: FETCH_CATALOG_ITEMS_BASICS_BY_STOREID,
    data,
  };
};

export const saveCatalogItemsBasicsByStoreId = (data) => {
  return {
    type: SAVE_CATALOG_ITEMS_BASICS_BY_STOREID,
    data,
  };
};

export const saveCatalogsListBasedOnCategories = (data, extra) => {
  return {
    type: SAVE_CATALOGS_LIST_BASED_ON_CATEGORIES,
    data,
    extra,
  };
};

export const resetCatalogsList = (categoryId) => {
  return {
    type: RESET_CATALOGS_LIST_BASED_ON_CATEGORIES,
    categoryId,
  };
};

export const savePDPData = (data) => {
  return {
    type: SAVE_PDP_DATA,
    data,
  };
};

// export function fetchCatalogItem(data) {
//   return {
//     type: FETCH_CATALOG_ITEM,
//     data
//   }
// }

// export function saveCatalogItemForPDP(data) {
//   return {
//     type: SAVE_CATALOG_ITEM_PDP,
//     data
//   }
// }

export const fetchSimilarCatalogItems = (data) => {
  return {
    type: FETCH_SIMILAR_CATALOG_ITEMS,
    data,
  };
};

export const saveSimilarCatalogItems = (data) => {
  return {
    type: SAVE_SIMILAR_CATALOG_ITEMS,
    data,
  };
};

export const fetchCatalogItemsByCategoryId = (data, callback) => {
  return {
    type: FETCH_CATALOG_ITEMS_BY_CATEGORY_ID,
    data,
    callback,
  };
};

export const saveCatalogCategories = (data) => {
  return {
    type: SAVE_CATALOG_CATEGORIES,
    data,
  };
};

export const saveCatalogTags = (data) => {
  return {
    type: SAVE_CATALOG_TAGS,
    data,
  };
};

export const searchProductItems = (data) => {
  return {
    type: SEARCH_PRODUCT_ITEMS,
    data,
  };
};

export const saveSearchedProductItems = (data) => {
  return {
    type: SAVE_SEARCHED_PRODUCT_ITEMS,
    data,
  };
};

export const fetchCatalogItemById = (data, isVariantData, cb) => {
  return {
    type: FETCH_CATALOG_ITEM_BY_ID,
    data,
    isVariantData,
    cb,
  };
};

export const saveCatalogItemById = (data) => {
  return {
    type: SAVE_CATALOG_ITEM_BY_ID,
    data,
  };
};

export const setCatalogProductFilter = (data) => {
  return {
    type: SET_CATALOG_PRODUCT_FILTER,
    data,
  };
};

export const saveCollections = (data) => {
  return {
    type: SAVE_COLLECTIONS,
    data,
  };
};

export const fetchCollectionCatalogItemById = (data, callback) => {
  return {
    type: FETCH_COLLECTION_CATALOG_ITEM_BY_ID,
    data,
    callback,
  };
};

export const saveCollectionCatalogItems = (data) => {
  return {
    type: SAVE_COLLECTION_CATALOG_ITEMS,
    data,
  };
};

export function getCustomerReviewSettings(data) {
  return {
    type: GET_CUSTOMER_REVIEW_SETTINGS,
    data,
  };
}

export function saveCustomerReviewSettings(data, callback) {
  return {
    type: SAVE_REVIEW_SETTINGS,
    data,
    callback,
  };
}

export function publishReview(data, callback) {
  return {
    type: PUBLISH_REVIEW,
    data,
    callback,
  };
}

export function verifyBuyerForItem(data, callback) {
  return {
    type: VERIFY_BUYER_FOR_ITEM,
    data,
    callback,
  };
}

export function getFilteredItemsOptions(data, callback) {
  return {
    type: GET_FILTERED_ITEMS_OPTIONS,
    data,
    callback,
  };
}

export function saveFilteredItems(data, lastStoredItems) {
  return {
    type: SAVE_FILTERED_ITEMS,
    data,
    lastStoredItems,
  };
}

export function saveFilteredOptions(data) {
  return {
    type: SAVE_FILTERED_OPTIONS,
    data,
  };
}

export function resetFilteredItemsOptions() {
  return {
    type: RESET_FILTERED_ITEMS_OPTIONS,
  };
}

export function filtersCounter(data, setFilterCount = false) {
  return {
    type: FILTERS_OPTION_APPLY_COUNTER,
    data,
    setFilterCount,
  };
}

export function setSortType(data) {
  return {
    type: SET_FILTER_OPTION,
    data,
  };
}

export function resetFilterItems(data) {
  return {
    type: RESET_FILTER_ITEMS,
    data,
  };
}

export function addNotifyProductInquiry(data, callback) {
  return {
    type: ADD_NOTIFY_PRODUCT_INQUIRY,
    data,
    callback,
  };
}

export function setMB5AllCategoryPaginationConfig(data) {
  return {
    type: SET_MB5_ALL_CATEGORY_PAGINATION_CONFIG,
    data,
  };
}

export function setMB5SelectedCategoryPaginationConfig(data) {
  return {
    type: SET_MB5_SELECTED_CATEGORY_PAGINATION_CONFIG,
    data,
  };
}

export function resetMB5AllSelectedCategoryPaginationConfig() {
  return {
    type: RESET_MB5_ALL_SELECTED_CATEGORIES_PAGINATION_CONFIG,
  };
}

export function setMB5SortAndFilterConfig(data) {
  return {
    type: SET_MB5_SORT_AND_FILTER_CONFIG,
    data,
  };
}

export function setMB6PaginationConfig(data) {
  return {
    type: SET_MB6_PAGINATION_CONFIG,
    data,
  };
}

export function resetCatalogItems() {
  return {
    type: RESET_CATALOG_ITEMS,
  };
}

export const openProductPersonalizationPopUp = (data) => {
  return {
    type: OPEN_PRODUCT_PERSONALIZATION_POPUP,
    data,
  };
};

export const closeProductPersonalizationPopUp = () => {
  return {
    type: CLOSE_PRODUCT_PERSONALIZATION_POPUP,
  };
};

export const addProductPersonalizationData = (data) => {
  return {
    type: ADD_PRODUCT_PERSONALIZATION_DATA,
    data,
  };
};

export const addTextToProductPersonalization = (data) => {
  return {
    type: ADD_TEXT_PRODUCT_PERSONALIZATION,
    data,
  };
};

export const incrementProductPersonalization = (data) => {
  return {
    type: INCREMENT_PRODUCT_PERSONALIZATION_DATA,
    data,
  };
};

export const removeProductPersonalization = (data) => {
  return {
    type: DECREMENT_PRODUCT_PERSONALIZATION_DATA,
    data,
  };
};

export const editProductPersonalization = (data) => {
  return {
    type: EDIT_PRODUCT_PERSONALIZATIO_DATA,
    data,
  };
};

export const editCartProductPersonalization = (data) => {
  return {
    type: EDIT_CART_PRODUCT_ITEM,
    data,
  };
};

export const setProductDataPersonalised = (data) => {
  return {
    type: SET_PRODUCT_DATA_PERSONALISED,
    data,
  };
};

export const setPersonalizedProductCustomizationCount = (data) => {
  return {
    type: SET_PERSONALIZED_PRODUCT_CUSTOMIZATION_COUNT,
    data,
  };
};

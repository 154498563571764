export function SupermarketIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 28}
      height={props.height || 24.61}
      viewBox={`0 0 28 24.61`}
      {...props}
    >
      <defs>
        <style>{`.prefix__a{fill:${props.color}}`}</style>
      </defs>
      <path
        className="prefix__a"
        d="M9.021 16.407H23.9a.82.82 0 00.789-.595l3.28-11.485a.82.82 0 00-.789-1.046H7.129L6.543.643A.82.82 0 005.742 0H.82a.82.82 0 000 1.641h4.264l2.962 13.328a2.461 2.461 0 00.978 4.719H23.9a.82.82 0 000-1.641H9.023a.82.82 0 010-1.64zM26.092 4.922l-2.812 9.844H9.681L7.494 4.922zm0 0M8.203 22.149a2.461 2.461 0 102.461-2.461 2.464 2.464 0 00-2.461 2.461zm2.461-.82a.82.82 0 11-.82.82.821.821 0 01.82-.82zm0 0M19.797 22.149a2.461 2.461 0 102.461-2.461 2.464 2.464 0 00-2.461 2.461zm2.461-.82a.82.82 0 11-.82.82.821.821 0 01.82-.82zm0 0"
      />
    </svg>
  );
}

import { IS_SERVER } from './checkRenderEnv';

export const isRedirectedFromWebsiteBuilder = () => {
  if (!IS_SERVER) {
    const currentUrl = window?.location?.href;
    const searchString = '/widgets/dnd';
    return currentUrl?.includes(searchString);
  } else {
    return false;
  }
};

import {
  SAVE_CART,
  EMPTY_CART,
  SAVE_ORDER_INSTRUCTION,
  VALIDATE_CART,
  APPLY_COUPON,
  SET_CART,
  SET_B2B_CART,
  GET_CART_BY_ID,
  UPDATE_CART_ID,
  UPDATE_B2B_CART,
  UPDATE_RFQ_DETAILS,
} from '../actionTypes';

export const saveCart = (data) => {
  return {
    type: SAVE_CART,
    data,
  };
};

export const emptyCart = (data) => {
  return {
    type: EMPTY_CART,
    data,
  };
};

export const saveorderInstruction = (data) => {
  return {
    type: SAVE_ORDER_INSTRUCTION,
    data,
  };
};

export const validateCart = (data, callback) => {
  return {
    type: VALIDATE_CART,
    data,
    callback,
  };
};

export const applyCoupon = (data) => {
  return {
    type: APPLY_COUPON,
    data,
  };
};

export const setCart = (data, callback) => {
  return {
    type: SET_CART,
    data,
    callback,
  };
};

export const setB2bCart = (data, callback) => {
  return {
    type: SET_B2B_CART,
    data,
    callback,
  };
};

export const getCartById = (data) => {
  return {
    type: GET_CART_BY_ID,
    data,
  };
};

export const updateCartId = (data) => {
  return {
    type: UPDATE_CART_ID,
    data,
  };
};

export const updateB2bCart = (data) => {
  return {
    type: UPDATE_B2B_CART,
    data,
  };
};

export const updateRfqDetails = (data) => {
  return {
    type: UPDATE_RFQ_DETAILS,
    data,
  };
};

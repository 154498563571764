export function getUniqueArrayOfObject(arr, propertyName) {
  const uniqueValueOfPropety = {};
  const newUniqueArr = [];

  if (arr?.length) {
    arr.forEach((item) => {
      if (item?.[propertyName] in uniqueValueOfPropety) {
        return;
      }
      uniqueValueOfPropety[item?.[propertyName]] = true;
      newUniqueArr.push(item);
    });
  }

  return newUniqueArr;
}

import { put, takeEvery } from 'redux-saga/effects';
import {
  GET_ALL_EMI_OPTIONS,
  GET_EMI_OPTIONS_FOR_CARD,
  SET_EMI_OPTION,
} from '../actionTypes';
import apiCall from '../../services/api';
import { ENDPOINT } from 'src/config/endpoints';
import { saveAllEmiOptions, saveEmiOptionsSpecificToCard } from '../actions';

function* getEmiOptionsSpecificToCard(actions) {
  try {
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.EMI.GET_EMI_OPTIONS_FOR_CARD,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = JSON.parse(response?.data?.data);
      yield put(saveEmiOptionsSpecificToCard(parsedData?.emi_options ?? {}));
      actions?.callback?.(!!parsedData?.emi_options?.length);
    }
  } catch (err) {
    yield put(saveEmiOptionsSpecificToCard({}));
    actions?.callback?.(false);
    console.log(err);
  }
}

function* getAllEmiOptions(actions) {
  try {
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.EMI.GET_ALL_EMI_OPTIONS,
      data: actions.data,
    });
    if (response?.data?.status) {
      const parsedData = JSON.parse(response?.data?.data);
      yield put(saveAllEmiOptions(parsedData));
    }
  } catch (err) {
    console.log(err);
  }
}

function* setEmiOption(actions) {
  try {
    const response = yield apiCall({
      method: 'PUT',
      url: ENDPOINT.EMI.SET_EMI_OPTION,
      data: actions.data,
    });
    actions?.callback(response?.data?.status);
  } catch (err) {
    actions?.callback(false);
  }
}

export default function* root() {
  yield takeEvery(GET_EMI_OPTIONS_FOR_CARD, getEmiOptionsSpecificToCard);
  yield takeEvery(GET_ALL_EMI_OPTIONS, getAllEmiOptions);
  yield takeEvery(SET_EMI_OPTION, setEmiOption);
}

import { getWidgetUrl, WIDGETS_REGISTRY } from './env';
// import { renderToString } from 'react-dom/server';
import createLoadRemoteModule from "@paciolan/remote-module-loader";
import { fetcher, getRemoteRequires } from './remoteComponentConfig';
import axios from 'axios';
// import { hydrationEngine } from './reactHydrationEngine';

const loaderCacheFactory = () => {
  const loaderCacheObjects: Record<string, Promise<any>> = {};

  const memo = <T extends any = any>(loaderFunction: (url: string) => Promise<T>) => {
    
    return (moduleUrl: string) => {
      if (!loaderCacheObjects[moduleUrl]) {
        loaderCacheObjects[moduleUrl] = loaderFunction(moduleUrl);
      } else {
        console.debug(`Loader cache found for [${moduleUrl}]`)
      }
      loaderCacheObjects[moduleUrl].catch(() => {
        delete loaderCacheObjects[moduleUrl];
      });
      return loaderCacheObjects[moduleUrl] as Promise<T>;
    }
  };

  return memo;
};

const loaderMemo = loaderCacheFactory();

export const loadRemoteModule = loaderMemo(createLoadRemoteModule({ requires: getRemoteRequires(), fetcher: fetcher }));
export const loadRemoteUrl = loaderMemo((remoteUrl: string) => axios.get<string>(remoteUrl).then((res) => res.data));

export const loadReactComponent = (widgetName: string) => {
  const url = getWidgetUrl(widgetName);
  return loadRemoteModule(url).then((module) => module.default).catch((err) => {
    console.error(err);
  });
};

export const loadReactElement = (id: string, widgetName: string, props: any, widgetHashProps: Record<string, any> = {}) => {
  return loadReactComponent(widgetName).then((Component) => {
    const { widgetProps, ...restData } = props;
    const allWidgetProps = { ...widgetHashProps, ...widgetProps };
    const reactComponent = <Component {...restData} widgetOptions={allWidgetProps} update={Date.now()} />;
    // const reactString = renderToString(reactComponent);
    // hydrationEngine.addToHydrationQueue(id, reactComponent, `#html-for-${id}`)
  
    // return reactString;
    return reactComponent;
  });
}

export const loadWidgetStyles = (widgetName: string) => {
  const stylePath = `${WIDGETS_REGISTRY}/${widgetName}/style.css`;
  return loadRemoteUrl(stylePath).catch(() => '');
}

import 'url-search-params-polyfill';
import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ENDPOINT } from '../../config/endpoints';
import apiCall from '../../services/api';

import {
  fetchOrderByHash,
  fetchStoreInfoBySubDomain,
  saveCart,
  saveCartValidationData,
  saveOrder,
  saveOrderDetails,
  setCartValidationErrorMsgModalVisibility,
  setLoaderState,
  setNotAcceptingOrdersModalVisibility,
  setPaymentModalVisiblity,
  saveGstInvoice,
  fetchLoggedInOrderByHash,
  saveParentOrderDetails,
  setOrderErrorForInvalidUser,
  emptyCart,
} from './../actions';
import { formatOrdersByDate } from '@/utils/formatOrdersByDate';
import {
  PLACE_ORDER,
  FETCH_ORDER_BY_HASH,
  FETCH_ORDER_LIST,
  UPDATE_ORDER_STATE,
  GET_GST_INVOICE,
  FETCH_LOGGED_IN_ORDER_BY_HASH,
  FETCH_ORDER_DETAILS_BY_ID,
  CANCEL_ORDER,
  GET_STORE_ORDER,
} from '../actionTypes';

import { getPrepaidInvalidAmountMessage } from '@/utils/getPrepaidInvalidAmountMessage';
import { validateItemProperties } from '@/utils/validateItemProperties';
import { setModifiedDeliveryInfo } from '@/utils/setModifiedDeliveryInfo';
import { isOrderGstExcluded, isOrderGstIncluded } from '@/utils/orderGSTStatus';
import { getStoreType } from '@/utils/getStoreType';
import store from './../store';
import { getRoute, PATH } from '../../utils/routes';
import { toastNotifyError } from '../../components/Common/Toast';
import {
  MINIMUM_ORDER_AMOUNT,
  ORDER_PAYMENT_TYPE,
  ORDER_STATUS,
  ORDER_TYPE,
  PREPAID_POSTPAID_METHODS,
  STORE_CUSTOMIZATION,
} from '@/utils/constants';
import { getTotalCartAmounts } from '@/utils/getTotalCartAmounts';
import { setPurchaseTrigger } from 'src/utils/purchaseEventTrigger';
// import { isStoreServiceBased } from '@/utils/isStoreServiceBased';

function* placeOrder(actions) {
  const payload = Object.assign({}, actions.data);
  // const isServiceBased = isStoreServiceBased();
  const storeData = store.getState().storeReducer.store;
  const cartData = store.getState().cartReducer;
  const userData = store.getState().userReducer;
  // const searchParams = window.location.search;
  // const urlParams = new URLSearchParams(searchParams);
  payload['items'] = payload['items'].reduce((acc, curr) => {
    if (curr.item_name === '' || isNaN(curr.quantity)) return acc;

    curr.quantity = Number(curr.quantity);
    acc.push(curr);

    return acc;
  }, []);
  // payload["source"] = urlParams.get("utm_source");
  payload['source'] = localStorage?.getItem('utm_source') || '';
  payload['promo_discount'] = cartData.promo_discount;
  payload['user_phone'] = userData.data.phone;
  payload['phone'] = userData.data.phone;
  payload['user_email'] = userData.data.email;
  payload['email'] = userData.data.email;
  payload['user_id'] = userData.data.user_id;

  const { items } = validateItemProperties(payload.items);
  payload.items = items;

  payload.delivery_info = setModifiedDeliveryInfo(payload);

  // const allowToPlaceOrder =
  //   isServiceBased ||
  //   (!isServiceBased &&
  //     payload.order_type === ORDER_TYPE.DELIVERY &&
  //     payload.delivery_info?.address1);

  try {
    // if (allowToPlaceOrder) {
    yield put(setLoaderState(true));
    const orderResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.ORDER.CREATE_ORDER,
      data: payload,
    });

    if (orderResponse && orderResponse.data.status) {
      if (
        orderResponse.data.order_mode === 3 &&
        orderResponse.data.order_type === ORDER_TYPE.B2B
      ) {
        setPurchaseTrigger();
        actions.router.push(
          getRoute(
            PATH.orderConfirm(orderResponse.data.order_hash),
            storeData.store_info.domain
          )
        );
      } else {
        if (
          storeData?.services?.payment_method === PREPAID_POSTPAID_METHODS.PREPAID &&
          orderResponse.data?.buyer_pay_value < MINIMUM_ORDER_AMOUNT
        ) {
          toastNotifyError(getPrepaidInvalidAmountMessage(getStoreType()));
          yield put(setPaymentModalVisiblity(false));
        } else if (orderResponse.data.is_cart_modified) {
          /** Check if data is modified */
          yield put(saveCartValidationData(orderResponse.data));
          /** Now if cart in modified So => Update cart */
          const cartData = store.getState().cartReducer;
          // Calculate new pay_amount amount
          const amount_values = getTotalCartAmounts(orderResponse.data.items);

          yield put(
            saveCart({
              ...cartData,
              items: orderResponse.data.items,
              ...amount_values,
            })
          );

          yield put(fetchStoreInfoBySubDomain(storeData?.store_info?.domain));
          if (
            payload.order_type === ORDER_TYPE.DELIVERY ||
            payload.order_type === ORDER_TYPE.SERVICE
          ) {
            if (
              localStorage?.getItem('webDeliveryType') !== 'delivery' ||
              localStorage?.getItem('deliveryType') === 'deliver'
            )
              actions.router.push(getRoute(PATH.CART, storeData.store_info.domain));
            yield put(setCartValidationErrorMsgModalVisibility(true));
          }
        } else if (orderResponse.data.is_store_closed) {
          /** Check is the store is closed */
          yield put(setNotAcceptingOrdersModalVisibility(true));
        } else {
          setPurchaseTrigger();
          /** Place place successfully */
          yield put(saveOrder(orderResponse.data));
          /** For Pre Paid Orders */
          if (payload.prepaid_flag === 1) {
            yield put(
              fetchOrderByHash({
                order_hash: orderResponse.data.order_hash,
                isPrepaid: payload.prepaid_flag,
              })
            );
          } else {
            /** For Post Paid Orders */
            if (actions.router) {
              // empty cart values after successful order
              actions.router.push(
                getRoute(
                  PATH.orderConfirm(orderResponse.data.order_hash),
                  storeData.store_info.domain
                )
              );
              yield put(emptyCart());
            }
          }
        }
      }
      actions?.callback?.(orderResponse?.data?.status, orderResponse?.data?.order_hash);
      yield put(setLoaderState(false));
    } else {
      yield put(setLoaderState(false));
      toastNotifyError(orderResponse?.data?.message || 'Something went wrong!');
    }
    // } else {
    //   toastNotifyError('Please add delivery address!');
    // }
  } catch (err) {
    yield put(setLoaderState(false));
    toastNotifyError(err?.data?.message || 'Something went wrong!!');
  }
}

function* fetchOrderDetailsByHash(actions) {
  try {
    const storeId = store.getState()?.storeReducer?.store?.store_id;
    const orderResponseHash = yield apiCall({
      method: 'GET',
      url: ENDPOINT.ORDER.getOrderFromHash(actions.data.order_hash, storeId),
    });
    if (orderResponseHash?.data?.status) {
      yield put(saveOrderDetails(orderResponseHash.data.order));
      if (actions.data.isPrepaid) {
        yield put(setPaymentModalVisiblity(true));
      }
    } else {
      toastNotifyError('Order not found!');
    }
    yield put(setLoaderState(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderState(false));
  }
}

function* fetchLoggedInOrderDetailsByHash(actions) {
  const errorStatus = store.getState()?.commonReducer?.showOrderStatusError;
  try {
    const orderResponseHash = yield apiCall({
      method: 'GET',
      url: ENDPOINT.ORDER.getLoggedInOrderFromHash({
        order_hash: actions.data.order_hash,
        phone: actions.data.phone,
        storeId: actions.data.storeId,
      }),
      parseToJson: true,
    });
    if (orderResponseHash?.data?.status) {
      yield put(saveOrderDetails(orderResponseHash.data?.data));
    }
    yield put(setLoaderState(false));
  } catch (err) {
    actions?.callback && actions.callback();
    if (!errorStatus) {
      toastNotifyError(err?.data?.message || `invalid user`);
      console.log(err);
      yield put(setOrderErrorForInvalidUser(true));
    }
    yield put(setLoaderState(false));
  }
}

function* fetchOrderLists(actions) {
  try {
    yield put(setLoaderState(true));
    const pageNum = actions.data?.pageNum || 1;
    const orderResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.ORDER.getCustomerOrders(
        actions.data.storeid,
        actions.data.phone,
        actions.data.userId || '',
        pageNum,
        actions.data.orderStatus
      ),
      parseToJson: true,
    });
    if (orderResponse && orderResponse.data.status) {
      const orders = formatOrdersByDate(orderResponse?.data?.data?.orders);
      actions.callback?.(true, {
        isNextPage: orderResponse?.data?.data?.next,
        orders,
      });
    }
  } catch (err) {
    console.log(err);
    actions.callback?.(false);
  } finally {
    yield put(setLoaderState(false));
  }
}

function* updateOrderState(actions) {
  try {
    const storeData = store.getState().storeReducer.store;
    const userData = store.getState().userReducer;
    const orderResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.ORDER.updateOrderStateByHash(actions.data),
    });
    if (orderResponse.data) {
      yield put(setLoaderState(false));
      yield put(
        fetchLoggedInOrderByHash({
          order_hash: actions.data,
          phone: userData?.data?.phone,
          storeId: storeData?.store_id,
        })
      );
      setPurchaseTrigger();
      actions.router &&
        actions.router.push(
          getRoute(PATH.orderConfirm(actions.data), storeData.store_info.domain)
        );
    }
  } catch (err) {
    toastNotifyError(
      getStoreType() !== STORE_CUSTOMIZATION.ORDER_BASED
        ? 'Unable to create book your service for pay after service right now, try Online Payment instead!'
        : 'Unable to create pay on delivery right now, try Online Payment instead!'
    );
    yield put(setLoaderState(false));
  }
}

function* fetchGstInvoice() {
  const reduxStore = store.getState();
  const orderDetails = reduxStore?.orderReducer?.orderDetails;
  const storeId = reduxStore?.storeReducer?.store?.store_id;

  if (
    !(
      (orderDetails?.status === ORDER_STATUS.COMPLETED ||
        orderDetails?.prepaid_flag === ORDER_PAYMENT_TYPE.PREPAID) &&
      (isOrderGstExcluded() || isOrderGstIncluded())
    )
  ) {
    yield put(saveGstInvoice(''));
    return;
  }

  try {
    const gstInvoiceResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.ORDER.getGstInvoice(orderDetails?.order_id, storeId),
      parseToJSon: true,
    });
    if (gstInvoiceResponse && gstInvoiceResponse.data.status) {
      yield put(saveGstInvoice(gstInvoiceResponse.data.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* fetchOrderDetailsById(actions) {
  yield put(setLoaderState(true));
  try {
    const orderResponseHash = yield apiCall({
      method: 'GET',
      url: ENDPOINT.ORDER.getOrderDetailsByID(actions.data.order_id),
    });
    if (orderResponseHash.data) {
      yield put(saveParentOrderDetails(orderResponseHash.data.order));
    }
    yield put(setLoaderState(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderState(false));
  }
}

function* fetchStoreOrder(actions) {
  yield put(setLoaderState(true));
  try {
    const storeOrderRes = yield apiCall({
      method: 'GET',
      url: ENDPOINT.ORDER.getStoreOrder(actions?.data?.hash),
    });
    const orderRes = storeOrderRes?.data?.order || {};
    if (storeOrderRes) {
      // yield put(saveParentOrderDetails(orderResponseHash.data.order));
      actions?.data?.callback(orderRes);
    }
    yield put(setLoaderState(false));
  } catch (err) {
    console.log(err);
    yield put(setLoaderState(false));
  }
}

function* cancelOrder(actions) {
  yield put(setLoaderState(true));
  try {
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.ORDER.cancelOrder(),
      data: actions.data,
    });
    if (response && response.data.status) {
      toastNotifyError('Order Cancelled Successfully');
    }
    yield put(setLoaderState(false));
  } catch (err) {
    console.log(err);
    toastNotifyError(err?.data?.message);
    yield put(setLoaderState(false));
  }
}

export default function* root() {
  yield takeEvery(PLACE_ORDER, placeOrder);
  yield takeEvery(CANCEL_ORDER, cancelOrder);
  yield takeEvery(FETCH_ORDER_BY_HASH, fetchOrderDetailsByHash);
  yield takeEvery(FETCH_LOGGED_IN_ORDER_BY_HASH, fetchLoggedInOrderDetailsByHash);
  yield takeEvery(FETCH_ORDER_LIST, fetchOrderLists);
  yield takeEvery(UPDATE_ORDER_STATE, updateOrderState);
  yield takeLatest(GET_GST_INVOICE, fetchGstInvoice);
  yield takeEvery(FETCH_ORDER_DETAILS_BY_ID, fetchOrderDetailsById);
  yield takeEvery(GET_STORE_ORDER, fetchStoreOrder);
}

/**
 * get index of specific item based on id from cart
 * if doesn't exist return -1
 * @param {*} cartData
 * @param {*} product
 * @return {*} number
 */
export const itemIndexFromCart = (cartData, product) => {
  if (cartData && product) {
    return cartData?.items?.findIndex((el) => (el.id || el.item_id) === product.id);
  }
  return -1;
};

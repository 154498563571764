import store from 'src/redux/store';
import { IS_SERVER } from './checkRenderEnv';
import { getRoute } from './routes';

export const checkIfHomePage = () => {
  const storeDomain = store.getState().storeReducer.store.domain;
  const isHomePage =
    !IS_SERVER &&
    ['/', '', '/' + storeDomain, getRoute('/', storeDomain)].includes(
      window.location.pathname
    );

  return isHomePage;
};

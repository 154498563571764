import { GET_STORE_POLICIES } from '../actionTypes';
import { saveStorePolicies } from '../actions';
import { takeEvery, put } from 'redux-saga/effects';
import { ENDPOINT } from '../../config/endpoints';
import apiCall from '../../services/api';

function* fetchStorePolicies(actions) {
  try {
    const storePoliciesResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.STORE_POLICIES.fetchStorePolicies(actions.payload.store_id),
      parseToJson: true,
    });
    if (
      storePoliciesResponse &&
      storePoliciesResponse.data.status &&
      storePoliciesResponse.data.data
    ) {
      yield put(saveStorePolicies(storePoliciesResponse.data.data));
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* root() {
  yield takeEvery(GET_STORE_POLICIES, fetchStorePolicies);
}

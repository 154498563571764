/**
 * Required Module
 */
import { get } from 'axios';
import { ENDPOINT } from './../config/endpoints';

/**
 * Function for getting Cities List by latitude & longitude
 * @param {Number} latitude
 * @param {Number} longitude
 */
async function getCitiesList(latitude, longitude) {
  try {
    const response = await get(
      ENDPOINT.SPOTLIGHT.cityList(latitude, longitude)
    );
    return { error: null, data: response.data };
  } catch (error) {
    return { error };
  }
}

/**
 * Function for getting Catalog List by storeId & pageNo
 * @param {string} hash
 */
async function getbusinessList() {
  try {
    const response = await get(ENDPOINT.SPOTLIGHT.BUSINESS_LIST);
    return { error: null, data: response.data };
  } catch (error) {
    return { error };
  }
}

async function getStoresByCityId(
  latitutde,
  longitude,
  cityId,
  pageNo,
  categoryId
) {
  try {
    const response = await get(
      ENDPOINT.SPOTLIGHT.storesList(
        latitutde,
        longitude,
        cityId,
        pageNo,
        categoryId
      )
    );
    return { error: null, data: response.data };
  } catch (error) {
    return { error };
  }
}

/**
 * Add Exported Function here
 */
export { getCitiesList, getbusinessList, getStoresByCityId };

/**
 * Helper function to check whether
 * emoticons exists in string
 * @param string
 * @returns boolean
 */

export const ifEmoticonsExists = (text) => {
  const regexExp =
    /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/gi;

  return regexExp.test(text); // true or false
};

/**
 * Replace all emoticons from string provided
 * @param string
 * @returns boolean
 */

export const removeEmoticons = (text) => {
  if (ifEmoticonsExists(text))
    return text
      .replace(
        /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g,
        ''
      )
      .trim();
  return text;
};

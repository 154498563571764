import { takeEvery } from "redux-saga/effects";
import { GET_FONT_DATA, GET_VARIANT_DATA, UPDATE_CUSTOM_FONT, UPDATE_FONT_LIST, UPDATE_GLOBAL_STYLE } from "../actionTypes";
import apiCall from './../../services/api';
import { ENDPOINT } from "src/config/endpoints";

function* getFontList(actions) {
  try {
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.GLOBAL_SETTING.getFontsData,
    });
    if (response && response.data.status) {
      actions?.data?.callback(JSON.parse(response?.data?.data));
    }
  } catch (err) {
    console.log(err);
  }
}

function* updateFontList(actions) {
  try {
    const response = yield apiCall({
      method: 'PATCH',
      url: ENDPOINT.GLOBAL_SETTING.updateFontData,
      data: actions?.data?.data || {},
    });
    if (response && response.data.status) {
      actions?.data?.callback && actions?.data?.callback();
    }
  } catch (err) {
    console.log(err);
  }
}

function* updateGlobalStyle(actions) {
  try {
    const response = yield apiCall({
      method: 'PATCH',
      url: ENDPOINT.GLOBAL_SETTING.updateGlobalStyle,
      data: actions?.data || {},
    });
    if (response && response.data.status) {
      actions?.data?.callback && actions?.data?.callback();
    }
  } catch (err) {
    console.log(err);
  }
}

function* updateCustomTag(actions) {
  try {
    const response = yield apiCall({
      method: 'PUT',
      url: ENDPOINT.GLOBAL_SETTING.updateCustomTag,
      data: actions?.data || {},
    });
    if (response && response.data.status) {
      actions?.data?.callback && actions?.data?.callback();
    }
  } catch (err) {
    console.log(err);
  }
}

function* getVariantData(actions) {
  try {
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.GLOBAL_SETTING.getVariantData(actions?.data?.storeId),
    });
    if (response && response.data.status) {
      actions?.data?.callback && actions?.data?.callback(JSON.parse(response?.data?.data));
    }
  } catch (err) {
    console.log(err);
  }
}

export default function* root() {
  yield takeEvery(GET_FONT_DATA, getFontList);
  yield takeEvery(UPDATE_FONT_LIST, updateFontList);
  yield takeEvery(UPDATE_GLOBAL_STYLE, updateGlobalStyle )
  yield takeEvery(UPDATE_CUSTOM_FONT, updateCustomTag )
  yield takeEvery(GET_VARIANT_DATA, getVariantData)
}

import React from 'react';

export function getMappedproductsTextSize(sizeString) {
  const style: React.CSSProperties = {};
  switch (sizeString) {
    case 'extraSmall':
      style.fontSize = '12px';
      style.lineHeight = '1.4';
      break;
    case 'small':
      style.fontSize = '13px';
      style.lineHeight = '1.4';
      break;
    case 'regular':
      style.fontSize = '14px';
      style.lineHeight = '1.4';
      break;
    case 'medium':
      style.fontSize = '16px';
      style.lineHeight = '1.4';
      break;
    case 'large':
      style.fontSize = '18px';
      style.lineHeight = '1.4';
      break;
    case 'extraLarge':
      style.fontSize = '20px';
      style.lineHeight = '1.4';
      break;
    default:
      style.fontSize = '14px';
      style.lineHeight = '1.4';
      break;
  }
  return style;
}

export function getMappedProductTextAlign(xAlignment) {
  const styles: React.CSSProperties = {};
  switch (xAlignment) {
    case 'left':
      styles.textAlign = 'left';
      break;
    case 'center':
      styles.textAlign = 'center';
      break;
    case 'right':
      styles.textAlign = 'right';
      break;
  }
  return styles;
}

export function getMappedProductTextThickness(textThicness) {
  const styles: React.CSSProperties = {};
  switch (textThicness) {
    case 'thin':
      styles.fontWeight = '300';
      break;
    case 'regular':
      styles.fontWeight = '400';
      break;
    case 'medium':
      styles.fontWeight = '500';
      break;
    case 'semiBold':
      styles.fontWeight = '600';
      break;
    case 'bold':
      styles.fontWeight = '700';
      break;
    default:
      styles.fontWeight = '400';
      break;
  }
  return styles;
}

export function getMappedProductCasing(titleTextCase) {
  const style: React.CSSProperties = {};
  switch (titleTextCase) {
    case 'none':
      style.textTransform = 'none';
      break;
    case 'capitalize':
      style.textTransform = 'capitalize';
      break;
    case 'uppercase':
      style.textTransform = 'uppercase';
      break;
  }
  return style;
}

export function getTextSizeNum(sizeString) {
  const textSizeStr = getMappedproductsTextSize(sizeString).fontSize as string;
  return Number(textSizeStr.replace('px', ''));
}

export function getVariantIdFromUrl() {
  if (typeof window !== undefined) {
    const urlParams = new URLSearchParams(window.location.search);
    const variantId = parseInt(urlParams.get('vid'));
    return variantId;
  }
}

export const isShowPPModal = (type, items, source) => {
  if (
    !items ||
    source === 'cartPage' ||
    ((type === 'add' || type === 'inc' || type === 'save') && items?.quantity === 1)
  ) {
    return false;
  }

  return items.quantity >= 1;
};

export const checkProductPersonalizationExist = (storeType, productPersonalization) => {
  return storeType !== 3 && !!productPersonalization;
};

import { cloneDeep } from 'lodash';
import { ANIMATION_TYPE } from '../Constants';
import { DEFAULT_ANNUNCEMENT } from './DefaultConfigs';

export const NEW_ANNOUNCEMENT_CARD_CONFIG = {
  hoverEffect: 'zoomIn',
  textAlign: 'center',
  textSizeDesktop: 'medium',
  textSizeMobile: 'small',
  textThickness: 'regular',
  textCase: 'none',
  textAnimationType: ANIMATION_TYPE.FADE_IN,
  announcementList: [cloneDeep(DEFAULT_ANNUNCEMENT)],
};

import { Popper } from '@mui/material';

const NestedLinksPopper = (props) => {
  const { anchorEl, onClose } = props;
  const open = Boolean(anchorEl);

  return (
    <Popper
      open={open}
      onBlur={onClose}
      anchorEl={anchorEl}
      placement="right"
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            boundary: 'window',
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['left'],
          },
        },
      ]}
      className="tw-z-[9999] tw-h-fit"
    >
      {props.children}
    </Popper>
  );
};

export default NestedLinksPopper;

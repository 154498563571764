import styled from 'styled-components';

export const BlurContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  z-index: 99999;
  opacity: 0.5;
`;
export const ImageEnlargeModal = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999; /* Place it above the blur container */

  img {
    max-width: 80vw;
    max-height: 80vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  .modal-content {
    width: 500px;
  }
  .enlarged-image {
    width: 100%;
    max-width: 100%;
    max-height: 100%;
  }
  button {
    position: absolute;
    background-color: transparent;
    color: white;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
  button.close {
    right: 0%;
    top: -9%;
    font-size: 2.5rem;
  }
  button.prev {
    left: -20%;
    top: 50%;
  }
  button.next {
    right: -20%;
    top: 50%;
  }
`;

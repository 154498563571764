/**
 * function used to redsirect to 404 page on any issue in
 * ssr conditions
 * @param {*} ctx
 */
export const _404Redirect = (ctx, statusCode = 302) => {
  // return;
  ctx.res.writeHead(statusCode, { location: '/404' });
  ctx.res.end();
};

export const checkIfRoutesAreValid = (isCustomDomain, ctx) => {
  if (!isCustomDomain) {
    const splittedUrl = ctx.req.url.split('/');
    const route = splittedUrl?.[2];

    if (route === '_next') {
      return;
    }

    if (route?.includes('?')) {
      return;
    }

    if (!route) {
      return true;
    }

    if (ctx?.params?.domain) {
      return true;
    }

    if (!VALID_PATHS?.[route]) {
      _404Redirect(ctx);
      return false;
    }
  } else {
    const splittedUrl = ctx.req.url.split('/');
    const route = splittedUrl?.[1];

    if (route === '_next') {
      return;
    }

    if (route?.includes('?')) {
      return;
    }

    if (!route) {
      return true;
    }

    if (ctx?.params?.domain) {
      return true;
    }

    if (!VALID_PATHS?.[route]) {
      _404Redirect(ctx);
      return false;
    }
  }
  return true;
};

const VALID_PATHS = {
  'about-us': true,
  'add-delivery': true,
  cart: true,
  categories: true,
  collections: true,
  'delivery-details': true,
  'delivery-list': true,
  'emi-options': true,
  'emi-payment': true,
  'member-login': true,
  'offers-list': true,
  'order-confirm': true,
  'order-receipt': true,
  'order-status': true,
  orders: true,
  p: true,
  payment: true,
  'payment-failed': true,
  'payment-process': true,
  policy: true,
  product: true,
  search: true,
  shop: true,
  store: true,
  'store-close': true,
  thankyou: true,
  'verify-otp': true,
  widgets: true,
  api: true,
};

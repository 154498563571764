import { allowEverything } from '@/utils/regex';
import CustomTextField from '../../../../WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/TextfieldBox';
import { useContext, useEffect, useState } from 'react';
import { RenderNewSearch } from './RenderNewSearch';
import { NewHeaderContext } from '../newHeaderContext';
import { getIcon } from './dynamicComponent';

const crossIcon = (color) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M16.5002 4.86641L15.2052 3.57141L10.0716 8.70498L4.93807 3.57141L3.64307 4.86641L8.77664 9.99998L3.64307 15.1336L4.93807 16.4286L10.0716 11.295L15.2052 16.4286L16.5002 15.1336L11.3666 9.99998L16.5002 4.86641Z"
        fill={color}
      />
    </svg>
  );
};

export const RenderFullSearch = ({ activeHeaderData, currentClass, isBuilder }) => {
  const {
    dynamic_section: { base_config },
    globalClass: { staticClass },
  } = activeHeaderData?.headerPropsConfig;
  const defaultColor =
    staticClass.base_config.defaultFillColorType !== 'transparent'
      ? staticClass.base_config.defaultBackgroundColor
      : 'transparent';
  const { setShowSearch } = useContext(NewHeaderContext);
  const [searchValue, setSearchValue] = useState<string>('');
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const isFilledWhite =
    currentClass?.base_config?.defaultBackgroundColor?.toLowerCase() === '#ffffff';
  const isTransparent = currentClass?.base_config?.defaultFillColorType === 'transparent';

  const placeholderColor = currentClass?.base_config?.defaultNavLinksColor || '';

  useEffect(() => {
    let intervalId;
    if (base_config?.placeholder.length > 1) {
      intervalId = setInterval(() => {
        setFade(false);
        setTimeout(() => {
          setPlaceholderIndex(
            (prevIndex) => (prevIndex + 1) % base_config?.placeholder.length
          );
          setFade(true);
        }, 500);
      }, 3000);
    }

    return () => clearInterval(intervalId);
  }, [base_config?.placeholder.length]);

  function getHeightData() {
    if (isBuilder) {
      return (window.innerHeight / 100) * 60;
    } else {
      return (window.innerHeight / 100) * 70;
    }
  }

  return (
    <div
      style={{ background: defaultColor, borderTop: '1px solid #111C361F' }}
      className="tw-relative tw-bg-[#ffff] tw-px-[40px] tw-py-[19px]"
    >
      <div className="search-component tw-flex tw-w-[100%] tw-items-center tw-justify-between">
        <div className="tw-flex tw-w-[100%] tw-items-center">
          <CustomTextField
            parentClass="tw-w-[100%]"
            placeholder={
              base_config?.placeholder[placeholderIndex]?.value?.trim() ||
              base_config?.defaultPlaceholder
            }
            regexCheck={allowEverything}
            value={searchValue}
            showErrorBox={false}
            customClass={`!tw-px-[9px] !tw-py-0 tw-border-none hover:tw-shadow-none tw-outline-none tw-w-[100%] tw-transition-opacity tw-duration-500 tw-ease-in-out ${fade ? 'tw-opacity-100' : searchValue?.length ? 'tw-opacity-100' : 'tw-opacity-0'}`}
            handleChange={(value: any) => setSearchValue(value)}
            rows={1}
            hideBorder
            hideInputPadding
            iconComponent={() => (
              <div>{getIcon(20, currentClass?.base_config?.defaultNavLinksColor)}</div>
            )}
            isFocus
            inputColor={!isFilledWhite || isTransparent ? 'white' : ''}
            placeholderColor={placeholderColor}
          />
        </div>
        <div onClick={() => setShowSearch(false)} className="">
          {crossIcon(currentClass?.base_config?.defaultNavLinksColor)}
        </div>
      </div>
      {searchValue?.length > 0 && (
        <div
          style={{ maxHeight: getHeightData() + 'px' }}
          className={`tw-absolute tw-left-0 tw-z-[100] tw-mt-[5px] tw-w-[100%] tw-overflow-scroll tw-bg-[#ffffff] tw-px-[40px] tw-py-[30px]`}
        >
          <RenderNewSearch
            productQuery={searchValue}
            sectionClass={'tw-w-[100%]'}
            fullSearch
          />
        </div>
      )}
    </div>
  );
};

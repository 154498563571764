import { AnnouncementConfig } from './AnnouncementConfig';
import {
  CartIconV1,
  CartIconV2,
  CartIconV3,
  ContactIconV1,
  SearchIconV1,
  SearchIconV2,
  UserIconV1,
  UserIconV2,
} from './components/Svgs';

export const POSITIONS_DATA = [
  {
    text: 'Left',
    value: 'left',
    iconBaseClass: 'wb-sprite ic-align-left faded',
    iconActiveClass: 'wb-sprite ic-align-left',
    itemLevelClass: 'tw-w-[33%]',
  },
  {
    text: 'Centre',
    value: 'center',
    iconBaseClass: 'wb-sprite ic-align-center faded',
    iconActiveClass: 'wb-sprite ic-align-center',
    itemLevelClass: 'tw-w-[33%]',
  },
  {
    text: 'Right',
    value: 'right',
    iconBaseClass: 'wb-sprite ic-align-right faded',
    iconActiveClass: 'wb-sprite ic-align-right',
    itemLevelClass: 'tw-w-[33%]',
  },
];

const SEAECHDROPDOWNVALUE = [
  {
    name: 'Icon',
    value: 'icon',
  },
  {
    name: 'Icon + Label',
    value: 'labelIcon',
  },
  {
    name: 'Field',
    value: 'field',
  },
];

export const HEADER_CONFIG = {
  desktopHeader: 'header1',
  mobileHeader: 'header5',
  globalClass: {
    staticClass: {
      base_config: {
        defaultFillColorType: 'colors',
        defaultBackgroundColor: '#ffffff',
        defaultNavLinksColor: '#000000',
        defaultTextFontWeight: '400',
        defaultTextFontSize: '14px',
        defaulthoverType: 'Underline',
        defaultUnderLineType: 'above',
        defaultUnderlineColor: '#000000',
        defaultUnderlineTextColor: '#000000',
        defaultBoxBackgroundColor: '#F1F1F3',
        defaultBoxTextColor: '#000000',
        defaultTextFontWeightOnHover: 'none',
        defaultSeparatorLine: [''],
        defaultSeparator1Color: '#000000',
        defaultSeparator1Opacity: 10,
        defaultSeparator2Color: '#000000',
        defaultSeparator2Opacity: 10,
        defaultTextDecoration: 'none',
        defaultHoverIconState: 'none',
        defaultContentSize: 'default',
        defaultFontSizeType: 'n',
        defaultFontSizeTypeMobile: 'n',
      },
      leftSectionConfig: {
        sectionTitle: {
          data: {
            section_title: 'Header design',
            tooltip: 'Please select header1 to header6 to apply changes',
          },
          componentName: 'sectionTitle',
        },
        headerFill: {
          key: 'defaultFillColorType',
          label: 'Header background',
          data: [
            {
              text: 'Colour',
              value: 'colors',
              iconBaseClass: 'wb-sprite ic-color-icon',
              iconActiveClass: 'wb-sprite ic-color-icon-active',
              itemLevelClass: 'tw-w-[50%]',
            },
            {
              text: 'Transparent',
              value: 'transparent',
              iconBaseClass: 'wb-sprite ic-transparent faded ',
              iconActiveClass: 'wb-sprite ic-transparent-active',
              itemLevelClass: 'tw-w-[50%]',
            },
          ],
          componentName: 'multiselect',
        },
        backgroundColors: {
          key: ['defaultBackgroundColor', 'defaultNavLinksColor'],
          label: 'Header colour',
          data: {
            normal: [
              {
                header: '#ffffff',
                navLinks: '#000000',
                isDefault: true,
              },
            ],
            transparent: [
              {
                header: '#0000001A',
                navLinks: '#ffffff',
                isDefault: true,
              },
            ],
          },
          selectedIndexNormal: 0,
          selectedIndexTransparent: 0,
          restriction: 5,
        },
        hoverState: {
          types: [
            {
              type: 'None',
              sprite: 'wb-sprite ic-none',
            },
            {
              type: 'Underline',
              sprite: 'wb-sprite ic-underline',
              sprite1: 'wb-sprite ic-underline-header',
            },
            {
              type: 'Background Box',
              sprite: 'wb-sprite ic-background-box',
            },
          ],
          multiSelect: {
            label: 'Underline placement',
            key: 'defaultUnderLineType',
            data: [
              {
                text: 'Below Text',
                value: 'above',
                itemLevelClass: 'tw-w-[50%]',
              },
              {
                text: 'Below header',
                value: 'below',
                itemLevelClass: 'tw-w-[50%]',
              },
            ],
          },
          underline: {
            key: ['defaultUnderlineColor', 'defaultUnderlineTextColor'],
            data: [
              {
                underline: '#000000',
                text: '#000000',
              },
            ],
            selectedIndex: 0,
          },
          box: {
            key: ['defaultBoxBackgroundColor', 'defaultBoxTextColor'],
            boxData: [
              {
                boxBackground: '#F1F1F3',
                boxText: '#000000',
              },
            ],
            selectedIndex: 0,
          },
          selectedIndex: 1,
          checkbox: [
            {
              key: 'defaultTextFontWeightOnHover',
              label: 'Bold',
              id: 'logoCheckbox',
              isChecked: false,
              opType: 'normal',
              value: {
                isChecked: 'bold',
                unChecked: '500',
              },
            },
          ],
          iconHoverState: {
            label: 'Icon hover interaction',
            subLabel: 'Search, Profile, Cart etc.',
            placeholder: 'Select icon hover state',
            key: 'defaultHoverIconState',
            data: [
              { text: 'None', value: 'none' },
              { text: 'Zoom In', value: 'zoomin' },
              { text: 'Background', value: 'background' },
            ],
          },
          label: 'Navigation link hover interaction',
        },
        sepratorLine: {
          label: 'Separator line',
          key: 'separator',
          separator1: {
            label: 'Line color 1',
            key: ['defaultSeparator1Color'],
            data: [
              {
                lineColor: '#000000',
              },
            ],
            selectedIndex: 0,
          },
          separator2: {
            label: 'Line color 2',
            key: ['defaultSeparator2Color'],
            data: [
              {
                lineColor: '#000000',
              },
            ],
            selectedIndex: 0,
          },
          checkbox: [
            {
              key: 'defaultSeparatorLine',
              label: 'Add separator line (below header)',
              id: 'belowHeader',
              isChecked: false,
              opType: 'addRemove',
              value: {
                isChecked: 'BELOW_HEADER',
              },
            },
            {
              key: 'defaultSeparatorLine',
              label: 'Add separator line (above nav links)',
              id: 'belowHeader',
              isChecked: false,
              opType: 'addRemove',
              value: {
                isChecked: 'ABOVE_NAVLINKS',
              },
            },
          ],
        },
        textDetails: {
          sectionTitle: {
            data: {
              section_title: 'Header properties',
              tooltip: 'Please select header1 to header6 to apply changes',
            },
            componentName: 'sectionTitle',
          },
          textCase: {
            label: 'Text Case',
            placeholder: 'Select Case Type',
            key: 'defaultTextDecoration',
            data: [
              {
                text: 'Default',
                value: 'none',
                itemLevelClass: 'tw-w-[33%]',
              },
              {
                text: 'Aa',
                value: 'capitalize',
                itemLevelClass: 'tw-w-[33%]',
              },
              {
                text: 'AA',
                value: 'uppercase',
                itemLevelClass: 'tw-w-[33%]',
              },
            ],
          },
          contentThicness: {
            key: 'defaultTextFontWeight',
            placeholder: 'Select Thickness Type',
            label: 'Navigation link thickness',
            data: [
              {
                text: 'Thin',
                value: '300',
                itemLevelClass: { fontWeight: '300 !important' },
              },
              {
                text: 'Regular',
                value: '400',
                itemLevelClass: { fontWeight: '400 !important' },
              },
              {
                text: 'Medium',
                value: '500',
                itemLevelClass: { fontWeight: '500 !important' },
              },
              {
                text: 'Bold',
                value: '700',
                itemLevelClass: { fontWeight: '700 !important' },
              },
            ],
          },
          contentSizeWeb: {
            key: 'defaultFontSizeType',
            placeholder: 'Select Content Size',
            label: 'Content Size Web',
            data: [
              {
                text: 'Extra small (11px)',
                value: 'n-3',
                itemLevelClass: { fontSize: '11px !important' },
              },
              {
                text: 'Small (13px)',
                value: 'n-1',
                itemLevelClass: { fontSize: '13px !important' },
              },
              {
                text: 'Default (14px)',
                value: 'n',
                itemLevelClass: { fontSize: '14px !important' },
              },
              {
                text: 'Large (15px)',
                value: 'n+1',
                itemLevelClass: { fontSize: '15px !important' },
              },
              {
                text: 'Extra Large (17px)',
                value: 'n+3',
                itemLevelClass: { fontSize: '17px !important' },
              },
            ],
          },
          contentSizeMobile: {
            key: 'defaultFontSizeTypeMobile',
            placeholder: 'Select Content Size',
            label: 'Content Size Mobile',
            data: [
              {
                text: 'Extra small (11px)',
                value: 'n-3',
                itemLevelClass: { fontSize: '11px !important' },
              },
              {
                text: 'Small (13px)',
                value: 'n-1',
                itemLevelClass: { fontSize: '13px !important' },
              },
              {
                text: 'Default (14px)',
                value: 'n',
                itemLevelClass: { fontSize: '14px !important' },
              },
              {
                text: 'Large (15px)',
                value: 'n+1',
                itemLevelClass: { fontSize: '15px !important' },
              },
              {
                text: 'Extra Large (17px)',
                value: 'n+3',
                itemLevelClass: { fontSize: '17px !important' },
              },
            ],
          },
        },
      },
    },
    scrollClass: {
      base_config: {
        defaultFillColorType: 'colors',
        defaultBackgroundColor: '#ffffff',
        defaultNavLinksColor: '#000000',
        defaultTextFontWeight: '400',
        defaultTextFontSize: '14px',
        defaulthoverType: 'Underline',
        defaultUnderLineType: 'above',
        defaultUnderlineColor: '#000000',
        defaultUnderlineTextColor: '#000000',
        defaultBoxBackgroundColor: '#F1F1F3',
        defaultBoxTextColor: '#000000',
        defaultTextFontWeightOnHover: '500',
        defaultSeparatorLine: [''],
        defaultSeparator1Color: '#000000',
        defaultSeparator1Opacity: 30,
        defaultSeparator2Color: '#000000',
        defaultSeparator2Opacity: 30,
        defaultTextDecoration: 'none',
        defaultHoverIconState: 'none',
        defaultContentSize: 'default',
        defaultFontSizeType: 'n',
        defaultScrollType: 'sticky',
      },
      leftSectionConfig: {
        sectionTitle: {
          data: {
            section_title: 'Header scroll settings',
            tooltip: 'Please select header1 to header6 to apply changes',
          },
          componentName: 'sectionTitle',
        },
        scrollOption: {
          key: 'defaultScrollType',
          data: [
            {
              label: 'Make header sticky on scroll',
              id: 'logoCheckbox',
              value: 'sticky',
            },
            {
              label: 'Show when scrolling up',
              id: 'logoCheckbox',
              value: 'scrollUp',
            },
            {
              label: 'Hide on scroll',
              id: 'logoCheckbox',
              value: 'hide',
            },
          ],
        },
        backgroundColors: {
          key: ['defaultBackgroundColor', 'defaultNavLinksColor'],
          label: 'Header colour',
          data: {
            transparent: [
              {
                header: '#ffffff',
                navLinks: '#000000',
                isDefault: true,
              },
            ],
          },
          selectedIndexNormal: 0,
          selectedIndexTransparent: 0,

          restriction: 5,
        },
        hoverState: {
          types: [
            {
              type: 'None',
              sprite: 'wb-sprite ic-none',
            },
            {
              type: 'Underline',
              sprite: 'wb-sprite ic-underline',
              sprite1: 'wb-sprite ic-underline-header',
            },
            {
              type: 'Background Box',
              sprite: 'wb-sprite ic-background-box',
            },
          ],
          multiSelect: {
            label: 'Underline placement',
            key: 'defaultUnderLineType',
            data: [
              {
                text: 'Below Text',
                value: 'above',
                itemLevelClass: 'tw-w-[50%]',
              },
              {
                text: 'Below header',
                value: 'below',
                itemLevelClass: 'tw-w-[50%]',
              },
            ],
          },
          underline: {
            key: ['defaultUnderlineColor', 'defaultUnderlineTextColor'],
            data: [
              {
                underline: '#000000',
                text: '#000000',
              },
            ],
            selectedIndex: 0,
          },
          box: {
            key: ['defaultBoxBackgroundColor', 'defaultBoxTextColor'],
            boxData: [
              {
                boxBackground: '#F1F1F3',
                boxText: '#000000',
              },
            ],
            selectedIndex: 0,
          },
          selectedIndex: 1,
          checkbox: [
            {
              key: 'defaultTextFontWeightOnHover',
              label: 'Bold',
              id: 'logoCheckbox',
              isChecked: false,
              opType: 'normal',
              value: {
                isChecked: 'bold',
                unChecked: '500',
              },
            },
          ],
          iconHoverState: {
            label: 'Icon hover interaction',
            subLabel: 'Search, Profile, Cart etc.',
            key: 'defaultHoverIconState',
            data: [
              { text: 'None', value: 'none' },
              { text: 'Zoom In', value: 'zoomin' },
              { text: 'Background', value: 'background' },
            ],
          },
          label: 'Navigation link hover interaction',
        },
      },
    },
  },
  logo_section: {
    customClass: '',
    componentName: 'LogoRenderer',
    staticLogo: {
      sectionId: 'logo-section',
      componentData: {
        url: '',
        src: '',
        storeName: 'Blue tokai',
      },
      base_config: {
        section_title: 'Logo',
        defaultDesktopSize: 60,
        defaultMobileSize: 48,
        showLogo: true,
        showStoreName: false,
        customClass: '',
        tooltip: 'Please select from header 1 to 6 to configure.',
      },
      leftSectionConfig: {
        slider: [
          {
            key: 'defaultMobileSize',
            value: {
              min: 16,
              value: 48,
              max: 144,
            },
          },
          {
            key: 'defaultDesktopSize',
            value: {
              min: 20,
              value: 60,
              max: 180,
            },
          },
        ],
        checkbox: [
          {
            key: 'showLogo',
            label: 'Hide logo',
            isChecked: false,
            id: 'logoCheckbox',
            value: false,
          },
          {
            key: 'showStoreName',
            label: 'Hide Store name',
            isChecked: true,
            id: 'storeNameCheckBox',
            value: false,
          },
        ],
      },
    },
    scrollLogo: {
      sectionId: 'logo-section',
      componentData: {
        url: '',
        src: '',
        storeName: 'shree ram',
      },
      base_config: {
        section_title: 'Logo',
        defaultDesktopSize: 60,
        defaultMobileSize: 48,
        showLogo: true,
        showStoreName: false,
        tooltip: 'Please select from header 1 to 6 to configure.',
      },
      leftSectionConfig: {
        slider: [
          {
            key: 'defaultMobileSize',
            value: {
              min: 16,
              value: 48,
              max: 144,
            },
          },
          {
            key: 'defaultDesktopSize',
            value: {
              min: 20,
              value: 60,
              max: 180,
            },
          },
        ],
        checkbox: [
          {
            key: 'showLogo',
            label: 'Hide logo',
            id: 'logoCheckbox',
            value: true,
          },
          {
            key: 'showStoreName',
            label: 'Hide Store name',
            id: 'storeNameCheckBox',
            value: true,
          },
        ],
      },
    },
  },
  navLinks_section: {
    componentName: 'NavLinkRenderer',
    sectionId: 'navLinks-section',
    componentData: [],
    base_config: {
      section_title: 'Nav link settings',
      type: 'flatElement',
      defaultPosition: 'center',
      customClass: '',
      tooltip: 'Please select header1 to header6 to apply changes',
    },
    leftSectionConfig: {
      sectionTitle: {
        data: {
          section_title: 'Menu',
          tooltip: 'Please select header1 to header6 to apply changes',
        },
        componentName: 'sectionTitle',
      },
      positions: {
        key: 'defaultPosition',
        value: POSITIONS_DATA,
        renderCondition: ['header1'],
      },
    },
  },
  navIcons_section: {
    componentName: 'NavIconRenderer',
    sectionId: 'navIcons-section',
    componentData: {
      appearanceType: 'icon',
      iconsList: [
        {
          spriteDetail: '',
          ImageComponent: SearchIconV1,
          id: 0,
          defaultPosition: 0,
          label: 'Search',
          name: 'search',
          visibility: { desktopHeader: false, mobileHeader: false, hamburger: false },
        },
        {
          name: 'loginLogout',
          spriteDetail1: 'wb-sprite ic-user-16',
          spriteDetail2: 'wb-sprite ic-user-20',
          spriteDetail: 'wb-sprite ic-user',
          spriteDetail3: 'wb-sprite ic-user-28',
          spriteDetail4: 'wb-sprite ic-user-32',
          ImageComponent: UserIconV1,
          id: 1,
          label: 'My Orders',
          elementLevelClass: '',
          action: {
            isCta: true,
            actionType: 'login-logout',
            actionData: '',
          },
          visibility: { desktopHeader: true, mobileHeader: false, hamburger: true },
        },
        {
          name: 'contact',
          spriteDetail1: 'wb-sprite ic-align-left-new',
          spriteDetail2: 'wb-sprite backword-arrow',
          spriteDetail: 'wb-sprite ic-whatsapp',
          spriteDetail3: 'wb-sprite ic-none',
          spriteDetail4: 'wb-sprite ic-none',
          ImageComponent: ContactIconV1,
          id: 2,
          elementLevelClass: '',
          label: 'Whatsapp',
          action: {
            isCta: true,
            actionType: 'whatsapp',
            actionData: '',
          },
          visibility: { desktopHeader: true, mobileHeader: true, hamburger: false },
        },
        {
          name: 'cart',
          spriteDetail1: 'wb-sprite ic-cart-16',
          spriteDetail2: 'wb-sprite ic-cart-20',
          spriteDetail: 'wb-sprite ic-cart',
          spriteDetail3: 'wb-sprite ic-cart-28',
          spriteDetail4: 'wb-sprite ic-cart-32',
          ImageComponent: CartIconV1,
          id: 3,
          label: 'Cart',
          elementLevelClass: '',
          action: {
            isCta: true,
            actionType: 'redirect',
            actionData: '/cart',
          },
          visibility: { desktopHeader: true, mobileHeader: true, hamburger: false },
        },
      ],
    },
    base_config: {
      section_title: 'Header icons',
      defaultPosition: 'right',
      customClass: '',
      tooltip: 'Please select header1 to header6 to apply changes',
    },
    leftSectionConfig: {
      sectionTitle: {
        data: {
          section_title: 'Icon appearance',
          tooltip: 'Please select header1 to header6 to apply changes',
        },
        componentName: 'sectionTitle',
      },
      appearanceType: [
        {
          label: 'Icon',
          type: 'icon',
        },
        {
          label: 'Icon + Text',
          type: 'icon-text',
        },
        {
          label: 'Text only',
          type: 'text',
        },
      ],
      appearanceVariants: {
        searchIcon: {
          variants: [
            {
              spriteDetail: '',
              ImageComponent: SearchIconV1,
              id: 0,
            },
            {
              spriteDetail: '',
              ImageComponent: SearchIconV2,
              id: 1,
            },
          ],
          details: {
            defaultPosition: 0,
            label: 'Search',
            name: 'search',
          },
        },
        contactIcon: {
          variants: [
            {
              spriteDetail: '',
              ImageComponent: ContactIconV1,
              id: 0,
            },
          ],
          details: {
            defaultPosition: 2,
            name: 'contact',
            label: 'Contact',
            action: {
              isCta: true,
              actionType: 'whatsapp',
              actionData: '',
            },
          },
        },
        userIcon: {
          variants: [
            {
              spriteDetail: '',
              ImageComponent: UserIconV1,
              id: 0,
            },
            {
              spriteDetail: '',
              ImageComponent: UserIconV2,
              id: 1,
            },
          ],
          details: {
            defaultPosition: 1,
            name: 'loginLogout',
            label: 'User',
            action: {
              isCta: true,
              actionType: 'login-logout',
              actionData: '',
            },
          },
        },
        cartIcon: {
          variants: [
            {
              spriteDetail: '',
              ImageComponent: CartIconV1,
              id: 0,
            },
            {
              spriteDetail: '',
              ImageComponent: CartIconV2,
              id: 1,
            },
            {
              spriteDetail: '',
              ImageComponent: CartIconV3,
              id: 2,
            },
          ],
          details: {
            defaultPosition: 3,
            name: 'cart',
            label: 'Cart',
            action: {
              isCta: true,
              actionType: 'redirect',
              actionData: '/cart',
            },
          },
        },
      },
    },
  },
  dynamic_section: {
    componentName: 'dynamicComponent',
    sectionId: 'dynamic-section',
    componentData: {
      search: {
        type: 'search',
        placeholder: '',
        action: {},
      },
    },
    base_config: {
      section_title: 'Search settings',
      defaultPosition: 'left',
      defaultType: 'icon',
      searchAlignment: {},
      customClass: '',
      tooltip: 'Please select header1 to header6 to apply changes',
      showSearch: true,
      showSearchAlignment: ['header3', 'header4', 'header6'],
      placeholderMaxLength: 60,
      defaultPlaceholder: 'Search store',
      placeholder: [
        {
          label: 'Placeholder 1',
          value: 'Search store',
        },
      ],
    },
    leftSection_config: {
      search: {
        positions: {
          key: 'defaultPosition',
          value: POSITIONS_DATA,
        },
        dropdown: { key: 'defaultType', value: SEAECHDROPDOWNVALUE },
      },
    },
  },
  announcementConfig: {
    ...AnnouncementConfig,
  },
};

import React from 'react';
import {
  getVideoParts,
  getYoutubeVideoId,
  isYoutubeVideo,
} from '../../utils/videoUtility';
import { VideoMediaContainer } from './VideoMedia.styled';

const DEFAULT_VIDEO = 'https://www.youtube.com/watch?v=lKUQqPrRaN4';

const RawVideo = (props) => {
  const videoUrl = props?.mediaContent?.content[0]?.url;
  const videoUrlParts = getVideoParts(videoUrl);

  return (
    <VideoMediaContainer key={videoUrl} className="video-container">
      <video
        key={videoUrl}
        autoPlay
        loop
        muted
        onError={(e) => (e.target.src = videoUrl)}
        playsInline
      >
        <source src={videoUrl} type={videoUrlParts?.mimeType} />
      </video>
    </VideoMediaContainer>
  );
};

const YTVideo = (props) => {
  const videoUrl = props?.mediaContent?.content[0]?.url;
  const ytVideoId = getYoutubeVideoId(videoUrl);

  if (ytVideoId) {
    const urlParams = [];
    urlParams.push('modestbranding=1');
    urlParams.push('color=white');
    urlParams.push('playsinline=1');
    props.hideControls && urlParams.push('controls=0');
    props.autoplay && urlParams.push(`autoplay=1`);
    (props.muted || props.autoplay) && urlParams.push(`mute=1`);
    const embedUrl = `https://www.youtube.com/embed/${ytVideoId}?${urlParams.join('&')}`;
    return (
      <VideoMediaContainer key={videoUrl} className="video-container">
        <iframe key={videoUrl} src={embedUrl} title="YouTube"></iframe>
      </VideoMediaContainer>
    );
  }
  return null;
};

const VideoMedia = (props) => {
  if (props?.mediaContent?.content) {
    const videoUrlParts = getVideoParts(
      props?.mediaContent?.content[0]?.url || DEFAULT_VIDEO
    );

    if (isYoutubeVideo(videoUrlParts)) {
      return <YTVideo {...props} />;
    }
    return <RawVideo {...props} />;
  } else {
    return null;
  }
};

export default VideoMedia;

import moment from 'moment';

/**
 * Helper function for formating orders array to map array with date
 * ex. if [{},{},{},{}, {} ....] => [[date, [orders]],[date, [orders]]
 * @param {Array} orders
 * @return {Array}
 */
export const formatOrdersByDate = (orders) => {
  try {
    if (!orders) return [];

    if (Array.isArray(orders) && orders.length <= 0) return [];

    const map = new Map();

    for (let i = 0; i < orders.length; i++) {
      const key = moment(orders[i].created_at).format('DD-MMM-YYYY');

      if (map.has(key)) {
        const temp = map.get(key);
        temp.push(orders[i]);
        map.set(key, temp);
      } else {
        map.set(key, [orders[i]]);
      }
    }

    return [...map];
  } catch (error) {
    console.log(error);
    return [];
  }
};

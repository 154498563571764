import { INPUT_NOT_VISIBLE, OPTIONAL_INPUT, inputFieldsData, InputFieldData } from "./constants";

const getInputObjectValuesAccordingToPriorityAssigned = (inputValue: number, inputObj: InputFieldData) => {
  if (inputValue === INPUT_NOT_VISIBLE) {
    return {
      ...inputObj,
      is_field_selected: false,
      is_mandatory: false,
    };
  }
  if (inputValue === OPTIONAL_INPUT) {
    return {
      ...inputObj,
      is_field_selected: true,
      is_mandatory: false,
    };
  }
  return {
    ...inputObj,
    is_field_selected: true,
    is_mandatory: true,
  };
};

export function transformInputAccordingToPriorityAssignedByB2bMerchant (b2bRfqInputFields: Record<string, number>) {
  const b2bCustomerDetailsKeys = Object.keys(b2bRfqInputFields);
  return inputFieldsData.map((inputFieldData: InputFieldData) => {
    const searchedInputKey = b2bCustomerDetailsKeys?.find((key) => key === inputFieldData.key);
    return getInputObjectValuesAccordingToPriorityAssigned(b2bRfqInputFields[searchedInputKey], inputFieldData);
  });
}
import { Slider } from '@/components/ReactSlickCarousel';
import { SliderComponent } from './ImageSlider.styled';
import { useState, useEffect, useRef } from 'react';
import { Tab3LeftArrow } from '@/assets/svgExports/Tab3LeftArrow';
import { Tab3RightArrow } from '@/assets/svgExports/Tab3RightArrow';
import { RightSliderArrow } from '@/assets/svgExports/RightSliderArrow';
import { useSelector } from 'react-redux';
import { getStyleForProps } from '../../utils/getStylesConfig';
import { useSSRSelector } from '@/redux/ssrStore';

const FALLBACK_COLOR = '#D3D3D3';

const ImageSlider = (props) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideToShow, setSlidesToShow] = useState(
    props?.mediaContent?.sliderConfig?.slideToShow?.desktop || 1
  );
  const [, setLastNextSlide] = useState(false);
  const [, setLastPreviousSlide] = useState(true);

  const sliderRef = useRef(null);
  const settings = {
    dots:
      props?.hideDots?.length > 0 ? (props?.hideDots === 'hide' ? false : true) : true,
    infinite: props?.mediaContent?.sliderConfig?.isInfinite,
    slidesToShow:
      slideToShow > props?.mediaContent?.content?.length
        ? props?.mediaContent?.content?.length
        : slideToShow,
    slidesToScroll: 1,
    autoplay: props?.mediaContent?.sliderConfig?.isAutoplay,
    swipeToSlide: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
  };
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const { widgetData } = useSelector((state) => ({
    widgetData: state.widgetsReducer,
  }));
  const [propStyle, setPropStyle] = useState(storeData?.theme?.style_template);

  useEffect(() => {
    if (widgetData?.activeStyleTemplate) {
      setPropStyle(getStyleForProps(widgetData?.activeStyleTemplate));
    } else {
      setPropStyle(getStyleForProps(storeData?.theme?.style_template));
    }
  }, [widgetData?.activeStyleTemplate, storeData?.theme?.style_template]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSlidesToShow(props?.mediaContent?.sliderConfig?.slideToShow?.mobile);
      } else {
        setSlidesToShow(props?.mediaContent?.sliderConfig?.slideToShow?.desktop);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleNext = (e) => {
    e.stopPropagation();
    const totalSlides = Math.ceil(props?.mediaContent?.content?.length / slideToShow);
    if (currentSlide < totalSlides - 1) {
      sliderRef?.current?.slickNext();
      setLastNextSlide(false);
      setLastPreviousSlide(false);
    } else {
      setLastNextSlide(true);
      sliderRef?.current?.slickNext(); // sliderRef.current.slickGoTo(0); // Go back to the first set of slides
    }
  };
  const handlePrev = (e) => {
    e.stopPropagation();
    if (currentSlide > 1) {
      setLastNextSlide(false);
      sliderRef?.current?.slickPrev();
      setLastPreviousSlide(false);
    } else {
      setLastPreviousSlide(true);
      sliderRef?.current?.slickPrev();
    }
  };
  const checkTabs = (type) => {
    if (type?.length > 0) {
      switch (type) {
        case 'gallery':
          return {
            display: props?.mediaContent?.content?.length < slideToShow ? 'none' : 'flex',
          };
        case 'tab3':
          return {
            display:
              props?.mediaContent?.content?.length <= slideToShow ? 'none' : 'flex',
          };
      }
    }
    return;
  };
  return (
    props?.mediaContent?.content && (
      <SliderComponent className="slider__parent" {...props} propStyle={propStyle}>
        <div
          className="arrow-group"
          style={checkTabs(props?.mediaContent?.usedIn || props?.usedIn)}
        >
          {' '}
          <button
            className={props?.usedIn === 'tab3' ? 'tab3_prev' : 'prev'}
            style={{
              color: props?.usedIn !== 'tab3' ? propStyle?.heading?.color : '',
              transform: props?.usedIn !== 'tab3' ? 'scale(-1)' : '',
              position: props?.usedIn !== 'tab3' ? 'relative' : '',
              top: props?.usedIn !== 'tab3' ? '-4.4px' : '',
            }}
            onClick={handlePrev}
          >
            {props?.usedIn === 'tab3' ? <Tab3LeftArrow /> : <RightSliderArrow />}
          </button>
          <button
            className={props?.usedIn === 'tab3' ? 'tab3_next' : 'next'}
            style={{
              color: props?.usedIn !== 'tab3' ? propStyle?.heading?.color : '',
            }}
            onClick={handleNext}
          >
            {props?.usedIn === 'tab3' ? <Tab3RightArrow /> : <RightSliderArrow />}
          </button>
        </div>
        <Slider className="imageSlide-container" {...settings} ref={sliderRef}>
          {props?.mediaContent?.content?.map(({ url, imgText, image_href }, idx) => (
            <div className="slider-media" key={idx}>
              {url?.length > 0 ? (
                <div className="slide-image-container">
                  <img src={url} onError={(e) => (e.target.src = url)} />
                  {<p>{imgText?.length ? imgText : ''}</p>}
                </div>
              ) : (
                <div
                  style={{ background: `${FALLBACK_COLOR}`, aspectRatio: '6/6' }}
                ></div>
              )}
            </div>
          ))}
        </Slider>
      </SliderComponent>
    )
  );
};

export default ImageSlider;

import { ICardDefaultConfigProps, ImageType } from '../Types';

export const COMMON_LAYOUT_TYPE = {
  ONE: 'layout1',
  TWO: 'layout2',
  THREE: 'layout3',
  FOUR: 'layout4',
  FIVE: 'layout5',
  SIX: 'layout6',
};

export const IMAGE_TYPES = {
  CIRCLE: 'circle' as ImageType,
  SQUARE: 'square' as ImageType,
  RECT: 'rectangle' as ImageType,
};

export const ORDER_DATA_IDS = {
  RATING: 'rating',
  REVIEW: 'review',
  AUTHOR_INFO: 'authorInfo',
};

export const MEDIA_TYPES = {
  IMAGE: 'image',
  VIDEO: 'video',
};

export const SCROLL_TYPE = {
  MANUAL: 'manual',
  AUTO_SCROLL: 'autoScroll', // continuous scroll behaviour
  AUTO_ROTATE: 'autoRotate', // assume it like auto next slide click after a certain time interval.
};

// will be removed later
export const CARD_DEFAULT_CONFIGS: { [layout: string]: ICardDefaultConfigProps } = {
  layout1: {
    cardWrapperConfig: {
      showBorder: false,
      padding: '35px 28px',
    },
    imageConfig: {
      showImage: true,
      showImageWithAuthorInfo: true,
      imageType: IMAGE_TYPES.CIRCLE,
      width: 40,
      height: 40,
    },
    infoWrapperConfig: {
      gap: 24,
    },
    reviewConfig: {},
    authorInfoConfig: {
      gap: 0,
      authornameConfig: {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 600,
      },
      authorProfileConfig: {
        fontWeight: 400,
      },
    },
  },
  layout2: {
    cardWrapperConfig: {
      showBorder: false,
      padding: '35px 28px',
    },
    imageConfig: {
      showImage: true,
      showImageWithAuthorInfo: true,
      imageType: IMAGE_TYPES.CIRCLE,
      width: 40,
      height: 40,
    },
    infoWrapperConfig: {
      gap: 24,
    },
    reviewConfig: {},
    authorInfoConfig: {
      gap: 0,
      authornameConfig: {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 600,
      },
      authorProfileConfig: {
        fontWeight: 400,
      },
    },
  },
  layout3: {
    cardWrapperConfig: {
      showBorder: false,
      flexDirection: 'column',
      gap: 18,
    },
    imageConfig: {
      showImage: true,
      showImageWithAuthorInfo: false,
      imageType: IMAGE_TYPES.CIRCLE,
      width: 76,
      height: 76,
    },
    infoWrapperConfig: {
      gap: 10.5,
    },
    reviewConfig: {},
    authorInfoConfig: {
      gap: 0,
      alignItems: 'center',
      authornameConfig: {
        fontSize: 14,
        lineHeight: '20px',
        fontWeight: 600,
      },
      authorProfileConfig: {
        fontWeight: 400,
      },
    },
  },
  layout4: {
    showAuthorImageList: true,
    cardWrapperConfig: {
      showBorder: false,
      paddingBottom: 100,
    },
    imageConfig: {
      showImage: false,
      showImageWithAuthorInfo: false,
      imageType: IMAGE_TYPES.CIRCLE,
      width: 40,
      height: 40,
    },
    infoWrapperConfig: {
      gap: 16,
    },
    reviewConfig: {
      marginTop: 8,
      gap: 16,
    },
    authorInfoConfig: {},
  },
  layout5: {
    cardWrapperConfig: {
      showBorder: false,
      gap: 34,
      padding: 0,
    },
    imageConfig: {
      showImage: true,
      showImageWithAuthorInfo: false,
      imageType: IMAGE_TYPES.RECT,
      width: 236,
      height: 316,
      flex: 'none',
    },
    infoWrapperConfig: {
      gap: 24,
      alignItems: 'start',
    },
    reviewConfig: {},
    authorInfoConfig: {
      gap: 9,
      authornameConfig: {
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 700,
      },
      authorProfileConfig: {
        fontWeight: 400,
      },
    },
  },
  layout6: {
    cardWrapperConfig: {
      showBorder: true,
      borderColor: '#111C3633',
      gap: 32,
      flexDirection: 'column',
      padding: '32px 48px',
    },
    imageConfig: {
      showImage: true,
      showImageWithAuthorInfo: false,
      imageType: IMAGE_TYPES.CIRCLE,
      width: 180,
      height: 180,
    },
    infoWrapperConfig: {
      gap: 20,
    },
    reviewConfig: {},
    authorInfoConfig: {
      gap: 8,
      alignItems: 'center',
      authornameConfig: {
        fontSize: 16,
        lineHeight: '20px',
        fontWeight: 700,
      },
      authorProfileConfig: {
        fontWeight: 400,
      },
    },
  },
};

export const DEFAULT_IMAGE = 'https://cdn.dotpe.in/longtail/themes/8697974/VUkpiiUx.webp';

export const IMAGE_LIMIT_L4 = {
  DESKTOP: 7,
  MOBILE: 5,
  MOBILE_XS: 3,
};

export const TESTIMONIAL_IMAGE_POSITION = {
  WITH_AUTHOR_INFO: 'withAuthorInfo',
  LEFT: 'left',
  TOP: 'top',
  BOTTOM: 'bottom',
};

export const TESTIMONIAL_IMAGE_SHAPES = {
  SMART: 'smart',
  CIRCLE: 'circle',
  SQUARE: 'square',
};

export const CARD_ANIMATION_CLASSES = {
  BASE: 'tw-relative tw-transition-all tw-ease-in-out tw-duration-1000 ',
  INACTIVE: 'tw-top-[20px] tw-opacity-0',
  ACTIVE: 'tw-top-0 tw-opacity-100',
};

export const REMOVE_GLOBAL_STYLE = ['circle', 'arch', 'blob'];

export const IMAGE_SIZE_L4 = { imageSizeActive: 48, imageSizeInactive: 40 };

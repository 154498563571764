import cloneDeep from 'lodash/cloneDeep';
import { useActiveHeaderSection } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/HeaderBuilder/Hooks/useActiveHeaderSection';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { SCROLL_TYPE } from '../../ListPresentation/components/Testimonials/Constants';

export const useActiveAnnouncementAdjustment = ({
  isAnnouncementBarHeader,
  cardFrame,
  listPresentation,
  announcementWidgetId,
}) => {
  const {
    widgetContextState: { widgets, activeWidgetId },
    widgetContextActions: {},
  } = useWidgetDndContextData();

  const isAnnouncementWidgetActive =
    !!announcementWidgetId && activeWidgetId === announcementWidgetId;
  const { subWidgetIndex } = widgets?.[activeWidgetId]?.props || {};
  const isAnnouncementSubWidgetActive = isAnnouncementWidgetActive && subWidgetIndex >= 0;

  const { showAnnouncementItem, activeSubWidgetHeaderIndex } = useActiveHeaderSection();

  const isAnnouncementItemActive = isAnnouncementBarHeader
    ? showAnnouncementItem
    : isAnnouncementSubWidgetActive;

  const activeAnnouncementIndex = isAnnouncementBarHeader
    ? activeSubWidgetHeaderIndex
    : subWidgetIndex;

  const cardFrameConfigAdjusted = isAnnouncementItemActive
    ? cloneDeep(cardFrame)
    : cardFrame;

  let listPresentationConfigAdjusted = isAnnouncementItemActive
    ? cloneDeep(listPresentation)
    : listPresentation;

  if (isAnnouncementItemActive) {
    listPresentationConfigAdjusted = {
      ...listPresentationConfigAdjusted,
      autoPlayConfig: {
        ...(listPresentationConfigAdjusted?.autoPlayConfig || {}),
        type: SCROLL_TYPE.MANUAL,
      },
    };
  }

  return {
    cardFrameConfigAdjusted,
    listPresentationConfigAdjusted,
    activeSlideIndex: isAnnouncementItemActive ? activeAnnouncementIndex : -1,
  };
};

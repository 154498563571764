import * as React from 'react';
import { styled } from '@mui/material/styles';
// eslint-disable-next-line import/named
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: 'rgba(17, 28, 54, 0.90)',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(12, 21, 42, 1)',
    borderRadius: '8px',
  },
}));

interface Props {
  title: string | React.ReactElement;
  children?: any;
  diableHover?: boolean;
}

export default function CustomizedTooltips(props: any | Props) {
  return (
    <BootstrapTooltip
      disableHoverListener={props.diableHover ?? false}
      title={
        <React.Fragment>
          <div className="tw-text-12 tw-max-w-[274px] tw-p-12 tw-font-normal tw-text-white">
            {props.title}
          </div>
        </React.Fragment>
      }
    >
      {props.children}
    </BootstrapTooltip>
  );
}

export function WidgetOutlineHeaderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clipPath="url(#clip0_1166_3149)">
        <path
          d="M14.25 3H3.75C2.9175 3 2.25 3.675 2.25 4.5V13.5C2.25 14.325 2.9175 15 3.75 15H14.25C15.075 15 15.75 14.325 15.75 13.5V4.5C15.75 3.675 15.0825 3 14.25 3ZM14.25 13.5H3.75V6H14.25V13.5Z"
          fill="#222222"
        />
      </g>
      <defs>
        <clipPath id="clip0_1166_3149">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

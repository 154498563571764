import styled from 'styled-components';

export const SliderComponent = styled.div`
  display: block;
  width: 100%;

  .slick-slide img {
    margin: auto;
    max-width: 100%;
    object-fit: cover;
  }
  .slick-slider {
    button {
      display: none !important;
    }
  }
  .slick-dots {
    display: flex !important;
    border: 0.1px solid ${(props) => props?.propStyle?.heading?.color};
    height: 2px;
    width: 100%;
    margin: 0 auto;
    gap: 2px;
  }
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: ${(props) => 100 / props?.mediaContent?.content.length}%;
    height: 2px;
    margin: 0 !important;
    padding: 0;
    cursor: pointer;
  }
  .slick-dots li button {
    display: none;
  }
  .slick-dots .slick-active {
    background-color: ${(props) => props?.propStyle?.heading?.color};
  }
  .arrow-group {
    display: none;
  }
  .arrow-group button {
    background: transparent;
    outline: none;
    border: none;
  }
`;

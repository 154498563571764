import { SUBSCRIPTION_FLAG } from '@/utils/constants';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export function useFeatureLocks() {
  const { featureLocks, storeData } = useSelector((state) => ({
    featureLocks: state.commonReducer.featureLocksData.featureLocks,
    storeData: state.storeReducer.store,
  }));

  const isEcommExpired = useMemo(
    () => storeData?.subscription_flag === SUBSCRIPTION_FLAG.EXPIRED,
    [storeData?.subscription_flag]
  );

  const crrFeatureStatus = featureLocks['crr'];
  const couponsFeatureStatus = featureLocks['couponsVouchers'];
  const fbPixelFeatureStatus = featureLocks['fbPixel'];
  const googleAnalyticsFeatureStatus = featureLocks['googleAnalytics'];
  const googleTagFeatureStatus = featureLocks['gtm'];
  const installAppFeatureStatus = featureLocks['installApp'];
  const leadGenFeatureStatus = featureLocks['leadGen'];
  const visitorLoginFeatureStatus = featureLocks['visitorLogin'];
  const collectionsFeatureStatus = featureLocks['collections'];
  const notifyMeFeatureStatus = featureLocks['notifyMe'];
  const partialPaymentFeatureStatus = featureLocks['partialPayment'];
  const memberOnlyLoginFeatureStatus = featureLocks['memberOnlyLogin'];

  return {
    collectionsFeatureStatus,
    couponsFeatureStatus,
    crrFeatureStatus,
    fbPixelFeatureStatus,
    googleAnalyticsFeatureStatus,
    googleTagFeatureStatus,
    installAppFeatureStatus,
    leadGenFeatureStatus,
    visitorLoginFeatureStatus,
    isEcommExpired,
    notifyMeFeatureStatus,
    partialPaymentFeatureStatus,
    memberOnlyLoginFeatureStatus,
  };
}

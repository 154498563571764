import {
  CHECK_IS_ORDER_LOCATION_SERVICEABLE,
  DELETE_USER_ACCOUNT,
  FETCH_CITY_AND_STATE_FROM_PINCODE,
  FETCH_COUNTRIES_LIST,
  SET_LOGIN,
  SET_LOGOUT,
  SET_ROUTES,
  SET_ROUTE_CURRENT,
  SET_ROUTE_NEXT,
  SET_TRUECALLER_ABSENT,
  SET_TRUECALLER_PRESENT,
  TOGGLE_VERIFY_OTP,
} from '../actionTypes';

export const checkIfOrderLocationIsServiceable = (data, callback) => {
  return {
    type: CHECK_IS_ORDER_LOCATION_SERVICEABLE,
    data,
    callback,
  };
};

export const fetchCityStateFromPincode = (data, callback) => {
  return {
    type: FETCH_CITY_AND_STATE_FROM_PINCODE,
    data,
    callback,
  };
};

export const fetchCountriesList = (data, callback) => {
  return {
    type: FETCH_COUNTRIES_LIST,
    data,
    callback,
  };
};

export const setLogin = () => {
  return {
    type: SET_LOGIN,
  };
};

export const setLogout = () => {
  return {
    type: SET_LOGOUT,
  };
};

export const toggleVerifyOtp = () => {
  return {
    type: TOGGLE_VERIFY_OTP,
  };
};

export const truecallerPresent = () => {
  return {
    type: SET_TRUECALLER_PRESENT,
  };
};

export const truecallerAbsent = () => {
  return {
    type: SET_TRUECALLER_ABSENT,
  };
};

export const setCurrentRoute = (data) => {
  return {
    type: SET_ROUTE_CURRENT,
    data,
  };
};

export const setNextRoute = (data) => {
  return {
    type: SET_ROUTE_NEXT,
    data,
  };
};

export const setRoutes = (data) => {
  return {
    type: SET_ROUTES,
    data,
  };
};

export const deleteUserAccount = (data, callback) => {
  return {
    type: DELETE_USER_ACCOUNT,
    data,
    callback,
  };
};

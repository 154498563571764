/**
 * Required Module
 */
import { get } from 'axios';
import { ENDPOINT } from '../config/endpoints';

/**
 * Function for fetching custom
 * seo details
 * @param {number} storeId
 * @return {object}
 */
async function getSEODetails(storeId) {
  try {
    const response = await get(ENDPOINT.SEO.getCustomSeoDetails(storeId), {
      headers: {
        app_os: 'cfe',
      },
    });
    return { error: null, data: response.data };
  } catch (error) {
    return { error };
  }
}

/**
 * Add Exported Function here
 */
export { getSEODetails };

export const FOOTER_HEADING = 'Footer';

export const SECTION_TYPE = {
  FOOTER_SELECTION: 'footerSelection',
  LOGO_ABOUTUS: 'logoAboutUs',
  QUICK_LINKS: 'quickLinks',
  CONTACTUS_LOCATION: 'contactUs',
  NEWS_LETTER: 'newsLetter',
  CATEGORIES_COLLECTIONS: 'categoriesCollections',
  TEXT_GROUP: 'textGroup',
  CUSTOM_HTML: 'customHTML',
  COPYRIGHT: 'copyright',
  PAYMENT_MODES: 'paymentModes',
  SHOP: 'shop',
};

export const LOGO_HEADING = 'Logo';

export const DEKSKTOP_LOGO_SLIDER = {
  label: 'Desktop logo size',
  min: 0,
  max: 180,
  suffix: 'px',
  key: 'desktopSize',
  step: 1,
};

export const MOBILE_LOGO_SLIDER = {
  label: 'Mobile logo size',
  min: 0,
  max: 144,
  suffix: 'px',
  key: 'mobileSize',
  step: 1,
};

export const HIDE_LOGO_CHECKBOX = {
  label: 'Hide logo',
  key: 'hideLogo',
};

export const HIDE_STORE_NAME_CHECKBOX = {
  label: 'Hide store name',
  key: 'hideStoreName',
};

export const SHOW_LEARN_MORE = {
  label: 'Show link to **“learn more”** below about us',
  key: 'showMoreLink',
};

export const PAGE_NAME = {
  label: 'Page name',
  key: 'name',
  placeholder: 'Enter name',
  textLimit: 36,
};

export const PAGE_LINK = {
  label: 'Page link',
  key: 'link',
  placeholder: 'Enter link',
};

export const STATE_DEFAULT_VALUE = {
  type: 'text',
  name: '',
  link: '',
  redirectNewPage: false,
  pageLength: {
    'Page name': 0,
    'Page link': 0,
  },
};

export const CONTACT_HEADING = {
  label: 'Heading',
  placeholder: 'Enter contact heading',
  key: 'contentContact',
  charCountKey: 'contactCharCount',
};

export const LOCATION_HEADING = {
  label: 'Heading',
  placeholder: 'Enter location heading',
  key: 'contentLocation',
  charCountKey: 'locationCharCount',
};

export const CONTACT_US_CONTENT =
  'Contact and location details are brought from your dashboard. Edit this from';

export const SHOWMAP_CHECKBOX = {
  label: 'Show map',
  key: 'showMap',
};

export const NEWS_HEADING = {
  label: 'Heading',
  placeholder: 'Enter news letter heading',
  key: 'contentHeading',
  charCountKey: 'headingCharCount',
  limit: 100,
};

export const NEWS_DESCRIPTION = {
  label: 'Description',
  placeholder: 'Enter news letter description',
  key: 'contentDescription',
  charCountKey: 'descriptionCharCount',
  limit: 300,
};

export const NEWS_LETTER_IMAGES = [
  {
    type: 'type1',
    url: '/assets/images/widgetImages/NewFooter/newsLetter/newsLetter1.png',
  },
  {
    type: 'type2',
    url: '/assets/images/widgetImages/NewFooter/newsLetter/newsLetter2.png',
  },
  {
    type: 'type3',
    url: '/assets/images/widgetImages/NewFooter/newsLetter/newsLetter3.png',
  },
  {
    type: 'type4',
    url: '/assets/images/widgetImages/NewFooter/newsLetter/newsLetter4.png',
  },
];

export const APP_DOWNLOAD_IMAGES = [
  {
    type: 'type1',
    url: '/assets/images/widgetImages/NewFooter/socialMedium/appdownload/App 1.jpg',
  },
  {
    type: 'type2',
    url: '/assets/images/widgetImages/NewFooter/socialMedium/appdownload/App 2.jpg',
  },
  {
    type: 'type3',
    url: '/assets/images/widgetImages/NewFooter/socialMedium/appdownload/App 3.jpg',
  },
  {
    type: 'type4',
    url: '/assets/images/widgetImages/NewFooter/socialMedium/appdownload/App 4.jpg',
  },
];

export const SOCIAL_MEDIA_IMAGES = [
  {
    type: 'type1',
    url: '/assets/images/widgetImages/NewFooter/socialMedium/socialIcons/Social 4.jpg',
  },
  {
    type: 'type2',
    url: '/assets/images/widgetImages/NewFooter/socialMedium/socialIcons/Social 1.jpg',
  },
  {
    type: 'type3',
    url: '/assets/images/widgetImages/NewFooter/socialMedium/socialIcons/Social 2.jpg',
  },
  {
    type: 'type4',
    url: '/assets/images/widgetImages/NewFooter/socialMedium/socialIcons/Social 3.jpg',
  },
];

export const SHOW_ANDROID_NAME_CHECKBOX = {
  label: 'Show android app download button',
  key: 'showGooglePlay',
};

export const SHOW_APP_STORE = {
  label: 'Show iOS app download button',
  key: 'showAppStore',
};

export const PLAY_STORE_FIELD = {
  label: 'Android app link',
  placeholder: 'Add playstore link',
  key: 'playStoreLink',
};

export const APP_STORE_FIELD = {
  label: 'iOS app link',
  placeholder: 'Add appstore link',
  key: 'appStoreLink',
};

export const GOOGLE_TAB_CHECKBOX = {
  label: 'Open in new tab',
  key: 'newTabPlayStore',
};

export const APP_TAB_CHECKBOX = {
  label: 'Open in new tab',
  key: 'newTabAppStore',
};

const ICON_DROPDOWN = [
  {
    text: 'Store details and utilities',
    value: 'logoAboutUs',
  },
  {
    text: 'Contact us',
    value: 'contactUs',
  },
  {
    text: 'News letter',
    value: 'newsLetter',
  },
];

export const APP_DROPDOWN = {
  label: 'Move this section to',
  key: 'columnType',
  data: ICON_DROPDOWN,
  placeholder: '',
};

export const SOCIAl_DROPDOWN = {
  label: 'Move this section to',
  key: 'columnType',
  data: ICON_DROPDOWN,
  placeholder: '',
};

export const ICON_COMPONENT = {
  LOGO: 'logoAboutUs',
  CONTACT: 'contactUs',
  NEWS_LETTER: 'newsLetter',
};

export const COLOR_CONTAINER = ['Background', 'Text'];
export const COLOR_PICKER_MAP = {
  Background: {
    key: 'background',
    valueKey: 'background',
  },
  Text: {
    key: 'color',
    valueKey: 'text',
  },
};

import { getSeperatorByType } from '../Utils';
import { CONTENT_ANIMATION_ICONS } from 'src/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/EcommWidgets/HeroBanner/Svgs';

export const SEPERATOR_TYPE = {
  CIRCLE_OUTLINE: 'circleOutline',
  DOT: 'dot',
  DIAMOND_OUTLINE: 'diamondOutline',
  DIAMOND: 'diamond',
  SQUARE: 'square',
  SQUARE_ROUNDED: 'squareRounded',
  SQUARE_OUTLINE: 'squareOutline',
  DIAMOND_GROUP: 'diamondGroup',
  VERIFIED_SOLID: 'verifiedSolid',
  VERIFIED_OUTLINE: 'verifiedOutline',
} as const;

export function getSeperator(seperatorType) {
  const SeperatorIcon = getSeperatorByType(seperatorType);
  return SeperatorIcon ? <SeperatorIcon /> : null;
}

export const SEPERATOR_TYPE_OPTIONS = Object.values(SEPERATOR_TYPE).map((value) => ({
  value,
  renderData: () => getSeperator(value),
}));

export const CONFIG_TYPE = {
  LAYOUT: 'layout',
  CARD: 'card',
} as const;

export const ELEMENT_TYPE = {
  COLOR_COMBINATION: 'colorsCombination',
  SLIDER: 'slider',
  DROPDOWN: 'dropdown',
  MULTI_SELECT: 'multiselect',
  RADIO_BUTTON: 'radioButton',
  CHECKBOX: 'checkbox',
  ANIMATION_SELECTOR: 'contentAnimation',
  CONTENT_ANIMATION: 'contentAnimation',
} as const;

export const AUTO_PLAY_SPEED_OPTIONS = [
  { text: '0.5x', value: 45 },
  { text: '1x', value: 30 },
  { text: '1.5x', value: 22.5 },
  { text: '2x', value: 15 },
];

export const SCROLL_TYPE = {
  MANUAL: 'manual',
  AUTO_SCROLL: 'autoScroll', // continuous scroll behaviour
  AUTO_ROTATE: 'autoRotate', // assume it like auto next slide click after a certain time interval.
};

export const ANIMATION_TYPE = {
  NONE: 'none',
  FADE_IN: 'fadeIn',
  MOVE_DOWN: 'moveDown',
  MOVE_UP: 'moveUp',
  SLIDE_LEFT: 'slideLeft',
  SLIDE_RIGHT: 'slideRight',
};

export const CONTENT_ANIMATION_TYPE_OPTIONS = [
  {
    animeType: 'none',
    animationName: 'None',
  },
  {
    animeType: 'fadeIn',
    Icon: CONTENT_ANIMATION_ICONS.Expand,
    animationName: 'Fade in',
  },
  {
    animeType: 'moveDown',
    Icon: CONTENT_ANIMATION_ICONS.Up,
    animationName: 'Slide in bottom',
  },
  {
    animeType: 'moveUp',
    Icon: CONTENT_ANIMATION_ICONS.Down,
    animationName: 'Slide in top',
  },
  {
    animeType: 'slideLeft',
    Icon: CONTENT_ANIMATION_ICONS.Right,
    animationName: 'Slide in right',
  },
  {
    animeType: 'slideRight',
    Icon: CONTENT_ANIMATION_ICONS.Left,
    animationName: 'Slide in left',
  },
];

export const ANIMATION_TYPE_MAP = {
  [ANIMATION_TYPE.NONE]: { animeClassName: '', animationName: 'None' },
  [ANIMATION_TYPE.FADE_IN]: {
    animeClassName: 'widgetContentFadeInWrapper',
    animationName: 'Fade in',
  },
  [ANIMATION_TYPE.MOVE_DOWN]: {
    animeClassName: 'widgetContentMoveDownWrapper',
    animationName: 'Slide in bottom',
  },
  [ANIMATION_TYPE.MOVE_UP]: {
    animeClassName: 'widgetContentMoveUpWrapper',
    animationName: 'Slide in top',
  },
  [ANIMATION_TYPE.SLIDE_LEFT]: {
    animeClassName: 'widgetContentSlideLeftWrapper',
    animationName: 'Slide in right',
  },
  [ANIMATION_TYPE.SLIDE_RIGHT]: {
    animeClassName: 'widgetContentSlideRightWrapper',
    animationName: 'Slide in left',
  },
};

import {
  GET_STORE_ACTIVE_LEADGEN_TEMPLATE,
  UPDATE_CUSTOM_LEAD,
  SAVE_LEAD_GENINFO,
} from './../actionTypes';

export function getStoreActiveLeadGenTemplate(data, callback) {
  return {
    type: GET_STORE_ACTIVE_LEADGEN_TEMPLATE,
    data,
    callback,
  };
}
export function updateCustomerLead(data, callback) {
  return {
    type: UPDATE_CUSTOM_LEAD,
    data,
    callback,
  };
}

export function saveLeadGeninfo(data) {
  return {
    type: SAVE_LEAD_GENINFO,
    data,
  };
}

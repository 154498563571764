import store from '../redux/store';

export function isThemeBrandWebsite() {
  const storeTheme = store.getState()?.storeReducer?.store?.theme;

  if (!storeTheme || !storeTheme.brand_website_flag) {
    return false;
  }

  return storeTheme.brand_website_flag > 0;
}

// ExpandedSection.js
import React, { useEffect, useState } from 'react';
import styles from './megaMenu.module.scss';
import cn from 'classnames';
import {
  AccordionCloseIcon,
  AccordionOpenIcon,
  CollectionWithNoImageMobile,
} from './svgIcons';
import router from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import { useSSRSelector } from '@/redux/ssrStore';

const MegaMenuMobileView = ({
  open,
  onClose,
  primaryColor = '',
  secondaryColor = '',
  collectionWrapperClass = '',
  collectionItemWrapperClass = ' tw-px-[16px] tw-py-[12px] ',
}) => {
  const { storeData, collections } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
    collections: state.catalogReducer.collections,
  }));

  const [activeCollection, setActiveCollection] = useState(null);

  useEffect(() => {
    setActiveCollection(null);
  }, [open]);

  if (!collections.length) return null;

  const handleCollectionClick = (collectionId) => {
    setActiveCollection(activeCollection === collectionId ? null : collectionId);
  };

  const handleCategoryClick = (category, collectionId) => {
    router.push(
      getRoute(
        `${PATH.collectionCategories(collectionId)}?cid=${category.id}`,
        storeData?.store_info?.domain
      ),
      undefined,
      { shallow: true, scroll: true }
    );
    onClose && onClose();
  };

  const renderCollectionCategoriesAccordionComponent = () => {
    return (
      <div
        className={styles.collectionMobileAccordionContainer}
        style={{ backgroundColor: primaryColor || '#fff' }}
      >
        {collections.map((collection) => (
          <div
            key={collection.id}
            id={collection.id}
            className={cn(
              styles.collectionAccordionItem,
              collectionItemWrapperClass,
              activeCollection === collection.id ? 'active' : ''
            )}
            onClick={() => handleCollectionClick(collection.id)}
          >
            <div className={styles.collectionWrapper}>
              <div className={styles.collectionImageAndName}>
                <div className={styles.collectionImage}>
                  {collection.image_url ? (
                    <img src={collection.image_url} />
                  ) : (
                    <CollectionWithNoImageMobile />
                  )}
                </div>
                <span className={styles.collectionName} style={{ color: secondaryColor }}>
                  {collection.name}
                </span>
              </div>
              <div className={styles.accordionOpenCloseIcon}>
                {activeCollection === collection.id ? (
                  <AccordionCloseIcon secondaryColor={secondaryColor} />
                ) : (
                  <AccordionOpenIcon secondaryColor={secondaryColor} />
                )}
              </div>
            </div>
            <div
              className={cn(
                styles.categoriesWrapper,
                activeCollection === collection.id ? styles.open : ''
              )}
            >
              <div className={styles.categoryImage}>
                {collection.image_url ? (
                  <img src={collection.image_url} />
                ) : (
                  <CollectionWithNoImageMobile />
                )}
              </div>
              <div className={cn(styles.categoryItem, 'tw-mt-[12px]')}>
                {collection.categories.map((category, index) => (
                  <div
                    key={category.id}
                    className={cn(
                      styles.categoryName,
                      index !== collection.categories?.length - 1 && 'tw-mb-[16px]'
                    )}
                    style={{ color: secondaryColor }}
                    onClick={() => handleCategoryClick(category, collection.id)}
                  >
                    {category.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div
      className={cn(styles.mobileView, open ? styles.open : '')} // 'no-scrollbar'
      onClick={(e) => e.stopPropagation()}
    >
      <div
        style={{ width: '100%', maxHeight: '500px', background: '#fff', display: 'flex' }}
        className={collectionWrapperClass}
      >
        {renderCollectionCategoriesAccordionComponent()}
      </div>
    </div>
  );
};

export default MegaMenuMobileView;

/** MODULES */
import { Provider } from 'react-redux';
import store from './../redux/store';
import { useRouter } from 'next/router';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';

/** STYLES */
import './../styles/index.scss';
import '@dotpe/additional-pages/dist/styles/index.scss';
import '@/components/DesignSystem';
const WebsiteBuilderComponent = dynamic(
  () => import('@/components/WebsiteBuilderComponents')
);
import { SSRSelectorContext } from '@/redux/useSSRSelector';
import { WidgetDndContext } from 'src/context/WidgetDndContext';
import { useEffect } from 'react';

function MyApp(appProps: AppProps<any>) {
  const { query: qqqq } = useRouter();
  const { Component, pageProps } = appProps;
  const query = qqqq || appProps.router.query;
  const router = useRouter();
  const isWidgetRoute = router.pathname.includes('/widgets');

  useEffect(() => {
    if (query.code) {
      localStorage.setItem('instaCode', query.code as string);
      window.close();
    }
  }, [query.code]);

  if (query.code) {
    return null;
  }

  const domain =
    pageProps?.domain || pageProps?.ssrStore?.storeReducer?.store?.domain || '';
  const userAgent = pageProps?.userAgent || '';

  return (
    <Provider store={store}>
      <SSRSelectorContext.Provider value={{ domain, userAgent }}>
        {isWidgetRoute ? (
          <WebsiteBuilderComponent Component={Component} pageProps={pageProps} />
        ) : (
          <WidgetDndContext>
            <Component {...pageProps} />
          </WidgetDndContext>
        )}
      </SSRSelectorContext.Provider>
    </Provider>
  );
}

export default MyApp;

import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const options = {
  hideProgressBar: true,
  position: toast.POSITION.BOTTOM_CENTER,
  pauseOnHover: true,
  autoClose: 3000,
  draggable: false,
};
const optionsInfo = {
  type: toast.TYPE.INFO,
  ...options,
};
const optionsSuccess = {
  type: toast.TYPE.SUCCESS,
  ...options,
};
const optionsError = {
  type: toast.TYPE.ERROR,
  ...options,
};
const optionsWarning = {
  type: toast.TYPE.WARNING,
  ...options,
};

toast.configure({});

export const toastNotifyInfo = (message) => {
  try {
    toast.clearWaitingQueue();
    toast(message, optionsInfo);
  } catch (err) {
    console.log(err);
  }
};
export const toastNotifySuccess = (message) => {
  try {
    toast.clearWaitingQueue();
    toast(message, optionsSuccess);
  } catch (err) {
    console.log(err);
  }
};
export const toastNotifyError = (message) => {
  try {
    toast.clearWaitingQueue();
    toast(message, optionsError);
  } catch (err) {
    console.log(err);
  }
};
export const toastNotifyWarning = (message) => {
  try {
    toast.clearWaitingQueue();
    toast(message, optionsWarning);
  } catch (err) {
    console.log(err);
  }
};

export const toastNotifyErrorCloseAutoClose = (message) => {
  try {
    toast.clearWaitingQueue();
    toast(message, { ...optionsError, autoClose: false, toastId: message });
  } catch (err) {
    console.log(err);
  }
};

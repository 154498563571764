export const Tab3LeftArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3739 17.3731C9.98333 17.7636 9.35017 17.7636 8.95964 17.3731L2.95964 11.3731C2.56912 10.9826 2.56912 10.3494 2.95964 9.95891L8.95964 3.95891C9.35017 3.56838 9.98333 3.56838 10.3739 3.95891C10.7644 4.34943 10.7644 4.9826 10.3739 5.37312L6.08096 9.66602L17.6667 9.66602C18.219 9.66602 18.6667 10.1137 18.6667 10.666C18.6667 11.2183 18.219 11.666 17.6667 11.666L6.08096 11.666L10.3739 15.9589C10.7644 16.3494 10.7644 16.9826 10.3739 17.3731Z"
      fill={props?.color}
    />
  </svg>
);

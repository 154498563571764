import ImageSlider from '../CommonComponents/ImageSlider/ImageSlider';
import VideoMedia from '../CommonComponents/VideoMedia/VideoMedia';
import ImageMedia from '../CommonComponents/ImageMedia/ImageMedia';
import GridComponent from '../CommonComponents/GridComponent/GridComponent';

export const renderMediaComponent = (
  mediaData,
  displayType = '',
  aspectRatio = '3/4'
) => {
  switch (mediaData?.type) {
    case 'image':
      return displayType === 'grid' ? (
        <GridComponent mediaContent={mediaData} aspectRatio={aspectRatio} />
      ) : (
        <ImageMedia mediaContent={mediaData} />
      );
    case 'gif':
      return <ImageMedia mediaContent={mediaData} />;
    case 'video':
      return <VideoMedia mediaContent={mediaData} />;
    case 'slider':
      return displayType === 'grid' ? (
        <GridComponent mediaContent={mediaData} aspectRatio={aspectRatio} />
      ) : (
        <ImageSlider mediaContent={mediaData} />
      );
  }
};
export const hexToRgba = (hex, opacity = 0.1) => {
  if (!hex) {
    return hex;
  }
  hex = hex.replace(/^#/, '');
  // Check if it's a three-character hex code
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map(function (s) {
        return s + s;
      })
      .join('');
  }
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r},${g},${b},${opacity})`;
};

export const extractLatLngFromGoogleMapsLink = (googleMapsLink) => {
  const regex = /@(-?\d+\.\d+),(-?\d+\.\d+)/;
  const match = googleMapsLink.match(regex);
  if (match && match.length === 3) {
    const latitude = parseFloat(match[1]);
    const longitude = parseFloat(match[2]);
    return { latitude, longitude };
  } else {
    console.error('Invalid Google Maps link format');
    return null;
  }
};
export const MAP_URL =
  'https://www.google.com/maps/place/DotPe+Private+Limited/@28.4433524,77.052414,17z/data=!3m1!4b1!4m6!3m5!1s0x390d19217385e303:0xd8e5882726fc277b!8m2!3d28.4433477!4d77.0549889!16s%2Fg%2F11qs7f_yb0?entry=ttu';

export const FALLBACK_COLOR = '#D3D3D3';
export const BANNER_OPACITY = 0.8;
export const BODY_COLOR_OPACITY = 0.6;
export const TAB_BAR_OPACITY = 0.1;
export const FOOTER_OPACITY = 0.8;

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';

const MAX_ALLOWED_CUSTOM_INPUT_VALUE = 10000;

const CartItemInput = ({ value, onChange }) => {
  const [localCount, setLocalCount] = useState(null);

  const { cartData } = useSelector((state: RootState) => ({
    cartData: state.cartReducer,
  }));

  useEffect(() => {
    localCount !== value && setLocalCount(value);
  }, [cartData, value]);

  const handleInputChange = (e) => {
    const updatedCount = +e.target.value;
    if (!isNaN(updatedCount) && updatedCount < MAX_ALLOWED_CUSTOM_INPUT_VALUE) {
      setLocalCount(updatedCount);
    }
  };

  const reset = () => setLocalCount(value);

  const onSubmit = () => {
    if (localCount !== value) {
      onChange(+localCount || 1, reset);
      !+localCount && setLocalCount(1);
    }
  };

  return (
    <input
      value={localCount || ''}
      onChange={handleInputChange}
      onBlur={onSubmit}
      className="tw-mx-auto tw-w-[40px] tw-border-none tw-text-center focus:tw-bg-[#AAD7F8]"
    />
  );
};

export default CartItemInput;

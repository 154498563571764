import { get } from 'axios';
import { ENDPOINT } from './../config/endpoints';

/**
 * Function for fetching all data necessary for theme generation. This includes all the categories, styles, components etc.
 */

async function fetchWidgetThemeInfo() {
  try {
    const response = await get(ENDPOINT.WIDGETS.getWidgetThemeContent, {
      headers: {
        app_os: 'cfe',
      },
    });
    return { error: null, data: response.data };
  } catch (error) {
    return { error, data: null };
  }
}

async function fetchExistingThemeData(themeId) {
  try {
    if (themeId) {
      const response = await get(ENDPOINT.WIDGETS.fetchTheme(themeId), {
        headers: {
          app_os: 'cfe',
        },
      });
      return { error: null, data: response.data };
    } else {
      return { error: true, data: null };
    }
  } catch (error) {
    return { error, data: null };
  }
}

/**
 * Add Exported Function here
 */
export { fetchWidgetThemeInfo, fetchExistingThemeData };

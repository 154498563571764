export const changeArrayIndex = <T extends any = any>(
  arr: T[],
  fromIndex: number,
  toIndex: number
) => {
  const itemToMove = arr[fromIndex];
  const newArr = [...arr];

  newArr.splice(fromIndex, 1); // remove item fromIndex
  newArr.splice(toIndex, 0, itemToMove); // add item toIndex
  return newArr;
};

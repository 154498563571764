/**
 * check if list type items are validated
 * @param {*} items
 * @return {*} items, validate
 */
export const validateItemProperties = (items) => {
  let validate = true;
  items = items.reduce((acc, curr) => {
    if (curr.item_type === 'list') {
      if (curr.quantity === '' || curr.item_name === '') {
        validate = false;
      } else {
        curr.quantity = curr.quantity !== '' ? Number(curr.quantity) : 0;
        acc.push(curr);
      }
    } else if (curr.item_type === 'catalog') {
      acc.push(curr);
    }
    return acc;
  }, []);
  if (items.length === 0) validate = false;
  return { items, validate };
};

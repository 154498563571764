import classNames from 'classnames';
import { IS_SERVER } from 'src/utils/checkRenderEnv';
import { KeyboardArrowRight } from '@mui/icons-material';

export const Option = ({
  option,
  idx,
  renderArray,
  isShopComponent,
  customItemConfigs,
  onNavItemClick,
  fromMobileMenuDrawer,
  handleMouseAction,
  isSubNavListActive,
}) => {
  const isNavLinkActive =
    !IS_SERVER && !!option.redirectionLink
      ? window.location.pathname.includes(
          option.redirectionLink?.startsWith('/')
            ? option.redirectionLink
            : `/${option.redirectionLink}`
        )
      : false;

  const { onLinkHoverEnter, onLinkHoverLeave, getNavElementStyle } =
    customItemConfigs || {};
  const isLastOption = idx === renderArray.length - 1;
  const hasSubNavList = option?.type === 'dropdown';

  return (
    <div
      key={option.name}
      className={classNames(
        'tw-relative tw-px-[20px] [&>.underline-comp]:hover:tw-block'
      )}
      onMouseOver={(e) => handleMouseAction?.(e.target)}
      onClick={() => !hasSubNavList && onNavItemClick(option)}
    >
      <div
        className={
          ((!option?.redirectionLink || isNavLinkActive) &&
            !isShopComponent &&
            !Object.hasOwn(option, 'id')) ||
          hasSubNavList
            ? 'tw-pointer-events-none'
            : ' '
        }
      >
        <p
          onMouseEnter={() => onLinkHoverEnter(idx)}
          onMouseLeave={onLinkHoverLeave}
          onClick={option.onClick}
          style={getNavElementStyle ? getNavElementStyle(idx) : {}}
          className={
            'tw-relative tw-w-fit tw-max-w-[150px] tw-select-none tw-whitespace-normal tw-break-words tw-leading-normal hover:!tw-font-semibold [&_.underline-comp]:hover:tw-block ' +
            (!fromMobileMenuDrawer
              ? 'tw-m-0 tw-text-[12px] tw-tracking-[0.95px] ' +
                (!isLastOption ? 'tw-pb-[16px]' : '')
              : 'tw-m-0  ' + (!isLastOption ? 'tw-pb-[17.5px]' : '')) +
            (isSubNavListActive ? ' !tw-font-semibold' : '') +
            (hasSubNavList ? ' !tw-w-full' : '')
          }
          key={idx.id}
        >
          <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
            {option.name}
            {hasSubNavList && <KeyboardArrowRight fontSize="small" />}
          </div>
        </p>
      </div>
    </div>
  );
};

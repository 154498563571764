import { getContrastingColor } from '@/utils/getContrastingColor';
import { ORDERS_DROPDOWN_LIST } from '@/utils/constants';
import { executeLogout } from '@/utils/excuteLogout';
import { getRoute, PATH } from '@/utils/routes';
import { UserIconDark } from '@/assets/svgExports/UserIconDark';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import getOrdersDropdownList from '@/utils/orderDropdownList';
import LogoutModal from '../LogoutModal';
import { ClickAwayListener } from '@mui/material';

const LoginComponent = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const router = useRouter();
  // const { headerHeight } = useSSRSelector((state) => ({
  //   headerHeight: state.commonReducer?.heightCalculation.headerHeight,
  // }));
  const [node, setNode] = useState(null);

  const { user, storeData } = useSelector((state) => ({
    user: state.userReducer,
    storeData: state.storeReducer.store,
  }));

  useEffect(() => {
    setShowLogoutModal(false);
  }, [user.isLogin]);

  const ordersDropdownList = getOrdersDropdownList();

  const iconRef = useCallback((node) => {
    if (!node) {
      return;
    }
    setNode(node);
  }, []);

  const loginClick = () => {
    if (user?.isLogin && !props.disableDropdown) {
      setShowDropdown(!showDropdown);
    } else if (router.asPath?.includes(`/${PATH.ORDERS}`)) router.reload();
    else {
      router.push(getRoute(PATH.ORDERS, storeData?.store_info?.domain));
    }
  };

  const onHandleOrdersDropdown = (item, e = null) => {
    switch (item.action) {
      case ORDERS_DROPDOWN_LIST.MY_ORDERS: {
        toggleShowDesktopDropdown();
        router.push(getRoute(PATH.ORDERS, storeData?.store_info?.domain));
        break;
      }
      case ORDERS_DROPDOWN_LIST.LOGOUT: {
        toggleLogoutModal();
        setShowDropdown(false);
        break;
      }
      case ORDERS_DROPDOWN_LIST.DEFAULT_PHONE: {
        break;
      }
      default: {
        break;
      }
    }
    props.closeParentContainer?.();
  };

  function toggleShowDesktopDropdown() {
    setShowDropdown(!showDropdown);
  }

  function toggleLogoutModal() {
    setShowLogoutModal(!showLogoutModal);
  }

  return (
    <>
      <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
        <div
          ref={iconRef}
          className="relative login-component tw-select-none tw-font-dmSans"
          style={props.style || {}}
        >
          <div className="flex items-center" onClick={loginClick}>
            {props.children}
          </div>
          {showDropdown && (
            <div
              className=" absolute z-999 login-component__dropdown"
              style={{
                top: node ? node?.offsetTop + node?.offsetHeight : undefined,
              }}
            >
              <div className="login-component__dropdown--arrow"></div>
              {ordersDropdownList?.map((item) => (
                <div
                  key={item.id}
                  className={`login-component__dropdown--item flex items-center z-5 tw-px-[20px] tw-py-[16px] 
                ${
                  props?.headerBgColor &&
                  getContrastingColor(props?.headerBgColor) === '#ffffff' &&
                  item.id !== 3
                    ? 'filter-invert-1'
                    : 'filter-invert-0'
                } ${item.id === 3 ? 'red ' : ''} ${item.id !== 1 && 'pointer'}`}
                  onClick={(e) => onHandleOrdersDropdown(item, e)}
                >
                  {item.id === 1 && (
                    <div className="tw-mr-[10px]">
                      <UserIconDark
                        color={storeData?.theme?.primary_color}
                        width="33"
                        height="33"
                      />
                    </div>
                  )}
                  {item.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </ClickAwayListener>
      {showLogoutModal && (
        <LogoutModal
          toggleLogoutModal={toggleLogoutModal}
          executeLogout={executeLogout}
          showLogoutConfirmation={showLogoutModal}
        />
      )}
    </>
  );
};

export default LoginComponent;

import { GENDER_TYPES } from './constants';

export function getGenderStatus(gender) {
  switch (gender) {
    case GENDER_TYPES.MALE:
      return 1;
    case GENDER_TYPES.FEMALE:
      return 2;
    case GENDER_TYPES.OTHERS:
      return 3;
    default:
      return 0;
  }
}

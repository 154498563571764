import { SAVE_STORE_POLICIES } from '../actionTypes';

const initialState = {
  data: [],
  isFetchedFromSSR: false,
};

const StorePolicyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_STORE_POLICIES:
      return {
        ...state,
        data: action.data,
        isFetchedFromSSR: action.fromSSR,
      };
    default:
      return state;
  }
};

export default StorePolicyReducer;

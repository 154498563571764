let videoRegex = `(?:(https?):)?//`; // protocol, http is optional
videoRegex += `(.*?)/`; // domain
videoRegex += `(.*?)`; // path
// eslint-disable-next-line no-useless-escape
videoRegex += `(?:\.(.*))?$`; // extension, optional

const VIDEO_EXTENSIONS = ['mp4', '3gp', 'ogg', 'mkv'];

const EXTENSION_MIME_TYPE = {
  mp4: 'video/mp4',
  avi: 'video/x-msvideo',
  mov: 'video/quicktime',
  wmv: 'video/x-ms-wmv',
  flv: 'video/x-flv',
  webm: 'video/webm',
  mkv: 'video/x-matroska',
};

export const getVideoParts = (url) => {
  const VIDEO_REGEX = new RegExp(videoRegex, 'igm');
  const videoUrlParts = VIDEO_REGEX.exec(url);
  if (videoUrlParts) {
    const [url, protocol, domain, path, extension] = videoUrlParts;
    const videoParts = {
      url,
      protocol,
      domain,
      path,
      extension,
      mimeType: EXTENSION_MIME_TYPE[extension],
    };
    return videoParts;
  }
};

export const getYoutubeVideoId = (url) => {
  const youtubeRegex =
    /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const youtubeVideoParts = youtubeRegex.exec(url);

  if (youtubeVideoParts) {
    return youtubeVideoParts[1];
  }

  return null; // Return null if no match is found
};

export const isYoutubeVideo = (videoParts) => {
  if (videoParts) {
    return (
      videoParts.domain.includes('youtube') || videoParts.domain.includes('youtu.be')
    );
  }
};

export const isVimeoVideo = (videoParts) => {
  if (videoParts) {
    return videoParts.domain.includes('vimeo');
  }
};

export const isVideoUrl = (uri) => {
  if (!uri) return false;
  const url = new URL(uri);
  const extension = url.pathname.split('.')[1];
  return VIDEO_EXTENSIONS.includes(extension);
};

import { IWidgetDndHelpers } from "../types";

export const DEFAULT_WIDGET_DND_CONTEXT_HELPERS: IWidgetDndHelpers = {
  toggleSidePanelState: () => {},
  handleWidgetPropChangeForBasicWidgets: () => {},
  handlePublish: () => {},
  handleWidgetPropChange: () => {},
  handleWidgetAddFromDrawerWithConfig: () => {},
  addWidget: () => {},
  handleWidgetAdd: () => {},
  addWidgetViaMainSection: () => {},
  handleWidgetSelect: () => {},
  handleDeleteIconClick: () => {},
  removeWidgetRecursive: () => {},
  removeWidget: () => {},
  handleDelete: () => {},
  handleUpDownMove: () => {},
  handleWidgetAddFromDrawer: () => {},
  handleReorderWidgetSidebar: () => {},
  redirectToWidgetOutline: () => {},
};

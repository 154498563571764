import { getSaveCartItems } from '@/utils/getSaveCartItems';
import { ORDER_TYPE } from '@/utils/constants';
import {
  SAVE_CART,
  SAVE_PHONE_NUMBER,
  SAVE_ORDER_INSTRUCTION,
  EMPTY_CART,
  APPLY_COUPON,
  UPDATE_CART_ID,
  UPDATE_B2B_CART,
  UPDATE_RFQ_DETAILS,
  SAVE_EMAIL_ID,
} from '../actionTypes';

const initialState = {
  items: [],
  instructions: '',
  delivery_info: null,
  store_id: null,
  order_type: 1,
  pay_amount: 0,
  amount: 0,
  delivery_charge: 0,
  promo_code: '',
  promo_discount: 0,
  user_phone: '',
  phone: '',
  user_email: '',
  email: '',
  cart_id: '',
  rfq_details: {
    name: '',
    phone: '',
    email: '',
    custom_message: '',
    address: '',
    date_and_time: '',
    gst: '',
  },
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_COUPON:
    case SAVE_CART:
      return {
        ...state,
        ...action.data,
        items: getSaveCartItems(action),
      };
    case SAVE_PHONE_NUMBER:
      return {
        ...state,
        user_phone: action.data,
        phone: action.data,
        email: '',
        user_email: '',
      };
    case SAVE_EMAIL_ID:
      return {
        ...state,
        user_email: action.data,
        email: action.data,
        phone: '',
        user_phone: '',
      };
    case SAVE_ORDER_INSTRUCTION:
      return { ...state, instructions: action.data };
    case EMPTY_CART:
      return {
        ...state,
        items: [],
        instructions: '',
        delivery_info: null,
        pay_amount: 0,
        amount: 0,
        delivery_charge: 0,
        promo_code: '',
        promo_discount: 0,
        cart_id: '',
        items_total: 0,
        buyer_pay_value: 0,
        store_offer: {
          ...(state?.store_offer || {}),
          promo_code: '',
          promo_discount: 0,
          promo_id: 0,
        },
        payment_offer: {
          ...(state?.payment_offer || {}),
          card_number: '',
          payment_method: '',
          promo_code: '',
          promo_discount: 0,
          promo_id: 0,
        },
      };

    case UPDATE_CART_ID:
      return {
        ...state,
        cart_id: action.data,
      };
    case UPDATE_B2B_CART:
      return {
        ...state,
        order_type: ORDER_TYPE.B2B,
        order_mode: 3,
        items: action.data,
      };
    case UPDATE_RFQ_DETAILS:
      return {
        ...state,
        rfq_details: { ...action.data },
      };
    default:
      return state;
  }
};

export default CartReducer;

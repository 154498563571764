import store from '@/redux/store';
/**
 * returns name of category given categoryID or TagId
 * @param {number} id categoryId or TagId
 * @return {string} name of category or Tag
 */
export function getCategoryTagNameFromId(id) {
  const categories = store.getState().catalogReducer?.categories;
  let categoryTagDetails = categories.find((categoryItem) => id === categoryItem?.id);

  // if categoryTagDetails is undefined return "all" as category name
  if (!categoryTagDetails) return 'all';

  if (!categoryTagDetails?.name) {
    const tags = store.getState().catalogReducer?.productTags;
    categoryTagDetails = tags.find((tagItem) => id === tagItem?.id);
  }

  return categoryTagDetails?.name;
}

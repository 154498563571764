export const ICON_POSITION = [
  {
    text: 'Top',
    value: 'top',
  },
  {
    text: 'Left',
    value: 'left',
  },
];

export const ICON_ALIGNMENT = [
  {
    text: 'Top',
    value: 'top',
  },
  {
    text: 'Center',
    value: 'center',
  },
];

export const HEADING_ALIGNMENT = [
  {
    text: 'Left',
    value: 'left',
    iconBaseClass: 'wb-sprite ic-align-left faded',
    iconActiveClass: 'wb-sprite ic-align-left',
  },
  {
    text: 'Centre',
    value: 'center',
    iconBaseClass: 'wb-sprite ic-align-center faded',
    iconActiveClass: 'wb-sprite ic-align-center',
  },
];

export const TEXT_SIZE_DROPDOWN = [
  {
    text: 'Extra small (12px)',
    value: 'extraSmall',
    itemLevelClass: { fontSize: '12px !important' },
  },
  {
    text: 'Small (14px)',
    value: 'small',
    itemLevelClass: { fontSize: '14px !important' },
  },
  {
    text: 'Medium (16px)',
    value: 'medium',
    itemLevelClass: { fontSize: '16px !important' },
  },
  {
    text: 'Large (18px)',
    value: 'large',
    itemLevelClass: { fontSize: '18px !important' },
  },
  {
    text: 'Extra Large (20px)',
    value: 'extraLarge',
    itemLevelClass: { fontSize: '20px !important' },
  },
];

export const CARD_APPEARANCE_DATA = [
  {
    text: 'Standard',
    value: 'standard',
  },
  {
    text: 'Card',
    value: 'card',
  },
];

export const HOVER_EFFECT_DROPDOWN = [
  {
    text: 'Zoom in',
    value: 'zoomIn',
  },
  {
    text: 'Ambient',
    value: 'ambient',
  },
  {
    text: 'None',
    value: 'none',
  },
];

export const MULTI_COLUMN_ICON_DEFAULT_VALUE = {
  imageDetail: {
    url: '',
    src: '',
    aspectRatio: '',
    zoom: '',
    manualUpdate: true,
  },
  title: '<p>Example title</p>',
  titleCharCount: 0,
  description:
    'Use this section to explain a set of product features, to link to a series of pages, or to answer common questions about your products.',
  descriptionCharCount: 0,
  redirectUrl: '',
  redirectionType: 'link',
  isOpenInNewTab: true,
  isVisible: true,
};

export const COLUMN_TITLE_CONFIG = {
  label: 'Heading',
  placeholder: 'Enter title',
  contentKey: 'title',
  countKey: 'titleCharCount',
};

export const COLUMN_DESCRIPTION_CONFIG = {
  label: 'Description',
  placeholder: 'Enter description',
  contentKey: 'description',
  countKey: 'descriptionCharCount',
};

export function WidgetOutlineCollectionIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clip-path="url(#clip0_1166_3499)">
        <path
          d="M2.25 9.75H3.75V8.25H2.25V9.75ZM2.25 12.75H3.75V11.25H2.25V12.75ZM2.25 6.75H3.75V5.25H2.25V6.75ZM5.25 9.75H15.75V8.25H5.25V9.75ZM5.25 12.75H15.75V11.25H5.25V12.75ZM5.25 5.25V6.75H15.75V5.25H5.25ZM2.25 9.75H3.75V8.25H2.25V9.75ZM2.25 12.75H3.75V11.25H2.25V12.75ZM2.25 6.75H3.75V5.25H2.25V6.75ZM5.25 9.75H15.75V8.25H5.25V9.75ZM5.25 12.75H15.75V11.25H5.25V12.75ZM5.25 5.25V6.75H15.75V5.25H5.25Z"
          fill="#222222"
        />
      </g>
      <defs>
        <clipPath id="clip0_1166_3499">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { transformBEDataToFE } from '@/utils/transformBEDataToFE';
import {
  TOGGLE_SEARCHBOX_STATE,
  SET_ATHS_POPUP_VISIBILITY,
  SAVE_ATHS_EVENT,
  SET_LOADER_STATE,
  SET_HEADER_HEIGHT,
  SET_SUBHEADER_HEIGHT,
  SET_HEROBANNER_HEIGHT,
  RESET_HEIGHT,
  SAVE_PRODUCT_LOADER_ID,
  SAVE_DETAILED_ITEM_BY_ID,
  SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY,
  SET_VARIANT_MODEL_VISIBILITY,
  SET_SELECTED_VARIANT_MODEL_VISIBILITY,
  SET_PAYMENT_MODEL_VISIBILITY,
  TOGGLE_LAZYPAY_AUTH_MODAL,
  TOGGLE_MOBILE_VIEW,
  TOGGLE_SET_CART_LOADER_STATE,
  SAVE_CREATE_LIST,
  COUPON_BANNER_PRESENT,
  COUPON_BANNER_ABSENT,
  SET_TOGGLE_ADD_DELIVERY_FORM,
  SAVE_HEADER_HEIGHT,
  SET_FILTER_LOADER,
  SAVE_HEADER_REF,
  SAVE_FEATURE_LOCKS,
  SET_PAYMENT_MODAL_OPTIONS_VISIBILITY,
  SET_GLOBAL_LOADER,
  PREPAID_LIMIT_DISABLE_PAY_NOW,
  SET_ORDER_STATUS_ERROR_FOR_INVALID_USER,
  HIDE_FILTERS_CATEGORY_AND_COLLECTION,
  TOGGLE_FORM_WIDGET,
  SET_EXIT_GATING_DATA,
  SET_SHOW_INSTALL_APP_OVERLAY,
  TOGGLE_LOGOUT_CONFIRM_MODAL,
  UPDATE_COMMON_REDUCER,
  SAVE_FOOTER_PAYMENT_MODES_LIST,
  TOGGLE_PROMO_DETAIL_MODAL,
  TOGGLE_PROMO_REMOVAL_ALERT_MODAL,
  SAVE_LATEST_SYNCED_CART,
  SET_UNAVAILABLE_QUANTITY_ALERT_STATE,
  SET_SCHEDULE_INFO,
  HYDRATE_COMMON_REDUCER,
} from '../actionTypes';

import {
  FEATURE_LOCKS_INITIAL_LIST,
  LAZYPAY_AUTH_MODAL_VIEWS,
} from '../../utils/constants';

const initialState = {
  searchModal: false,
  athsPopupVisibilty: false,
  athsEvent: null,
  loader: false,
  productLoaderId: null,
  variant: {
    variantModal: false,
    chooseRepeatModal: false,
    selectedVariantsModal: false,
  },
  detailedItem: null,
  heightCalculation: {
    headerHeight: 0,
    headerHeightCalculated: false,
    subHeaderHeight: 0,
    subHeaderHeightCalculated: false,
    heroBannerHeight: 0,
    heroBannerHeightCalculated: false,
  },
  paymentModalVisibility: false,
  paymentModalOptionsVisibility: true,
  lazyPayAuthModal: {
    show: false,
    view: LAZYPAY_AUTH_MODAL_VIEWS.OTP,
  },
  toggleMobileView: false,
  setCartLoader: false,
  createList: [],
  couponBannerPresent: false,
  toggleAddDeliveryForm: false,
  headerHeight: 0,
  filterOptionLoader: false,
  headerRef: '',
  featureLocksData: {
    isFeatureLocksLoaded: false,
    featureLocks: FEATURE_LOCKS_INITIAL_LIST,
  },
  globalLoader: false,
  prepaidLimitDisablePayNow: false,
  showOrderStatusError: false,
  hideFiltersCategoryAndCollection: false,
  toggleFormState: false,
  exitGatingData: {
    status: null,
    selectedTemplate: null,
    imagePath: null,
    minUserTimeSpent: null,
    perDayPopUpLimit: null,
    offerSelected: null,
    couponData: null,
  },
  showInstallAppOverlay: false,
  showLogoutConfirmationModal: false,
  footerPaymentModesList: [],
  promoDetailModalState: {
    show: false,
    promoId: null,
  },
  promoRemovalAlertModalState: {
    show: false,
    cartDataAfterOfferRemoval: null,
  },
  latestSyncedCart: null,
  unavailableQuantityAlertState: {
    show: false,
    availableQuantity: null,
    onConfirm: null,
    onCancel: null,
  },
  scheduleInfo: null,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SEARCHBOX_STATE:
      return { ...state, searchModal: action.data };
    case SET_ATHS_POPUP_VISIBILITY:
      return { ...state, athsPopupVisibilty: action.data };
    case SAVE_ATHS_EVENT:
      return { ...state, athsEvent: action.data };
    case SET_LOADER_STATE:
      return { ...state, loader: action.data };
    case SET_HEADER_HEIGHT:
      return {
        ...state,
        heightCalculation: {
          ...state.heightCalculation,
          headerHeight: !state.heightCalculation.headerHeightCalculated
            ? state.heightCalculation.headerHeight + action.data
            : state.heightCalculation.headerHeight,
          headerHeightCalculated: true,
        },
      };
    case SET_SUBHEADER_HEIGHT:
      return {
        ...state,
        heightCalculation: {
          ...state.heightCalculation,
          subHeaderHeight: !state.heightCalculation.subHeaderHeightCalculated
            ? state.heightCalculation.subHeaderHeight + action.data
            : state.heightCalculation.subHeaderHeight,
          subHeaderHeightCalculated: true,
        },
      };
    case SET_HEROBANNER_HEIGHT:
      return {
        ...state,
        heightCalculation: {
          ...state.heightCalculation,
          heroBannerHeight: !state.heightCalculation.heroBannerHeightCalculated
            ? state.heightCalculation.headerHeight +
              state.heightCalculation.subHeaderHeight
            : state.heightCalculation.heroBannerHeight,
          heroBannerHeightCalculated: true,
        },
      };
    case RESET_HEIGHT:
      return {
        ...state,
        heightCalculation: {
          ...state.heightCalculation,
          headerHeight: 0,
          headerHeightCalculated: false,
          subHeaderHeight: 0,
          subHeaderHeightCalculated: false,
          heroBannerHeight: 0,
          heroBannerHeightCalculated: false,
        },
      };
    case SAVE_PRODUCT_LOADER_ID:
      return {
        ...state,
        productLoaderId: action.data,
      };
    case SAVE_DETAILED_ITEM_BY_ID:
      return {
        ...state,
        detailedItem: action.data,
      };
    case SET_CHOOSE_AND_REPEAT_MODEL_VISIBILITY:
      return {
        ...state,
        variant: {
          variantModal: false,
          chooseRepeatModal: action.data,
          selectedVariantsModal: false,
        },
      };
    case SET_VARIANT_MODEL_VISIBILITY:
      return {
        ...state,
        variant: {
          variantModal: action.data,
          chooseRepeatModal: false,
          selectedVariantsModal: false,
        },
      };
    case SET_SELECTED_VARIANT_MODEL_VISIBILITY:
      return {
        ...state,
        variant: {
          variantModal: false,
          chooseRepeatModal: false,
          selectedVariantsModal: action.data,
        },
      };
    case SET_PAYMENT_MODEL_VISIBILITY:
      return {
        ...state,
        paymentModalVisibility: action.data,
      };
    case TOGGLE_LAZYPAY_AUTH_MODAL:
      return {
        ...state,
        lazyPayAuthModal: {
          show: action.data.show,
          view: action.data.view || LAZYPAY_AUTH_MODAL_VIEWS.OTP,
        },
      };
    case TOGGLE_MOBILE_VIEW:
      return {
        ...state,
        toggleMobileView: action.data,
      };
    case TOGGLE_SET_CART_LOADER_STATE:
      return {
        ...state,
        setCartLoader: action.data,
      };
    case SAVE_CREATE_LIST:
      return {
        ...state,
        createList: action.data,
      };
    case COUPON_BANNER_PRESENT:
      return {
        ...state,
        couponBannerPresent: true,
      };
    case COUPON_BANNER_ABSENT:
      return {
        ...state,
        couponBannerPresent: false,
      };
    case SET_TOGGLE_ADD_DELIVERY_FORM:
      return {
        ...state,
        toggleAddDeliveryForm: action.data,
      };
    case SAVE_HEADER_HEIGHT:
      return {
        ...state,
        headerHeight: action.data,
      };
    case SET_FILTER_LOADER:
      return {
        ...state,
        filterOptionLoader: action.data,
      };
    case SAVE_HEADER_REF:
      return {
        ...state,
        headerRef: action.data,
      };
    case SAVE_FEATURE_LOCKS:
      return {
        ...state,
        featureLocksData: {
          isFeatureLocksLoaded: true,
          featureLocks: transformBEDataToFE(action.data),
        },
      };
    case SET_PAYMENT_MODAL_OPTIONS_VISIBILITY:
      return {
        ...state,
        paymentModalOptionsVisibility: action.data,
      };
    case SET_GLOBAL_LOADER:
      return {
        ...state,
        globalLoader: action.data,
      };
    case PREPAID_LIMIT_DISABLE_PAY_NOW:
      return {
        ...state,
        prepaidLimitDisablePayNow: action.data,
      };
    case SET_ORDER_STATUS_ERROR_FOR_INVALID_USER:
      return {
        ...state,
        showOrderStatusError: action.data,
      };
    case HIDE_FILTERS_CATEGORY_AND_COLLECTION: {
      return {
        ...state,
        hideFiltersCategoryAndCollection: action.data,
      };
    }
    case TOGGLE_FORM_WIDGET: {
      return {
        ...state,
        toggleFormState: action.data,
      };
    }
    case SET_EXIT_GATING_DATA: {
      return {
        ...state,
        exitGatingData: action.data,
      };
    }
    case SET_SHOW_INSTALL_APP_OVERLAY: {
      return {
        ...state,
        showInstallAppOverlay: action.data,
      };
    }
    case TOGGLE_LOGOUT_CONFIRM_MODAL: {
      return {
        ...state,
        showLogoutConfirmationModal: action.data ?? !state.showLogoutConfirmationModal,
      };
    }
    case UPDATE_COMMON_REDUCER: {
      return {
        ...state,
        ...action.data,
      };
    }
    case SAVE_FOOTER_PAYMENT_MODES_LIST: {
      return {
        ...state,
        footerPaymentModesList: action.data,
      };
    }
    case TOGGLE_PROMO_DETAIL_MODAL: {
      return {
        ...state,
        promoDetailModalState: {
          show: action?.data?.show ?? false,
          promoId: action?.data?.promoId ?? null,
        },
      };
    }
    case TOGGLE_PROMO_REMOVAL_ALERT_MODAL: {
      return {
        ...state,
        promoRemovalAlertModalState: {
          show: action?.data?.show ?? false,
          cartDataAfterOfferRemoval: action?.data?.cartDataAfterOfferRemoval ?? null,
        },
      };
    }
    case SAVE_LATEST_SYNCED_CART: {
      return {
        ...state,
        latestSyncedCart: action?.data || null,
      };
    }
    case SET_UNAVAILABLE_QUANTITY_ALERT_STATE: {
      return {
        ...state,
        unavailableQuantityAlertState: {
          show: action?.data?.show || false,
          availableQuantity: action?.data?.availableQuantity || null,
          onConfirm: action?.data?.onConfirm || null,
          onCancel: action?.data?.onCancel || null,
        },
      };
    }
    case SET_SCHEDULE_INFO: {
      return {
        ...state,
        scheduleInfo: action?.data || null,
      };
    }
    case HYDRATE_COMMON_REDUCER: {
      return {
        ...state,
        ...(action?.data || {}),
      };
    }
    default:
      return state;
  }
};

export default commonReducer;

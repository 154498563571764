import { createRequires } from '@paciolan/remote-module-loader';
import { Slider } from '@/components/ReactSlickCarousel';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';

export const getRemoteRequires = (additionalRequires: Record<string, any> = {}) => {
  return createRequires({
    react: require('react'),
    'react-slick': Slider,
    ...additionalRequires,
  });
};

export const fetcher = (url: string) => {
  return axios
    .get<string>(url, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
    .then((resp) => resp.data);
};

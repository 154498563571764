/**
This file has been deprecated.
Only to be used to add reference for any new component added.

import { Button, CartButton, Switch } from './AtomicComponents';
import { SubHeader1, SubHeader2, SubHeader3, SubHeader4, SubHeader } from './SubHeader';
import {
  HeroBanner1,
  HeroBanner2,
  HeroBanner3,
  HeroBanner4,
  HeroBanner5,
  HeroBanner6,
  HeroBanner7,
  HeroBanner8,
  HeroBanner9,
} from './HeroBanner';
import {
  Header1,
  Header2,
  Header3,
  Header4,
  Header5,
  Header6,
  Header7,
  Header8,
  Header9,
  Header10,
  Header11,
  Header12,
  Header14,
} from './Headers';
import { Footer, Footer1, Footer2, Footer3 } from './Footers';
import {
  MiddleBody1,
  MiddleBody2,
  MiddleBody3,
  MiddleBody4,
  MiddleBody5,
  MiddleBody6,
} from './MiddleBody';
import { PromoList1, PromoList2, PromoList3, PromoList4, PromoList5 } from './PromoLists';
import {
  CategoryWidget1,
  CategoryWidget2,
  CategoryWidget3,
  CategoryWidget4,
  CategoryWidget7,
  CategoryWidget8,
  CategoryWidget9,
  CategoryWidget10,
  CategoryWidget11,
  CategoryWidget12,
  CategoryWidget13,
  CategoryWidget14,
  CategoryWidget15,
  CategoryWidget16,
} from './CategoryWidget';
import {
  CategoryHeader1,
  CategoryHeader2,
  CategoryHeader3,
  CategoryHeader4,
  CategoryHeader5,
} from './CategoryHeaderWidget';
import { CollectionWidget1 } from './CollectionWidget';
import { CollectionCategoryProductWidget1 } from './CollectionCategoryProductWidget';
import EmptyWidget from './EmptyWidget';
import CLHaveSomthingInMind from './CLHaveSomthingInMind';
import QuickViewModal from './QuickViewModal';
import OrderConfirmLoader from './OrderConfirmLoader';
import AuthModal from './AuthPopUp';
import CartItem from './CartItem';
import ProductItem from './ProductItem';
import MultiImageViewer from './MultiImageViewer';
import OrderInstructionModal from './OrderInstructionModal';

export {
   Button,
   CartButton,
   Switch,
  HeroBanner1,
  HeroBanner2,
  HeroBanner3,
  HeroBanner4,
  HeroBanner5,
  HeroBanner6,
  HeroBanner7,
  HeroBanner8,
  HeroBanner9,
  SubHeader1,
  SubHeader2,
  SubHeader3,
  SubHeader4,
  SubHeader,
  Header1,
  Header2,
  Header3,
  Header4,
  Header5,
  Header6,
  Header7,
  Header8,
  Header9,
  Header10,
  Header11,
  Header12,
  Header14,
  Footer,
  Footer1,
  Footer2,
  Footer3,
  MiddleBody1,
  MiddleBody2,
  MiddleBody3,
  MiddleBody4,
  MiddleBody5,
  MiddleBody6,
  PromoList1,
  PromoList2,
  PromoList3,
  PromoList4,
  PromoList5,
  CategoryWidget1,
  CategoryWidget2,
  CategoryWidget3,
  CategoryWidget4,
  CategoryWidget7,
  CategoryWidget8,
  CategoryWidget9,
  CategoryWidget10,
  CategoryWidget11,
  CategoryWidget12,
  CategoryWidget13,
  CategoryWidget14,
  CategoryWidget15,
  CategoryWidget16,
  CategoryHeader1,
  CategoryHeader2,
  CategoryHeader3,
  CategoryHeader4,
  CategoryHeader5,
  CollectionWidget1,
  CollectionCategoryProductWidget1,
  EmptyWidget,
  CLHaveSomthingInMind,
  QuickViewModal,
  OrderConfirmLoader,
  AuthModal,
  CartItem,
  ProductItem,
  MultiImageViewer,
  OrderInstructionModal,
};
*/

import './registerDesignSystemWidgets';

import {
  FETCH_SPOTLIGHT_CITIES,
  SAVE_SPOTLIGHT_CITIES,
  SAVE_DEFAULT_CITY,
  SAVE_SELECTED_CITY,
  FETCH_STORES_LIST,
  SAVE_STORES_LIST,
  EMPTY_STORES_LIST,
  FETCH_BUSSINESS_TYPES,
  SAVE_BUSSINESS_TYPES,
  SAVE_LOCATION_CORDS,
  SEARCH_SPOTLIGHT_STORES,
  SAVE_SEARCHED_SPOTLIGHT_STORES,
} from './../actionTypes';

export const fetchSpotAllcities = (data, history, callback) => {
  return {
    type: FETCH_SPOTLIGHT_CITIES,
    data,
    history,
    callback,
  };
};

export const saveSpotAllCities = data => {
  return {
    type: SAVE_SPOTLIGHT_CITIES,
    data,
  };
};

export const saveSpotDefaultCity = data => {
  return {
    type: SAVE_DEFAULT_CITY,
    data,
  };
};

export const saveSpotSelectedCity = data => {
  return {
    type: SAVE_SELECTED_CITY,
    data,
  };
};

export const fetchSpotStoresList = data => {
  return {
    type: FETCH_STORES_LIST,
    data,
  };
};

export const saveSpotStoresList = data => {
  return {
    type: SAVE_STORES_LIST,
    data,
  };
};

export const emptySpotStoresList = () => {
  return {
    type: EMPTY_STORES_LIST,
  };
};

export const fetchBussinessTypes = data => {
  return {
    type: FETCH_BUSSINESS_TYPES,
    data,
  };
};

export const saveBussinessTypes = data => {
  return {
    type: SAVE_BUSSINESS_TYPES,
    data,
  };
};

export const saveLocationCords = data => {
  return {
    type: SAVE_LOCATION_CORDS,
    data,
  };
};

export const searchSpotStores = data => {
  return {
    type: SEARCH_SPOTLIGHT_STORES,
    data,
  };
};

export const saveSearchedSpotStores = data => {
  return {
    type: SAVE_SEARCHED_SPOTLIGHT_STORES,
    data,
  };
};

import {
  SET_CART_VALIDATION_ERROR_MSG_MODAL_VISIBILITY,
  SAVE_CART_VALIDATION_DATA,
  SET_NOT_ACCEPTING_ORDERS_MODAL_VISIBILITY,
} from '../actionTypes';

export const setCartValidationErrorMsgModalVisibility = data => {
  return {
    type: SET_CART_VALIDATION_ERROR_MSG_MODAL_VISIBILITY,
    data,
  };
};

export const saveCartValidationData = data => {
  return {
    type: SAVE_CART_VALIDATION_DATA,
    data,
  };
};

export const setNotAcceptingOrdersModalVisibility = data => {
  return {
    type: SET_NOT_ACCEPTING_ORDERS_MODAL_VISIBILITY,
    data,
  };
};

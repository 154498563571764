import {
  SAVE_SPOTLIGHT_CITIES,
  SAVE_DEFAULT_CITY,
  SAVE_SELECTED_CITY,
  SAVE_STORES_LIST,
  EMPTY_STORES_LIST,
  SAVE_BUSSINESS_TYPES,
  SAVE_LOCATION_CORDS,
} from '../actionTypes';

const initialState = {
  defaultCity: {},
  selectedCity: {},
  cities: [],
  stores: {
    data: [],
    isNext: 0,
  },
  bussinessTypes: [],
};

const SpotlightReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_SPOTLIGHT_CITIES:
      return { ...state, cities: action.data };
    case SAVE_DEFAULT_CITY:
      return { ...state, defaultCity: action.data };
    case SAVE_SELECTED_CITY:
      // return { ...state, selectedCity: { ...state.selectedCity, ...action.data }};
      return { ...state, selectedCity: action.data };
    case SAVE_STORES_LIST:
      return {
        ...state,
        stores: {
          data: [...state.stores.data, ...action.data.stores],
          isNext: action.data.isNext,
        },
      };
    case EMPTY_STORES_LIST:
      return { ...state, stores: { ...state.stores, data: [] } };
    case SAVE_BUSSINESS_TYPES:
      return { ...state, bussinessTypes: action.data };
    case SAVE_LOCATION_CORDS:
      return {
        ...state,
        selectedCity: {
          ...state.selectedCity,
          latitue: action.data.latitude,
          longitude: action.data.longitude,
        },
      };
    default:
      return state;
  }
};

export default SpotlightReducer;

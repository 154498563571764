import { RegisteredWidget } from '@dotpe/kui/styles/registerWidgetForDnD';
import { HEADER_CONFIG } from './WidgetProperties/HeaderBuilder/NewHeaderConfig';
import { cloneDeep, merge } from 'lodash';
import { NewHeaderComponent } from '../../DesignSystem/Widgets2.0/Header';
import {
  ADMIN_WIDGETS,
  ADMIN_WIDGETS_FOR_BRAND_WEBSITE,
  ADMIN_WIDGETS_NAMES,
} from 'src/constants/widgets';
import { GLOBAL_STYLE } from 'src/constants/globalConfigs';
import { isThemeBrandWebsite } from 'src/utils/isThemeBrandWebsite';
import { FOOTER_DATA } from './WidgetProperties/FooterBuilder/NewFooterConfig';
import { normalizeFooterConfig } from 'src/components/DesignSystem/Widgets2.0/Footer/utils';

export const LAST_ACTIVE_WIDGET_KEY_BUILDER = 'lastActiveWidgetBuilder';
export const NEW_FOOTER_ID = 'footer-builder';
export const NEW_HEADER_ID = 'header-builder';

export const WIDGET_GROUPS = {
  NEW: 'New Widgets',
  LEGACY: 'legacy',
};

export const getGroupAndWidgetsMap = (
  widgets: RegisteredWidget<Record<string, any>>[]
) => {
  const adminWidgetNames = Object.values(ADMIN_WIDGETS_NAMES);
  const isBrandWebsite = isThemeBrandWebsite();
  const map = {};

  widgets.forEach((widget) => {
    const widgetGroup = widget.group || widget.type;
    // separate out new(admin) widgets from legacy
    if (widgetGroup === WIDGET_GROUPS.LEGACY && adminWidgetNames.includes(widget.name)) {
      // in case of brand website, only allowed widgets would be visible
      if (isBrandWebsite && !ADMIN_WIDGETS_FOR_BRAND_WEBSITE.includes(widget.name)) {
        return;
      }
      map[WIDGET_GROUPS.NEW] = [...(map[WIDGET_GROUPS.NEW] || []), widget];
    } else if (!isBrandWebsite) {
      // consider other groups only if not brand website
      map[widgetGroup] = [...(map[widgetGroup] || []), widget];
    }
  });

  return map;
};

export function getOrderedWidgetGroups(widgetGroups) {
  return widgetGroups.reduce((orderedGroups, group) => {
    if (group === WIDGET_GROUPS.LEGACY || group === WIDGET_GROUPS.NEW) {
      if (orderedGroups.includes(WIDGET_GROUPS.NEW)) {
        orderedGroups.splice(1, 0, group);
      } else {
        orderedGroups.unshift(group);
      }
    } else {
      orderedGroups.push(group);
    }
    return orderedGroups;
  }, []);
}

export function getBuilderReadyHeaderElement(newHeaderComponent) {
  if (newHeaderComponent?.config) {
    for (const key in HEADER_CONFIG) {
      if (key) {
        if (key === 'globalClass') {
          newHeaderComponent.config[key] = merge(
            HEADER_CONFIG[key],
            newHeaderComponent.config[key]
          );
        } else {
          if (HEADER_CONFIG[key] && !newHeaderComponent?.config[key]) {
            newHeaderComponent.config[key] = HEADER_CONFIG[key];
          }
          if (HEADER_CONFIG[key]?.leftSection_config) {
            newHeaderComponent.config[key].leftSection_config =
              HEADER_CONFIG[key].leftSection_config;
          }
          if (HEADER_CONFIG[key]?.leftSectionConfig) {
            newHeaderComponent.config[key].leftSectionConfig =
              HEADER_CONFIG[key].leftSectionConfig;
          }
          // if (HEADER_CONFIG[key]?.componentData) {
          //   newHeaderComponent.config[key].componentData = HEADER_CONFIG[key].componentData;
          // }
          if (key === 'navIcons_section') {
            newHeaderComponent.config[key].componentData =
              HEADER_CONFIG[key]?.componentData;
          }
        }
      }
    }
  }
  return newHeaderComponent;
}

export function getCFEHeaderElementStructure(headerElProps) {
  const headerElementProps = cloneDeep(headerElProps);
  if (headerElementProps) {
    for (const key in headerElementProps) {
      if (key) {
        if (headerElementProps[key]?.leftSection_config) {
          delete headerElementProps[key].leftSection_config;
        }
        if (headerElementProps[key]?.leftSectionConfig) {
          delete headerElementProps[key].leftSectionConfig;
        }
        // if (headerElementProps[key]?.componentData) {
        //   delete headerElementProps[key].componentData;
        // }
        if (key === 'globalClass') {
          delete headerElementProps[key];
        }
      }
    }
  }
  return headerElementProps;
}

export function generateHeaderOrFooterPropsConfig(component, isHeader = true): any {
  const currentComponentOnStore = component?.[0] || {};
  let newComponentPropConfig = {};
  let currentConfig =
    currentComponentOnStore?.config || (isHeader ? HEADER_CONFIG : FOOTER_DATA);
  if (!isHeader) {
    currentConfig = normalizeFooterConfig(currentConfig);
  }

  if (
    [ADMIN_WIDGETS.HEADER.type, ADMIN_WIDGETS.FOOTER.type].includes(
      currentComponentOnStore?.name
    )
  ) {
    newComponentPropConfig = {
      ...currentConfig,
      widget_id: currentComponentOnStore?.widget_id,
      sub_type: isHeader ? 'Header' : 'Footer',
    };
  } else {
    newComponentPropConfig = {
      ...currentComponentOnStore,
      widget_id: currentComponentOnStore?.widget_id,
      ...(currentConfig || {}),
    };
  }
  return newComponentPropConfig;
}

export function getHeaderElement(headerComponents, headerElement, headerPropsConfig) {
  return headerComponents[0]?.name === ADMIN_WIDGETS.HEADER.type
    ? [
        // eslint-disable-next-line
        <NewHeaderComponent
          {...{
            sub_type: 'Header',
            name: ADMIN_WIDGETS.HEADER.type,
            config: headerPropsConfig,
          }}
        />,
      ]
    : headerElement;
}

export function getUpdatedGlobalSetting(globalStyle, globalSetting) {
  globalSetting.fonts.base_config = {
    ...globalStyle?.fonts,
    selectedId: globalStyle?.fonts?.id || '',
  };
  globalSetting.appearance.base_config = {
    btnType: globalStyle?.buttons?.borderType || '',
    btnRadius: extractNumberFromString(globalStyle?.buttons?.style?.borderRadius),
    inputRadius: extractNumberFromString(globalStyle?.inputs?.style?.borderRadius),
    imageCardRadius: extractNumberFromString(
      globalStyle?.imageCards?.style?.borderRadius
    ),
    isEnabled: globalStyle?.imageCards?.isEnabled,
  };
  globalSetting.loader.base_config = globalStyle?.appLoader;
  globalSetting.chevrons.base_config = {
    ...GLOBAL_STYLE.chevrons,
    chevronId: globalStyle?.chevrons?.selected,
    isEnabled: globalStyle?.chevrons?.isEnabled,
  };
  return globalSetting;
}

function extractNumberFromString(value) {
  if (typeof value === 'number') {
    return value;
  }
  if (typeof value === 'string') {
    const match = value.match(/-?\d+(\.\d+)?/);
    return match ? parseInt(match[0]) : null;
  }

  return null;
}

export function getLastActiveWidgetIntoView() {
  const widgetId = getLastActiveWidgetId();
  if (widgetId) {
    document
      ?.getElementById(widgetId)
      ?.scrollIntoView({ behavior: 'instant', block: 'center' });
    clearLastActiveWidgetIntoView();
  }
}

export function getLastActiveWidgetId() {
  return sessionStorage?.getItem(LAST_ACTIVE_WIDGET_KEY_BUILDER);
}

export function setLastActiveWidget(id) {
  sessionStorage?.setItem(LAST_ACTIVE_WIDGET_KEY_BUILDER, id);
}

export function clearLastActiveWidgetIntoView() {
  sessionStorage?.removeItem(LAST_ACTIVE_WIDGET_KEY_BUILDER);
}

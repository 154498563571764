import {
  NEW_ANNOUNCEMENT_BAR_PRESENTATION_CONFIG,
  NEW_ANNOUNCEMENT_CARD_CONFIG,
} from '../NewAnnouncementBar/Config';

export const WIDGET_BG_TYPE = {
  COLOR: 'color',
  TRANSPARENT: 'transparent',
};
export const ANNOUNCEMENT_BAR_POSITION = {
  ABOVE_HEADER: 'aboveHeader',
  BELOW_HEADER: 'belowHeader',
} as const;

export const VISIBILITY_TYPE = {
  ALL: 'all',
  HOME_PAGE: 'homepage',
} as const;

export const AnnouncementConfig = {
  listConfig: {
    ...NEW_ANNOUNCEMENT_BAR_PRESENTATION_CONFIG,
    isVisible: false,
    position: ANNOUNCEMENT_BAR_POSITION.ABOVE_HEADER,
    visibilty: {
      type: VISIBILITY_TYPE.ALL,
    },
    fillType: WIDGET_BG_TYPE.COLOR,
  },
  cardConfig: NEW_ANNOUNCEMENT_CARD_CONFIG,
} as const;

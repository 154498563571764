import { SAVE_LEAD_GENINFO } from '../actionTypes';

const initialState = {
  leadgenInfo: {},
};

const LeadGenReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_LEAD_GENINFO:
      return { ...state, leadgenInfo: action.data };
    default:
      return state;
  }
};

export default LeadGenReducer;

import { getDefaultConfig } from 'src/components/WidgetMaker/utils/common';

export const GLOBAL_STYLE = {
  fonts: {
    type: 'default',
    id: 'default1',
    heading: {
      fontFamily: 'DM Sans',
      fontCdn:
        'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap',
      letterSpacing: '0px',
    },
    body: {
      fontFamily: 'DM Sans',
      fontCdn:
        'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap',
      letterSpacing: '0px',
    },
    buttonInherit: 'body',
    isEnabled: false,
  },
  buttons: {
    style: {
      borderRadius: '0px',
    },
    borderType: 'Sharp',
    isEnabled: false,
  },
  inputs: {
    style: {
      borderRadius: '0px',
    },
    isEnabled: false,
  },
  imageCards: {
    style: {
      borderRadius: '0px',
    },
    isEnabled: false,
  },
  appLoader: {
    loaderId: 'loader1',
    color: '#E2E8F8',
    isEnabled: false,
  },
  chevrons: {
    selected: 0,
    arrowPosition: 'center',
    arrowShape: 'circular',
    showArrowInMobile: true,
    isEnabled: true,
  },
  productCard: {
    layoutName: 'layout1',
    isEnabled: false,
    infoList: [
      {
        type: 'layout',
        isSortable: false,
        elementPosition: '',
        config: {
          numColumnsDesktop: 4,
          numColumnsMobile: 2,
          contentAlignment: 'left',
          productAppearance: 'standard',
          imageWidth: 'fullWidth',
          backgroundColor: '#FFFFFF',
          borderColor: '#E2E4E7',
        },
      },
      {
        type: 'name',
        displayVal: 'Name & description',
        isSortable: true,
        elementPosition: 'below',
        config: {
          productNameNumOfLine: 'one',
          productNameSize: 'regular',
          productNameColor: '#111C36',
          productNameThicness: 'thin',
        },
      },
      {
        type: 'description',
        isSortable: false,
        elementPosition: 'below',
        config: {
          showDescription: true,
          productdescriptionDesktopNumOfLines: 'one',
          productdescriptionMobileNumOfLines: 'two',
          productDescriptionSize: 'extraSmall',
          productDescriptionColor: '#586072',
          productDescriptionThicness: 'thin',
        },
      },
      {
        type: 'price',
        displayVal: 'Price',
        isSortable: true,
        elementPosition: 'below',
        config: {
          productPriceSize: 'small',
          productPriceColor: '#111C36',
          productPriceThicness: 'thin',
        },
      },
      {
        type: 'rating',
        displayVal: 'Ratings',
        isSortable: true,
        elementPosition: 'overlay',
        config: {
          showRatings: true,
          coordinates: {
            x: 'left',
            y: 'bottom',
          },
          ratingAppearance: 'avgRating',
          starColor: '#219653',
          textColor: '#111C36',
          backgroundColor: '#FFFFFF',
          showReviewCount: false,
          reviewCountColor: '#707786',
          reviewCountAppearance: 'bracket',
          minRating: true,
          minAvgRating: true,
        },
      },
      {
        type: 'variant',
        displayVal: 'Variants',
        isSortable: true,
        elementPosition: 'below',
        config: {
          showVariant: true,
          variantType: 'Colour',
          variantShape: 'box',
          variantSize: 'small',
          separateVariantWithComa: false,
          showOutOfStockVariant: false,
        },
      },
      {
        type: 'discount',
        displayVal: 'Discount',
        isSortable: false,
        elementPosition: 'below',
        config: {
          coordinates: {
            x: 'right',
            y: 'top',
          },
          discountTag: 'tag1',
          discountStyle: 'textInBox',
          discountType: 'percentage',
          textColor: '#FFFFFF',
          backgroundColor: '#2CA861',
        },
      },
      {
        type: 'button',
        isSortable: false,
        elementPosition: 'below',
        config: {
          coordinates: {
            x: 'right',
            y: 'bottom',
          },
          showAddToCart: false,
          buttonType: 'fullLength',
          buttonConfig: [
            getDefaultConfig(
              'productCardButton',
              '#CFD2D7',
              '#111C36',
              'Add to cart',
              'outline',
              'widgetBtnFillRightWrapper',
              'Swipe to fill',
              true
            ),
          ],
          iconInCta: false,
          iconType: 'plus',
          iconShape: 'square',
          buttonBehaviour: 'always',
          buttonTextThicness: 'regular',
        },
      },
      {
        type: 'image',
        elementPosition: '',
        isSortable: false,
        config: {
          imageType: 'single',
          imageShape: 'portraitShort',
          aspectRation: '3:4',
          hoverType: 'zoomIn',
          activeDotColor: '#FFFFFF',
          arrowColor: '#111C36',
          arrowShape: 'circular',
          autoSlideTimer: 2,
        },
      },
      {
        type: 'tags',
        elementPosition: 'overlay',
        isSortable: false,
        config: {
          coordinates: {
            x: 'left',
            y: 'top',
          },
          showTag: true,
          tagAppearance: 'default',
          tagShape: 'sharp',
          tagStyle: 'type1',
          tagColor: '#FFFFFF',
          isCustomColor: false,
          text_color: '#111C36',
          tag_color: '#FFFFFF',
        },
      },
    ],
  },
  carousel: {
    isEnabled: true,
    config: {
      progressIndicator: 'none',
      progressIndicatorColor: '#000000',
      shapeColor: '#ffffff',
      arrowColor: '#000000',
      arrowPosition: 'top',
      arrowShape: 'circular',
      showArrowInMobile: true,
    },
  },
};

export const TEXT_POSITION = [
  {
    text: 'Outside image',
    value: 'below',
  },
  {
    text: 'On image',
    value: 'overlay',
  },
]

export const DATE_POSITION = [
  {
    text: 'Top',
    value: 'top',
  },
  {
    text: 'Bottom',
    value: 'bottom',
  },
]

export const MOBILE_POSITION = [
  {
    text: 'Top',
    value: 'top',
  },
  {
    text: 'Left',
    value: 'left',
  },
]

export const BLOG_DESKTOP_LAYOUT = {
  label: 'Blog layout',
  editLabel: 'Change',
};
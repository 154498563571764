import { post } from 'axios';
import { ENDPOINT } from './../config/endpoints';
import apiCall from './api';

/**
 * Function for getting additional page
 * @param {string} slug
 * @param {number} storeId
 * @param {boolean} isPreview
 * @param {string} templateId
 * @return {object}
 */
async function getAdditionalPage(slug, storeId, isPreview, templateId) {
  if (!storeId) {
    return { error: 'invalid store ID' };
  }
  try {
    const response = await post(
      ENDPOINT.ADDITIONAL_PAGES.GET_ADDITIONAL_PAGE,
      {
        slug_url: slug,
        store_id: storeId,
        preview: isPreview,
        template_id: templateId,
      },
      {
        headers: {
          app_os: 'cfe',
        },
      }
    );

    return { error: null, data: response.data };
  } catch (error) {
    return { error };
  }
}

/**
 *
 * @param {Number} storeId
 * @param {Boolean} isPublished
 * @returns {Promise<import('@/redux/reducers/additionalPages').AdditionalPageInfo[]>}
 */
export const getNavigationList = async (storeId, isPublished) => {
  const pageResponse = await apiCall({
    method: 'GET',
    url: ENDPOINT.ADDITIONAL_PAGES.getNavigationList(storeId, isPublished),
    parseToJson: true,
  });

  if (pageResponse && pageResponse.data.status) {
    return pageResponse.data.data;
  }
};

export { getAdditionalPage };

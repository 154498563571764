export const SessionStorageHelper = {
  add: (key, value) => {
    if (!key) {
      return false;
    }
    sessionStorage.setItem(key, JSON.stringify(value));
    return true;
  },
  get: (key, defaultVal = null) => {
    if (!key) {
      return defaultVal;
    }
    let data = sessionStorage?.getItem(key);
    try {
      data = JSON.parse(data);
    } catch (e) {
      console.log(e);
    }
    return data ? data : defaultVal;
  },
  delete: (key) => {
    if (!key) {
      return false;
    }
    sessionStorage.removeItem(key);
    return true;
  },
  clear: () => {
    sessionStorage.clear();
  },
};

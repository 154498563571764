import {
  CircleOutline,
  DiamondGroup,
  DiamondOutlined,
  DiamondSolid,
  Dot,
  SquareOutlined,
  SquareRoundedSolid,
  SquareSolid,
  VerifiedOutlined,
  VerifiedSolid,
} from '../Components/Seperators';
import {
  ANIMATION_TYPE,
  ANIMATION_TYPE_MAP,
  CONTENT_ANIMATION_TYPE_OPTIONS,
  SEPERATOR_TYPE,
} from '../Constants';

export function getSeperatorByType(iconType) {
  switch (iconType) {
    case SEPERATOR_TYPE.CIRCLE_OUTLINE:
      return CircleOutline;
    case SEPERATOR_TYPE.DOT:
      return Dot;
    case SEPERATOR_TYPE.DIAMOND_OUTLINE:
      return DiamondOutlined;
    case SEPERATOR_TYPE.DIAMOND:
      return DiamondSolid;
    case SEPERATOR_TYPE.SQUARE:
      return SquareSolid;
    case SEPERATOR_TYPE.SQUARE_ROUNDED:
      return SquareRoundedSolid;
    case SEPERATOR_TYPE.SQUARE_OUTLINE:
      return SquareOutlined;
    case SEPERATOR_TYPE.DIAMOND_GROUP:
      return DiamondGroup;
    case SEPERATOR_TYPE.VERIFIED_SOLID:
      return VerifiedSolid;
    case SEPERATOR_TYPE.VERIFIED_OUTLINE:
      return VerifiedOutlined;
    default:
      return null;
  }
}

export function getAnnouncementSeperatorOptionsAndMap() {
  const allowedAnimationTypes = [
    ANIMATION_TYPE.NONE,
    ANIMATION_TYPE.FADE_IN,
    ANIMATION_TYPE.MOVE_UP,
    ANIMATION_TYPE.MOVE_DOWN,
  ];

  const animationOptions = CONTENT_ANIMATION_TYPE_OPTIONS.filter((option) =>
    allowedAnimationTypes.includes(option.animeType)
  );
  const animationOptionsMap = allowedAnimationTypes.reduce((newMap, type) => {
    newMap[type] = ANIMATION_TYPE_MAP[type];
    return newMap;
  }, {});

  return { animationOptions, animationOptionsMap };
}

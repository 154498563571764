/**
 * Dukaan Card
 */
import React from 'react';

const DukaanCard = props => {
  const renderLoaderView = () => {
    return (
      <div className="dukaanCard-loader">
        <div className="dukaanCard-loader__img animate"></div>
        <div className="dukaanCard-loader__wrap">
          <span className="dukaanCard-loader__name animate"></span>
          <span className="dukaanCard-loader__type animate"></span>
          <span className="dukaanCard-loader__address animate"></span>
        </div>
      </div>
    );
  };

  const renderDataView = () => {
    const categories =
      props.bussinessTypes.filter(types =>
        props.category.includes(types.business_id)
      ) || [];
    return (
      <div className="dukaanCard" onClick={props.onClick}>
        <div className="dukaanCard-wrap">
          <div className="dukaanCard__image">
            <img
              src={
                props.icon
                  ? props.icon.includes('cdn.dotpe.in')
                    ? props.icon
                    : `https://cdn.dotpe.in/${props.icon}`
                  : '/assets/images/demo-dukaan-icon.png'
              }
              alt="demo dukaan image"
              loading="lazy"
            />
          </div>
          <div className="dukaanCard__body">
            {/* <a className="dukaanCard__name" href={props.href} onClick={onDukaanNameClick}>{props.name || ""}</a> */}
            <span className="dukaanCard__name">{props.name || ''}</span>
            <div className="dukaanCard__categoryDistance">
              {categories.map((category, index) => {
                return (
                  <React.Fragment
                    key={`${index}-category-names-${category.business_id}`}
                  >
                    <span className="dukaanCard__categoryDistance--name">
                      {category.business_name || ''}{' '}
                      {categories.length !== index + 1 && (
                        <span className="dukaanCard__categoryDistance--dot"></span>
                      )}{' '}
                    </span>
                  </React.Fragment>
                );
              })}
              {/* <span>{props.withinDistance || ""}</span> */}
            </div>
            <div className="dukaanCard__address">{props.address || ''}</div>
          </div>
        </div>
        <div className="dukaanCard__bottom-line"></div>
      </div>
    );
  };

  return props.isLoader ? renderLoaderView() : renderDataView();
};

export default DukaanCard;

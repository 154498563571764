import { createContext } from 'react';

interface SSRSelectorContextValue {
  domain: string;
  userAgent: string;
}
export const SSRSelectorContext = createContext<SSRSelectorContextValue>({
  domain: '',
  userAgent: '',
});

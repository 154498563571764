import { cloneDeep } from 'lodash';
import {
  CARD_APPEARANCE_DATA,
  HEADING_ALIGNMENT,
} from '../MultiColumn/MultiColumnStaticValues';
import { LAYOUT_STYLE, SUBSCRIPTION_NAMES } from '@/constants/builderConstants';

const SPEED_OPTION = [
  {
    text: 'Slowest',
    value: 0.2,
  },
  {
    text: 'Slow',
    value: 0.5,
  },
  {
    text: 'Normal',
    value: 1,
  },
  {
    text: 'Fast',
    value: 2,
  },
];

const HOVER_TYPE = [
  {
    text: 'Expand',
    value: 'zoomIn',
  },
  {
    text: 'Box shadow',
    value: 'shadow',
  },
];

export const LOGO_SLIDER_DEFAULT_CONFIG = {
  imageDetail: {
    url: '',
    src: '',
    aspectRatio: '',
    zoom: '',
    manualUpdate: true,
  },
  redirectUrl: '',
  redirectionType: 'link',
  isOpenInNewTab: true,
  isVisible: true,
};

export const DEFAULT_LOGO_SLIDER_LAYOUT = {
  type: 'logoSlider',
  heading: '<h2>Spotlighted by the media<h2>',
  headingCharCount: 0,
  textAlign: 'left',
  colors: {
    selectedColorSchemeIndex: 0,
    colorSchemes: [
      {
        background: '#ffffff',
        text: '#000000',
        isDefault: true,
      },
      {
        background: '#000000',
        text: '#ffffff',
        isDefault: false,
      },
    ],
  },
  numColumnsDesktop: 4,
  numColumnsMobile: 2,
  appearance: 'standard',
  backgroundColor: '#FFFFFF',
  borderColor: '#E2E4E7',
  mobilePaddingY: 40,
  paddingY: 70,
  marginTop: 0,
  marginBottom: 0,
  layoutType: 'carousel',
  arrowPosition: 'bottom',
};

export const DEFAULT_LOGO_SLIDER_CARD = {
  autoScroll: false,
  scrollFullWidth: true,
  aspectRatio: '1:1',
  scrollSpeed: 1,
  isGreyScaleLogo: false,
  hoverType: 'zoomIn',
  multiColumnData: [
    {
      ...cloneDeep(LOGO_SLIDER_DEFAULT_CONFIG),
    },
    {
      ...cloneDeep(LOGO_SLIDER_DEFAULT_CONFIG),
    },
    {
      ...cloneDeep(LOGO_SLIDER_DEFAULT_CONFIG),
    },
    {
      ...cloneDeep(LOGO_SLIDER_DEFAULT_CONFIG),
    },
  ],
};

export const LOGO_SLIDER_CONFIG = {
  sectionContent: {
    sectionHeading: 'Section content',
    description: '',
    tooltipContent: '',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Heading',
        element: 'inputEditor',
        accessKey: ['heading'],
        charCountKey: 'headingCharCount',
        placeholder: 'Enter title',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Heading alignment',
        element: 'multiselect',
        accessKey: ['textAlign'],
        componentData: HEADING_ALIGNMENT,
      },
      {
        forType: 'layout',
        componentLabel: 'Section colour',
        editLabel: 'Edit colour',
        uniqueKey: 'selectionColors',
        element: 'colorsCombination',
        accessKey: ['colors'],
      },
    ],
  },
  layoutColumns: {
    sectionHeading: 'Layout & design',
    description: '',
    tooltipContent: '',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Layout style',
        element: 'multiselect',
        accessKey: ['layoutType'],
        componentData: LAYOUT_STYLE,
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns desktop',
        element: 'slider',
        accessKey: ['numColumnsDesktop'],
        min: 2,
        max: 12,
        suffix: '',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns mobile',
        element: 'slider',
        accessKey: ['numColumnsMobile'],
        min: 1,
        max: 3,
        suffix: '',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Appearance',
        element: 'multiselect',
        accessKey: ['appearance'],
        componentData: CARD_APPEARANCE_DATA,
      },
      {
        element: 'colorDetails',
        componentLabel: 'Colours',
        forType: 'layout',
        conditionalRender: true,
        isVisible: (activeWidgetProps) =>
          activeWidgetProps?.listPresentation?.appearance === 'card',
        colorsData: [
          {
            forType: 'layout',
            text: 'backgroundColor',
            displayVal: 'Background colour',
            accessKey: ['backgroundColor'],
            shouldUpdateData: false,
            data: [],
          },
          {
            forType: 'layout',
            text: 'borderColor',
            displayVal: 'Border colour',
            accessKey: ['borderColor'],
            shouldUpdateData: false,
            data: [],
          },
        ],
      },
      {
        forType: 'card',
        element: 'checkbox',
        componentLabel: 'Make carousel auto-scroll',
        accessKey: ['autoScroll'],
        renderConditional: true,
        isVisible: (activeWidgetProps) =>
          activeWidgetProps?.listPresentation?.layoutType === 'carousel',
        subscription: [SUBSCRIPTION_NAMES.ELITE],
      },
      {
        forType: 'card',
        element: 'checkbox',
        componentLabel: 'Scroll to full width of website',
        accessKey: ['scrollFullWidth'],
        renderConditional: true,
        isVisible: (activeWidgetProps) =>
          activeWidgetProps?.listPresentation?.layoutType === 'carousel' &&
          activeWidgetProps?.cardFrame?.autoScroll,
        subscription: [SUBSCRIPTION_NAMES.ELITE],
      },
      {
        forType: 'card',
        componentLabel: 'Speed',
        element: 'multiselect',
        accessKey: ['scrollSpeed'],
        componentData: SPEED_OPTION,
        renderConditional: true,
        isVisible: (activeWidgetProps) =>
          activeWidgetProps?.listPresentation?.layoutType === 'carousel' &&
          activeWidgetProps?.cardFrame?.autoScroll,
      },
    ],
  },
  logoInteraction: {
    sectionHeading: 'Logo Interaction',
    description: '',
    tooltipContent: '',
    subscription: [SUBSCRIPTION_NAMES.ELITE],
    builder: [
      {
        forType: 'card',
        element: 'checkbox',
        componentLabel: 'Make logo greyscale',
        accessKey: ['isGreyScaleLogo'],
        subLabel: 'On hover it will be of original colour',
        subLabelStyle: 'tw-text-[13px] tw-font-normal tw-text-newBlack tw-opacity-70',
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Logo hover animation',
        accessKey: ['hoverType'],
        dropdownData: HOVER_TYPE,
        customChildRender: false,
        placeholder: '',
      },
    ],
  },
  sectionSetting: {
    sectionHeading: 'Section settings',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Desktop padding top and bottom',
        element: 'slider',
        accessKey: ['paddingY'],
        min: 0,
        max: 150,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Mobile padding top and bottom',
        element: 'slider',
        accessKey: ['mobilePaddingY'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Margin top',
        element: 'slider',
        accessKey: ['marginTop'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Margin bottom',
        element: 'slider',
        accessKey: ['marginBottom'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
    ],
  },
};

export const LOGO_SLIDER_MULTICOLUMN_CONFIG = {
  config: {
    keyName: 'reelsData',
    defaultData: LOGO_SLIDER_DEFAULT_CONFIG,
    subWidgetType: 'logoSlider',
    title: 'Logo',
    addBtnTitle: 'Add Logo',
    accessKeyPath: 'cardFrame.multiColumnData',
  },
  childData: {
    srcKey: '',
    thumbnailkey: '',
    inlineTitleKey: '',
  },
};

import { twMerge } from 'tailwind-merge';
import style from '../../ListPresentation/ListPresentation.module.scss';
import { CSSProperties } from 'react';

export const InfiniteAutoScroller = (props) => {
  let {
    gap,
    children = [],
    scrollSpeedInSec,
    totalItems,
    customClasses = '',
    wrapperClasses,
  } = props;

  const finalChildren = totalItems > 1 ? children : children.concat(children);
  totalItems = totalItems > 1 ? totalItems : 2 * totalItems;

  const groupWrapperStyles: CSSProperties = {
    gap: gap,
    paddingRight: gap,
    animationDuration: scrollSpeedInSec * totalItems + 's',
  };

  return (
    <div
      style={{
        maxWidth: '100%',
      }}
      className={twMerge(style.carousel, customClasses)}
    >
      <GroupWrapper style={groupWrapperStyles} className={wrapperClasses}>
        {finalChildren}
      </GroupWrapper>
      <GroupWrapper style={groupWrapperStyles} className={wrapperClasses}>
        {finalChildren}
      </GroupWrapper>
      <GroupWrapper style={groupWrapperStyles} className={wrapperClasses}>
        {finalChildren}
      </GroupWrapper>
    </div>
  );
};

function GroupWrapper(props) {
  return (
    <div style={props.style} className={twMerge(style.group, props.className)}>
      {props.children}
    </div>
  );
}

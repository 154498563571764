import React from 'react';

interface SvgProps {
  style?: React.CSSProperties;
  className?: string;
}

export function DeleteRedIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="#E04E4E"
        d="M12.667 4.666a.667.667 0 00-.667.667v7.46A1.28 1.28 0 0110.66 14H5.34A1.28 1.28 0 014 12.793v-7.46a.667.667 0 10-1.333 0v7.46a2.612 2.612 0 002.673 2.54h5.32a2.612 2.612 0 002.674-2.54v-7.46a.667.667 0 00-.667-.667zM13.333 2.667h-2.666V1.334A.667.667 0 0010 .667H6a.667.667 0 00-.667.667v1.333H2.667a.667.667 0 000 1.333h10.666a.667.667 0 100-1.333zm-6.666 0V2h2.666v.667H6.667z"
      ></path>
      <path
        fill="#E04E4E"
        d="M7.333 11.333V6.667a.667.667 0 10-1.333 0v4.666a.667.667 0 101.333 0zM10 11.333V6.667a.667.667 0 10-1.333 0v4.666a.667.667 0 101.333 0z"
      ></path>
    </svg>
  );
}

export function DeleteIcon({ style = {}, className = '' }: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
      style={style}
      className={className}
    >
      <path
        fill="#111C36"
        d="M12.667 4.667a.667.667 0 00-.667.666v7.46A1.28 1.28 0 0110.66 14H5.34A1.28 1.28 0 014 12.794v-7.46a.667.667 0 10-1.333 0v7.46a2.612 2.612 0 002.673 2.54h5.32a2.612 2.612 0 002.674-2.54v-7.46a.667.667 0 00-.667-.667zM13.333 2.667h-2.666V1.333A.667.667 0 0010 .667H6a.667.667 0 00-.667.666v1.334H2.667a.667.667 0 000 1.333h10.666a.667.667 0 100-1.333zm-6.666 0V2h2.666v.667H6.667z"
      ></path>
      <path
        fill="#111C36"
        d="M7.333 11.333V6.667a.667.667 0 10-1.333 0v4.666a.667.667 0 101.333 0zM10 11.333V6.667a.667.667 0 10-1.333 0v4.666a.667.667 0 101.333 0z"
      ></path>
    </svg>
  );
}

export function ResizeIcon({ style = {}, className = '' }: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="10"
      fill="none"
      viewBox="0 0 13 10"
      style={style}
      className={className}
    >
      <path
        fill="#111C36"
        d="M10.636 2.5L8.273 5h1.773c0 2.069-1.59 3.75-3.546 3.75a3.32 3.32 0 01-1.655-.438l-.862.913C4.71 9.713 5.573 10 6.5 10c2.612 0 4.727-2.237 4.727-5H13l-2.364-2.5zM2.955 5c0-2.069 1.59-3.75 3.545-3.75a3.32 3.32 0 011.655.438l.862-.913A4.502 4.502 0 006.5 0C3.888 0 1.773 2.237 1.773 5H0l2.364 2.5L4.727 5H2.955z"
      ></path>
    </svg>
  );
}

export function ReplaceIcon({ style = {}, className = '' }: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
      style={style}
      className={className}
    >
      <path
        fill="#111C36"
        d="M8.727 7.636h1.091V3.273c0-.6-.49-1.091-1.09-1.091H4.363v1.09h4.363v4.364zM3.273 8.727V0H2.182v2.182H0v1.09h2.182v5.455c0 .6.49 1.091 1.09 1.091h5.455V12h1.091V9.818H12v-1.09H3.273z"
      ></path>
    </svg>
  );
}

export function UploadIcon({ style = {}, className = '' }: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      style={style}
      className={className}
    >
      <path
        fill="#111C36"
        d="M4.697 3.995L6.3 2.385v6.714a.7.7 0 101.4 0V2.384l1.603 1.61a.7.7 0 001.147-.227.7.7 0 00-.153-.767L7.497.2a.7.7 0 00-.231-.147.7.7 0 00-.532 0A.7.7 0 006.503.2L3.703 3a.703.703 0 00.994.995zM13.3 6.998a.7.7 0 00-.7.7V11.9a.7.7 0 01-.7.7H2.1a.7.7 0 01-.7-.7V7.7a.7.7 0 10-1.4 0v4.2A2.1 2.1 0 002.1 14h9.8a2.1 2.1 0 002.1-2.1V7.697a.7.7 0 00-.7-.7z"
      ></path>
    </svg>
  );
}

export function ErrorIcon({ style = {}, className = '' }: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#E24848"
        d="M6.305 3.5h1.4v4.2h-1.4V3.5zm.7 10.5a7 7 0 11-.01-14 7 7 0 01.01 14zm0-12.6A5.6 5.6 0 1012.604 7a5.607 5.607 0 00-5.6-5.6zm-.7 7.7h1.4v1.4h-1.4V9.1z"
      ></path>
    </svg>
  );
}

export function PlusOutlineIcon({ style = {}, className = '' }: SvgProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      fill="none"
      viewBox="0 0 16 17"
    >
      <rect width="15" height="15" x="0.5" y="1" stroke="#2B74D8" rx="3.5"></rect>
      <path fill="#2B74D8" d="M8.5 8V4.5h-1V8H4v1h3.5v3.5h1V9H12V8H8.5z"></path>
    </svg>
  );
}

export function AnimationSelectCustomCheckboxIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
    >
      <g>
        <path
          fill="#2B74D8"
          stroke="#2B74D8"
          d="M1 .5H.5V11A6.5 6.5 0 007 17.5h10.5V7A6.5 6.5 0 0011 .5H1z"
        ></path>
        <path stroke="#fff" strokeWidth="1.3" d="M5 8.562L7.695 11 13 6"></path>
      </g>
    </svg>
  );
}

export function NoAnimationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill="#606577"
        d="M16 32c-2.213 0-4.293-.42-6.24-1.26-1.947-.84-3.64-1.98-5.08-3.42s-2.58-3.133-3.42-5.08C.42 20.293 0 18.213 0 16s.42-4.293 1.26-6.24c.84-1.947 1.98-3.64 3.42-5.08S7.813 2.1 9.76 1.26C11.707.42 13.787 0 16 0s4.293.42 6.24 1.26c1.947.84 3.64 1.98 5.08 3.42s2.58 3.133 3.42 5.08C31.58 11.707 32 13.787 32 16s-.42 4.293-1.26 6.24c-.84 1.947-1.98 3.64-3.42 5.08s-3.133 2.58-5.08 3.42C20.293 31.58 18.213 32 16 32zm0-3.2a12.5 12.5 0 004.16-.7 12.721 12.721 0 003.68-2.02L5.92 8.16a12.72 12.72 0 00-2.02 3.68c-.467 1.333-.7 2.72-.7 4.16 0 3.573 1.24 6.6 3.72 9.08C9.4 27.56 12.427 28.8 16 28.8zm10.08-4.96a12.721 12.721 0 002.02-3.68 12.5 12.5 0 00.7-4.16c0-3.573-1.24-6.6-3.72-9.08C22.6 4.44 19.573 3.2 16 3.2c-1.44 0-2.827.233-4.16.7a12.72 12.72 0 00-3.68 2.02l17.92 17.92z"
        opacity="0.6"
      ></path>
    </svg>
  );
}

export const CONTENT_ANIMATION_ICONS = {
  Expand: function () {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <g>
          <path
            stroke="#111C36"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.5 2.5v3m0-3h3m-3 0L6 6m-3.5 7.5v-3m0 3h3m-3 0L6 10m7.5-7.5h-3m3 0v3m0-3L10 6m3.5 7.5h-3m3 0v-3m0 3L10 10"
          ></path>
        </g>
      </svg>
    );
  },
  Up: function () {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <g>
          <path
            stroke="#111C36"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2 5V3.5A1.5 1.5 0 013.5 2h9A1.5 1.5 0 0114 3.5V5m-3 3L8 5m0 0L5 8m3-3v9"
          ></path>
        </g>
      </svg>
    );
  },
  Down: function () {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <g>
          <path
            stroke="#111C36"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2 11v1.5A1.5 1.5 0 003.5 14h9a1.5 1.5 0 001.5-1.5V11m-3-3l-3 3m0 0L5 8m3 3V2"
          ></path>
        </g>
      </svg>
    );
  },
  Right: function () {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <g>
          <path
            stroke="#111C36"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M11 14h1.5a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0012.5 2H11M8 5l3 3m0 0l-3 3m3-3H2"
          ></path>
        </g>
      </svg>
    );
  },
  Left: function () {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
      >
        <g>
          <path
            stroke="#111C36"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5 14H3.5A1.5 1.5 0 012 12.5v-9A1.5 1.5 0 013.5 2H5m3 3L5 8m0 0l3 3M5 8h9"
          ></path>
        </g>
      </svg>
    );
  },
};

export function InfoBlackIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      fill="none"
      viewBox="0 0 15 16"
    >
      <g>
        <circle cx="7.2" cy="8" r="6.4" fill="#111C36"></circle>
        <path
          fill="#F2F9FF"
          d="M6.493 5.103a.714.714 0 11.709.692.694.694 0 01-.71-.692zm.048 1.236h1.32v4.988H6.54V6.34z"
        ></path>
        <path
          fill="#111C36"
          d="M7.204 1.773a6.23 6.23 0 11-4.41 1.82A6.19 6.19 0 017.2 1.77m0-.97a7.2 7.2 0 100 14.4A7.2 7.2 0 007.2.8z"
        ></path>
      </g>
    </svg>
  );
}

export function EmptyImageIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#111C36"
        d="M15.95 18.125H4.05a2.175 2.175 0 01-2.175-2.175V4.05A2.175 2.175 0 014.05 1.875h11.9a2.175 2.175 0 012.175 2.175v11.9a2.176 2.176 0 01-2.175 2.175zm-11.9-15a.925.925 0 00-.925.925v11.9a.925.925 0 00.925.925h11.9a.925.925 0 00.925-.925V4.05a.925.925 0 00-.925-.925H4.05z"
      ></path>
      <path
        fill="#111C36"
        d="M2.5 15.675a.631.631 0 01-.475-.217.625.625 0 01.075-.883l4.267-3.6a1.667 1.667 0 012.25.092l2.025 2.025a.416.416 0 00.541 0l1.967-1.425a1.667 1.667 0 012.033 0l2.709 2.158a.627.627 0 01-.321 1.116.629.629 0 01-.463-.133l-2.7-2.166a.417.417 0 00-.508 0l-1.967 1.525a1.667 1.667 0 01-2.175-.15L7.733 11.95a.417.417 0 00-.558 0L2.9 15.533a.6.6 0 01-.4.142zM12.5 9.792a2.292 2.292 0 110-4.584 2.292 2.292 0 010 4.584zm0-3.334a1.042 1.042 0 100 2.084 1.042 1.042 0 000-2.084z"
      ></path>
    </svg>
  );
}

import { SAVE_COUPONS_LIST, SAVE_PAYMENT_OFFERS_LIST } from '../actionTypes';

const initialState = {
  couponsList: [],
  isHiddenCouponPresent: false,
  paymentOffersList: [],
};

const PromoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_COUPONS_LIST:
      return {
        ...state,
        couponsList: action.data.coupons || [],
        isHiddenCouponPresent: action.data.is_hidden_coupon_present,
      };
    case SAVE_PAYMENT_OFFERS_LIST:
      return {
        ...state,
        paymentOffersList: action.data || [],
      };
    default:
      return state;
  }
};

export default PromoReducer;

import { put, takeEvery } from 'redux-saga/effects';
import { ENDPOINT } from 'src/config/endpoints';
import {
  GET_ITEMS_DELIVERY_CHARGE,
  GET_SERVICEABLE_ADDRESS,
  GET_ESTIMATED_DELVIVERY_TIME,
} from '../actionTypes';
import apiCall from '../../services/api';
import { setItemDeliveyCharges, setServiceableAddressId } from '../actions';

/**
 * Gets and Sets Items Delivery Charge inside the Cart.
 * in deliveryReducer
 * @param {*} actions
 */

function* fetchItemsDeliveryCharges(actions) {
  try {
    const deliveryResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.DELIVERY.GET_ITEMS_DELIVERY_CHARGE,
      data: actions.data,
      parseToJson: true,
    });
    if (deliveryResponse && deliveryResponse.data.status) {
      yield put(setItemDeliveyCharges(deliveryResponse.data.data));
    }
  } catch (err) {}
}

/**
 * Gets and Sets List Of Addresses that Are Servicable , unservicable and Pincode based.
 * in deliveryReducer
 * @param {*} actions
 */

function* fetchServicabiltyOfAddress(actions) {
  try {
    const deliveryResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.DELIVERY.GET_SERVICABLE_ADDRESS_LIST,
      data: actions.data,
      parseToJson: true,
    });
    if (deliveryResponse && deliveryResponse.data.status) {
      yield put(setServiceableAddressId(deliveryResponse.data.data));
    }
  } catch (err) {}
}

function* getEstimatedDeliveryTime(actions) {
  try {
    const deliveryResponse = yield apiCall({
      method: 'GET',
      url:
        ENDPOINT.DELIVERY.GET_ESTIMATED_DELIVERY_TIME +
        '/' +
        actions?.data?.id +
        '/' +
        actions?.data?.text,
    });
    if (deliveryResponse && deliveryResponse.data.status && actions.callback) {
      actions.callback(deliveryResponse.data.text);
    }
  } catch (err) {}
}

export default function* root() {
  yield takeEvery(GET_ITEMS_DELIVERY_CHARGE, fetchItemsDeliveryCharges);
  yield takeEvery(GET_SERVICEABLE_ADDRESS, fetchServicabiltyOfAddress);
  yield takeEvery(GET_ESTIMATED_DELVIVERY_TIME, getEstimatedDeliveryTime);
}

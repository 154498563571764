import { getGenderStatus } from './getGenderStatus';

export function setModifiedDeliveryInfo(cartData) {
  const { delivery_info } = cartData;

  const modifiedDeliveryInfo = {
    ...delivery_info,
    alternate_phone: delivery_info?.alternate_phone
      ? delivery_info?.alternate_phone
      : cartData?.user_phone,
    age: delivery_info?.age ? parseInt(delivery_info?.age) : 0,
    gender: getGenderStatus(delivery_info?.gender),
  };
  return modifiedDeliveryInfo;
}

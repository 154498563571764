export function getBackgroundColorForOutline(currentColor) {
  switch (currentColor?.toLowerCase()) {
    case '#ffffff':
      return {
        background: '#ffffff',
        text: '#000000',
      };
    case '#000000':
      return {
        background: '#000000',
        text: '#ffffff',
      };
    default:
      return {
        background: currentColor,
        text: '#ffffff',
      };
  }
}

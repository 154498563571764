import { ADMIN_WIDGETS } from '@/constants/widgets';
import { CARD_SHAPE_DROPDOWN } from '../ListPresentation/ListPresentationConfig';
import { IMAGE_DATA, IMAGE_TYPE } from './constant';
import {
  HEADING_ALIGNMENT,
  HOVER_EFFECT_DROPDOWN,
} from '../MultiColumn/MultiColumnStaticValues';
import { ASPECT_RATIO_MAP, DESCRIPTION_POSITION } from '@/constants/builderConstants';
import { getDefaultConfig } from "src/components/WidgetMaker/utils/common";
// import { LAYOUT_STYLE } from "../LogoSlider/LogoSliderConfig";

export const GALLERY_LAYOUT_SELECTABLE = [
  {
    name: 'layout1',
    heading: 'Layout 1 (Grid)',
    cdn: 'https://cdn.dotpe.in/longtail/themes/8603030/Cym2OQXr.webp',
    list: {
      layoutName: 'layout1',
      layoutType: 'grid',
      imageWidth: 'equal',
      numColumnsMobile: 2,
      rowColumnGap: 20
    },
    card: {
      imageShape: 'square',
      aspectRatio: ASPECT_RATIO_MAP.square,
    },
    customStyle: {
      width: 'max-content'
    }
  },
  {
    name: 'layout2',
    heading: 'Layout 2 (Carousel)',
    cdn: 'https://cdn.dotpe.in/longtail/themes/8603030/hZlLZPaH.webp',
    list: {
      layoutName: 'layout2',
      layoutType: 'carousel',
      imageWidth: 'equal',
      numColumnsMobile: 2,
      rowColumnGap: 20
    },
    card: {
    },
    customStyle: {
      width: 'max-content'
    }
  },
  {
    name: 'layout3',
    heading: 'Layout 3 (Image in focus)',
    cdn: 'https://cdn.dotpe.in/longtail/themes/8603030/oeFRcAM6.webp',
    list: {
      layoutName: 'layout3',
      layoutType: 'carousel',
      rowColumnGap: 0
    },
    card: {
      imageShape: 'portraitTall',
      aspectRatio: ASPECT_RATIO_MAP.portraitTall,
    },
    customStyle: {
      width: 'max-content'
    }
  },
  {
    name: 'layout4',
    heading: 'Layout 4 (Masonry)',
    cdn: 'https://cdn.dotpe.in/longtail/themes/8603030/6G0DueZp.webp',
    list: {
      layoutName: 'layout4',
      layoutType: 'carousel',
      numColumnsMobile: 3,
      rowColumnGap: 4
    },
    card: {
      imageShape: 'portraitTall',
      aspectRatio: ASPECT_RATIO_MAP.portraitTall,
    },
  },
  {
    name: 'layout5',
    heading: 'Layout 5 (Collage 1)',
    cdn: 'https://cdn.dotpe.in/longtail/themes/8603030/rqRcQX6E.webp',
    list: {
      layoutName: 'layout5',
      layoutType: 'carousel',
      rowColumnGap: 0
    },
    card: {
      imageShape: 'portraitTall',
      aspectRatio: ASPECT_RATIO_MAP.portraitTall,
    },
  },
  {
    name: 'layout6',
    heading: 'Layout 6 (Collage 2)',
    cdn: 'https://cdn.dotpe.in/longtail/themes/8603030/D5sdAR31.webp',
    list: {
      layoutName: 'layout6',
      layoutType: 'carousel',
      rowColumnGap: 0,
    },
    card: {
      imageShape: 'portraitTall',
      aspectRatio: ASPECT_RATIO_MAP.portraitTall,
    },
  }
];
const COLUMN_HIDE_DATA = ['layout5', 'layout6', 'layout3']

export const GALLERY_PRESENTATION_CONFIG = {
  layoutName: 'layout1',
  viewLabel: 'Gallery',
  widgetName: ADMIN_WIDGETS.GALLERY.type,
  layoutType: 'grid',
  heading: '<h2></h2>',
  descriptionPosition: 'below',
  textAlign: 'center',
  headingCharCount: 0,
  rowColumnGap: 20,
  instagramAccount: '',
  description: '',
  descriptionCharCount: 0,
  type: ADMIN_WIDGETS.GALLERY.type,
  colors: {
    selectedColorSchemeIndex: 0,
    colorSchemes: [
      {
        background: '#ffffff',
        text: '#000000',
        isDefault: true,
      },
      {
        background: '#000000',
        text: '#ffffff',
        isDefault: false,
      },
    ],
  },
  imageWidth: 'equal',
  backgroundColor: '#FFFFFF',
  borderColor: '#E2E4E7',
  numColumnsDesktop: 5,
  numColumnsMobile: 2,
  mobilePaddingY: 40,
  paddingY: 70,
  marginTop: 0,
  marginBottom: 0,
  viewAllItemsButtonConfig: [
    getDefaultConfig('bannerButton', '#111C36', '#FFFFFF', 'See all images'),
  ],
};

export const DEFAULT_GALLERY_CARD_CONFIG = {
  imageShape: 'square',
  aspectRatio: ASPECT_RATIO_MAP.square,
  hoverEffect: 'zoomIn',
  textAlign: 'left',
  hoverType: 'zoomIn',
  reelDesktopBehaviour: 'autoPlayAll',
  mobileEnlargeAndFocus: false,
  imagesData: [
    {
      ...IMAGE_DATA,
    },
    {
      ...IMAGE_DATA,
    },
    {
      ...IMAGE_DATA,
    },
    {
      ...IMAGE_DATA,
    },
    {
      ...IMAGE_DATA,
    },
  ],
};

export const GALLERY_CONFIG = {
  sectionContent: {
    sectionHeading: 'Section content',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Heading',
        element: 'inputEditor',
        accessKey: ['heading'],
        charCountKey: 'headingCharCount',
        placeholder: 'Enter title',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Description',
        element: 'inputEditor',
        placeholder: 'Enter description',
        charCountKey: 'descriptionCharCount',
        accessKey: ['description'],
        textColor: '#808080',
        maxLength: 100,
      },
      {
        forType: 'layout',
        componentLabel: 'Description position',
        element: 'multiselect',
        accessKey: ['descriptionPosition'],
        componentData: DESCRIPTION_POSITION,
      },
      {
        forType: 'layout',
        componentLabel: 'Heading alignment',
        element: 'multiselect',
        accessKey: ['textAlign'],
        componentData: HEADING_ALIGNMENT,
      },
      {
        forType: 'layout',
        componentLabel: 'Section colour',
        editLabel: 'Edit colour',
        uniqueKey: 'selectionColors',
        element: 'colorsCombination',
        accessKey: ['colors'],
      },
    ],
  },
  layoutAndDesign: {
    sectionHeading: 'Layout & design',
    description: '',
    tooltipContent: '',
    builder: [
      {
        componentLabel: 'Layout/design',
        componentData: GALLERY_LAYOUT_SELECTABLE,
        element: 'testimonialLayout',
        customStyle: '!tw-w-[795px]'
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns - Desktop',
        element: 'slider',
        accessKey: ['numColumnsDesktop'],
        min: 4,
        max: 8,
        suffix: '',
        step: 1,
        renderConditional: true,
        isVisible: ({listPresentation}) => !COLUMN_HIDE_DATA?.includes(listPresentation?.layoutName),
        dynamicData: true,
        getDynamicData: (listPresentation) => {
          if (listPresentation?.layoutName === 'layout4') {
            return {
              min: 3,
              max: 6
            }
          }
          return {
            min: 4,
            max: 8
          }
        }
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns - Mobile',
        element: 'slider',
        accessKey: ['numColumnsMobile'],
        min: 1,
        max: 2,
        suffix: '',
        step: 1,
        renderConditional: true,
        isVisible: ({listPresentation}) => !COLUMN_HIDE_DATA?.includes(listPresentation?.layoutName),
        dynamicData: true,
        getDynamicData: (listPresentation) => {
          if (listPresentation?.layoutName === 'layout4') {
            return {
              min: 2,
              max: 3
            }
          }
          return {
            min: 1,
            max: 2
          }
        }
        
      },
      {
        forType: 'layout',
        componentLabel: 'Column gap/Image spacing',
        element: 'slider',
        accessKey: ['rowColumnGap'],
        min: 0,
        max: 24,
        suffix: 'px',
        step: 1,
        renderConditional: true,
        isVisible: ({listPresentation}) => (listPresentation?.layoutName !== 'layout3'),
        dynamicData: true,
        getDynamicData: (listPresentation) => {
          if (listPresentation?.layoutName === 'layout5' || listPresentation?.layoutName === 'layout6') {
            return {
              min: 0,
              max: 8
            }
          }
          return {
            min: 0,
            max: 24
          }
        }
      },
    ],
  },
  image: {
    sectionHeading: 'Image',
    description: '',
    tooltipContent: '',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Image width',
        element: 'multiselect',
        accessKey: ['imageWidth'],
        componentData: IMAGE_TYPE,
        renderConditional: true,
        isVisible: ({listPresentation}) => (listPresentation?.layoutName === 'layout2'),
        dynamicSubText: (activeWidgetProps) => {
          const { listPresentation } = activeWidgetProps;
          if (listPresentation?.imageWidth === IMAGE_TYPE[1].value) {
            return 'Number of columns may not work as expected since image width are unequal.'
          }
        },
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Image shape',
        accessKey: ['imageShape'],
        dropdownData: CARD_SHAPE_DROPDOWN,
        customChildRender: true,
        placeholder: '',
        renderConditional: true,
        isVisible: (activeWidgetProps) => {
          const listPresentation = activeWidgetProps?.listPresentation;
          const cardFrameConfig = activeWidgetProps?.cardFrame;

          if (
            listPresentation?.layoutName === 'layout3' ||
            listPresentation?.imageWidth === 'original'
          ) {
            (cardFrameConfig.imageShape = 'landscape'),
              (cardFrameConfig.aspectRatio = ASPECT_RATIO_MAP.landscapeWide);
            return false;
          }
          else if (listPresentation?.layoutName === 'layout1' || listPresentation?.layoutName === 'layout2') {
            return true
          }
          return false
        }
      },
      {
        forType: 'card',
        element: 'dropdown',
        componentLabel: 'Image hover interaction',
        accessKey: ['hoverEffect'],
        dropdownData: HOVER_EFFECT_DROPDOWN,
        customChildRender: false,
        placeholder: '',
      },
    ],
  },

  viewAllButton: {
    isVisible: (layout) =>
      layout.layoutName === 'layout1',
    builder: [
      {
        forType: 'layout',
        element: 'button',
        showColorCombination: true,
        showDeleteIcon: false,
        showRedirectionField: false,
        accessKey: ['viewAllItemsButtonConfig'],
      },
    ],
  },

  sectionSetting: {
    sectionHeading: 'Section settings',
    builder: [
      {
        forType: 'layout',
        componentLabel: 'Desktop padding top and bottom',
        element: 'slider',
        accessKey: ['paddingY'],
        min: 0,
        max: 150,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Mobile padding top and bottom',
        element: 'slider',
        accessKey: ['mobilePaddingY'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Margin top',
        element: 'slider',
        accessKey: ['marginTop'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Margin bottom',
        element: 'slider',
        accessKey: ['marginBottom'],
        min: 0,
        max: 100,
        suffix: 'px',
        step: 1,
      },
    ],
  },
};

export const GALLERY_WIDGET_LIST_CONFIG = {
  config: {
    keyName: 'imagesData',
    defaultData: IMAGE_DATA,
    subWidgetType: 'gallery',
    title: 'Gallery',
    addBtnTitle: 'Add image',
    accessKeyPath: 'cardFrame.imagesData',
  },
  childData: {
    srcKey: '',
    thumbnailkey: 'imageUrl',
    inlineTitleKey: '',
    isImage : true
  },
};

import { DISCOUNT_IMAGE_TAG } from 'src/components/DesignSystem/Widgets2.0/ProductCard/utils';
import { getDefaultConfig } from '../../utils/common';
import { SUBSCRIPTION_NAMES, TEXT_THICKNESS } from 'src/constants/builderConstants';

export const HEADING_TYPE = [
  {
    type: 'font',
    subTitle: '',
    title: 'Fonts',
  },
  {
    type: 'appearance',
    subTitle: '',
    title: 'Appearance',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  {
    type: 'loader',
    subTitle: '',
    title: 'Loader',
  },
  {
    type: 'chevrons',
    subTitle: '',
    title: 'Carousel',
  },
  {
    type: 'productCard',
    subTitle: '',
    title: 'Product card',
  },
];

export const HEADING_PRODUCT_CARD = [
  {
    type: 'layout',
    subTitle: '',
    title: 'Layout',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    type: 'name',
    subTitle: '',
    title: 'Name, description, price',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  {
    type: 'cta',
    subTitle: '',
    title: 'CTA',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    type: 'ratings',
    subTitle: '',
    title: 'Ratings and reviews',
    subscription: [SUBSCRIPTION_NAMES.ELITE],
  },
  {
    type: 'discount',
    subTitle: '',
    title: 'Discount and offers',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    type: 'variant',
    subTitle: '',
    title: 'Variants',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    type: 'image',
    subTitle: '',
    title: 'Product image',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    type: 'tag',
    subTitle: '',
    title: 'Tags',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
];

export const BTN_TYPE = 'btnType';

export const APPEARANCE_CARD_TYPE = {
  SHARP: 'Sharp',
  PILL: 'Pill',
  ROUNDED: 'Rounded',
};

export const LOADER_TYPE = [
  {
    name: 'Logo 1',
    type: 'logo1',
  },
  {
    name: 'Logo 2',
    type: 'logo2',
  },
  {
    name: 'Loader 1',
    type: 'loader1',
  },
  {
    name: 'Loader 2',
    type: 'loader2',
  },
  {
    name: 'Loader 3',
    type: 'loader3',
  },
  {
    name: 'Loader 4',
    type: 'loader4',
  },
  {
    name: 'Loader 5',
    type: 'loader5',
  },
];

export const GLOBAL_SETTING_CONFIG = {
  fonts: {
    base_config: {
      selectedId: 'default1',
      heading: {
        fontFamily: 'DM Sans',
        fontCdn:
          'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap',
        letterSpacing: '0px',
      },
      body: {
        fontFamily: 'DM Sans',
        fontCdn:
          'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap',
        letterSpacing: '0px',
      },
      buttonInherit: 'body',
      isEnabled: false,
    },
    leftSection: {
      sectionHeading: {
        heading: 'Font styles',
        subHeading: 'Applicable to all the texts tagged from h1 to h6',
      },
      defaultStyles: [
        {
          subscription: [
            SUBSCRIPTION_NAMES.ESSENTIAL,
            SUBSCRIPTION_NAMES.ELITE,
            SUBSCRIPTION_NAMES.PRO,
          ],
          type: 'default',
          id: 'default1',
          heading: {
            fontFamily: 'DM Sans',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap',
            letterSpacing: '0px',
          },
          body: {
            fontFamily: 'DM Sans',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap',
            letterSpacing: '0px',
          },
          buttonInherit: 'body',
        },
        {
          subscription: [SUBSCRIPTION_NAMES.ELITE],
          type: 'default',
          id: 'default4',
          heading: {
            fontFamily: 'Inter',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
            letterSpacing: '0px',
          },
          body: {
            fontFamily: 'Inter',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
            letterSpacing: '0px',
          },
          buttonInherit: 'body',
        },
        {
          subscription: [SUBSCRIPTION_NAMES.ELITE],
          type: 'default',
          id: 'default2',
          heading: {
            fontFamily: 'Inria Serif',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap',
            letterSpacing: '0px',
          },
          body: {
            fontFamily: 'Inter',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
            letterSpacing: '0px',
          },
          buttonInherit: 'body',
        },
        {
          subscription: [SUBSCRIPTION_NAMES.ELITE],
          type: 'default',
          id: 'default3',
          heading: {
            fontFamily: 'Montserrat',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300&family=DM+Sans:opsz,wght@9..40,400;9..40,600&family=Montserrat+Alternates:wght@400;600&family=Montserrat:wght@400;600&family=MuseoModerno:wght@300&family=Noto+Sans:wght@300;600&family=Sofia&family=Work+Sans:wght@400;600&display=swap',
            letterSpacing: '0px',
          },
          body: {
            fontFamily: 'Montserrat',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300&family=DM+Sans:opsz,wght@9..40,400;9..40,600&family=Montserrat+Alternates:wght@400;600&family=Montserrat:wght@400;600&family=MuseoModerno:wght@300&family=Noto+Sans:wght@300;600&family=Sofia&family=Work+Sans:wght@400;600&display=swap',
            letterSpacing: '0px',
          },
          buttonInherit: 'body',
        },
        {
          subscription: [SUBSCRIPTION_NAMES.ELITE],
          type: 'default',
          id: 'default5',
          heading: {
            fontFamily: 'Josefin Sans',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap',
            letterSpacing: '0px',
          },
          body: {
            fontFamily: 'Manrope',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap',
            letterSpacing: '0px',
          },
          buttonInherit: 'body',
        },
        {
          subscription: [SUBSCRIPTION_NAMES.ELITE],
          type: 'default',
          id: 'default6',
          heading: {
            fontFamily: 'Playfair Display',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap',
            letterSpacing: '0px',
          },
          body: {
            fontFamily: 'Albert Sans',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap',
            letterSpacing: '0px',
          },
          buttonInherit: 'body',
        },
        {
          subscription: [SUBSCRIPTION_NAMES.ELITE],
          type: 'default',
          id: 'default7',
          heading: {
            fontFamily: 'Oswald',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap',
            letterSpacing: '0px',
          },
          body: {
            fontFamily: 'Karla',
            fontCdn:
              'https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap',
            letterSpacing: '0px',
          },
          buttonInherit: 'body',
        },
      ],
      customStyles: [],
      allStyles: [],
    },
  },
  appearance: {
    base_config: {
      btnType: 'Sharp',
      btnRadius: 0,
      inputRadius: 0,
      imageCardRadius: 0,
      isEnabled: false,
    },
    leftSection: {
      button: {
        sectionHeading: {
          heading: 'Button',
          subHeading: 'Will be applicable to solid and outline button',
        },
        cardMultiselect: {
          label: 'Button roundness',
          key: 'btnRadius',
          selectedIndex: 0,
          types: [
            {
              type: 'Sharp',
              sprite: 'wb-sprite ic-button-sharp',
            },
            {
              type: 'Rounded',
              sprite: 'wb-sprite ic-button-rounded',
            },
            {
              type: 'Pill',
              sprite: 'wb-sprite ic-button-pill',
            },
          ],
        },
        slider: {
          type: 'buttons',
          label: 'Border radius',
          key: 'btnRadius',
          min: 0,
          max: 16,
          step: 1,
          suffix: 'px',
        },
        preview: {
          type: 'button',
          valKey: 'btnRadius',
        },
      },
      inputField: {
        sectionHeading: {
          heading: 'Input fields',
          subHeading: 'Forms, search, newsletter.',
        },
        slider: {
          type: 'inputs',
          label: 'Border radius',
          key: 'inputRadius',
          min: 0,
          max: 16,
          step: 1,
          suffix: 'px',
        },
        preview: {
          type: 'input',
          valKey: 'inputRadius',
        },
      },
      imageCards: {
        sectionHeading: {
          heading: 'Images, Cards.',
          subHeading: 'Product, category, collection.',
        },
        slider: {
          type: 'imageCards',
          label: 'Border radius',
          key: 'imageCardRadius',
          min: 0,
          max: 16,
          step: 1,
          suffix: 'px',
        },
        preview: {
          type: 'imageCard',
          valKey: 'imageCardRadius',
        },
      },
    },
  },
  loader: {
    base_config: {
      loaderId: 'loader1',
      color: '#E2E8F8',
      isEnabled: false,
    },
    leftSection: {
      colorPicker: {
        key: 'color',
        text: 'color',
        displayVal: 'Loader colour',
      },
    },
  },
  chevrons: {
    base_config: {
      chevronId: 0,
      isEnabled: false,
    },
    leftSection: {
      chevronsList: [
        {
          type: 0,
          image: 'https://cdn.dotpe.in/longtail/themes/8073865/5TyxjncZ.webp',
          subscription: [
            SUBSCRIPTION_NAMES.ESSENTIAL,
            SUBSCRIPTION_NAMES.ELITE,
            SUBSCRIPTION_NAMES.PRO,
          ],
        },
        {
          type: 1,
          image: 'https://cdn.dotpe.in/longtail/themes/8073865/rFcpxKuf.webp',
          subscription: [SUBSCRIPTION_NAMES.ELITE, SUBSCRIPTION_NAMES.PRO],
        },
        {
          type: 2,
          image: 'https://cdn.dotpe.in/longtail/themes/8073865/O9PrLsxd.webp',
          subscription: [SUBSCRIPTION_NAMES.ELITE, SUBSCRIPTION_NAMES.PRO],
        },
        {
          type: 3,
          image: 'https://cdn.dotpe.in/longtail/themes/8073865/Jl0wGLHx.webp',
          subscription: [SUBSCRIPTION_NAMES.ELITE, SUBSCRIPTION_NAMES.PRO],
        },
      ],
    },
  },
};

export const BUTTON_ABOVE_BELOW_CONFIG = {
  below: {
    coordinates: {
      x: 'right',
      y: 'bottom',
    },
    showAddToCart: true,
    buttonType: 'fullLength',
    buttonConfig: [
      getDefaultConfig(
        'productCardButton',
        '#CFD2D7',
        '#111C36',
        'Add to cart',
        'outline',
        'widgetBtnFillRightWrapper',
        'Swipe to fill',
        true
      ),
    ],
    iconInCta: false,
    iconType: 'plus',
    buttonTextThicness: 'regular',
    iconShape: 'square',
    buttonBehaviour: 'always',
  },
  overlay: {
    coordinates: {
      x: 'right',
      y: 'bottom',
    },
    showAddToCart: true,
    buttonType: 'fullLength',
    buttonConfig: [
      getDefaultConfig(
        'productCardButton',
        '#FFFFFF',
        '#111C36',
        'Add to cart',
        'solid',
        'widgetProductBtnShadowWrapper',
        'Add shadow',
        true
      ),
    ],
    iconInCta: false,
    iconType: 'plus',
    buttonTextThicness: 'regular',
    iconShape: 'square',
    buttonBehaviour: 'hover',
  },
};

export const CARD_TYPE_ACC_TO_LAYOUT = {
  layout1: 'standard',
  layout2: 'standard',
  layout3: 'standard',
  layout4: 'card',
};

export const RESET_TO_DEFAULT_CONFIGS = {
  rating: {
    elementPosition: 'overlay',
    config: {
      coordinates: {
        x: 'left',
        y: 'bottom',
      },
      ratingAppearance: 'avgRating',
      starColor: '#219653',
      textColor: '#111C36',
      backgroundColor: '#FFFFFF',
      reviewCountColor: '#707786',
      reviewCountAppearance: 'bracket',
      minRating: true,
      showRatings: true,
      minAvgRating: true,
    },
  },
  discount: {
    elementPosition: 'below',
    config: {
      coordinates: {
        x: 'right',
        y: 'top',
      },
      discountStyle: 'textInBox',
      discountType: 'percentage',
      textColor: '#FFFFFF',
      backgroundColor: '#2CA861',
      discountTag: 'tag1',
    },
  },
  image: {
    elementPosition: '',
    config: {
      imageType: 'single',
      imageShape: 'portraitShort',
      aspectRation: '3:4',
      hoverType: 'zoomIn',
      activeDotColor: '#FFFFFF',
      arrowColor: '#111C36',
      arrowShape: 'circular',
      autoSlideTimer: 2,
    },
  },
  variant: {
    elementPosition: 'below',
    config: {
      showVariant: true,
      variantType: 'Colour',
      variantShape: 'box',
      variantSize: 'small',
      separateVariantWithComa: false,
      showOutOfStockVariant: true,
    },
  },
  tags: {
    elementPosition: 'overlay',
    config: {
      coordinates: {
        x: 'left',
        y: 'top',
      },
      showTag: true,
      tagAppearance: 'default',
      tagShape: 'sharp',
      tagStyle: 'type1',
      tagColor: '#FFFFFF',
      isCustomColor: false,
      textColor: '#111C36',
      backgroundColor: '#FFFFFF',
    },
  },
};

export const CONFIG_DEFAULT_VALUES = {
  rating: {
    overlay: {
      coordinates: {
        x: 'left',
        y: 'bottom',
      },
      ratingAppearance: 'avgRating',
      starColor: '#219653',
      textColor: '#111C36',
      backgroundColor: '#FFFFFF',
      reviewCountColor: '#707786',
      reviewCountAppearance: 'bracket',
      showRatings: true,
      minRating: true,
      minAvgRating: true,
    },
    below: {
      coordinates: {
        x: 'left',
        y: 'bottom',
      },
      ratingAppearance: 'avgRating',
      starColor: '#219653',
      textColor: '#111C36',
      backgroundColor: '#FFFFFF',
      reviewCountColor: '#707786',
      reviewCountAppearance: 'bracket',
      minRating: true,
      showRatings: true,
      minAvgRating: true,
    },
  },
  discount: {
    overlay: {
      coordinates: {
        x: 'right',
        y: 'top',
      },
      discountStyle: 'discountOverlay',
      discountType: 'percentage',
      textColor: '#FFFFFF',
      backgroundColor: '#0EBE59',
      discountTag: 'tag1',
    },
    below: {
      coordinates: {
        x: 'right',
        y: 'top',
      },
      discountStyle: 'textInBox',
      discountType: 'percentage',
      textColor: '#FFFFFF',
      backgroundColor: '#2CA861',
    },
  },
};

export const DEFAULT_ANIMATION_VALUE = {
  solid: { classKey: 'widgetProductBtnShadowWrapper', animationName: 'Add shadow' },
  outline: { classKey: 'widgetBtnFillRightWrapper', animationName: 'Swipe to fill' },
};

export const PRODUCT_CARD_LAYOUT = [
  {
    title: 'Layout1',
    name: 'layout1',
    imgUrl: '/assets/images/widgetImages/ProductCard/L1.png',
    thumbnail: '/assets/images/widgetImages/ProductCard/L1.png',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    title: 'Layout2',
    name: 'layout2',
    imgUrl: '/assets/images/widgetImages/ProductCard/L2.png',
    thumbnail: '/assets/images/widgetImages/ProductCard/L2.png',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    title: 'Layout3',
    name: 'layout3',
    imgUrl: '/assets/images/widgetImages/ProductCard/L3.png',
    thumbnail: '/assets/images/widgetImages/ProductCard/L3.png',
    subscription: [SUBSCRIPTION_NAMES.ELITE],
  },
  {
    title: 'Layout4',
    name: 'layout4',
    imgUrl: '/assets/images/widgetImages/ProductCard/L4.png',
    thumbnail: '/assets/images/widgetImages/ProductCard/L4.png',
    subscription: [SUBSCRIPTION_NAMES.ELITE],
  },
];

export const PRODUCT_CARD_LAYOUT_WISE_CONFIG = {
  layout1: {
    name: 'layout1',
    elementPosition: 'below',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
    config: {
      coordinates: {
        x: 'right',
        y: 'bottom',
      },
      showAddToCart: false,
      buttonType: 'fullLength',
      buttonConfig: [
        getDefaultConfig(
          'productCardButton',
          '#CFD2D7',
          '#111C36',
          'Add to cart',
          'outline',
          'widgetBtnFillRightWrapper',
          'Swipe to fill',
          true
        ),
      ],
      iconInCta: false,
      iconType: 'plus',
      buttonTextThicness: 'regular',
      iconShape: 'square',
      buttonBehaviour: 'always',
    },
  },
  layout2: {
    name: 'layout2',
    elementPosition: 'below',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
    config: {
      coordinates: {
        x: 'right',
        y: 'bottom',
      },
      showAddToCart: true,
      buttonType: 'fullLength',
      buttonConfig: [
        getDefaultConfig(
          'productCardButton',
          '#CFD2D7',
          '#111C36',
          'Add to cart',
          'outline',
          'widgetBtnFillRightWrapper',
          'Swipe to fill',
          true
        ),
      ],
      iconInCta: false,
      iconType: 'plus',
      buttonTextThicness: 'regular',
      iconShape: 'square',
      buttonBehaviour: 'always',
    },
  },
  layout3: {
    name: 'layout3',
    elementPosition: 'overlay',
    subscription: [SUBSCRIPTION_NAMES.ELITE],
    config: {
      coordinates: {
        x: 'right',
        y: 'bottom',
      },
      showAddToCart: true,
      buttonType: 'icon',
      buttonConfig: [
        getDefaultConfig(
          'productCardButton',
          '#FFFFFF',
          '#111C36',
          'Add to cart',
          'solid',
          'widgetProductBtnShadowWrapper',
          'Add shadow',
          true
        ),
      ],
      iconInCta: false,
      iconType: 'plus',
      buttonTextThicness: 'regular',
      iconShape: 'square',
      buttonBehaviour: 'always',
    },
  },
  layout4: {
    name: 'layout1',
    elementPosition: 'below',
    subscription: [SUBSCRIPTION_NAMES.ELITE],
    config: {
      coordinates: {
        x: 'right',
        y: 'bottom',
      },
      showAddToCart: false,
      buttonType: 'fullLength',
      buttonConfig: [
        getDefaultConfig('bannerButton', '#FFFFFF', '#111C36', 'Add to cart'),
      ],
      iconInCta: false,
      iconType: 'plus',
      buttonTextThicness: 'regular',
      iconShape: 'square',
      buttonBehaviour: 'always',
    },
  },
};

function getStyleAccToType(text: string) {
  return (
    <div style={{ fontFamily: text }} className="tw-text-[14px] tw-font-normal ">
      {text}
    </div>
  );
}

export const FONT_DROPDOWN = [
  {
    fontFamily: 'Montserrat',
    fontCdn:
      'https://fonts.googleapis.com/css2?family=Advent+Pro:wght@300&family=DM+Sans:opsz,wght@9..40,400;9..40,600&family=Montserrat+Alternates:wght@400;600&family=Montserrat:wght@400;600&family=MuseoModerno:wght@300&family=Noto+Sans:wght@300;600&family=Sofia&family=Work+Sans:wght@400;600&display=swap',
    renderData: () => getStyleAccToType('Montserrat'),
  },
  {
    fontFamily: 'Inria Serif',
    fontCdn:
      'https://fonts.googleapis.com/css2?family=Inria+Serif:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap',
    renderData: () => getStyleAccToType('Inria Serif'),
  },
  {
    fontFamily: 'Inter',
    fontCdn:
      'https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap',
    renderData: () => getStyleAccToType('Inter'),
  },
  {
    fontFamily: 'DM Sans',
    fontCdn:
      'https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap',
    renderData: () => getStyleAccToType('DM Sans'),
  },
  {
    fontFamily: 'Josefin Sans',
    fontCdn:
      'https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap',
    renderData: () => getStyleAccToType('Josefin Sans'),
  },
  {
    fontFamily: 'Manrope',
    fontCdn:
      'https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap',
    renderData: () => getStyleAccToType('Manrope'),
  },
  {
    fontFamily: 'Playfair Display',
    fontCdn:
      'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap',
    renderData: () => getStyleAccToType('Playfair Display'),
  },
  {
    fontFamily: 'Albert Sans',
    fontCdn:
      'https://fonts.googleapis.com/css2?family=Albert+Sans:ital,wght@0,100..900;1,100..900&display=swap',
    renderData: () => getStyleAccToType('Albert Sans'),
  },
  {
    fontFamily: 'Oswald',
    fontCdn: 'https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap',
    renderData: () => getStyleAccToType('Oswald'),
  },
  {
    fontFamily: 'Karla',
    fontCdn:
      'https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap',
    renderData: () => getStyleAccToType('Karla'),
  },
];

export const HEADING_DROPDOWN = {
  label: 'Heading Font',
  subLabel: 'Applicable to all the texts tagged from h1 to h6',
  key: 'heading',
  data: FONT_DROPDOWN,
  placeholder: '',
  customChildRender: true,
};

export const HEADING_SLIDER = {
  label: 'Heading font letter spacing',
  subLabel: 'Space between letters',
  min: -1,
  max: 3,
  suffix: 'px',
  key: 'heading',
  step: 0.5,
};

export const BODY_DROPDOWN = {
  label: 'Body text font',
  subLabel:
    'Applicable to all the texts tagged as paragraph and texts over website like header, footer, Item cards, cart page, etc.',
  key: 'body',
  data: FONT_DROPDOWN,
  placeholder: '',
  customChildRender: true,
};

export const BODY_SLIDER = {
  label: 'Body text letter spacing',
  subLabel: 'Space between letters',
  min: -1,
  max: 3,
  suffix: 'px',
  key: 'body',
  step: 0.5,
};

export const FONT_TABS = [
  {
    title: 'All',
    val: 'all',
  },
  {
    title: 'Default styles',
    val: 'default',
  },
  {
    title: 'Custom styles',
    val: 'custom',
  },
];

export const BUTTON_MULTISELECT = {
  label: 'Button text font',
  subLabel: 'Select font for button text',
  data: [
    {
      text: 'Body text font',
      value: 'body',
      itemLevelClass: 'tw-w-[50%]',
    },
    {
      text: 'Heading font',
      value: 'heading',
      itemLevelClass: 'tw-w-[50%]',
    },
  ],
};

const PRODUCT_NAME_DESCRIPTION_OPTION = [
  {
    text: 'One line',
    value: 'one',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  {
    text: 'Two line',
    value: 'two',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Full length',
    value: 'fullLength',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
];

const BUTTON_IMAGE_OPTION = [
  {
    text: 'Below image',
    value: 'below',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'On image',
    value: 'overlay',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
];

// const BUTTON_TYPE_BELOW = [
//   {
//     text: 'Full length',
//     value: 'fullLength',
//   },
//   {
//     text: 'Icon - In front of product name',
//     value: 'iconFrontOfProductName',
//   },
// ];

const BUTTON_TYPE_OVERLAY = [
  {
    text: 'Full length',
    value: 'fullLength',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Icon',
    value: 'icon',
    subscription: [SUBSCRIPTION_NAMES.ELITE],
  },
];

const BUTTON_BEHAVIOUR = [
  {
    text: 'Always show',
    value: 'always',
  },
  {
    text: 'On hover',
    value: 'hover',
  },
];

const getCardShare = (text, spiteDetails) => (
  <div className={`tw-flex tw-items-center tw-gap-[10px]`}>
    <div className={`wb-sprite ${spiteDetails}`}></div>
    <div>{text}</div>
  </div>
);

const renderBottomInfo = (text: string) => {
  return (
    <div className="tw-my-[10px] tw-px-[12px] tw-text-[13px] tw-font-normal tw-text-newBlack tw-opacity-70">
      {text}
    </div>
  );
};

const renderTopInfo = (text: string) => {
  return (
    <div
      style={{ border: '1px solid #D1E2F0', background: 'rgba(17, 28, 54, 0.04)' }}
      className="tw-mx-[12px] tw-my-[4px] tw-flex tw-items-start tw-gap-[8px] tw-rounded-[6px] tw-p-[8px] tw-text-[14px] tw-font-normal tw-text-[#000]"
    >
      <span className="wb-sprite ic-info-black tw-mt-[2px] !tw-w-[28px]"></span>
      <span>{text}</span>
    </div>
  );
};

const ICON_DATA_OPTION = [
  {
    value: 'plus',
    renderData: () => getCardShare('Plus', 'plus-icon'),
  },
  {
    value: 'bag',
    renderData: () => getCardShare('Bag', 'bag-icon'),
  },
  {
    value: 'cart',
    renderData: () => getCardShare('Cart', 'cart-icon'),
  },
];

// const ICON_SHAPE_OPTION = [
//   {
//     value: 'square',
//     renderData: () => getCardShare('Square', 'ic-arrow-square'),
//   },
//   {
//     value: 'circular',
//     renderData: () => getCardShare('Circular', 'ic-arrow-circle'),
//   },
//   {
//     value: 'rounded',
//     renderData: () => getCardShare('Rounded', 'ic-arrow-rounded'),
//   },
// ];

const REVIEW_OPTION = [
  {
    value: 'avgRating',
    renderData: () => getCardShare('Average rating', 'avg-rating'),
  },
  {
    value: 'fiveStar',
    renderData: (data) => getCardShare('5 Stars', 'star-rating'),
  },
];

const REVIEW_COUNT_OPTION = [
  {
    value: 'bracket',
    renderData: () => getCardShare('Bracket', 'review-count1'),
  },
  {
    value: 'divider',
    renderData: () => getCardShare('Divider', 'review-count2'),
  },
];

const DISCOUNT_MULTISELECT_OPTION = [
  {
    text: 'Below image',
    value: 'below',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'On image',
    value: 'overlay',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
];

const DISCOUNT_DROPDOWN = [
  {
    text: 'Text in box',
    value: 'textInBox',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Text only',
    value: 'text',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  {
    text: 'Discount tag on image',
    value: 'discountOverlay',
    subscription: [SUBSCRIPTION_NAMES.ELITE],
  },
];

const DISCOUNT_TAG = [
  {
    value: DISCOUNT_IMAGE_TAG.REVERSE_ARCH,
    renderData: (data) => getCardShare('Tag 1', 'tag1-icon'),
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    value: DISCOUNT_IMAGE_TAG.CIRCLE,
    renderData: (data) => getCardShare('Tag 2', 'tag2-icon'),
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    value: DISCOUNT_IMAGE_TAG.SQUARE,
    renderData: (data) => getCardShare('Tag 3', 'tag3-icon'),
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
];

const VARIANT_TYPE_DROPDOWN = [
  {
    text: 'Colour',
    value: 'Colour',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Size',
    value: 'Size',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
];

const VARIANT_SIZE_DROPDOWN = [
  {
    text: 'Small',
    value: 'small',
    subscription: [SUBSCRIPTION_NAMES.ELITE],
  },
  {
    text: 'Medium',
    value: 'medium',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Large',
    value: 'large',
    subscription: [SUBSCRIPTION_NAMES.ELITE],
  },
];

const PRODUCT_IMAGE_OPTION = [
  {
    text: 'Single image',
    value: 'single',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Multi-images',
    value: 'multiImage',
    subscription: [SUBSCRIPTION_NAMES.ELITE],
  },
];

const CARD_SHAPE_DROPDOWN = [
  {
    value: 'square',
    renderData: () => getCardShare('Square (1:1)', 'ic-square'),
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    value: 'portraitTall',
    renderData: () => getCardShare('Portrait tall (2:3)', 'ic-PortraitTall'),
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    value: 'portraitShort',
    renderData: () => getCardShare('Portrait short (3:4)', 'ic-PortraitShort'),
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    value: 'landscape',
    renderData: () => getCardShare('Landscape (3:2)', 'ic-landscape'),
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  {
    value: 'landscapeWide',
    renderData: () => getCardShare('Landscape (4:3)', 'ic-landscape2'),
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  {
    value: 'circle',
    renderData: () => getCardShare('Circle (1:1)', 'ic-circle'),
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  {
    value: 'blob',
    renderData: () => getCardShare('Blob (1:1)', 'ic-blob'),
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  {
    value: 'arch',
    renderData: () => getCardShare('Arch (3:4)', 'ic-arch'),
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
];

const HOVER_EFFECT_DROPDOWN = [
  {
    text: 'Zoom in',
    value: 'zoomIn',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Next image',
    value: 'nextImage',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  {
    text: 'Ambient on hover',
    value: 'ambient',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  {
    text: 'None',
    value: 'none',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
];

const HOVER_EFFECT_DROPDOWN_MULTI_IMAGE = [
  {
    text: 'Zoom in',
    value: 'zoomIn',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Next image',
    value: 'nextImage',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Run slideshow of images',
    value: 'runSlideshow',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
];

const ARROW_SHAPE_DROPDOWN = [
  {
    value: 'square',
    renderData: () => getCardShare('Square', 'ic-arrow-square'),
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    value: 'circular',
    renderData: () => getCardShare('Circular', 'ic-arrow-circle'),
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  {
    value: 'rounded',
    renderData: () => getCardShare('Rounded', 'ic-arrow-rounded'),
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
];

const BUTTON_STYLE_OPTION = [
  {
    text: 'Solid',
    value: 'solid',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Outline',
    value: 'outline',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
];

export const PRODUCT_CARD_LAYOUT_CONFIG = {
  label: 'Product card layout',
  editLabel: 'Change',
};

export const PRODUCT_APPEARANCE_DATA = [
  {
    text: 'Standard',
    value: 'standard',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Card',
    value: 'card',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
];

export const IMAGE_WIDTH_DATA = [
  {
    text: 'Full width',
    value: 'fullWidth',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'With margin',
    value: 'withMargin',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
];

const TAG_APPEARANCE = [
  {
    text: 'Keep default',
    value: 'default',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Custom colours',
    value: 'custom',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
];

const TAG_SHAPE_DATA = [
  {
    value: 'sharp',
    renderData: () => getCardShare('Sharp corner', 'tag-shape1'),
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    value: 'rounded',
    renderData: () => getCardShare('Rounded corner', 'tag-shape2'),
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    value: 'pill',
    renderData: () => getCardShare('Pill shaped', 'tag-shape3'),
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
];

export const TEXT_SIZE_DROPDOWN = [
  {
    text: 'Extra small (12px)',
    value: 'extraSmall',
    itemLevelClass: { fontSize: '12px !important' },
  },
  {
    text: 'Small (13px)',
    value: 'small',
    itemLevelClass: { fontSize: '13px !important' },
  },
  {
    text: 'Regular (14px)',
    value: 'regular',
    itemLevelClass: { fontSize: '14px !important' },
  },
  {
    text: 'Medium (15px)',
    value: 'medium',
    itemLevelClass: { fontSize: '15px !important' },
  },
  {
    text: 'Large (16px)',
    value: 'large',
    itemLevelClass: { fontSize: '16px !important' },
  },
  {
    text: 'Extra Large (18px)',
    value: 'extraLarge',
    itemLevelClass: { fontSize: 'px !important' },
  },
];

function getTagColor(
  text,
  bgColor,
  color = '#FFFFFF',
  borderColor = '#D2D9E4',
  borderWidth = '2px'
) {
  return (
    <div
      style={{
        background: bgColor,
        border: `${borderWidth} solid ${borderColor}`,
        color: color,
      }}
      className="tw-flex tw-h-[20px] tw-w-[56px] tw-items-center tw-justify-center tw-text-[10px] tw-font-medium"
    >
      {text}
    </div>
  );
}

export const TAG_COLOR_DATA = [
  {
    value: 'custom',
    renderData: () => getTagColor('Custom', '#FFFFFF', '#111C36'),
  },
  {
    value: '#FFFFFF',
    renderData: () => getTagColor('White', '#FFFFFF', '#111C36'),
  },
  {
    value: '#4966FF',
    renderData: () => getTagColor('Blue', '#4966FF'),
  },
  {
    value: '#DE4A53',
    renderData: () => getTagColor('Red', '#DE4A53'),
  },
  {
    value: '#501E9D',
    renderData: () => getTagColor('Violet', '#501E9D'),
  },
  {
    value: '#F09859',
    renderData: () => getTagColor('Orange', '#F09859'),
  },
  {
    value: '#219653',
    renderData: () => getTagColor('Green', '#219653'),
  },
  {
    value: '#E7B042',
    renderData: () => getTagColor('Golden', '#E7B042'),
  },
  {
    value: '#D55ED9',
    renderData: () => getTagColor('Lavender', '#D55ED9'),
  },
];

export const TAG_COLOR_TYPE_2 = [
  {
    value: 'custom',
    renderData: () => getTagColor('Custom', '#FFFFFF', '#111C36', '#D2D9E4', '.5px'),
  },
  {
    value: '#111C36',
    renderData: () => getTagColor('Black', '#FFFFFF', '#111C36', '#111C36', '.5px'),
  },
  {
    value: '#4966FF',
    renderData: () => getTagColor('Blue', '#FFFFFF', '#4966FF', '#4966FF', '.5px'),
  },
  {
    value: '#DE4A53',
    renderData: () => getTagColor('Red', '#FFFFFF', '#DE4A53', '#DE4A53', '.5px'),
  },
  {
    value: '#501E9D',
    renderData: () => getTagColor('Violet', '#FFFFFF', '#501E9D', '#501E9D', '.5px'),
  },
  {
    value: '#F09859',
    renderData: () => getTagColor('Orange', '#FFFFFF', '#F09859', '#F09859', '.5px'),
  },
  {
    value: '#219653',
    renderData: () => getTagColor('Green', '#FFFFFF', '#219653', '#219653', '.5px'),
  },
  {
    value: '#E7B042',
    renderData: () => getTagColor('Golden', '#FFFFFF', '#E7B042', '#E7B042', '.5px'),
  },
  {
    value: '#D55ED9',
    renderData: () => getTagColor('Lavender', '#FFFFFF', '#D55ED9', '#D55ED9', '.5px'),
  },
];

export const TAG_COLOR_TYPE_3 = [
  {
    value: 'custom',
    renderData: () => getTagColor('Custom', '#FFFFFF', '#111C36'),
  },
  {
    value: '#111C36',
    renderData: () => getTagColor('Black', '#111C360A', '#111C36', '#111C360A'),
  },
  {
    value: '#4966FF',
    renderData: () => getTagColor('Blue', '#4966FF0D', '#4966FF', '#4966FF0D'),
  },
  {
    value: '#DE4A53',
    renderData: () => getTagColor('Red', '#DE4A530D', '#DE4A53', '#DE4A530D'),
  },
  {
    value: '#501E9D',
    renderData: () => getTagColor('Violet', '#501E9D0D', '#501E9D', '#501E9D0D'),
  },
  {
    value: '#F09859',
    renderData: () => getTagColor('Orange', '#F098590D', '#F09859', '#F098590D'),
  },
  {
    value: '#219653',
    renderData: () => getTagColor('Green', '#2196530D', '#219653', '#2196530D'),
  },
  {
    value: '#E7B042',
    renderData: () => getTagColor('Golden', '#E7B0420D', '#E7B042', '#E7B0420D'),
  },
  {
    value: '#D55ED9',
    renderData: () => getTagColor('Lavender', '#D55ED90D', '#D55ED9', '#D55ED90D'),
  },
];

export const TAG_COLOR_ACC_TYPE = {
  type1: TAG_COLOR_DATA,
  type2: TAG_COLOR_TYPE_2,
  type3: TAG_COLOR_TYPE_3,
};

export const POSITIONS_DATA = [
  {
    text: 'Left',
    value: 'left',
    iconBaseClass: 'wb-sprite ic-align-left faded',
    iconActiveClass: 'wb-sprite ic-align-left',
    itemLevelClass: 'tw-w-[33%]',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Centre',
    value: 'center',
    iconBaseClass: 'wb-sprite ic-align-center faded',
    iconActiveClass: 'wb-sprite ic-align-center',
    itemLevelClass: 'tw-w-[33%]',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  {
    text: 'Right',
    value: 'right',
    iconBaseClass: 'wb-sprite ic-align-right faded',
    iconActiveClass: 'wb-sprite ic-align-right',
    itemLevelClass: 'tw-w-[33%]',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
];

export const DISCOUNT_TYPE_DATA = [
  {
    text: '% Percentage OFF (X% OFF)',
    value: 'percentage',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
  },
  {
    text: 'Amount off (₹X OFF)',
    value: 'amount',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
];

function capitalizeFirstLetter(val) {
  return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}

function variantcomponentData(variantData = []) {
  const filteredVariantData = variantData?.filter(
    (data) =>
      data?.name?.toLowerCase() !== 'size' ||
      data?.name?.toLowerCase() !== 'color' ||
      data?.name?.toLowerCase() !== 'colour'
  );
  const updatedVariantData = filteredVariantData?.map((data) => {
    return {
      text: capitalizeFirstLetter(data?.name),
      value: data?.name,
    };
  });
  return [...VARIANT_TYPE_DROPDOWN, ...updatedVariantData];
}

export const PRODUCT_CARD_BUILDER = {
  layout: {
    sectionHeading: 'Layout',
    description: '',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
    builder: [
      {
        element: 'productCardLayout',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'layout',
        componentLabel: 'Content alignment',
        element: 'multiselect',
        accessKey: ['config', 'contentAlignment'],
        componentData: POSITIONS_DATA,
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns - Desktop',
        element: 'slider',
        accessKey: ['config', 'numColumnsDesktop'],
        min: 3,
        max: 6,
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
        subscriptionBasedValues: {
          [SUBSCRIPTION_NAMES.ESSENTIAL]: {
            min: 4,
            max: 4,
          },
          [SUBSCRIPTION_NAMES.PRO]: {
            min: 3,
            max: 5,
          },
          [SUBSCRIPTION_NAMES.ELITE]: {
            min: 3,
            max: 6,
          },
        },
        suffix: '',
        step: 1,
      },
      {
        forType: 'layout',
        componentLabel: 'Number of columns - Mobile',
        element: 'slider',
        accessKey: ['config', 'numColumnsMobile'],
        min: 1,
        max: 2,
        suffix: '',
        step: 1,
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'layout',
        componentLabel: 'Product appearance',
        element: 'multiselect',
        accessKey: ['config', 'productAppearance'],
        componentData: PRODUCT_APPEARANCE_DATA,
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'layout',
        componentLabel: 'Image width',
        element: 'multiselect',
        renderConditional: true,
        isVisible: (data) => data?.config?.productAppearance === 'card',
        accessKey: ['config', 'imageWidth'],
        componentData: IMAGE_WIDTH_DATA,
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        element: 'colorDetails',
        componentLabel: 'Colour',
        isEnabled: (data) => data?.config?.productAppearance === 'card',
        renderDynamically: true,
        forType: 'layout',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
        componentData: [
          {
            forType: 'layout',
            text: 'backgroundColor',
            isVisible: (data) => data?.config?.productAppearance === 'card',
            renderConditional: true,
            displayVal: 'Background colour',
            accessKey: ['config', 'backgroundColor'],
            shouldUpdateData: false,
            data: [],
            subscription: [
              SUBSCRIPTION_NAMES.ESSENTIAL,
              SUBSCRIPTION_NAMES.PRO,
              SUBSCRIPTION_NAMES.ELITE,
            ],
          },
          {
            forType: 'layout',
            text: 'borderColor',
            isVisible: (data) => data?.config?.productAppearance === 'card',
            renderConditional: true,
            displayVal: 'Border colour',
            accessKey: ['config', 'borderColor'],
            shouldUpdateData: false,
            data: [],
            subscription: [
              SUBSCRIPTION_NAMES.ESSENTIAL,
              SUBSCRIPTION_NAMES.PRO,
              SUBSCRIPTION_NAMES.ELITE,
            ],
          },
        ],
      },
    ],
  },
  productInfo: {
    sectionHeading: 'Product info',
    description: '',
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
    builder: [
      {
        forType: 'name',
        componentLabel: 'Product name length',
        element: 'multiselect',
        accessKey: ['config', 'productNameNumOfLine'],
        componentData: PRODUCT_NAME_DESCRIPTION_OPTION,
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'description',
        element: 'checkbox',
        componentLabel: 'Show description',
        accessKey: ['config', 'showDescription'],
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'description',
        componentLabel: 'Description length - desktop',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.config?.showDescription) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        element: 'multiselect',
        accessKey: ['config', 'productdescriptionDesktopNumOfLines'],
        componentData: PRODUCT_NAME_DESCRIPTION_OPTION?.slice(0, 2),
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'description',
        componentLabel: 'Description length - mobile',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.config?.showDescription) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        element: 'multiselect',
        accessKey: ['config', 'productdescriptionMobileNumOfLines'],
        componentData: PRODUCT_NAME_DESCRIPTION_OPTION?.slice(0, 2),
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        componentLabel: 'Edit size/colour/thickness',
        componentSubLabel: 'Customise properties of product name, description and price.',
        element: 'customModal',
        modalHeading: 'Edit fonts',
        subscription: [SUBSCRIPTION_NAMES.ELITE],
        componentData: [
          {
            forType: 'name',
            element: 'dropdown',
            componentLabel: 'Product name size',
            accessKey: ['config', 'productNameSize'],
            componentData: TEXT_SIZE_DROPDOWN,
            placeholder: '',
            topInfo: () => renderTopInfo('Ideal size: 14-16px for product name.'),
            subscription: [SUBSCRIPTION_NAMES.ELITE],
          },
          {
            forType: 'description',
            element: 'dropdown',
            componentLabel: 'Description size',
            accessKey: ['config', 'productDescriptionSize'],
            componentData: TEXT_SIZE_DROPDOWN,
            placeholder: '',
            topInfo: () =>
              renderTopInfo('Ideal size: Should be 1-2px small from product name size.'),
            subscription: [SUBSCRIPTION_NAMES.ELITE],
          },
          {
            forType: 'price',
            element: 'dropdown',
            componentLabel: 'Price size',
            accessKey: ['config', 'productPriceSize'],
            componentData: TEXT_SIZE_DROPDOWN,
            placeholder: '',
            topInfo: () =>
              renderTopInfo('Ideal size: 13-15px, Feel free to keep as per need.'),
            subscription: [SUBSCRIPTION_NAMES.ELITE],
          },
          {
            element: 'separator',
            subscription: [
              SUBSCRIPTION_NAMES.ESSENTIAL,
              SUBSCRIPTION_NAMES.PRO,
              SUBSCRIPTION_NAMES.ELITE,
            ],
          },
          {
            element: 'colorDetails',
            componentLabel: 'Colours',
            subscription: [SUBSCRIPTION_NAMES.ELITE],
            componentData: [
              {
                forType: 'name',
                text: 'productNameColor',
                displayVal: 'Product name',
                accessKey: ['config', 'productNameColor'],
                shouldUpdateData: false,
                data: [],
              },
              {
                forType: 'description',
                text: 'productDescriptionColor',
                displayVal: 'Description',
                accessKey: ['config', 'productDescriptionColor'],
                shouldUpdateData: false,
                data: [],
                subscription: [SUBSCRIPTION_NAMES.ELITE],
              },
              {
                forType: 'price',
                text: 'productPriceColor',
                displayVal: 'Price',
                accessKey: ['config', 'productPriceColor'],
                shouldUpdateData: false,
                data: [],
                subscription: [SUBSCRIPTION_NAMES.ELITE],
              },
            ],
          },
          {
            element: 'separator',
          },
          {
            forType: 'name',
            element: 'dropdown',
            componentLabel: 'Product name thickness',
            accessKey: ['config', 'productNameThicness'],
            componentData: TEXT_THICKNESS,
            customChildRender: false,
            placeholder: '',
          },
          {
            forType: 'description',
            element: 'dropdown',
            componentLabel: 'Description thickness',
            accessKey: ['config', 'productDescriptionThicness'],
            componentData: TEXT_THICKNESS,
            customChildRender: false,
            placeholder: '',
          },
          {
            forType: 'price',
            element: 'dropdown',
            componentLabel: 'Price thickness',
            accessKey: ['config', 'productPriceThicness'],
            componentData: TEXT_THICKNESS,
            customChildRender: false,
            placeholder: '',
          },
        ],
      },
    ],
  },
  contentOrder: {
    sectionHeading: 'Content order (below image)',
    description: '',
    subscription: [SUBSCRIPTION_NAMES.ELITE],
    builder: [
      {
        element: 're-arrange',
        subscription: [SUBSCRIPTION_NAMES.ELITE],
      },
    ],
  },
  addToCartButton: {
    sectionHeading: 'Add to cart',
    description: '',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
    builder: [
      {
        forType: 'button',
        element: 'checkbox',
        componentLabel: 'Show add to cart',
        accessKey: ['config', 'showAddToCart'],
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'button',
        componentLabel: 'Position',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.config?.showAddToCart) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        element: 'multiselect',
        accessKey: ['elementPosition'],
        componentData: BUTTON_IMAGE_OPTION?.reverse(),
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'button',
        element: 'dropdown',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showAddToCart) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        componentLabel: 'Button type',
        accessKey: ['config', 'buttonType'],
        componentData: BUTTON_TYPE_OVERLAY,
        isVisible: (data) => data?.elementPosition === 'overlay',
        showDescription: true,
        getDescription: (data) =>
          data?.buttonType === 'fullLength'
            ? 'This CTA will come on hover, and won’t appear on mobile.'
            : '',
        renderConditional: true,
        placeholder: '',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'button',
        element: 'dropdown',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showAddToCart) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        componentLabel: 'Button behaviour',
        accessKey: ['config', 'buttonBehaviour'],
        componentData: BUTTON_BEHAVIOUR,
        isVisible: (data) =>
          data?.elementPosition === 'overlay' && data?.config?.buttonType === 'icon',
        renderConditional: true,
        placeholder: '',
        subscription: [SUBSCRIPTION_NAMES.ELITE],
      },
      {
        forType: 'button',
        element: 'dropdown',
        componentLabel: 'Text thicness',
        accessKey: ['config', 'buttonTextThicness'],
        isVisible: (data) => data?.elementPosition === 'overlay',
        renderConditional: true,
        componentData: TEXT_THICKNESS,
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showAddToCart) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        customChildRender: false,
        placeholder: '',
        subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
      },
      {
        forType: 'button',
        element: 'dropdown',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showAddToCart) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        isVisible: (data) =>
          data?.config?.buttonType === 'iconFrontOfProductName' ||
          data?.config?.buttonType === 'icon',
        renderConditional: true,
        componentLabel: 'Select icon',
        accessKey: ['config', 'iconType'],
        customChildRender: true,
        componentData: ICON_DATA_OPTION,
        placeholder: '',
        subscription: [SUBSCRIPTION_NAMES.ELITE],
      },
      {
        forType: 'button',
        componentLabel: 'Button style',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.config?.showAddToCart) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        element: 'multiselect',
        accessKey: ['config', 'buttonConfig', 'type'],
        componentData: BUTTON_STYLE_OPTION,
        subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
        renderConditional: true,
        isVisible: (data) =>
          data?.config.buttonType === 'iconFrontOfProductName' ||
          (data?.elementPosition === 'overlay' &&
            data?.config?.buttonType === 'fullLength'),
      },
      {
        forType: 'button',
        element: 'button',
        buttonHeading: '',
        twoTypeBtn: true,
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showAddToCart) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        subHeading: '',
        showColorCombination: false,
        showDeleteIcon: false,
        showRedirectionField: false,
        showButtonNameField: false,
        showButtonType: false,
        keepSameColor: true,
        isVisible: (data) =>
          data?.config?.buttonType === 'iconFrontOfProductName' ||
          data?.config?.buttonType === 'icon' ||
          (data?.elementPosition === 'overlay' &&
            data?.config?.buttonType === 'fullLength'),
        renderConditional: true,
        accessKey: ['config', 'buttonConfig'],
      },
      {
        element: 'colorDetails',
        componentLabel: 'Button color',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.config?.showAddToCart) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        isEnabled: (data) =>
          data?.config?.buttonType === 'iconFrontOfProductName' ||
          data?.config?.buttonType === 'icon' ||
          (data?.elementPosition === 'overlay' &&
            data?.config?.buttonType === 'fullLength'),
        renderDynamically: true,
        forType: 'button',
        subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
        componentData: [
          {
            forType: 'button',
            text: 'backgroundColor',
            renderConditional: true,
            isVisible: (data) => data?.config?.buttonConfig?.[0]?.type !== 'icon',
            dynamicDisplayVal: true,
            getDynamicDisVal: (data) => {
              if (data?.config?.buttonConfig?.[0]?.type === 'solid') {
                return 'Background';
              } else if (data?.config?.buttonConfig?.[0]?.type === 'outline') {
                return 'Border colour';
              }
            },
            accessKey: ['config', 'buttonConfig', 'btnStyleConfig', 'backgroundColor'],
            shouldUpdateData: false,
            data: [],
            subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
          },
          {
            forType: 'button',
            text: 'textColor',
            dynamicDisplayVal: true,
            getDynamicDisVal: (data) => {
              if (
                data?.elementPosition === 'overlay' &&
                data?.config?.buttonType === 'fullLength'
              ) {
                return 'Text colour';
              }
              return 'Icon colour';
            },
            accessKey: ['config', 'buttonConfig', 'btnStyleConfig', 'textColor'],
            shouldUpdateData: false,
            data: [],
            subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
          },
        ],
      },
      {
        forType: 'button',
        element: 'button',
        buttonHeading: '',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showAddToCart) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        subHeading: '',
        showColorCombination: true,
        showDeleteIcon: false,
        keepSameColor: true,
        showRedirectionField: false,
        isVisible: (data) =>
          data?.config?.buttonType === 'fullLength' &&
          data?.elementPosition !== 'overlay',
        renderConditional: true,
        accessKey: ['config', 'buttonConfig'],
        subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
      },
      {
        forType: 'button',
        element: 'checkbox',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showAddToCart) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        isVisible: (data) => data?.config?.buttonType === 'fullLength',
        renderConditional: true,
        componentLabel: 'Show icon in CTA',
        accessKey: ['config', 'iconInCta'],
        subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
      },
      {
        forType: 'button',
        element: 'dropdown',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showAddToCart) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        isVisible: (data) =>
          data?.config?.iconInCta && data?.config?.buttonType === 'fullLength',
        renderConditional: true,
        componentLabel: 'Select icon',
        accessKey: ['config', 'iconType'],
        customChildRender: true,
        componentData: ICON_DATA_OPTION,
        placeholder: '',
        subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
      },
      {
        forType: 'button',
        element: 'reset',
        heading: 'Reset to defaults',
        subHeading:
          'CTA configurations will be reset to default as per the selected Layout.',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
    ],
  },
  reviewAndRating: {
    sectionHeading: 'Ratings and reviews',
    description: '',
    subscription: [SUBSCRIPTION_NAMES.ELITE],
    builder: [
      {
        forType: 'rating',
        element: 'checkbox',
        componentLabel: 'Show ratings and reviews',
        accessKey: ['config', 'showRatings'],
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'rating',
        componentLabel: 'Position',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.config?.showRatings) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        element: 'multiselect',
        accessKey: ['elementPosition'],
        componentData: BUTTON_IMAGE_OPTION,
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'rating',
        element: 'dropdown',
        componentLabel: 'Appearance',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showRatings) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        accessKey: ['config', 'ratingAppearance'],
        customChildRender: true,
        showDisableOption: true,
        checkForDisable: (key, data) => {
          if (key === 'fiveStar' && data?.elementPosition === 'overlay') {
            return true;
          }
          return false;
        },
        componentData: REVIEW_OPTION,
        placeholder: '',
        bottomInfo: () =>
          renderBottomInfo(
            '5 Star view works best when placed below image. Choosing this will change its placement.'
          ),
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        element: 'colorDetails',
        componentLabel: 'Ratings colour',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.config?.showRatings) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        forType: 'rating',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
        componentData: [
          {
            forType: 'rating',
            text: 'starColor',
            displayVal: 'Star colour',
            accessKey: ['config', 'starColor'],
            shouldUpdateData: false,
            data: [],
            subscription: [
              SUBSCRIPTION_NAMES.ESSENTIAL,
              SUBSCRIPTION_NAMES.PRO,
              SUBSCRIPTION_NAMES.ELITE,
            ],
          },
          {
            forType: 'rating',
            text: 'textColor',
            isVisible: (data) => {
              if (
                data?.elementPosition === 'below' &&
                data?.config?.ratingAppearance === 'fiveStar'
              ) {
                return false;
              }
              return true;
            },
            renderConditional: true,
            displayVal: 'Text colour',
            accessKey: ['config', 'textColor'],
            shouldUpdateData: false,
            data: [],
            subscription: [
              SUBSCRIPTION_NAMES.ESSENTIAL,
              SUBSCRIPTION_NAMES.PRO,
              SUBSCRIPTION_NAMES.ELITE,
            ],
          },
          {
            forType: 'rating',
            text: 'backgroundColor',
            isVisible: (data) => {
              if (
                data?.elementPosition === 'below' &&
                data?.config?.ratingAppearance === 'fiveStar'
              ) {
                return false;
              }
              return true;
            },
            renderConditional: true,
            displayVal: 'Background color',
            accessKey: ['config', 'backgroundColor'],
            shouldUpdateData: false,
            data: [],
            subscription: [
              SUBSCRIPTION_NAMES.ESSENTIAL,
              SUBSCRIPTION_NAMES.PRO,
              SUBSCRIPTION_NAMES.ELITE,
            ],
          },
        ],
      },
      // {
      //   componentLabel: 'When to show ratings',
      //   element: 'checkboxCombination',
      //   componentData: [
      //     {
      //       forType: 'rating',
      //       element: 'checkbox',
      //       componentLabel: `Show ratings on items only when
      //         average rating > 3.5 `,
      //       accessKey: ['config', 'minAvgRating'],
      //       isCenterAlign: false
      //     },
      //     {
      //       forType: 'rating',
      //       element: 'checkbox',
      //       componentLabel: `Show ratings only if item has received
      //       at least 3 ratings.`,
      //       accessKey: ['config', 'minRating'],
      //       isCenterAlign: false
      //     }
      //   ]
      // },
      {
        forType: 'rating',
        element: 'checkbox',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showRatings) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        componentLabel: 'Enable review count',
        accessKey: ['config', 'showReviewCount'],
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        element: 'colorDetails',
        componentLabel: 'Review count',
        text: 'reviewCountColor',
        isEnabled: (data) => data?.config?.showReviewCount,
        renderDynamically: true,
        forType: 'rating',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
        componentData: [
          {
            forType: 'rating',
            text: 'reviewCountColor',
            isVisible: (data) => data?.config?.showReviewCount,
            renderConditional: true,
            displayVal: 'Text colour',
            accessKey: ['config', 'reviewCountColor'],
            shouldUpdateData: false,
            data: [],
            subscription: [
              SUBSCRIPTION_NAMES.ESSENTIAL,
              SUBSCRIPTION_NAMES.PRO,
              SUBSCRIPTION_NAMES.ELITE,
            ],
          },
        ],
      },
      {
        forType: 'rating',
        element: 'dropdown',
        isVisible: (data) => data?.config?.showReviewCount,
        renderConditional: true,
        componentLabel: 'Review count appearance',
        accessKey: ['config', 'reviewCountAppearance'],
        customChildRender: true,
        componentData: REVIEW_COUNT_OPTION,
        placeholder: '',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        element: 'separator',
      },
      {
        forType: 'rating',
        element: 'reset',
        heading: 'Reset to defaults',
        subHeading: 'Ratings and reviews configurations will be reset to default.',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
    ],
  },
  discountAndOffer: {
    sectionHeading: 'Discounts and offers',
    description: '',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
    builder: [
      {
        forType: 'discount',
        componentLabel: 'Position',
        element: 'multiselect',
        accessKey: ['elementPosition'],
        componentData: DISCOUNT_MULTISELECT_OPTION,
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'discount',
        element: 'dropdown',
        componentLabel: 'Discounting type',
        accessKey: ['config', 'discountType'],
        customChildRender: false,
        componentData: DISCOUNT_TYPE_DATA,
        placeholder: '',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'discount',
        element: 'dropdown',
        componentLabel: 'Discount style',
        accessKey: ['config', 'discountStyle'],
        customChildRender: false,
        dynamicData: true,
        getDynamicData: function (data) {
          if (data?.elementPosition === 'overlay') {
            return DISCOUNT_DROPDOWN;
          }
          return DISCOUNT_DROPDOWN?.slice(0, 2);
        },
        placeholder: '',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'discount',
        element: 'dropdown',
        componentLabel: 'Select tag',
        accessKey: ['config', 'discountTag'],
        customChildRender: true,
        renderConditional: true,
        isVisible: (data) =>
          data?.elementPosition === 'overlay' &&
          data?.config?.discountStyle === 'discountOverlay',
        componentData: DISCOUNT_TAG,
        placeholder: '',
      },
      {
        element: 'colorDetails',
        componentLabel: 'Button colour',
        subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
        colorsData: [
          {
            forType: 'discount',
            text: 'textColor',
            displayVal: 'Text colour',
            accessKey: ['config', 'textColor'],
            shouldUpdateData: false,
            data: [],
            subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
          },
          {
            forType: 'discount',
            text: 'backgroundColor',
            isVisible: (data) => data?.config?.discountStyle !== 'text',
            renderConditional: true,
            displayVal: 'Background color',
            accessKey: ['config', 'backgroundColor'],
            shouldUpdateData: false,
            data: [],
            subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
          },
        ],
      },
      {
        forType: 'discount',
        element: 'reset',
        heading: 'Reset to defaults',
        subHeading: 'Discounting configurations will be reset to default.',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
    ],
  },
  variants: {
    sectionHeading: 'Variants',
    description: '',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
    builder: [
      {
        forType: 'variant',
        element: 'checkbox',
        componentLabel: 'Show variants',
        accessKey: ['config', 'showVariant'],
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'variant',
        element: 'dropdown',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showVariant) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        componentLabel: 'Select variant to show',
        accessKey: ['config', 'variantType'],
        customChildRender: false,
        dynamicData: true,
        getDynamicData: function (data) {
          return variantcomponentData(data);
        },
        componentData: VARIANT_TYPE_DROPDOWN,
        placeholder: '',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'variant',
        element: 'radioButton',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showVariant) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        componentLabel: 'Appearance',
        accessKey: ['config', 'variantShape'],
        getDynamicData: function (data) {
          if (data?.variantType === 'Colour') {
            return [
              {
                label: 'Box',
                id: 'box',
                value: 'box',
                subscription: [SUBSCRIPTION_NAMES.ELITE],
              },
              {
                label: 'Circle',
                id: 'circle',
                value: 'circle',
                subscription: [SUBSCRIPTION_NAMES.ELITE],
              },
            ];
          }

          return [
            {
              label: 'Text',
              id: 'text',
              value: 'text',
              subscription: [
                SUBSCRIPTION_NAMES.ESSENTIAL,
                SUBSCRIPTION_NAMES.PRO,
                SUBSCRIPTION_NAMES.ELITE,
              ],
            },
            {
              label: 'Box',
              id: 'box',
              value: 'box',
              subscription: [SUBSCRIPTION_NAMES.ELITE],
            },
            {
              label: 'Circle/rounded',
              id: 'circle/rounded',
              value: 'circle/rounded',
              subscription: [SUBSCRIPTION_NAMES.ELITE],
            },
          ];
        },
        dynamicData: true,
        placeholder: '',
      },
      {
        forType: 'variant',
        element: 'checkbox',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showVariant) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        renderConditional: true,
        isVisible: (data) => data?.config?.variantShape === 'text',
        componentLabel: 'Separate variants with comma',
        accessKey: ['config', 'separateVariantWithComa'],
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'variant',
        element: 'dropdown',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showVariant) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        componentLabel: 'Size',
        accessKey: ['config', 'variantSize'],
        customChildRender: false,
        componentData: VARIANT_SIZE_DROPDOWN,
        placeholder: '',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      // {
      //   forType: 'variant',
      //   element: 'checkbox',
      //   conditionalStyle: true,
      //   getStyles: (data) => {
      //     if (!data?.showVariant) {
      //       return ' tw-opacity-60 tw-pointer-events-none';
      //     }
      //   },
      //   componentLabel: 'Show out of stock variants',
      //   accessKey: ['config', 'showOutOfStockVariant'],
      // },
      {
        forType: 'variant',
        element: 'reset',
        heading: 'Reset to defaults',
        subHeading: 'Variants configurations will be reset to default.',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
    ],
  },
  productImage: {
    sectionHeading: 'Product image',
    description: '',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
    builder: [
      {
        forType: 'image',
        componentLabel: 'Image to display',
        element: 'multiselect',
        accessKey: ['config', 'imageType'],
        componentData: PRODUCT_IMAGE_OPTION,
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'image',
        element: 'dropdown',
        componentLabel: 'Image shape',
        accessKey: ['config', 'imageShape'],
        componentData: CARD_SHAPE_DROPDOWN,
        customChildRender: true,
        placeholder: '',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'image',
        element: 'dropdown',
        componentLabel: 'Image hover interaction',
        accessKey: ['config', 'hoverType'],
        componentData: HOVER_EFFECT_DROPDOWN,
        renderConditional: true,
        isVisible: (data) => data?.config?.imageType === 'single',
        customChildRender: false,
        placeholder: '',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'image',
        element: 'dropdown',
        componentLabel: 'Image hover interaction',
        accessKey: ['config', 'hoverType'],
        componentData: HOVER_EFFECT_DROPDOWN_MULTI_IMAGE,
        renderConditional: true,
        isVisible: (data) => data?.config?.imageType === 'multiImage',
        customChildRender: false,
        placeholder: '',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'image',
        element: 'autoSlide',
        componentLabel: 'Change image every:',
        accessKey: ['config', 'autoSlideTimer'],
        renderConditional: true,
        isVisible: (data) => data?.config?.hoverType === 'runSlideshow',
        placeholder: '',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        element: 'colorDetails',
        componentLabel: 'Colours',
        forType: 'image',
        accessKey: ['config', 'activeDotColor'],
        isEnabled: (data) => data?.config?.imageType === 'multiImage',
        renderDynamically: true,
        belowText: 'Arrow will come on hover',
        showLabelConditional: (data) => data?.imageType === 'multiImage',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
        componentData: [
          {
            forType: 'image',
            text: 'activeDotColor',
            displayVal: 'Active dot',
            accessKey: ['config', 'activeDotColor'],
            shouldUpdateData: false,
            data: [],
            renderConditional: true,
            isVisible: (data) => data?.config?.imageType === 'multiImage',
            subscription: [
              SUBSCRIPTION_NAMES.ESSENTIAL,
              SUBSCRIPTION_NAMES.PRO,
              SUBSCRIPTION_NAMES.ELITE,
            ],
          },
          {
            forType: 'image',
            text: 'arrowColor',
            displayVal: 'Arrow',
            renderConditional: true,
            isVisible: (data) => data?.config?.imageType === 'multiImage',
            accessKey: ['config', 'arrowColor'],
            shouldUpdateData: false,
            data: [],
            subscription: [
              SUBSCRIPTION_NAMES.ESSENTIAL,
              SUBSCRIPTION_NAMES.PRO,
              SUBSCRIPTION_NAMES.ELITE,
            ],
          },
        ],
      },
      {
        forType: 'image',
        element: 'dropdown',
        componentLabel: 'Arrow shape',
        accessKey: ['config', 'arrowShape'],
        renderConditional: true,
        isVisible: (data) => data?.config?.imageType === 'multiImage',
        componentData: ARROW_SHAPE_DROPDOWN,
        customChildRender: true,
        placeholder: '',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'image',
        element: 'reset',
        heading: 'Reset to defaults',
        subHeading: 'Product image settings will be reset to default.',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
    ],
  },
  tags: {
    sectionHeading: 'Tags',
    description: '',
    subscription: [
      SUBSCRIPTION_NAMES.ESSENTIAL,
      SUBSCRIPTION_NAMES.PRO,
      SUBSCRIPTION_NAMES.ELITE,
    ],
    builder: [
      {
        forType: 'tags',
        element: 'checkbox',
        componentLabel: 'Show tags',
        accessKey: ['config', 'showTag'],
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'tags',
        componentLabel: 'Appearance',
        element: 'multiselect',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.config?.showTag) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        accessKey: ['config', 'tagAppearance'],
        componentData: TAG_APPEARANCE,
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'tags',
        element: 'tagStyle',
        componentLabel: 'Tag style',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showTag) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        isVisible: (data) => data?.config?.tagAppearance === 'custom',
        renderConditional: true,
        accessKey: ['config', 'tagStyle'],
        modalHeading: 'Tag style',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
        componentData: [
          {
            value: 'type1',
            image: '/assets/images/widgetImages/GlobalStyle/tags/Tag style 1.png',
            subscription: [
              SUBSCRIPTION_NAMES.ESSENTIAL,
              SUBSCRIPTION_NAMES.PRO,
              SUBSCRIPTION_NAMES.ELITE,
            ],
          },
          {
            value: 'type2',
            image: '/assets/images/widgetImages/GlobalStyle/tags/Tag style 2.png',
            subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
          },
          {
            value: 'type3',
            image: '/assets/images/widgetImages/GlobalStyle/tags/Tag style 3.png',
            subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
          },
        ],
      },
      {
        forType: 'tags',
        element: 'dropdown',
        componentLabel: 'Tag shape',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showTag) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        accessKey: ['config', 'tagShape'],
        customChildRender: true,
        componentData: TAG_SHAPE_DATA,
        placeholder: '',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'tags',
        element: 'dropdown',
        componentLabel: 'Tag colour',
        conditionalStyle: true,
        getStyles: (data) => {
          if (!data?.showTag) {
            return ' tw-opacity-60 tw-pointer-events-none';
          }
        },
        renderConditional: true,
        isVisible: (data) => data?.config?.tagAppearance === 'custom',
        accessKey: ['config', 'tagColor'],
        customChildRender: true,
        getDynamicData: function (data) {
          return TAG_COLOR_ACC_TYPE[data?.config?.tagStyle];
        },
        dynamicData: true,
        componentData: TAG_COLOR_DATA,
        placeholder: '',
        subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
      },
      {
        element: 'colorDetails',
        componentLabel: '',
        isEnabled: (data) =>
          data?.config?.isCustomColor && data?.config?.tagAppearance === 'custom',
        renderDynamically: true,
        forType: 'tags',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
        componentData: [
          {
            forType: 'tags',
            text: 'text_color',
            isVisible: (data) =>
              data?.config?.isCustomColor &&
              data?.config?.tagAppearance === 'custom' &&
              data?.config?.tagStyle === 'type1',
            renderConditional: true,
            displayVal: 'Text colour',
            accessKey: ['config', 'text_color'],
            shouldUpdateData: false,
            data: [],
            subscription: [
              SUBSCRIPTION_NAMES.ESSENTIAL,
              SUBSCRIPTION_NAMES.PRO,
              SUBSCRIPTION_NAMES.ELITE,
            ],
          },
          {
            forType: 'tags',
            text: 'tag_color',
            isVisible: (data) =>
              data?.config?.isCustomColor && data?.config?.tagAppearance === 'custom',
            renderConditional: true,
            dynamicDisplayVal: true,
            getDynamicDisVal: (data) => {
              if (data?.config?.tagStyle === 'type1') {
                return 'Background colour';
              } else {
                return 'Colour';
              }
            },
            displayVal: 'Background colour',
            accessKey: ['config', 'tag_color'],
            shouldUpdateData: false,
            data: [],
            subscription: [
              SUBSCRIPTION_NAMES.ESSENTIAL,
              SUBSCRIPTION_NAMES.PRO,
              SUBSCRIPTION_NAMES.ELITE,
            ],
          },
        ],
      },
      {
        forType: 'tags',
        element: 'customTag',
        heading: 'Reset to defaults',
        subHeading: '',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
      {
        forType: 'tags',
        element: 'reset',
        heading: 'Reset to defaults',
        subHeading: 'Tags configurations will be reset to default.',
        subscription: [
          SUBSCRIPTION_NAMES.ESSENTIAL,
          SUBSCRIPTION_NAMES.PRO,
          SUBSCRIPTION_NAMES.ELITE,
        ],
      },
    ],
  },
};

export const RESET_MODAL_DESCRIPTION = {
  button:
    'CTA design and position will be reset to default as per the selected Layout. Are you sure you want to reset to defaults?',
  image:
    'Product image settings will be reset to default. Are you sure you want to reset?',
};

export const SUBSCRIPTION_TOGGLE_VALUES = [
  {
    text: 'Essential',
    value: SUBSCRIPTION_NAMES.ESSENTIAL,
    itemLevelClass: 'tw-w-[33%]',
  },
  {
    text: 'Pro',
    value: SUBSCRIPTION_NAMES.PRO,
    itemLevelClass: 'tw-w-[33%]',
  },
  {
    text: 'Elite',
    value: SUBSCRIPTION_NAMES.ELITE,
    itemLevelClass: 'tw-w-[33%]',
  },
];

import {
  fetchStoreInfo,
  getThemeById,
  getFeatureLocks,
  fetchAdvSeoDetails,
  getDummyProductsByThemeTagId,
} from './store';
import { getCatalogItemById, getCatalogCategories, getCollectionData } from './catalog';
import { getOrderDetailsByHash, getStoreOrderByHash } from './orders';
import { getCitiesList, getbusinessList, getStoresByCityId } from './spotlight';
import { getAdditionalPage } from './additionalPages';
import { getSEODetails } from './seo';
import { fetchWidgetThemeInfo } from './widgets';

export {
  fetchStoreInfo,
  getThemeById,
  getCatalogItemById,
  getCatalogCategories,
  getCollectionData,
  getOrderDetailsByHash,
  getStoreOrderByHash,
  getCitiesList,
  getbusinessList,
  getStoresByCityId,
  getAdditionalPage,
  getSEODetails,
  getFeatureLocks,
  fetchAdvSeoDetails,
  fetchWidgetThemeInfo,
  getDummyProductsByThemeTagId,
};

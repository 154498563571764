import store from '../redux/store';
import { ORDER_GST_TYPE } from './constants';

export function isOrderGstIncluded() {
  const { orderDetails } = store.getState().orderReducer;
  if (orderDetails?.gst_type === ORDER_GST_TYPE.GST_INCLUDED) return true;
  return false;
}

export function isOrderGstExcluded() {
  const { orderDetails } = store.getState().orderReducer;
  if (orderDetails?.gst_type === ORDER_GST_TYPE.GST_EXCLUDED) return true;
  return false;
}

/*
  Utility file for atomic components
  Conains code that may be used by all components
 */
export const roundnessCalculator = (val) => {
  switch(val) {
    case 0:
      return `0px`;
    case 2:
      return `5px`;
    case 4:
      return `30px`;
    default:
      return `0px`;
  }
};
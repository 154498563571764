import { Component, ComponentType, FunctionComponent, PropsWithChildren } from "react";

export class ErrorBoundary extends Component<PropsWithChildren<{}>> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.warn(error);
  }

  render() {
    if ((this.state as any).hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children; 
  }
}

export const withErrorBoundary = <T extends Record<string, any> = {}>(Component: ComponentType<T>) => {
  const withErrorBoundaryComponent: FunctionComponent<T> = (props) => {
    const JSXComponent = Component as any;
    return <ErrorBoundary>
      <JSXComponent {...props} />
    </ErrorBoundary>
  };

  return withErrorBoundaryComponent;
}

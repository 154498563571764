import { getDefaultConfig } from '@/components/WidgetMaker/utils/common';

export interface WidgetOptions {
  isVisible: boolean;
  baseConfig: {
    background: string;
    foregroundImage: string;
    bgImage: Record<string, string | number>;
    backgroundType: string;
    overlapType: string;
    paddingY: number;
    paddingX: number;
    paddingMobileX: number;
    marginTop: number;
    marginBottom: number;
    showBorder: boolean;
  };
  textSection: {
    backgroundColors: Record<string, string | boolean>[];
    textColor: string;
    selectedIndex: number;
    backgroundColor: string;
    alignment: string;
    position: string;
    mobileAlignment: string;
    bgFillType: string;
    bgImage: Record<string, string | number>;
  };
  imageSection: {
    desktop: {
      height: string;
      width: string;
      imageAlignment: string;
    };
    mobile: {
      height: string;
      width: string;
      imageAlignment: string;
    };
    url: string;
    src: string;
    animationType: string;
  };
  textGroupSection: any;
}

export interface WidgetProps {
  store: any;
  cart: any;
  catalog: any;
  user: any;
  promo: any;
  widgetOptions: WidgetOptions;
  update: number;
}

export const BASE_SECTION_BACKGROUND_TYPES = {
  color: 'color',
  image: 'image',
};

export const SUBTITLE_POSITIONS_DATA = [
  {
    text: 'Top',
    value: 'top',
    itemLevelClass: 'tw-w-[50%]',
  },
  {
    text: 'Bottom',
    value: 'bottom',
    itemLevelClass: 'tw-w-[50%]',
  },
];

export const SUBTITLE_SIZE_LIST = [
  { text: 'Small', value: 'small' },
  { text: 'Medium', value: 'medium' },
  { text: 'Large', value: 'large' },
];

export const TEXT_GROUP_OPTIONS_ITEM = {
  title: '<h2>Title</h2>',
  subtitleSize: 'small',
  isVisible: true,
  subtitlePosition: 'top',
  subtitle: `<p>Subtitle</p>`,
  description: `<p>Pair text with an image to focus on your
  chosen product, collection, or blog post.
  Add details on availability, style, or even
  provide a review.</p>`,
  button: [getDefaultConfig('bannerButton', '#000000', '#FFFFFF')],
};

export const OVERLAP_TYPE = {
  OVERLAP: 'overlap',
  NO_OVERLAP: 'noOverlap',
};

export const RICH_IMAGE_TEXT_DEFAULT_WIDGET_OPTIONS: Partial<WidgetOptions> = {
  isVisible: true,
  baseConfig: {
    background: '#ffffff',
    foregroundImage: '',
    paddingY: 0,
    paddingX: 0,
    paddingMobileX: 0,
    marginTop: 0,
    marginBottom: 0,
    bgImage: {
      url: '',
      src: '',
      opacity: 70,
    },
    backgroundType: BASE_SECTION_BACKGROUND_TYPES.color,
    overlapType: OVERLAP_TYPE.NO_OVERLAP,
    showBorder: false,
  },
  textSection: {
    backgroundColors: [
      {
        background: '#ffffff',
        text: '#000000',
        isDefault: false,
      },
      {
        background: 'transparent',
        text: '#ffffff',
        isDefault: false,
      },
    ],
    bgFillType: BASE_SECTION_BACKGROUND_TYPES.color,
    bgImage: {
      url: '',
      src: '',
      opacity: 70,
    },
    selectedIndex: 0,
    textColor: '#000000',
    backgroundColor: '#ffffff',
    position: 'center',
    alignment: 'left',
    mobileAlignment: 'left',
  },
  imageSection: {
    url: '',
    src: '',
    desktop: {
      width: '50%',
      height: '450px',
      imageAlignment: 'left',
    },
    mobile: {
      width: '50%',
      height: '450px',
      imageAlignment: 'top',
    },
    animationType: 'zoomIn',
  },
  textGroupSection: {
    builderConfig: {
      textSection: {
        subtitlePositions: SUBTITLE_POSITIONS_DATA,
        subtitleSize: SUBTITLE_SIZE_LIST,
      },
    },
    groupItemsList: [TEXT_GROUP_OPTIONS_ITEM],
  },
};

export const HEIGHT_ACC_IMAGE_TYPE_1 = {
  top: '0px',
  middle: '75px',
  bottom: '150px',
};

export const HEIGHT_ACC_IMAGE_TYPE_2 = {
  top: '0px',
  middle: '150px',
  bottom: '300px',
};


export function getTextAndBackgroundColor(widgetOptions) {
  const selectedBgIndex = widgetOptions?.textSection?.selectedIndex;
  const selectedColorDetails = widgetOptions?.textSection?.backgroundColors[selectedBgIndex];
  return selectedColorDetails;
}
import {
  SAVE_PAGE_LAYOUT_WIDGETS,
  SAVE_WIDGET_THEME_INFO,
  SAVE_FLOATING_BUTTON_DATA,
  SET_ACTIVE_STYLE_TEMPLATE,
  SAVE_ACTIVE_WIDGET_CHANGE_DESIGN_TEMPLATE,
  SAVE_FORM_DATA,
  UPDATE_THEME_BUILDER_DATA,
  SET_THEME_BUILDER_FLOW,
  SAVE_CURRENT_THEME_DATA,
} from '../actionTypes';

const initialState = {
  themeConfig: {},
  pageLayoutWidgets: {},
  floatingButtonWidget: {},
  activeStyleTemplate: null,
  activeWidgetChangeDesignTemplate: null,
  activeHeaderWidgetChangesDesignTemplate: [
    {
      title: 'Header1',
      name: 'Header1',
      imgUrl: '/assets/images/widgetImages/Headers/header1.png',
      propsConfig: { name: 'Header1' },
    },
    {
      title: 'Header2',
      name: 'Header2',
      imgUrl: '/assets/images/widgetImages/Headers/header2.png',
      propsConfig: { name: 'Header2' },
    },
    {
      title: 'Header3',
      name: 'Header3',
      imgUrl: '/assets/images/widgetImages/Headers/header3.png',
      propsConfig: { name: 'Header3' },
    },
    {
      title: 'Header4',
      name: 'Header4',
      imgUrl: '/assets/images/widgetImages/Headers/header4.png',
      propsConfig: { name: 'Header4' },
    },
    {
      title: 'Header5',
      name: 'Header5',
      imgUrl: '/assets/images/widgetImages/Headers/header5.png',
      propsConfig: { name: 'Header5' },
    },
    {
      title: 'Header6',
      name: 'Header6',
      imgUrl: '/assets/images/widgetImages/Headers/header6.png',
      propsConfig: { name: 'Header6' },
    },
    {
      title: 'Header7',
      name: 'Header7',
      imgUrl: '/assets/images/widgetImages/Headers/header7.png',
      propsConfig: { name: 'Header7' },
    },
    {
      title: 'Header8',
      name: 'Header8',
      imgUrl: '/assets/images/widgetImages/Headers/header8.png',
      propsConfig: { name: 'Header8' },
    },
    {
      title: 'Header9',
      name: 'Header9',
      imgUrl: '/assets/images/widgetImages/Headers/header9.png',
      propsConfig: { name: 'Header9' },
    },
    {
      title: 'Header10',
      name: 'Header10',
      imgUrl: '/assets/images/widgetImages/Headers/header10.png',
      propsConfig: { name: 'Header10' },
    },
    {
      title: 'Header11',
      name: 'Header11',
      imgUrl: '/assets/images/widgetImages/Headers/header11.png',
      propsConfig: { name: 'Header11' },
    },
    {
      title: 'Header12',
      name: 'Header12',
      imgUrl: '/assets/images/widgetImages/Headers/header12.png',
      propsConfig: { name: 'Header12' },
    },
    {
      title: 'Header13',
      name: 'Header13',
      imgUrl: '/assets/images/widgetImages/Headers/header13.png',
      propsConfig: { name: 'Header13' },
    },
    {
      title: 'Header14',
      name: 'Header14',
      imgUrl: '/assets/images/widgetImages/Headers/header14.png',
      propsConfig: { name: 'Header14' },
    },
  ],
  formData: null,
  themeBuilderData: {
    currentThemeData: null,
    activeBusinessCategory: null,
    activeSubscriptionType: null,
    isActive: false,
    themeBuilderFlowType: null, // Determines whether current flow is for update/create.
  },
};

const widgetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_WIDGET_THEME_INFO:
      return {
        ...state,
        themeConfig: action.data || {},
      };
    case SAVE_PAGE_LAYOUT_WIDGETS:
      return {
        ...state,
        pageLayoutWidgets: action.data || {},
      };
    case SAVE_FLOATING_BUTTON_DATA:
      return {
        ...state,
        floatingButtonWidget: action.data || {},
      };
    case SET_ACTIVE_STYLE_TEMPLATE:
      return {
        ...state,
        activeStyleTemplate: action.data || null,
      };
    case SAVE_ACTIVE_WIDGET_CHANGE_DESIGN_TEMPLATE:
      return {
        ...state,
        activeWidgetChangeDesignTemplate: action.data || null,
      };
    case SAVE_FORM_DATA:
      return {
        ...state,
        formData: action.data || null,
      };
    case UPDATE_THEME_BUILDER_DATA: {
      if (action.data.type === 'activeBusinessCategoryAndSubscription') {
        return {
          ...state,
          themeBuilderData: {
            ...state.themeBuilderData,
            activeBusinessCategory: action?.data?.activeBusinessCategory || null,
            activeSubscriptionType: action?.data?.activeSubscriptionType || null,
          },
        };
      } else if (action.data.type === 'activateThemeBuilder') {
        return {
          ...state,
          themeBuilderData: {
            ...state.themeBuilderData,
            isActive: action.data.isActive,
          },
        };
      }
    }
    case SET_THEME_BUILDER_FLOW: {
      return {
        ...state,
        themeBuilderData: {
          ...state.themeBuilderData,
          themeBuilderFlowType: action.data,
        },
      };
    }

    case SAVE_CURRENT_THEME_DATA: {
      return {
        ...state,
        themeBuilderData: {
          ...state.themeBuilderData,
          currentThemeData: action.data,
        },
      };
    }
    default:
      return state;
  }
};

export default widgetsReducer;

import store from '@/redux/store';
import { getVisibleStorePhone } from './getContactDetails';

export function getWhatAppRedirectionUrlHelp() {
  const storeData = store.getState().storeReducer.store;
  const message = `Hi%2C%0D%0A%0D%0AI+am+at+your+online+store+and+need+some+help.%0D%0A%0D%0A${
    storeData?.store_info?.store_url
  }`;
  return getWhatAppRedirectionUrl(message);
}

export function getWhatAppRedirectionUrlCODRequest(payAmount, orderId) {
  const storeData = store.getState().storeReducer.store;
  const message = `Hi%2C%0AI%20would%20pay%20Rs.${payAmount}%20in%20*cash*%20for%20the%20*Order%20%23*%20-%20${orderId}%0Athat%20was%20placed%20at%20your%20online%20store%20-%20%0A${storeData?.store_info?.store_url}`;

  return getWhatAppRedirectionUrl(message);
}

export function getWhatAppRedirectionUrl(message = '') {
  const storeData = store.getState().storeReducer.store;
  const storePhone = getVisibleStorePhone(storeData);
  return `https://api.whatsapp.com/send?phone=+91${storePhone}&text=${message}`;
}

import { MAX_MOBILE_WIDTH } from './constants';
import { IS_SERVER, IS_CLIENT } from './checkRenderEnv';
import { deviceWidth } from './deviceWidth';

/**
 * `false` if code is running on SSR
 * `true` if code is running in browser Desktop
 * @type {Boolean}
 */
export const IS_DESKTOP = IS_CLIENT && deviceWidth > MAX_MOBILE_WIDTH;

/**
 * `false` if code is running on SSR
 * `true` if code is running in browser Mobile
 * * @type {Boolean}
 */
export const IS_MOBILE = IS_CLIENT && deviceWidth <= MAX_MOBILE_WIDTH;

/**
 * - `true` if code is running on SSR or browser Desktop
 * - Use with CSS utility class `.visible-desktop` for correct SSR behavior.
 *
 * ```javascript
 * {SSR_DESKTOP && <div className="visible-desktop"></div>}
 * ```
 */
export const SSR_DESKTOP = IS_SERVER || IS_DESKTOP;
/**
 * - `true` if code is running on SSR or browser Mobile
 * - Use with CSS utility class `.visible-mobile` for correct SSR behavior.
 * ```javascript
 * {SSR_MOBILE && <div className="visible-mobile"></div>}
 * ```
 */
export const SSR_MOBILE = IS_SERVER || IS_MOBILE;

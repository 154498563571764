export function LeftCaratIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
    >
      <path
        d="M3.28956 2.03627C3.25196 1.99649 3.22258 1.9497 3.20307 1.89856C3.18356 1.84742 3.17432 1.79295 3.17587 1.73824C3.17742 1.68353 3.18972 1.62966 3.21209 1.57971C3.23446 1.52975 3.26644 1.4847 3.30622 1.44711C3.346 1.40951 3.3928 1.38013 3.44393 1.36062C3.49507 1.34111 3.54955 1.33187 3.60426 1.33342C3.65897 1.33497 3.71283 1.34727 3.76279 1.36964C3.81274 1.39201 3.8578 1.42399 3.89539 1.46377L7.43706 5.21377C7.5102 5.29113 7.55095 5.39356 7.55095 5.50002C7.55095 5.60649 7.5102 5.70891 7.43706 5.78627L3.89539 9.53669C3.85805 9.57734 3.813 9.61017 3.76286 9.63326C3.71273 9.65635 3.6585 9.66925 3.60334 9.67121C3.54817 9.67317 3.49317 9.66415 3.44152 9.64468C3.38987 9.6252 3.34261 9.59565 3.30247 9.55776C3.26234 9.51986 3.23014 9.47436 3.20773 9.42391C3.18533 9.37347 3.17318 9.31907 3.17197 9.26388C3.17077 9.2087 3.18055 9.15382 3.20073 9.10245C3.22092 9.05107 3.25111 9.00422 3.28956 8.96461L6.56122 5.50002L3.28956 2.03627Z"
        fill="#414141"
      />
    </svg>
  );
}

export function CollectionWithNoImage() {
  return (
    <svg
      width="211"
      height="210"
      viewBox="0 0 211 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="210" height="210" transform="translate(0.253906)" fill="#DFDFDF" />
      <path
        d="M95.2539 113.333H115.254L109.004 105L104.004 111.667L100.254 106.667L95.2539 113.333ZM90.2539 120V90H120.254V120H90.2539Z"
        fill="#5E5E5E"
      />
    </svg>
  );
}

export function CollectionWithNoImageMobile() {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="2" fill="#DFDFDF" />
      <path
        d="M11.7598 18.6592H19.2832L16.9321 15.5244L15.0513 18.0322L13.6406 16.1514L11.7598 18.6592ZM9.87891 21.167V9.88184H21.1641V21.167H9.87891Z"
        fill="#5E5E5E"
      />
    </svg>
  );
}

export function AccordionCloseIcon(props) {
  const { secondaryColor, height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.45686 11.1452C2.39322 11.2054 2.31835 11.2524 2.23653 11.2836C2.15471 11.3148 2.06754 11.3296 1.98001 11.3271C1.89247 11.3246 1.80628 11.3049 1.72636 11.2692C1.64644 11.2334 1.57434 11.1822 1.5142 11.1185C1.45405 11.0549 1.40703 10.98 1.37582 10.8982C1.34461 10.8164 1.32982 10.7292 1.3323 10.6417C1.33477 10.5542 1.35447 10.468 1.39025 10.388C1.42604 10.3081 1.47722 10.236 1.54086 10.1759L7.54086 4.50921C7.66464 4.39218 7.82852 4.32698 7.99886 4.32698C8.16921 4.32698 8.33309 4.39218 8.45686 4.50921L14.4575 10.1759C14.5226 10.2356 14.5751 10.3077 14.612 10.3879C14.649 10.4681 14.6696 10.5549 14.6728 10.6432C14.6759 10.7314 14.6615 10.8194 14.6303 10.9021C14.5991 10.9847 14.5519 11.0603 14.4912 11.1245C14.4306 11.1888 14.3578 11.2403 14.2771 11.2761C14.1964 11.312 14.1093 11.3314 14.021 11.3333C13.9327 11.3353 13.8449 11.3196 13.7627 11.2873C13.6805 11.255 13.6056 11.2067 13.5422 11.1452L7.99886 5.91054L2.45686 11.1452Z"
        fill={secondaryColor || '#414141'}
      />
    </svg>
  );
}

export function AccordionOpenIcon(props) {
  const { secondaryColor, height, width } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13.5431 4.85479C13.6068 4.79465 13.6817 4.74763 13.7635 4.71642C13.8453 4.68521 13.9325 4.67042 14.02 4.67289C14.1075 4.67537 14.1937 4.69506 14.2736 4.73085C14.3536 4.76663 14.4257 4.81781 14.4858 4.88146C14.5459 4.94511 14.593 5.01998 14.6242 5.1018C14.6554 5.18362 14.6702 5.27078 14.6677 5.35831C14.6652 5.44585 14.6455 5.53204 14.6097 5.61196C14.574 5.69189 14.5228 5.76398 14.4591 5.82413L8.45914 11.4908C8.33536 11.6078 8.17148 11.673 8.00114 11.673C7.83079 11.673 7.66691 11.6078 7.54314 11.4908L1.54247 5.82413C1.47743 5.76438 1.42491 5.6923 1.38796 5.61208C1.35101 5.53186 1.33036 5.4451 1.32723 5.35684C1.32409 5.26858 1.33853 5.18057 1.36969 5.09793C1.40085 5.0153 1.44813 4.93967 1.50876 4.87546C1.5694 4.81125 1.64219 4.75972 1.72291 4.72388C1.80363 4.68803 1.89066 4.66858 1.97896 4.66666C2.06726 4.66474 2.15506 4.68038 2.23726 4.71268C2.31946 4.74497 2.39443 4.79328 2.4578 4.85479L8.00114 10.0895L13.5431 4.85479Z"
        fill={secondaryColor || '#414141'}
      />
    </svg>
  );
}

export function MoreCarat() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M16.778 10.2099C16.926 10.3499 17 10.528 17 10.7443C17 10.9606 16.926 11.1387 16.778 11.2786L13.065 14.7901C12.9843 14.8664 12.8969 14.9204 12.8027 14.9519C12.7085 14.9835 12.6076 14.9995 12.5 15C12.3924 15 12.288 14.9809 12.1868 14.9427C12.0857 14.9046 12.0017 14.8537 11.935 14.7901L8.22197 11.2786C8.07399 11.1387 8 10.9606 8 10.7443C8 10.528 8.07399 10.3499 8.22197 10.2099C8.36996 10.07 8.5583 10 8.787 10C9.0157 10 9.20404 10.07 9.35202 10.2099L12.5 13.187L15.648 10.2099C15.796 10.07 15.9843 10 16.213 10C16.4417 10 16.63 10.07 16.778 10.2099Z"
        fill="black"
      />
    </svg>
  );
}

export function LessCarat() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M8.88604 13.7901C8.73805 13.6501 8.66406 13.472 8.66406 13.2557C8.66406 13.0394 8.73805 12.8613 8.88604 12.7214L12.599 9.20992C12.6798 9.13359 12.7672 9.07964 12.8614 9.04809C12.9555 9.01654 13.0564 9.00051 13.1641 9C13.2717 9 13.3761 9.01908 13.4772 9.05725C13.5784 9.09542 13.6624 9.14631 13.7291 9.20992L17.4421 12.7214C17.5901 12.8613 17.6641 13.0394 17.6641 13.2557C17.6641 13.472 17.5901 13.6501 17.4421 13.7901C17.2941 13.93 17.1058 14 16.8771 14C16.6484 14 16.46 13.93 16.312 13.7901L13.1641 10.813L10.0161 13.7901C9.8681 13.93 9.67976 14 9.45106 14C9.22236 14 9.03402 13.93 8.88604 13.7901Z"
        fill="black"
      />
    </svg>
  );
}

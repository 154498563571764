export function UserIconV1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="22"
      fill="none"
      viewBox="0 0 18 22"
      {...props}
    >
      <path
        stroke="#111C36"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M12.75 5a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM1.501 19.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 019 20.75c-2.676 0-5.216-.584-7.499-1.632z"
      ></path>
    </svg>
  );
}

export function UserIconV2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      fill="none"
      viewBox="0 0 18 20"
      {...props}
    >
      <path
        stroke="#111C36"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17 19v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2M13 5a4 4 0 11-8 0 4 4 0 018 0z"
      ></path>
    </svg>
  );
}

export function CartIconV1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="20"
      fill="none"
      viewBox="0 0 18 20"
      {...props}
    >
      <path
        stroke="#111C36"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M.9 4.6L3.6 1h10.8l2.7 3.6M.9 4.6v12.6A1.8 1.8 0 002.7 19h12.6a1.8 1.8 0 001.8-1.8V4.6M.9 4.6h16.2m-4.5 3.6a3.6 3.6 0 01-7.2 0"
      ></path>
    </svg>
  );
}

export function CartIconV2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      fill="none"
      viewBox="0 0 20 18"
      {...props}
    >
      <path
        fill="#111C36"
        d="M8.213 13.29c-1.256 0-2.278 1.056-2.278 2.355S6.957 18 8.213 18s2.278-1.056 2.278-2.355S9.47 13.29 8.213 13.29zm0 3.501c-.611 0-1.108-.514-1.108-1.146 0-.632.497-1.147 1.108-1.147.612 0 1.109.515 1.109 1.147s-.497 1.146-1.109 1.146zm6.968-3.501c-1.256 0-2.278 1.056-2.278 2.355S13.925 18 15.181 18s2.278-1.056 2.278-2.355-1.022-2.355-2.278-2.355zm0 3.501c-.611 0-1.108-.514-1.108-1.146 0-.632.497-1.147 1.108-1.147.612 0 1.109.515 1.109 1.147s-.497 1.146-1.109 1.146zm4.764-12.109l-1.476 6.424c-.21.917-.995 1.558-1.908 1.558H7.378c-.91 0-1.693-.637-1.907-1.55l-1.34-5.752-.97-4.153H.586A.595.595 0 010 .604C0 .271.262 0 .585 0h3.037c.27 0 .505.191.569.463l1.077 4.616 1.34 5.75a.786.786 0 00.77.627h9.183a.785.785 0 00.77-.63l1.476-6.423a.794.794 0 00-.15-.697.74.74 0 00-.621-.311H7.15a.595.595 0 01-.585-.605c0-.333.262-.604.585-.604h10.886c.612 0 1.158.274 1.538.77.38.497.512 1.11.37 1.726z"
      ></path>
    </svg>
  );
}

export function CartIconV3(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="#111C36"
        d="M6.154 19.558c-.486 0-.9-.17-1.24-.511-.34-.34-.51-.753-.51-1.24 0-.485.17-.898.51-1.239.34-.34.754-.51 1.24-.51s.899.17 1.24.51c.34.34.51.754.51 1.24s-.17.899-.51 1.239c-.341.34-.754.51-1.24.51zm9.692 0c-.486 0-.899-.17-1.24-.511-.34-.34-.51-.753-.51-1.24 0-.485.17-.898.51-1.239.341-.34.754-.51 1.24-.51s.9.17 1.24.51c.34.34.51.754.51 1.24s-.17.899-.51 1.239c-.34.34-.754.51-1.24.51zM5.016 3.75L7.55 9.058h6.817a.28.28 0 00.154-.043.377.377 0 00.115-.12l2.683-4.876c.039-.07.042-.133.01-.187-.032-.055-.087-.082-.164-.082H5.015zm-.72-1.5h13.885c.409 0 .718.174.927.522.21.348.22.704.03 1.067l-3.204 5.803c-.163.289-.38.513-.65.674a1.7 1.7 0 01-.888.242H7.1l-1.158 2.115c-.051.077-.053.16-.005.25s.12.135.217.135h11.442v1.5H6.154c-.667 0-1.168-.288-1.503-.863-.335-.575-.347-1.149-.035-1.722l1.426-2.565L2.404 1.75H.5V.25h2.846l.95 2z"
      ></path>
    </svg>
  );
}

export function SearchIconV1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        stroke="#111C36"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15.223 8.111a7.111 7.111 0 11-14.224 0 7.111 7.111 0 0114.225 0h-.001zM17 16.998l-3.867-3.867"
      ></path>
    </svg>
  );
}

export function SearchIconV2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        fill="#111C36"
        fillRule="evenodd"
        d="M7.615 1.385a6.23 6.23 0 100 12.461 6.23 6.23 0 000-12.461zM0 7.615a7.615 7.615 0 1113.468 4.874l4.33 4.33a.692.692 0 11-.98.978l-4.33-4.33A7.615 7.615 0 010 7.616z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export function ContactIconV1(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fill="#111C36"
        d="M12.152 11.57c-.138.021-.226.154-.364.362-.144.223-.31.479-.63.564-.171.048-.492.048-1.25-.474A11.523 11.523 0 018.457 10.8c-.763-.75-.774-.937-.774-1.006-.017-.309.238-.564.464-.788.138-.133.276-.271.32-.388.017-.085-.099-.469-.414-1.011-.315-.543-.597-.841-.685-.873-.011-.005-.033-.005-.055-.005-.16 0-.553.09-.896.393-.353.32-.707.937-.38 2.054.602 2.081 2.497 3.763 5.337 4.726.752.255 1.37.255 1.83-.01.64-.373.834-1.155.884-1.475.005-.026-.006-.053-.022-.074-.382-.383-1.564-.83-1.912-.772z"
      ></path>
      <path
        fill="#111C36"
        stroke="#111C36"
        strokeWidth="0.2"
        d="M1.076 8.937l-.1-.008a8.694 8.694 0 012.552-5.48l.07.071-.07-.07C5.192 1.804 7.42.9 9.8.9c2.466 0 4.796.96 6.561 2.704 1.733 1.712 2.706 3.973 2.737 6.366a8.616 8.616 0 01-2.58 6.305c-1.664 1.644-3.893 2.539-6.273 2.539h0a9.278 9.278 0 01-4.83-1.343l-3.76 1.581h0a.552.552 0 01-.213.048.546.546 0 01-.374-.148m.007-10.015l.061 9.942m-.061-9.942l-.1-.008a9.056 9.056 0 001.402 5.59M1.076 8.936l1.413 5.57m-1.42 4.445l.068-.073m-.068.073s0 0 0 0l.068-.073m-.068.073a.534.534 0 01-.135-.577h0l1.444-3.857m-1.24 4.36a.434.434 0 01-.11-.468l1.462-3.903m-.112.011l.028.043.083-.054m-.11.011l.017-.047.094.036m2.764 1.874l-2.864 1.2 1.102-2.943h0a.533.533 0 00-.06-.488h0c-2.148-3.145-1.783-7.325.863-9.94 1.459-1.44 3.414-2.234 5.506-2.234a8.2 8.2 0 015.794 2.39l.07-.072-.07.072c1.532 1.513 2.39 3.508 2.418 5.618a7.575 7.575 0 01-2.262 5.54c-1.458 1.441-3.413 2.235-5.505 2.235a8.2 8.2 0 01-4.482-1.332l-.054.084.054-.084a.547.547 0 00-.51-.046z"
      ></path>
    </svg>
  );
}

import { getCategoryTagNameFromId } from '../utils/getCategoryTagNameFromId';
import { isWebsiteInternallyRouted } from 'src/utils/isWebsiteInternallyRouted';
import { IS_SERVER } from './checkRenderEnv';

function getCidFromRouteName(routeName) {
  const cidParam = '?cid=';
  try {
    const cid = routeName.split(cidParam)[1];
    return parseInt(cid);
  } catch (err) {
    return -1;
  }
}

/**
 *
 * @param {string} routeName
 * @param {string} domain
 * @return {string}
 */
export const getRoute = (routeName, domain = '') => {
  try {
    if (!IS_SERVER && location.href.includes('preview')) {
      return location.pathname;
    }
    if (
      (routeName.includes(PATH.SHOP) || routeName.includes(PATH.PRODUCT)) &&
      routeName.includes('?cid=')
    ) {
      const cid = getCidFromRouteName(routeName);
      const categoryName = encodeURIComponent(
        getCategoryTagNameFromId(cid).replace(/ /g, '').replace(/\//g, '-')
      );
      routeName = `shop/${categoryName}?cid=${cid}`;
    }

    let domainPrefix = '/';
    if (domain) {
      if (domain.startsWith('d-temporary-')) {
        const domainParts = domain.split('-');
        domainPrefix = `/${domainParts[1]}-${domainParts[2]}/`;
      } else if (domain.startsWith('d-')) {
        domainPrefix = `/${domain.split('-')[1]}/`;
      } else if (checkIsValidDomain(domain)) {
        if (location.pathname.startsWith(`/${domain}`)) {
          // domain is in pathname
          domainPrefix = `/${domain}/`;
        }
      } else {
        domainPrefix = `/${domain}/`;
      }
    }
    return `${domainPrefix}${routeName}`;
  } catch (error) {
    console.log(error);
    return '/404';
  }
};

export const checkIsValidDomain = (domain) => {
  const re = new RegExp(
    /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/
  );
  return domain.match(re);
};

export const getCanonicalUrl = (url) => {
  const urlObj = url?.split('?');
  // we just need to fetch the url part before query param on pdp page.
  if (urlObj?.length === 2 && url?.includes(PATH.PDP)) {
    return urlObj[0];
  }
  return url;
};

export const getSeoProductRoute = (city, domain) => {
  if (domain.startsWith('d-temporary-'))
    return `/${PATH.productCity(city)}/${domain.split('-')[1]}-${domain.split('-')[2]}`;
  else if (domain.startsWith('d-'))
    return `/${PATH.productCity(city)}/${domain.split('-')[1]}`;
  else {
    return `/${PATH.productCity(city)}/${domain}`;
  }
};

export const PATH = {
  /** APP PATHS */
  PRODUCT: '',
  SHOP: 'shop',
  PDP: '/product/',
  VERIFY_OTP: 'verify-otp',
  MEMBER_LOGIN: 'member-login',
  ORDERS: 'orders',
  STORE_CLOSE: 'store-close',
  CART: 'cart',
  SEARCH: 'search',
  _404: '404',
  paymentProcess: (txnID) => `payment-process/${txnID}`,
  paymentProcessing: (txnID, paymentMethod, orderHash) =>
    `payment/processing/${txnID}?method=${paymentMethod}${
      orderHash ? `&oHash=${orderHash}` : ''
    }`,
  DELIVERY_DETAILS: 'delivery-details',
  DELIVERY_LIST: 'delivery-list',
  ADD_DELIVERY: 'add-delivery',
  orderStatus: (hash) => `order-status/${hash}`,
  orderReceipt: (hash) => `order-receipt/${hash}`,
  thankyou: (hash) => `thankyou/${hash}`,
  orderConfirm: (hash) => `order-confirm/${hash}`,
  payment: (hash) => `payment/${hash}`,
  cardPayment: (hash) => `payment/card/${hash}`,
  paymentFailed: (hash) => `payment-failed/${hash}`,
  productDescription: (pId, pname) => `product/${pId}/${pname}`,
  ABOUT_US: `about-us`,
  STORE_POLICIES: `policy`,
  DOMAIN_HOME: `/`,
  additionalPage: (route) => `p/${route}`,
  USER_COUPONS_LIST: 'offers-list',
  CATEGORIES: 'categories',
  emiOptions: (emiPrice) => `emi-options?emiPrice=${emiPrice}`,
  EMI_PAYMENT: `emi-payment`,
  collectionCategories: (collectionId) => `collections/${collectionId}`,
  WEBSITE_BUILDER: '/widgets/',
  STORE: '/store/',
  MY_ACCOUNT: 'my-account', // only for app when user is logged in
  BUILDER_PREVIEW: 'play-preview',
};

/**
 * Server side 3XX redirection
 * @param {import('next').NextPageContext} ctx
 * @param {String} redirectUrl Url to redirect to
 * @param {Number} status @default 301 Can be 301, 302, 303
 * @returns
 */
export const redirectToUrl = (ctx, redirectUrl, status = 301) => {
  if (ctx && redirectUrl) {
    ctx.res.writeHead(status, { location: redirectUrl });
    ctx.res.end();
    return { props: { status } };
  } else {
    return null;
  }
};

/**
 * Server side 404 redirection
 * @param {import('next').NextPageContext} ctx
 * @returns
 */
export const redirectTo404 = (ctx) => {
  ctx.res.writeHead(302, { location: '/404' });
  ctx.res.end();
};

/**
 * Server side 3XX redirection for store if redirect url exists
 * @param {import('next').NextPageContext} ctx
 *
 */
export const redirectStoreIfNeeded = (ctx, storeInfo) => {
  if (ctx) {
    if (storeInfo?.redirect_url) {
      redirectToUrl(ctx, storeInfo?.redirect_url);
      return true;
    }
    if (!storeInfo?.status) {
      redirectTo404(ctx);
      return true;
    }
  }
  return false;
};

/**
 * Server side home redirection, when purchased domain has missing complete subscription
 * @param {import('next').NextPageContext} ctx
 * @returns
 */
export const isRouteHomepage = (ctx) => {
  const isDomainInternallyRouted = isWebsiteInternallyRouted(
    ctx.req.headers?.domain || ctx.req.headers.host
  );
  const path = isDomainInternallyRouted
    ? ctx.req.url?.replace(`/${ctx.params?.domain}`, PATH.DOMAIN_HOME)
    : ctx.req.url;

  if (
    path === PATH.DOMAIN_HOME ||
    [PATH.WEBSITE_BUILDER, PATH.BUILDER_PREVIEW].includes(ctx.req.url)
  ) {
    return false;
  }
  return true;
};

import { all } from 'redux-saga/effects';
import store from './store';
import catalog from './catalog';
import auth from './auth';
import order from './order';
import payment from './payment';
import spotlight from './spotlight';
import logs from './logs';
import cart from './cart';
import promo from './promo';
import user from './user';
import additionalPages from './additionalPages';
import storePolicies from './storePolicies';
import leadgen from './leadgen';
import emi from './emi';
import delivery from './delivery';
import widgets from './widgets';
import common from './common';
import globalSetting from './globalSetting';

export default function* root() {
  yield all([
    store(),
    catalog(),
    auth(),
    order(),
    payment(),
    spotlight(),
    logs(),
    cart(),
    promo(),
    user(),
    additionalPages(),
    storePolicies(),
    leadgen(),
    emi(),
    delivery(),
    widgets(),
    common(),
    globalSetting(),
  ]);
}

const SliderArrowBack = ({ color = '#000000' }) => {
  return (
    <svg
      width="7"
      height="12"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.2929 0.292886C5.68341 -0.0976287 6.3166 -0.0976287 6.70711 0.292886C7.09763 0.683401 7.09763 1.31659 6.70711 1.7071L2.41421 6L6.70711 10.2929C7.09763 10.6834 7.09763 11.3166 6.70711 11.7071C6.3166 12.0976 5.68341 12.0976 5.2929 11.7071L0.292887 6.70711C-0.0976281 6.31659 -0.0976281 5.68341 0.292887 5.29289L5.2929 0.292886Z"
        fill={color}
      />
    </svg>
  );
};

export default SliderArrowBack;

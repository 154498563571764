import React, { useContext } from 'react';
import { CartButtonBody, CentralText, InteractionComponent } from './CartButton.styles';
import { MinusIcon } from '@/assets/svgExports/MinusIcon';
import { PlusIcon } from '@/assets/svgExports/PlusIcon';
import { useSSRSelector } from '@/redux/ssrStore';
import CartItemInput from './CartItemInput';
import { DeleteIcon } from 'src/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/EcommWidgets/HeroBanner/Svgs';
import { CartContext } from 'src/context/CartContext';
import { getThemeColor } from 'src/utils/getThemeColor';

const BUTTON_ACTION = {
  INCREMENT: 'inc',
  DECREMENT: 'dec',
};

const AnimatedDash = () => {
  return (
    <div className="tw-absolute tw-bottom-0 tw-left-0 tw-right-0">
      <div
        className="tw-h-[2px] tw-w-[20%] tw-animate-[sprint_0.5s_linear_infinite]"
        style={{
          backgroundColor: getThemeColor(),
        }}
      />
    </div>
  );
};

const CartButton = (props) => {
  const { px = '1rem', py = '0.3rem' } = props;
  const {
    allowCustomInput = false,
    allowItemDeletion = false,
    quantity,
    fontSize = 20,
    onChange,
    disableAddition = false,
    customClasses = '',
    wrapperClasses = '',
    isPDPPage,
    isUpdating = false,
    hasScheduler = false,
    ...args
  } = props;

  const { setDeletionAlertState } = useContext(CartContext);

  const storeInfo = useSSRSelector((state) => state.storeReducer.store);
  const isEditable =
    storeInfo?.services?.custom_cart_item_quantity_flag && allowCustomInput;
  const onChangeBtn = (e, type, isLabel = false) => {
    if (!hasScheduler) {
      e.stopPropagation?.();
      e.preventDefault?.();
      if (isLabel && quantity) return;
      if (type === BUTTON_ACTION.INCREMENT && disableAddition) return;
      onChange?.(type);
    }
  };

  const onDeleteClick = () => {
    setDeletionAlertState?.({
      show: true,
      callback: () => onChange?.('custom', 0),
    });
  };

  return (
    <div className={`tw-flex tw-h-fit tw-items-center tw-gap-[8px] ${wrapperClasses}`}>
      {allowItemDeletion && (
        <span
          onClick={onDeleteClick}
          className="tw-absolute tw-right-0 tw-top-0 tw-cursor-pointer md:tw-static"
        >
          <DeleteIcon className="tw-h-[20px] tw-w-[20px] md:tw-h-[14px] md:tw-w-[14px]" />
        </span>
      )}
      <CartButtonBody
        className={`!tw-h-full tw-w-full tw-min-w-[80px]  !tw-justify-between ${customClasses}`}
        roundness={storeInfo?.theme?.roundness}
        px={px}
        py={py}
        centered={!quantity}
        isPDPPage={isPDPPage}
        cursor={!quantity ? 'pointer' : 'default'}
        {...args}
      >
        {!quantity ? (
          <CentralText
            className="tw-min-w-[40px]"
            onClick={(e) => onChangeBtn(e, 'add', true)}
            quantity={quantity}
            fontSize={fontSize}
            isPDPPage={isPDPPage}
          >
            {props.label ? props.label : isPDPPage ? 'Add to Cart' : 'ADD'}
          </CentralText>
        ) : (
          <>
            {props.leftButton ? (
              props.leftButton
            ) : (
              <InteractionComponent
                position="left:0px;"
                onClick={(e) => onChangeBtn(e, BUTTON_ACTION.DECREMENT)}
                isPDPPage={isPDPPage}
                zIndex={9}
              >
                <div
                  className={`dib flex items-center
                  ${disableAddition ? 'o-20' : 'o-100'}
                `}
                >
                  <MinusIcon />
                </div>
              </InteractionComponent>
            )}
            {isEditable ? (
              <CartItemInput
                value={quantity}
                onChange={(value, reset) => onChange?.('custom', value, reset)}
              />
            ) : (
              <CentralText
                isPDPPage={isPDPPage}
                quantity={quantity === 0}
                fontSize={fontSize}
              >
                {/* change for only variant modal qty button */}
                {quantity || 0}
              </CentralText>
            )}

            {props.rightButton ? (
              props.rightButton
            ) : (
              <InteractionComponent
                position="right:0px;"
                onClick={(e) => onChangeBtn(e, BUTTON_ACTION.INCREMENT)}
                isPDPPage={isPDPPage}
                zIndex={9}
              >
                <div
                  className={`dib flex items-center
                  ${disableAddition ? 'o-20' : 'o-100'}
                `}
                >
                  <PlusIcon />
                </div>
              </InteractionComponent>
            )}
          </>
        )}
        {isUpdating && <AnimatedDash />}
      </CartButtonBody>
    </div>
  );
};

export default CartButton;

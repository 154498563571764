export function WidgetOutlineFooterIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clip-path="url(#clip0_1261_2448)">
        <path
          d="M3.75 15L14.25 15C15.0825 15 15.75 14.325 15.75 13.5L15.75 4.5C15.75 3.675 15.0825 3 14.25 3L3.75 3C2.925 3 2.25 3.675 2.25 4.5L2.25 13.5C2.25 14.325 2.9175 15 3.75 15ZM3.75 4.5L14.25 4.5L14.25 12L3.75 12L3.75 4.5Z"
          fill="#222222"
        />
      </g>
      <defs>
        <clipPath id="clip0_1261_2448">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

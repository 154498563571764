import {
  GET_ALL_EMI_OPTIONS,
  GET_EMI_OPTIONS_FOR_CARD,
  SAVE_ALL_EMI_OPTIONS,
  SAVE_EMI_OPTION_FOR_CARD,
  SET_EMI_OPTION,
} from '../actionTypes';

export const getEmiOptionsSpecificToCard = (data, callback) => {
  return {
    type: GET_EMI_OPTIONS_FOR_CARD,
    data,
    callback,
  };
};

export const getAllEmiOptions = data => {
  return {
    type: GET_ALL_EMI_OPTIONS,
    data,
  };
};

export const saveEmiOptionsSpecificToCard = data => {
  return {
    type: SAVE_EMI_OPTION_FOR_CARD,
    data,
  };
};

export const saveAllEmiOptions = data => {
  return {
    type: SAVE_ALL_EMI_OPTIONS,
    data,
  };
};

export const setEmiOptions = (data, callback) => {
  return {
    type: SET_EMI_OPTION,
    data,
    callback,
  };
};

import React, { FunctionComponent, PropsWithChildren } from 'react';
import ReactTooltip from 'react-tooltip';

interface TooltipProps {
  delay?: number;
  position?: 'bottom' | 'top' | 'left' | 'right';
  id?: string;
  content: string;
}
const Tooltip: FunctionComponent<PropsWithChildren<TooltipProps>> = (props) => {
  const { delay = 250, position = 'bottom', content } = props;

  return (
    <div className="tooltip" data-tip data-for="common-tooltip">
      {props.children}
      <ReactTooltip
        id="common-tooltip"
        place={position}
        effect="solid"
        multiline={true}
        padding="8px"
        delayHide={delay}
        delayShow={delay}
      >
        {content}
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;

import { TOO_MANY_ITEMS_ERROR, MAX_CART_ITEMS_LIMIT } from './constants';
import { toastNotifyError } from '@/components/Common/Toast';

export function getSaveCartItems(action) {
  if (action.data?.items?.length > MAX_CART_ITEMS_LIMIT) {
    action.data?.items.pop();
    toastNotifyError(TOO_MANY_ITEMS_ERROR);
  }
  return action.data?.items ? action.data?.items : [];
}

import {
  GET_ADDITIONAL_PAGE_DATA_BY_SLUG,
  SAVE_ADDITIONAL_PAGE,
  SAVE_ADDITIONAL_PAGE_LIST,
  GET_NAVIGATION_PAGES_LIST,
  SAVE_NAVIGATION_PAGES_LIST,
  GET_STATIC_WIDGET_DATA,
  UPDATE_ADDITIONAL_PAGE_REDUCER,
} from './../actionTypes';

export const getAddtionalPageDataBySlug = (data) => {
  return {
    type: GET_ADDITIONAL_PAGE_DATA_BY_SLUG,
    data,
  };
};

export const saveAddtionalPage = (data) => {
  return {
    type: SAVE_ADDITIONAL_PAGE,
    data,
  };
};

export const updateAdditionalPageReducer = (data) => {
  return {
    type: UPDATE_ADDITIONAL_PAGE_REDUCER,
    data,
  };
};

export const saveAdditionalPageList = (data) => {
  return {
    type: SAVE_ADDITIONAL_PAGE_LIST,
    data,
  };
};

export const getNavigationPagesList = (data, callback) => {
  return {
    type: GET_NAVIGATION_PAGES_LIST,
    data,
    callback,
  };
};

export const saveNavigationPagesList = (data) => {
  return {
    type: SAVE_NAVIGATION_PAGES_LIST,
    data,
  };
};

export const getStaticWidgetData = (data, callback) => {
  return {
    type: GET_STATIC_WIDGET_DATA,
    data,
    callback,
  };
};

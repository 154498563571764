/**
 * Adds gst_info object while calling setOrderCart To items array.
 * @param {*} cartItems
 * @return {*} array with gst adjusted items details
 */
export function getCartItemsWithGst(cartItems) {
  const newGstAdjustedItems = cartItems.map((item) => {
    if ('tax' in item) {
      item['gst_info'] = {
        gst_percentage: item?.tax?.gst,
        tax_rule_id: item?.tax?.id,
      };
      delete item.tax;
    }
    return item;
  });
  return newGstAdjustedItems;
}

// import { Slider } from 'src/components/ReactSlickCarousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ANIMATION_TYPE } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/NewAnnouncementBar/Constants';
import { getAnnouncementBarStyles } from './Utils';
import { getSeperatorByType } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/NewAnnouncementBar/Utils';
import BasicSlider from 'src/components/BasicSlider';
import { AnnouncementStrip } from './Components/AnnouncementStrip';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import {
  NEW_HEADER_ID,
  setLastActiveWidget,
} from 'src/components/WidgetMaker/WidgetDnD/utils';
import { InfiniteAutoScroller } from './Components/InfiniteAutoScroller';
import { CSSProperties, useRef, useState } from 'react';
import { useAutoScrollOrRotation } from '../ListPresentation/hooks/useAutoScrollOrRotation';
import { useActiveHeaderSection } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/HeaderBuilder/Hooks/useActiveHeaderSection';
import { useDeviceType } from 'src/components/WidgetMaker/WidgetDnD/hooks/useDeviceType';
import { HEADER_INITIATOR_TYPE } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/HeaderBuilder/constants';
import { useActiveAnnouncementAdjustment } from './Hooks/useActiveAnnouncement';
import classNames from 'classnames';
import { useNavConfigs } from 'src/hooks/useNavConfigs';
import { HEADER_ANNOUNCEMENT_ID } from './Constants';
// import { useState } from 'react';

const HEADER_SCROLL_BEHAVIOUR_STICKY = 'sticky';
const ARROW_CLASS = 'tw-flex tw-items-center tw-justify-center tw-h-[28px] tw-w-[28px]';

export const NewAnnouncementBar = (props) => {
  // const [activeSlide, setActiveSlide] = useState(0);
  const { cardFrame, listPresentation } = props?.widgetOptions || {};

  const fromHeader = props?.fromHeader || false;
  const isPositionTop = props?.isPositionTop || false;
  const announcementWidgetId = props?.id;
  const announcementRef = useRef(null);

  const {
    widgetContextActions: { setIsHeaderFooterPropertyActive, setActiveWidget },
    widgetContextHelpers: { toggleSidePanelState },
    widgetContextState: { isBuilder },
  } = useWidgetDndContextData();

  const { isMobile } = useDeviceType();

  const { isTransparent, scrollType } = useNavConfigs(props?.isScroll, false);
  const isStickyHeader = scrollType === HEADER_SCROLL_BEHAVIOUR_STICKY;

  const hideHeaderAnnouncement =
    fromHeader &&
    isStickyHeader &&
    (isTransparent ? props?.isScroll : props?.canHideHeaderAnnouncement);

  const { cardFrameConfigAdjusted, listPresentationConfigAdjusted, activeSlideIndex } =
    useActiveAnnouncementAdjustment({
      isAnnouncementBarHeader: fromHeader,
      cardFrame,
      listPresentation,
      announcementWidgetId,
    });

  const { isHeaderAnnouncementActive } = useActiveHeaderSection();
  const { isAutoRotateEnabled, isAutoScrollEnabled, autoRotateTimer, autoScrollTimer } =
    useAutoScrollOrRotation({ listPresentationConfig: listPresentationConfigAdjusted });

  const [activeAnnouncement, setActiveAnnouncement] = useState(0);

  const activeWidgetBorderStyle: CSSProperties = {
    border: '2px solid blue',
    ...(isPositionTop
      ? { borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }
      : {}),
  };

  const { announcementStyles, containersStyles } = getAnnouncementBarStyles({
    isMobile,
    cardFrameConfig: cardFrameConfigAdjusted,
    listPresentationConfig: listPresentationConfigAdjusted,
  });

  const announcementList =
    cardFrameConfigAdjusted?.announcementList?.filter(
      (announcement) => announcement?.isVisible
    ) || [];

  const totalItems = announcementList?.length;

  const showArrow = listPresentationConfigAdjusted?.showArrow ?? true;
  const colGapForAutoScroll = isMobile
    ? listPresentationConfigAdjusted?.colGapMobile ?? 20
    : listPresentationConfigAdjusted?.colGap ?? 24;

  const showAutoRotation = isAutoRotateEnabled && !!autoRotateTimer;
  const showAutoScroll = isAutoScrollEnabled && !!autoScrollTimer;

  const animationType = cardFrameConfigAdjusted?.textAnimationType;

  const showAnimation = animationType !== ANIMATION_TYPE.NONE && isAutoRotateEnabled;

  // it's a seperator component which will be be rendered in between texts
  const SeperatorComponent = getSeperatorByType(
    listPresentationConfigAdjusted?.seperator
  );

  const SETTINGS = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: showAnimation ? 0 : 1000,
    autoplay: showAutoRotation,
    autoplaySpeed: autoRotateTimer * 1000,
    pauseOnHover: true,
    afterChange: (current) => setActiveAnnouncement(current),
  };

  function handleAnnouncementBarClick(e) {
    if (!isBuilder || !fromHeader) return;

    e.stopPropagation();
    setLastActiveWidget(NEW_HEADER_ID);
    setIsHeaderFooterPropertyActive({
      status: true,
      type: 'Header',
      subWidgetType: 'announcementList',
      subWidgetIndex: null,
      initiator: HEADER_INITIATOR_TYPE.HEADER,
    });
    setActiveWidget(null);
    toggleSidePanelState('widgetProperty');
  }

  function renderAnnouncements() {
    return announcementList?.map((announcementData, index) => (
      <>
        <AnnouncementStrip
          isAutoScroll={isAutoScrollEnabled}
          announcementData={announcementData}
          announcementStyle={announcementStyles}
          isActive={activeAnnouncement === index}
          animationType={animationType}
          showAnimation={showAnimation}
          autoRotateTimer={autoRotateTimer}
          isBuilder={isBuilder}
        />
        {showAutoScroll && (
          <span className="tw-flex">
            <SeperatorComponent
              className="tw-my-auto tw-text-[24px]"
              color={announcementStyles.color}
            />
          </span>
        )}
      </>
    ));
  }

  if (!totalItems) {
    return null;
  }

  return (
    <div
      ref={announcementRef}
      style={{
        ...containersStyles,
        ...(fromHeader && isHeaderAnnouncementActive ? activeWidgetBorderStyle : {}),
        ...(announcementRef.current
          ? (announcementRef?.current || ({} as any))?.clientHeight
          : {}),
      }}
      onClick={handleAnnouncementBarClick}
      className={classNames(
        'tw-overflow-hidden tw-transition-all tw-duration-300 tw-ease-in-out',
        hideHeaderAnnouncement ? 'tw-h-0 !tw-p-0' : ''
      )}
      {...(fromHeader ? { id: HEADER_ANNOUNCEMENT_ID } : {})}
    >
      {!isAutoScrollEnabled ? (
        <BasicSlider
          arrowColor={announcementStyles.color}
          showArrows={
            !showAutoScroll && totalItems > 1 && showArrow && activeSlideIndex === -1
          }
          removeArrowDefault={true}
          arrowCommonClasses="tw-absolute tw-top-1/2 -tw-translate-y-1/2 tw-z-10 tw-cursor-pointer"
          arrowLeftClasses={classNames('tw-left-0 md:tw-left-[8px]', ARROW_CLASS)}
          arrowRightClasses={classNames('tw-right-0 md:tw-right-[8px]', ARROW_CLASS)}
          showDots={false}
          customSettings={SETTINGS}
          activeIndex={activeSlideIndex}
          customStyles={`
          .slick-track {
            display: flex !important; 
            align-items: center !important; 
          }
          `}
        >
          {renderAnnouncements()}
        </BasicSlider>
      ) : (
        <InfiniteAutoScroller
          gap={colGapForAutoScroll}
          scrollSpeedInSec={autoScrollTimer}
          totalItems={announcementList?.length}
          wrapperClasses="!tw-flex-none"
        >
          {renderAnnouncements()}
        </InfiniteAutoScroller>
      )}
    </div>
  );
};

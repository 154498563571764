import { getAppendedFilteredItems } from '@/utils/getAppendedFilteredItems';
import { COLLECTION_CATALOG_ITEMS, COLLECTION_STATUS } from '@/utils/constants';
import LocalStorageHelper from '@/utils/LocalStorageHelper';
import {
  SAVE_CATALOG_CATEGORIES,
  SAVE_CATALOGS_LIST_BASED_ON_CATEGORIES,
  SAVE_PDP_DATA,
  SAVE_CATALOG_ITEM_BY_ID,
  SAVE_SIMILAR_CATALOG_ITEMS,
  SAVE_SEARCHED_PRODUCT_ITEMS,
  SAVE_CATALOG_ITEMS_BASICS_BY_STOREID,
  SAVE_CATALOG_TAGS,
  SET_CATALOG_PRODUCT_FILTER,
  SAVE_COLLECTIONS,
  SAVE_COLLECTION_CATALOG_ITEMS,
  RESET_CATALOGS_LIST_BASED_ON_CATEGORIES,
  SAVE_REVIEW_SETTINGS,
  SAVE_FILTERED_OPTIONS,
  SAVE_FILTERED_ITEMS,
  RESET_FILTERED_ITEMS_OPTIONS,
  FILTERS_OPTION_APPLY_COUNTER,
  SET_FILTER_OPTION,
  RESET_FILTER_ITEMS,
  SET_MB5_SELECTED_CATEGORY_PAGINATION_CONFIG,
  SET_MB5_ALL_CATEGORY_PAGINATION_CONFIG,
  SET_MB5_SORT_AND_FILTER_CONFIG,
  SET_MB6_PAGINATION_CONFIG,
  RESET_MB5_ALL_SELECTED_CATEGORIES_PAGINATION_CONFIG,
  RESET_CATALOG_ITEMS,
  OPEN_PRODUCT_PERSONALIZATION_POPUP,
  CLOSE_PRODUCT_PERSONALIZATION_POPUP,
  ADD_PRODUCT_PERSONALIZATION_DATA,
  EDIT_PRODUCT_PERSONALIZATIO_DATA,
  EDIT_CART_PRODUCT_ITEM,
  SET_PRODUCT_DATA_PERSONALISED,
  SET_PERSONALIZED_PRODUCT_CUSTOMIZATION_COUNT,
} from '../actionTypes';
import { cloneDeep } from 'lodash';

export const CATALOG_REDUCER_INIT_STATE = {
  categories: [],
  productTags: [],
  searchedProducts: {
    items: [],
    isNext: 1,
    page: 1,
  },
  catalog_items: {},
  isNextPage: false, // related to catalog_items, for paginated calls (undefined in case of unpaginated calls)
  pdpData: {
    visible: false,
  },
  sharePDP: {
    item: null,
    suggestions: [],
    similar_suggestions: true,
  },
  productFilters: {
    is_hide_out_of_stock: false,
    sort_option: {},
  },
  collections: [],
  collectionCatalogItems: {},
  reviewSettings: {},
  filteredItems: [],
  filteredOptions: [],
  filterAppliedCounter: 0,
  sortType: 0,
  mb5PaginationData: {
    allCategoryComponent: {
      currentCategoryIndex: 0,
      pageNo: 1,
      isNextPage: false,
      showLoaderComponent: false,
      shouldLoad: false,
    },
    selectedCategoryComponent: {
      // structure -> [catID]: { pageNo: number, isNext: false },
    },
    sortAndFilterConfig: {
      pageNo: undefined,
      isNext: undefined,
    },
  },
  mb6PaginationData: {},
  personalizedProduct: {
    productData: null,
    productCustomizationCount: null,
    personalizationPopUp: false,
    showInitialInputScreen: false,
    editPersonalizationPopUp: false,
    personalizationData: {},
    editableData: {},
    source: '',
    actionType: '',
  },
};

const CatalogReducer = (state = CATALOG_REDUCER_INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_CATALOG_CATEGORIES:
      return { ...state, categories: action.data };
    case SAVE_CATALOG_TAGS:
      return { ...state, productTags: action.data };
    case SAVE_CATALOG_ITEMS_BASICS_BY_STOREID: {
      let tempCatalogItems = cloneDeep(state.catalog_items);
      tempCatalogItems = { ...tempCatalogItems, ...action.data };

      return { ...state, catalog_items: tempCatalogItems || {} };
    }
    case SAVE_CATALOGS_LIST_BASED_ON_CATEGORIES: {
      const updatedCatalogItems = getCatalogItemsList(state.catalog_items, action.data);
      return {
        ...state,
        catalog_items: updatedCatalogItems,
        isNextPage: action.data.isNextPage,
      };
    }
    case RESET_CATALOGS_LIST_BASED_ON_CATEGORIES: {
      let newCatalogItems = cloneDeep(state.catalog_items);
      if (action.categoryId) delete newCatalogItems[action.categoryId];
      else newCatalogItems = {};
      return {
        ...state,
        catalog_items: newCatalogItems,
        isNextPage: false,
      };
    }
    case SAVE_PDP_DATA:
      return { ...state, pdpData: action.data };
    case SAVE_CATALOG_ITEM_BY_ID:
      return {
        ...state,
        pdpData: {
          ...state.pdpData,
          product: action.data || state.pdpData.product,
        },
      };
    case SAVE_SIMILAR_CATALOG_ITEMS:
      return {
        ...state,
        sharePDP: {
          ...state.sharePDP,
          suggestions: action.data.suggestions,
          similar_suggestions: action.data.similar,
        },
      };
    case SAVE_SEARCHED_PRODUCT_ITEMS:
      const stateItems = state.searchedProducts.items;
      const newData = action.data.items;
      return {
        ...state,
        searchedProducts: {
          items: newData && newData.length ? [...stateItems, ...newData] : [],
          isNext: action.data.isNext,
          page: action.data.page || 1,
        },
      };
    case SET_CATALOG_PRODUCT_FILTER:
      return {
        ...state,
        productFilters: Object.keys(action.data).length
          ? action.data
          : state.productFilters,
      };
    case SAVE_COLLECTIONS: {
      /*
        filter out all collections that are not active and do not have
        any number of categories associated with that collection
      */
      const collectionSet =
        action.data?.reduce((acc, currentCollection) => {
          if (
            currentCollection?.categories?.length &&
            currentCollection.status === COLLECTION_STATUS.ACTIVE
          )
            acc.push(currentCollection);
          return acc;
        }, []) || [];

      return {
        ...state,
        collections: collectionSet,
      };
    }
    case SAVE_COLLECTION_CATALOG_ITEMS: {
      const tempCollectionCatalogItems = cloneDeep(state.collectionCatalogItems) || {};
      tempCollectionCatalogItems[action.data.collection_id] = {
        ...tempCollectionCatalogItems[action.data.collection_id],
        [action.data.category_id]: action.data.items,
      };
      LocalStorageHelper.add(
        COLLECTION_CATALOG_ITEMS,
        JSON.stringify(tempCollectionCatalogItems)
      );
      return {
        ...state,
        collectionCatalogItems: tempCollectionCatalogItems || {},
      };
    }
    case SAVE_REVIEW_SETTINGS:
      return {
        ...state,
        reviewSettings: action.data,
      };
    case SAVE_FILTERED_ITEMS:
      return {
        ...state,
        filteredItems: getAppendedFilteredItems(action.data, action.lastStoredItems),
      };
    case SAVE_FILTERED_OPTIONS:
      return {
        ...state,
        filteredOptions: action.data,
      };
    case RESET_FILTERED_ITEMS_OPTIONS:
      return {
        ...state,
        filteredItems: [],
        filteredOptions: [],
      };
    case FILTERS_OPTION_APPLY_COUNTER:
      return {
        ...state,
        filterAppliedCounter: action?.setFilterCount
          ? action.data
          : state?.filterAppliedCounter + action.data,
      };
    case SET_FILTER_OPTION:
      return {
        ...state,
        sortType: action.data,
      };
    case RESET_FILTER_ITEMS:
      return {
        ...state,
        filteredItems: [],
      };
    case SET_MB5_ALL_CATEGORY_PAGINATION_CONFIG:
      return {
        ...state,
        mb5PaginationData: {
          ...state.mb5PaginationData,
          allCategoryComponent: action.data,
        },
      };
    case SET_MB5_SELECTED_CATEGORY_PAGINATION_CONFIG:
      return {
        ...state,
        mb5PaginationData: {
          ...state.mb5PaginationData,
          selectedCategoryComponent: {
            ...state.mb5PaginationData.selectedCategoryComponent,
            ...action.data,
          },
        },
      };
    case RESET_MB5_ALL_SELECTED_CATEGORIES_PAGINATION_CONFIG:
      return {
        ...state,
        mb5PaginationData: CATALOG_REDUCER_INIT_STATE.mb5PaginationData,
      };
    case SET_MB5_SORT_AND_FILTER_CONFIG:
      return {
        ...state,
        mb5PaginationData: {
          ...state.mb5PaginationData,
          sortAndFilterConfig: {
            ...state.mb5PaginationData.sortAndFilterConfig,
            ...action.data,
          },
        },
      };
    case SET_MB6_PAGINATION_CONFIG:
      return {
        ...state,
        mb6PaginationData: {
          ...state.mb6PaginationData,
          ...action.data,
        },
      };
    case RESET_CATALOG_ITEMS:
      return {
        ...state,
        catalog_items: {},
        isNextPage: true,
      };
    case OPEN_PRODUCT_PERSONALIZATION_POPUP:
      return {
        ...state,
        personalizedProduct: {
          ...state.personalizedProduct,
          personalizationPopUp: true,
          showInitialInputScreen: true,
          personalizationData: {},
        },
      };
    case CLOSE_PRODUCT_PERSONALIZATION_POPUP:
      return {
        ...state,
        personalizedProduct: {
          ...state.personalizedProduct,
          personalizationPopUp: false,
          showInitialInputScreen: false,
          editPersonalizationPopUp: false,
          productCustomizationCount: null,
          personalizationData: {},
          actionType: '',
          editableData: {},
        },
      };
    case ADD_PRODUCT_PERSONALIZATION_DATA:
      return {
        ...state,
        personalizedProduct: {
          ...state.personalizedProduct,
          personalizationPopUp: action?.data?.showPopUp ?? true,
          showInitialInputScreen: false,
          personalizationData: action?.data?.personalizationData,
          source: action?.data?.source,
          actionType: action?.data?.actionType,
        },
      };
    case EDIT_PRODUCT_PERSONALIZATIO_DATA:
      return {
        ...state,
        personalizedProduct: {
          ...state.personalizedProduct,
          personalizationPopUp: true,
          showInitialInputScreen: true,
          editPersonalizationPopUp: true,
          personalizationData: action.data.personalizedProductData,
        },
      };
    case EDIT_CART_PRODUCT_ITEM:
      return {
        ...state,
        personalizedProduct: {
          ...state.personalizedProduct,
          personalizationData: action.data.personalizedProductData,
          personalizationPopUp: true,
          showInitialInputScreen: true,
          editPersonalizationPopUp: true,
          editableData: action.data.editableData,
          source: action.data.source,
        },
      };
    case SET_PRODUCT_DATA_PERSONALISED:
      return {
        ...state,
        personalizedProduct: {
          ...state.personalizedProduct,
          productData: action.data,
        },
      };
    case SET_PERSONALIZED_PRODUCT_CUSTOMIZATION_COUNT:
      return {
        ...state,
        personalizedProduct: {
          ...state.personalizedProduct,
          productCustomizationCount: action.data,
        },
      };
    default:
      return state;
  }
};

function getCatalogItemsList(catalogItems, actionData) {
  const updatedCatalogItems = cloneDeep(catalogItems);
  /**  If the request was paginated, then the data is concatenated for similar api calls,
   * else it works as same as for old ones (other MiddleBodies).
   * (As the paginated calls might cause duplicacy of data in other API calls for this kind of calls
   * as while hopping between categories you are not clearing previous data)
   */
  if (actionData.isPaginated) {
    const currentItemList = updatedCatalogItems[actionData.category_id] ?? [];
    updatedCatalogItems[actionData.category_id] = [
      ...currentItemList,
      ...actionData.items,
    ];
  } else updatedCatalogItems[actionData.category_id] = actionData.items;
  return updatedCatalogItems;
}

export default CatalogReducer;

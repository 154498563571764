import axios from 'axios';
import { WidgetsProjectData } from './widgetDeveloperTypes';
import { getFileTypeFromName } from '../widgetMakerUtils';
import { sprintf } from 'sprintf-js';
export const babelTransform = async (code: string = '') => {
  try {
    const Babel = await import('@babel/standalone');
    return Babel.transform(code, {
      presets: ['env', 'react', 'typescript'],
      filename: 'index.tsx',
    }).code as string;
  } catch (err) {
    console.error(err);
  }
};

const WIDGET_BASE_API = 'https://api.digitaldukaan.app/api/dotk/vo1/settings/widgets';

const WIDGET_API = {
  WIDGET_LIST: `${WIDGET_BASE_API}`,
  WIDGET_FILES: `${WIDGET_BASE_API}/%(projectName)s`,
  SAVE_WIDGET: `${WIDGET_BASE_API}`,
  UPLOAD_WIDGET: `${WIDGET_BASE_API}/uploadImage`,
};

export const getWidgetList = () => {
  return axios.get(WIDGET_API.WIDGET_LIST).then<WidgetsProjectData[]>((resp) => {
    const allWidgets = resp.data.widget_data;
    return allWidgets;
  });
};

export const fetchWidgetFiles = (projectName: string) => {
  return axios
    .get(sprintf(WIDGET_API.WIDGET_FILES, { projectName }))
    .then<WidgetsProjectData>((resp) => {
      const projectData = resp.data.widget_folder_data as WidgetsProjectData;
      const files = projectData.files;
      const filteredFiles = files.filter(
        (file) => file.value.indexOf('/* BABEL TRANSFORMED */') !== 0
      );
      projectData.files = filteredFiles.map((file) => {
        file.name = `${file.name}.${file.language}`;
        file.language = getFileTypeFromName(file.name);
        return file;
      });

      projectData.files.push({
        name: 'manifest.json',
        defaultName: 'manifest.json',
        language: 'json',
        value: JSON.stringify(projectData.manifest, null, 2),
      });

      if (projectData.thumb) {
        projectData.files.push({
          name: 'thumb.png',
          defaultName: 'thumb.png',
          language: 'png',
          value: projectData.thumb,
        });
      }

      return projectData;
    });
};

export const saveWidgetProject = async (payload: WidgetsProjectData) => {
  const tsFile = payload.files.find((file) => file.language === 'typescript');
  if (tsFile) {
    let tsFileTransformed = await babelTransform(tsFile.value);
    tsFileTransformed = `/* BABEL TRANSFORMED */` + tsFileTransformed;
    payload.files = payload.files.filter(
      (file) => !(file.name === 'index.js' || file.value === '')
    );
    payload.files.push({
      name: 'index.js',
      defaultName: 'index.js',
      language: 'javascript',
      value: tsFileTransformed,
    });
  }
  return axios.post(WIDGET_API.SAVE_WIDGET, payload);
};

export const uploadThumb = (project: string, file: FileList) => {
  const formData = new FormData();
  formData.append('project', project);
  formData.append('media', file[0]);
  return axios
    .post(sprintf(WIDGET_API.UPLOAD_WIDGET, { projectName: project }), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((resp) => resp.data.data as string);
};

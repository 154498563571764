import LocalStorageHelper from './LocalStorageHelper';

/**
 * This function removes the cached data which has been expired now.
 * data gets expired after the interval of 10 mins.
 * @param {*} existingState - last stored state in localStorage
 */
function removeExpiredState(existingState) {
  const appOpenTime = LocalStorageHelper.get('appOpenTime');
  if (!appOpenTime) {
    LocalStorageHelper.add('appOpenTime', Date.now());
  } else {
    const currentTime = Date.now();
    // Time diff in minutes
    const timeDiff = (currentTime - appOpenTime) / (1000 * 60);

    if (timeDiff > 10) {
      // remove keys from local storage of reducer
      for (const key in existingState) {
        if (key) {
          if (['storePolicyReducer', 'promoReducer'].includes(key)) {
            delete existingState[key];
          }

          LocalStorageHelper.add('stateReducer', existingState);
        }
      }
      LocalStorageHelper.add('appOpenTime', Date.now());
    }
  }
}

export const loadState = () => {
  try {
    const serializedState = localStorage?.getItem('stateReducer');
    if (serializedState === null) {
      return undefined;
    }
    const existingState = JSON.parse(serializedState);
    removeExpiredState(existingState);
    return existingState;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    // const serializedState = JSON.stringify({ [window.storeinfo.store_info.domain] :  state });
    const serializedState = JSON.stringify(state);

    localStorage?.setItem('stateReducer', serializedState);
  } catch {
    // ignore write errors
  }
};

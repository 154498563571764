export function WidgetOutlineHeroBannerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clip-path="url(#clip0_1166_2811)">
        <path
          d="M15.75 3H2.25C1.425 3 0.75 3.675 0.75 4.5V13.5C0.75 14.325 1.425 15 2.25 15H15.75C16.575 15 17.25 14.325 17.25 13.5V4.5C17.25 3.675 16.575 3 15.75 3ZM15.75 13.5H2.25V4.5H15.75V13.5ZM10.875 8.25L8.25 11.6325L6.375 9.375L3.75 12.75H14.25L10.875 8.25Z"
          fill="#222222"
        />
      </g>
      <defs>
        <clipPath id="clip0_1166_2811">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

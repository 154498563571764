import { useSSRSelector } from '@/redux/ssrStore';
import { useReducer } from 'react';
import {
  WIDGET_DND_INITIAL_STATE,
  WidgetDndContextReducer,
} from './WidgetDndContextReducer';
import { useRouter } from 'next/router';
import { useWidgetDndContextActions } from './WidgetDndContextReducer/actions';
import { useWidgetContextHelpers } from './WidgetDndContextReducer/helpers';
import { IUseWidgetDndData } from './types';
import { IS_SERVER } from 'src/utils/checkRenderEnv';

let ssrState = WIDGET_DND_INITIAL_STATE;

export function useWidgetDndData(): IUseWidgetDndData {
  const { query } = useRouter();

  const [widgetContextState, widgetContextDispatch] = useReducer(
    WidgetDndContextReducer,
    WIDGET_DND_INITIAL_STATE
  );

  function setWidgetSSRState(key, value) {
    if (typeof key === 'function') {
      ssrState = key(ssrState);
    } else {
      ssrState[key] = value;
    }
    return ssrState;
  }

  const { widgetsCatalogLoaded, storeData } = useSSRSelector((state) => ({
    widgetsCatalogLoaded: !!state.jsonWidgetsReducer.widgetTemplateLibraryList?.length,
    storeData: state.storeReducer.store,
  }));

  const isInternalAdminAccess =
    typeof storeData?.internal_admin_access === 'number' &&
    storeData?.internal_admin_access === 1;

  const widgetContextActions = useWidgetDndContextActions({
    widgetContextState,
    widgetContextDispatch,
    setWidgetSSRState,
  });

  const widgetContextHelpers = useWidgetContextHelpers({
    widgetContextState,
    widgetContextActions,
  });

  const getWidgetSSRState = () => (IS_SERVER ? ssrState : widgetContextState);

  return {
    authToken: query?.token || '',

    builderAccess: {
      // Merchant level admin
      hasAdminAccess: !!query.loginViaAdminCreds || isInternalAdminAccess,
      // Company level admin to directly access merchant's store
      isInternalAdminAccess,
      // isInternalAdminAccess: true,
      isInternalAdminAccessExpired: false,
    },

    /** Context State */
    widgetContextState,
    getWidgetSSRState,
    widgetsCatalogLoaded,

    /** Context State Updaters */
    widgetContextActions,

    /** Context Helpers */
    widgetContextHelpers,
  };
}

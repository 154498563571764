import { useEffect, useState } from 'react';
import { HEADER_ANNOUNCEMENT_ID } from '../Constants';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { getMaxAnnouncementHeight } from '../Utils';
import { useDeviceType } from 'src/components/WidgetMaker/WidgetDnD/hooks/useDeviceType';

export const useHeaderAnnouncementHeight = () => {
  const {
    widgetContextState: { activeHeaderData },
  } = useWidgetDndContextData();
  const { isMobile } = useDeviceType();
  const { maxAnnouncementHeight } = getMaxAnnouncementHeight({
    activeHeaderData,
    isMobile,
  });

  const [headerAnnouncementHeight, setHeaderAnnouncementHeight] =
    useState(maxAnnouncementHeight);

  useEffect(() => {
    setTimeout(() => {
      const headerAnnouncement = document.getElementById(HEADER_ANNOUNCEMENT_ID);
      if (headerAnnouncement) {
        setHeaderAnnouncementHeight(
          (headerAnnouncement.clientHeight || maxAnnouncementHeight) as number
        );
      }
    }, 1500);
  }, []);

  return { headerAnnouncementHeight };
};

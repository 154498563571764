import { SAVE_ALL_EMI_OPTIONS, SAVE_EMI_OPTION_FOR_CARD } from '../actionTypes';

const initialState = {
  emiOptionsForCard: {},
  allEmiOptions: {},
};

const EMIReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_EMI_OPTION_FOR_CARD:
      return {
        ...state,
        emiOptionsForCard: action.data,
      };
    case SAVE_ALL_EMI_OPTIONS:
      return {
        ...state,
        allEmiOptions: action.data,
      };
    default:
      return state;
  }
};

export default EMIReducer;

export const Tab3RightArrow = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="currentColor"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.9595 3.95989C11.35 3.56936 11.9832 3.56936 12.3737 3.95989L18.3737 9.95989C18.7643 10.3504 18.7643 10.9836 18.3737 11.3741L12.3737 17.3741C11.9832 17.7646 11.35 17.7646 10.9595 17.3741C10.569 16.9836 10.569 16.3504 10.9595 15.9599L15.2524 11.667L3.66663 11.667C3.11434 11.667 2.66663 11.2193 2.66663 10.667C2.66663 10.1147 3.11434 9.66699 3.66663 9.66699L15.2524 9.66699L10.9595 5.3741C10.569 4.98357 10.569 4.35041 10.9595 3.95989Z"
      fill={props?.color}
    />
  </svg>
);

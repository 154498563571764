import { ImageContainer } from './ImageMedia.styled';
const ImageMedia = (props) => {
  return (
    props?.mediaContent?.content && (
      <ImageContainer
        src={props?.mediaContent?.content[0]?.url}
        onError={(e) => (e.target.src = props?.mediaContent?.content[0]?.url)}
      />
    )
  );
};

export default ImageMedia;

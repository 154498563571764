import classNames from 'classnames';
import { btnActionHandler } from 'src/components/DesignSystem/ModernTheme/utils/btnUtils';
import {
  ANIMATION_TYPE,
  ANIMATION_TYPE_MAP,
} from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/NewAnnouncementBar/Constants';
import contentAnimationStyle from '@/components/DesignSystem/AtomicDesignsUtilities/ContentAnimationStyles.module.scss';

export const AnnouncementStrip = (props) => {
  const {
    announcementData,
    announcementStyle,
    isAutoScroll,
    animationType,
    isActive,
    showAnimation,
    isBuilder,
  } = props;

  const animationClassByConfig =
    contentAnimationStyle[ANIMATION_TYPE_MAP[animationType]?.animeClassName] || '';
  const isFadeInAnimation = animationType === ANIMATION_TYPE.FADE_IN;

  const { announcementText, redirectUrl, redirectionType, isOpenInNewTab } =
    announcementData;

  function handleAnnoumcementClick() {
    if (redirectUrl && !isBuilder) {
      const actionObj = {
        type: redirectionType || '',
        handler: redirectUrl || '',
        newTab: isOpenInNewTab || '',
      };
      btnActionHandler(actionObj);
    }
  }

  return (
    <div
      className={classNames(
        'tw-my-auto tw-flex tw-h-full tw-flex-nowrap tw-items-center tw-justify-center',
        !isAutoScroll ? 'tw-w-full' : '',
        redirectUrl ? 'tw-cursor-pointer' : '',
        showAnimation ? (isActive ? animationClassByConfig : 'tw-invisible') : ''
      )}
      onClick={handleAnnoumcementClick}
      style={{
        ...announcementStyle,
        ...(isFadeInAnimation ? { animationTimingFunction: 'ease-in-out' } : {}),
      }}
    >
      <AnnouncementText announcementText={announcementText} isAutoScroll={isAutoScroll} />
    </div>
  );
};

export function AnnouncementText({ announcementText, isAutoScroll }) {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: announcementText,
      }}
      className={classNames(
        'tw-line-clamp-3 tw-max-w-[90vw] !tw-space-y-0 tw-whitespace-nowrap tw-text-wrap tw-text-center [&>p]:tw-m-0',
        !isAutoScroll ? 'tw-px-[24px]' : ''
      )}
    />
  );
}

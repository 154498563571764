import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { HEADER_INITIATOR_TYPE } from '../constants';

export const useActiveHeaderSection = () => {
  const {
    widgetContextState: { isHeaderFooterPropertyActive },
  } = useWidgetDndContextData();

  const { subWidgetType, subWidgetIndex, initiator, type, status } =
    isHeaderFooterPropertyActive || {};

  const initiatedFromHeaderOutline = initiator === HEADER_INITIATOR_TYPE.HEADER;
  const initiatedFromHeaderAnnouncementOutline =
    initiator === HEADER_INITIATOR_TYPE.ANNOUNCEMENT;

  const isHeaderActive = status && type === 'Header';

  const showAnnouncementSections =
    isHeaderActive &&
    (initiatedFromHeaderOutline || initiatedFromHeaderAnnouncementOutline) &&
    subWidgetType === 'announcementList';

  const showAnnouncementItem =
    isHeaderActive &&
    showAnnouncementSections &&
    (subWidgetIndex || subWidgetIndex === 0);

  const isHeaderAnnouncementActive = showAnnouncementItem || showAnnouncementSections;

  return {
    showAnnouncementItem,
    showAnnouncementSections,
    initiatedFromHeaderOutline,
    initiatedFromHeaderAnnouncementOutline,
    isHeaderAnnouncementActive,
    isHeaderActive,
    activeSubWidgetHeaderIndex: subWidgetIndex,
  };
};

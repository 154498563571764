import { takeEvery } from 'redux-saga/effects';
import apiCall from '../../services/api';
import { ENDPOINT } from './../../config/endpoints';
import { PUSH_EVENTS_LOG } from '../actionTypes';

function* pushEventLogs(actions) {
  try {
    yield apiCall({
      method: 'POST',
      url: ENDPOINT.LOGS.PUSH_EVENT_LOG,
      data: actions.data,
    });
  } catch (err) {}
}

export default function* root() {
  yield takeEvery(PUSH_EVENTS_LOG, pushEventLogs);
}

export function InfoIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 23.374}
      height={props.size || 23.374}
      viewBox="0 0 23.374 23.374"
      {...props}
    >
      <path d="M0 0h23.374v23.374H0z" fill="none" />
      <path
        d="M11.687 2.632a9.054 9.054 0 109.054 9.054 9.064 9.064 0 00-9.054-9.054zm0 16.462a7.408 7.408 0 117.408-7.408 7.416 7.416 0 01-7.408 7.408z"
        fill={props.secondaryColor}
      />
      <path
        d="M11.688 6.473a1.1 1.1 0 101.1 1.1 1.1 1.1 0 00-1.1-1.1zM11.687 10.314a.823.823 0 00-.823.823v4.938a.823.823 0 101.646 0v-4.938a.823.823 0 00-.823-.823z"
        fill={props.secondaryColor}
      />
    </svg>
  );
}

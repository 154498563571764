export const CancelIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 62 62"
    fill="none"
  >
    <path
      d="M15.5 46.5L46.5 15.5M15.5 15.5L46.5 46.5"
      stroke="white"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></path>
  </svg>
);

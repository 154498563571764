import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { NewHeaderComponent } from './NewHeaderComponent';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { checkIfHomePage } from 'src/utils/checkIfHomePage';
import { IS_SERVER } from 'src/utils/checkRenderEnv';
import { useHeaderAnnouncementHeight } from '../NewAnnouncementBar/Hooks/useHeaderAnnoucementHeight';

const header_common_class = {
  top: 0,
  zIndex: 500,
  width: '100%',
  opacity: 1,
  transition: 'top .3s ease-in-out',
};

const NewHeaderWrapper = (props: any) => {
  const isHomePage = checkIfHomePage();
  const [isScroll, setIsScroll] = useState(false);
  const [isScrollUp, setIsScrollUp] = useState(false);

  const [canHideHeaderAnnouncement, setCanHideHeaderAnnouncement] = useState(false);
  const { headerAnnouncementHeight } = useHeaderAnnouncementHeight();

  const headerRef = useRef();
  const lastScrollVal = useRef(0);
  const {
    getWidgetSSRState,
    widgetContextActions: { setActiveHeaderData },
  } = useWidgetDndContextData();
  const { activeHeaderData } = getWidgetSSRState();

  if (props?.config) {
    if (IS_SERVER) activeHeaderData.headerPropsConfig = props.config;
    // else {
    //   if (isInitialRender.current) {
    //     setActiveHeaderData((prevData) => ({
    //       ...prevData,
    //       headerPropsConfig: props.config,
    //     }));
    //     isInitialRender.current = false;
    //   }
    // }
  }

  useLayoutEffect(() => {
    if (props?.config) {
      setActiveHeaderData((prevData) => ({
        ...prevData,
        headerPropsConfig: props.config,
      }));
    }
  }, [props?.config]);

  const scrollType =
    activeHeaderData?.headerPropsConfig?.globalClass?.scrollClass?.base_config
      ?.defaultScrollType || '';
  const customProps = {
    ...props,
    isScroll: scrollType === 'hide' ? false : isScroll && isHomePage,
    isScrollUp,
  };

  useEffect(() => {
    if (!isScroll && isScrollUp) {
      setIsScrollUp(false);
    }
  }, [isScroll]);

  function handleScroll(e) {
    const val = window.scrollY;
    setCanHideHeaderAnnouncement(
      val > (!canHideHeaderAnnouncement ? headerAnnouncementHeight : 0)
    );
    if (val > 20) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
    if (lastScrollVal.current - val > 180) {
      setIsScrollUp(true);
      lastScrollVal.current = val;
    } else if (val - lastScrollVal.current > 180) {
      setIsScrollUp(false);
      lastScrollVal.current = val;
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [headerAnnouncementHeight, canHideHeaderAnnouncement]);

  if (!Object.keys(activeHeaderData?.headerPropsConfig)?.length) {
    return null;
  }

  const getStyleForTransparent = () => {
    if (!isHomePage) {
      return {};
    }
    const type =
      activeHeaderData?.headerPropsConfig?.globalClass?.staticClass?.base_config
        ?.defaultFillColorType;
    if (type === 'transparent') {
      return {
        position: 'fixed',
        ...header_common_class,
      };
    }
    return {
      position: 'sticky',
      ...header_common_class,
    };
  };

  const getScrollStyling = () => {
    if (!isHomePage) {
      return {};
    }
    const type =
      activeHeaderData?.headerPropsConfig?.globalClass?.staticClass?.base_config
        ?.defaultFillColorType;
    const commonClass = {
      position: type === 'transparent' ? 'fixed' : 'sticky',
      ...header_common_class,
    };
    const hideClass = {
      position: type === 'transparent' ? 'fixed' : 'sticky',
      top: -(headerRef?.current || ({} as any))?.clientHeight,
      zIndex: 5000,
      width: '100%',
      transition: 'top .3s ease-in-out',
    };
    const scrollUpClass = isScrollUp ? commonClass : hideClass;
    switch (scrollType) {
      case 'hide':
        return {
          ...hideClass,
        };
      case 'sticky':
        return {
          ...commonClass,
        };
      case 'scrollUp': {
        return {
          ...scrollUpClass,
        };
      }
      default:
        return {};
    }
  };

  return (
    <div
      ref={headerRef}
      style={{
        ...(isScroll ? getScrollStyling() : getStyleForTransparent()),
        // transform: isScroll ? `translateY(-54px)` : `translateY(0px)`,
      }}
    >
      <NewHeaderComponent
        {...customProps}
        isHomePage={isHomePage}
        activeHeaderData={activeHeaderData}
        canHideHeaderAnnouncement={canHideHeaderAnnouncement}
      />
    </div>
  );
};

export default NewHeaderWrapper;

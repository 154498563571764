export function WidgetOutlineTestimonialsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clip-path="url(#clip0_1166_2323)">
        <path
          d="M6.75 10.5C7.9875 10.5 9 9.4875 9 8.25C9 7.0125 7.9875 6 6.75 6C5.5125 6 4.5 7.0125 4.5 8.25C4.5 9.4875 5.5125 10.5 6.75 10.5ZM6.75 7.5C7.155 7.5 7.5 7.845 7.5 8.25C7.5 8.655 7.155 9 6.75 9C6.345 9 6 8.655 6 8.25C6 7.845 6.345 7.5 6.75 7.5Z"
          fill="#222222"
        />
        <path
          d="M16.5 2.25H1.5C0.675 2.25 0 2.925 0 3.75V14.25C0 15.075 0.675 15.75 1.5 15.75H16.5C17.325 15.75 17.9925 15.075 17.9925 14.25L18 3.75C18 2.925 17.325 2.25 16.5 2.25ZM3.405 14.25C4.23 13.335 5.4225 12.75 6.75 12.75C8.0775 12.75 9.27 13.335 10.095 14.25H3.405ZM16.5 14.25H11.94C10.905 12.4575 8.97 11.25 6.75 11.25C4.53 11.25 2.595 12.4575 1.56 14.25H1.5V3.75H16.5V14.25Z"
          fill="#222222"
        />
        <path
          d="M11.835 8.3625L12.9375 7.725V9H14.0625V7.725L15.165 8.3625L15.7275 7.3875L14.625 6.75L15.7275 6.1125L15.165 5.1375L14.0625 5.775V4.5H12.9375V5.775L11.835 5.1375L11.2725 6.1125L12.375 6.75L11.2725 7.3875L11.835 8.3625Z"
          fill="#222222"
        />
      </g>
      <defs>
        <clipPath id="clip0_1166_2323">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { checkForIpAddress } from './checkForIpAddress';

export const checkForWrongDomain = (ctx) => {
  if (
    ctx.query.domain === 'health' ||
    ctx.query.domain === '_next' ||
    checkForIpAddress(ctx?.req?.headers?.host)
  ) {
    return true;
  }
  return false;
};

import {
  SAVE_ADDITIONAL_PAGE,
  SAVE_ADDITIONAL_PAGE_LIST,
  SAVE_NAVIGATION_PAGES_LIST,
  UPDATE_ADDITIONAL_PAGE_REDUCER,
} from '../actionTypes';
export interface AdditionalPageInfo {
  can_change_visibility: boolean;
  is_editable: boolean;
  is_external_url: boolean;
  page_id: string;
  page_name: string;
  page_type: string;
  slug_url: string;
  status: number;
  structure_type: number;
  updated_at: string;
}

interface AdditionalPageListItem {
  date: {
    element: string;
    error_msg?: string;
    isMandatory: true;
    key: string;
    maxLength: number;
    selected_date: string;
    selected_month: string;
  };
  description: {
    display_text: string;
    element: string;
    error_msg?: string;
    isMandatory: true;
    key: string;
  };
  heading: {
    display_text: string;
    element: string;
    error_msg?: string;
    isMandatory: true;
    key: string;
    maxLength: number;
  };
  id: string;
  image: {
    display_text: string;
    element: string;
    key: string;
    url: string;
  };
  ordering: string[];
}
export interface AdditionalPageData extends AdditionalPageInfo {
  template_id: string;
  json_data: {
    [slug: string]: {
      section_title: {
        display_text: string;
        element: string;
        isMandatory: boolean;
        key: string;
      };
      users_list: {
        cardTemplateId: number;
        editable: boolean;
        element: string;
        key: string;
        list: AdditionalPageListItem[];
        sample_item: AdditionalPageListItem;
      };
    };
  };
}

export interface AdditionalPagesReducer {
  additionalPage: AdditionalPageData;
  navigationList: AdditionalPageInfo[];
  additionalPageList: Record<string, AdditionalPageData>;
}

const initialState: AdditionalPagesReducer = {
  additionalPage: null,
  navigationList: [],
  additionalPageList: {},
};

const additionalPages = (state: AdditionalPagesReducer = initialState, action) => {
  switch (action.type) {
    case SAVE_ADDITIONAL_PAGE:
      return {
        ...state,
        additionalPage: action.data as AdditionalPageData,
      };
    case SAVE_ADDITIONAL_PAGE_LIST:
      if (action.data.slug_url) {
        return {
          ...state,
          additionalPageList: {
            ...(state.additionalPageList || {}),
            [action.data.slug_url]: action.data,
          },
        };
      } else {
        return state;
      }
    case SAVE_NAVIGATION_PAGES_LIST:
      return {
        ...state,
        navigationList: (action.data as AdditionalPageInfo[]) || [],
      };
    case UPDATE_ADDITIONAL_PAGE_REDUCER:
      return {
        ...state,
        ...(action.data || {}),
      };
    default:
      return state;
  }
};

export default additionalPages;

// Website live status, whether the website was "Published" from builder/manually or not
export const NEW_STORE_WEBSITE_STATUS = {
  INACTIVE: 0, // Keep the old flow running, for new or old store
  ACTIVE_NOT_LIVE: 1,
  LIVE: 2,
};

export const NEW_SUBSCRIPTIONS_STATUS = {
  INACTIVE: 0, // Old subscriptions i.e. new subscription is not active
  ACTIVE: 1,
  EXPIRED: 2,
  DUE_FOR_RENEWAL: 3, // (Renewal details to be updated on Renewal date)
  TOKEN_AMOUNT_RECEIVED: 4,
};

export const STORE_PREMIUM_FLAGS = {
  NEW_SUBSCRPTION: 18,
};

import CustomizedButton from '../../../../WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Button';
import { useWidgetDndContextData } from '@/context/WidgetDndContext';
import { useContext, useEffect, useState } from 'react';
import { RenderNewSearch } from './RenderNewSearch';
import { NewHeaderContext } from '../newHeaderContext';
import { SearchIcon } from '@/assets/svgExports/SearchIcon';
import { getIconSizeAccToConfig, getSizeAccType } from './getSizeAccType';
import router from 'next/router';
import { getRoute } from '@/utils/routes';
import { useSSRSelector } from 'src/redux';
import { useGetDeviceType } from '@/hooks/useGetDeviceType';
import { allowEverything } from 'src/utils/regex';
import CustomTextField from 'src/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/TextfieldBox';

export const getIcon = (size, color) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 28 28`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.0023 13.0012C21.0023 14.5837 20.533 16.1307 19.6538 17.4465C18.7746 18.7623 17.5249 19.7878 16.0628 20.3934C14.6007 20.9989 12.9919 21.1573 11.4398 20.8485C9.88771 20.5397 8.46204 19.7775 7.3431 18.6584C6.22416 17.5394 5.46221 16.1136 5.15361 14.5614C4.84501 13.0093 5.00361 11.4005 5.60937 9.93849C6.21512 8.47649 7.24082 7.22696 8.55675 6.34791C9.87268 5.46886 11.4197 4.99978 13.0023 5C15.1243 5 17.1594 5.84298 18.6599 7.34349C20.1604 8.84399 21.0034 10.8791 21.0034 13.0012H21.0023Z"
        stroke={color}
        stroke-width="1.4"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.0042 23L18.6538 18.6497"
        stroke={color}
        stroke-width="1.4"
        strokeLinecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const crossIcon = (color) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M16.5002 4.86641L15.2052 3.57141L10.0716 8.70498L4.93807 3.57141L3.64307 4.86641L8.77664 9.99998L3.64307 15.1336L4.93807 16.4286L10.0716 11.295L15.2052 16.4286L16.5002 15.1336L11.3666 9.99998L16.5002 4.86641Z"
        fill={color}
      />
    </svg>
  );
};

export const DynamicComponent = (props: any) => {
  const { currentClass, globalClass } = props;
  const { base_config: { defaultHoverIconState } = {} } = currentClass || {};
  const { setShowSearch } = useContext(NewHeaderContext);
  const { deviceType } = useGetDeviceType();
  const isMobile = deviceType === 'mobile';
  const [searchText, setSearchText] = useState('');
  const { getWidgetSSRState } = useWidgetDndContextData();
  const { activeHeaderData } = getWidgetSSRState();
  const {
    dynamic_section: {
      componentData: {},
      base_config,
    },
    desktopHeader,
  } = activeHeaderData.headerPropsConfig;
  const { storeData } = useSSRSelector((state) => ({
    storeData: state.storeReducer.store,
  }));
  const { searchAlignment, defaultPosition, defaultType } = base_config;
  const isCenterAlignedHeader =
    desktopHeader === 'header4' && base_config?.defaultPosition === 'center';

  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    let intervalId;
    if (defaultType === 'field' && base_config?.placeholder.length > 1) {
      intervalId = setInterval(() => {
        setFade(false);
        setTimeout(() => {
          setPlaceholderIndex(
            (prevIndex) => (prevIndex + 1) % base_config?.placeholder.length
          );
          setFade(true);
        }, 500);
      }, 3000);
    }

    if (defaultType !== 'field') {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [base_config?.placeholder.length, defaultType]);

  function getStyleForText(changeColor = false) {
    return {
      color: changeColor ? currentClass?.base_config?.defaultNavLinksColor : '',
      fontSize: getSizeAccType(
        globalClass.staticClass.base_config.defaultTextFontSize,
        isMobile
          ? globalClass.staticClass.base_config.defaultFontSizeTypeMobile
          : globalClass.staticClass.base_config.defaultFontSizeType
      ),
      fontWeight: globalClass.staticClass.base_config.defaultTextFontWeight,
      textTransform: globalClass.staticClass.base_config.defaultTextDecoration,
    };
  }

  function renderSearchWithField() {
    const fullSearch =
      desktopHeader === 'header4' && base_config?.defaultPosition === 'center';
    const isTransparent =
      currentClass?.base_config?.defaultFillColorType === 'transparent';
    const isFilledWhite =
      currentClass?.base_config?.defaultBackgroundColor?.toLowerCase() === '#ffffff';

    return (
      <div
        className={`tw-relative ${fullSearch ? (isCenterAlignedHeader ? 'tw-flex-1' : 'tw-w-[500px]') : 'tw-w-[100%]'} `}
      >
        <div className={`tw-flex ${!fullSearch ? 'tw-w-[238px]' : ''}  tw-items-center`}>
          <CustomTextField
            parentClass="tw-w-[100%]"
            placeholder={
              base_config?.placeholder[placeholderIndex]?.value?.trim() ||
              base_config?.defaultPlaceholder
            }
            regexCheck={allowEverything}
            value={searchText}
            showErrorBox={false}
            customClass={`${!isFilledWhite || isTransparent ? '!tw-text-[#ffffff]' : ''} !tw-py-[10] custom-input tw-ml-[8px] tw-w-[100%]
             tw-font-dmSans tw-flex-grow tw-truncate tw-border-none tw-text-[14px]
             tw-transition-opacity tw-duration-500 tw-ease-in-out 
             ${fade ? 'tw-opacity-100' : searchText?.length ? 'tw-opacity-100' : 'tw-opacity-0'} ${!fullSearch ? 'tw-w-[238px]' : ''}`}
            handleChange={(value: any) => setSearchText(value)}
            rows={1}
            iconComponent={() => (
              <div className="tw-ml-[14px] tw-flex tw-items-center tw-justify-center">
                {getIcon('24', currentClass?.base_config?.defaultNavLinksColor)}
              </div>
            )}
            isFocus
            borderColor={!isFilledWhite || isTransparent ? '#ffffff99' : '#111C361F'}
            customPadding={'10px 0px'}
            inputColor={!isFilledWhite || isTransparent ? '#ffffff' : ''}
            rightIconCompo={() => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setSearchText('');
                }}
                className="tw-mr-[14px] tw-flex tw-items-center tw-justify-center"
              >
                {searchText?.length > 0 &&
                  crossIcon(currentClass?.base_config?.defaultNavLinksColor)}
              </div>
            )}
          />
        </div>
        {searchText?.length > 0 && (
          <div
            style={{
              boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.08)',
              border: '1px solid #111C361F',
            }}
            className={`tw-absolute tw-z-[100] ${fullSearch ? 'tw-min-w-[100%]' : 'tw-min-w-[585px]'} tw-bg-[#ffffff]
       ${base_config?.defaultPosition === 'left' ? 'tw-left-0' : 'tw-right-0'} tw-mt-[5px] tw-max-h-[500px] tw-overflow-scroll tw-px-20 tw-py-[30px]`}
          >
            <RenderNewSearch productQuery={searchText} fullSearch={false} />
          </div>
        )}
      </div>
    );
  }

  function handleClick() {
    if (isMobile) {
      router.push(getRoute('search', storeData?.store_info?.domain));
    } else {
      setShowSearch((prev) => !prev);
    }
  }

  function _RenderSearchLayout() {
    const desktopIconConfig = globalClass?.staticClass?.base_config?.defaultFontSizeType;
    const mobileIconConfig =
      globalClass.staticClass.base_config.defaultFontSizeTypeMobile;
    const sizeDetails = getIconSizeAccToConfig(
      isMobile ? mobileIconConfig : desktopIconConfig
    );
    const typeAccHeader = isCenterAlignedHeader ? 'field' : defaultType;
    const isTransparent =
      currentClass?.base_config?.defaultFillColorType === 'transparent';
    const isFilledWhite =
      currentClass?.base_config?.defaultBackgroundColor === '#ffffff' || isTransparent;

    switch (typeAccHeader) {
      case 'icon':
        return (
          <div
            style={{ width: Number(sizeDetails) + 4, height: Number(sizeDetails) + 4 }}
            className={`${
              defaultHoverIconState === 'zoomin'
                ? 'tw-transition-transform tw-duration-500 hover:tw-scale-[1.2]'
                : defaultHoverIconState === 'background'
                  ? `${!isFilledWhite ? 'hover:tw-bg-[#ffffff4d]' : 'hover:tw-bg-[#111C360F]'}`
                  : ''
            } tw-flex tw-cursor-pointer tw-items-center tw-justify-center`}
            onClick={handleClick}
          >
            {getIcon(sizeDetails, currentClass?.base_config?.defaultNavLinksColor)}
          </div>
        );
      case 'field':
        return renderSearchWithField();
      case 'labelIcon':
        return (
          <div
            onClick={handleClick}
            className="tw-flex tw-cursor-pointer tw-items-center"
          >
            <div
              style={{ width: Number(sizeDetails) + 4, height: Number(sizeDetails) + 4 }}
              className={`${
                defaultHoverIconState === 'zoomin'
                  ? 'tw-transition-transform tw-duration-500 hover:tw-scale-[1.2]'
                  : defaultHoverIconState === 'background'
                    ? `${!isFilledWhite ? 'hover:tw-bg-[#ffffff4d]' : 'hover:tw-bg-[#111C360F]'}`
                    : ''
              } tw-mb-[2px] tw-flex tw-items-center tw-justify-center`}
            >
              {getIcon(sizeDetails, currentClass?.base_config?.defaultNavLinksColor)}
            </div>
            <div
              style={getStyleForText(true)}
              className="tw-m-0 tw-ml-[8px] tw-cursor-pointer tw-select-none tw-font-dmSans"
            >
              Search
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  return (
    <div
      style={{ [defaultPosition]: searchAlignment[defaultPosition] }}
      className={`${
        isCenterAlignedHeader
          ? 'tw-relative tw-flex tw-flex-grow tw-justify-center' // tw-right-[2.5%] !tw-max-w-[42%]
          : ''
      }`}
    >
      {base_config.showSearch && _RenderSearchLayout()}
    </div>
  );
};

export function SearchIconComp({
  className = '',
  dimensions = { width: 19, height: 19 },
  handleClick = () => {},
}) {
  return (
    <CustomizedButton
      variant="tertiary"
      className={'tw-min-w-0 ' + className}
      backgroundColor="#fff"
      onClick={handleClick}
    >
      <SearchIcon color="tw-text-newBlack" {...dimensions} />
    </CustomizedButton>
  );

  /**
   const {
    widgetContextState: { activeHeaderData },
  } = useWidgetDndContextData();
  const {
    headerPropsConfig: {
      navIcons_section: { componentData },
    },
  } = activeHeaderData;
  const canRenderText = componentData?.appearanceType?.includes('text');
  const canRenderImage = componentData?.appearanceType?.includes('icon');

  const searchIconConfig = componentData?.iconsList?.find(
    (item) => item.name === 'search'
  );

  return (
    <CustomizedButton
      variant="tertiary"
      className={
        '!tw-text-uppercase !tw-m-0 !tw-flex tw-min-w-0 !tw-items-center !tw-gap-[4px] !tw-text-[10px] !tw-font-light !tw-text-newBlack ' +
        className
      }
      backgroundColor="#fff"
      onClick={handleClick}
      image={
        canRenderImage && (
          <searchIconConfig.ImageComponent
            width={canRenderText ? 12 : 24}
            height={canRenderText ? 12 : 24}
          />
        )
      }
    >
      {canRenderText && (
        <p className="tw-text-[10px] tw-uppercase tw-leading-[1] tw-tracking-[1.48px]">
          {searchIconConfig?.label}
        </p>
      )}
    </CustomizedButton>
  );
   */
}

export function MainSectionAddIcon(props) {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#4285f4" />
      <g clip-path="url(#clip0_540_3251)">
        <path
          d="M21.25 16.75H16.75V21.25H15.25V16.75H10.75V15.25H15.25V10.75H16.75V15.25H21.25V16.75Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_540_3251">
          <rect width="18" height="18" fill="white" transform="translate(7 7)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export const RightSliderArrow = () => (
  <svg
    width="44"
    height="21"
    viewBox="0 0 44 21"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.4393 0.422194C33.0251 -0.140731 33.9749 -0.140731 34.5607 0.422194L43.5607 9.07096C44.1464 9.63389 44.1464 10.5466 43.5607 11.1095L34.5607 19.7583C33.9749 20.3212 33.0251 20.3212 32.4393 19.7583C31.8536 19.1953 31.8536 18.2826 32.4393 17.7197L38.8787 11.5317L1.5 11.5317C0.671575 11.5317 0 10.8863 0 10.0902C0 9.29413 0.671575 8.64876 1.5 8.64876L38.8787 8.64876L32.4393 2.46073C31.8536 1.8978 31.8536 0.98512 32.4393 0.422194Z"
      fill="white"
    />
  </svg>
);

import {
  SAVE_USER_DETAILS,
  SAVE_ADDRESS_LIST,
  SAVE_SELECTED_ADDRESS,
  REMOVE_SELECTED_ADDRESS,
  SET_LOGIN,
  SET_LOGOUT,
  TOGGLE_VERIFY_OTP,
  SET_TRUECALLER_PRESENT,
  SET_TRUECALLER_ABSENT,
  SET_ROUTE_CURRENT,
  SET_ROUTE_NEXT,
  SET_ROUTES,
} from '../actionTypes';

const initialState = {
  data: {},
  isLogin: false,
  truecallerPresent: false,
  toggleVerifyOtp: false,
  addressList: [],
  savedAddress: {
    address1: '',
    address2: '',
    addressType: 'home',
    phone_number: '',
  },
  defaultAddress: {},
  routeChanges: {
    current: '',
    next: '',
  },
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_USER_DETAILS:
      return { ...state, data: action.data };
    case SAVE_ADDRESS_LIST:
      return {
        ...state,
        addressList: action.data.addressList,
        defaultAddress: action.data.defaultAddress,
      };
    case SAVE_SELECTED_ADDRESS:
      return { ...state, savedAddress: action.data };
    case REMOVE_SELECTED_ADDRESS:
      return { ...state, savedAddress: {} };
    case SET_LOGIN:
      return { ...state, isLogin: true };
    case SET_LOGOUT:
      return { ...state, isLogin: false, data: {} };
    case TOGGLE_VERIFY_OTP:
      return { ...state, toggleVerifyOtp: !state.toggleVerifyOtp };
    case SET_TRUECALLER_PRESENT:
      return { ...state, truecallerPresent: true };
    case SET_TRUECALLER_ABSENT:
      return { ...state, truecallerPresent: false };
    case SET_ROUTE_CURRENT:
      return { ...state, routeChanges: { ...state.routeChanges, current: action.data } };
    case SET_ROUTE_NEXT:
      return { ...state, routeChanges: { ...state.routeChanges, next: action.data } };
    case SET_ROUTES:
      return {
        ...state,
        routeChanges: { current: action.data.current, next: action.data.next },
      };
    default:
      return state;
  }
};

export default UserReducer;

/**
 * @author Pradyumna Tomar
 */
import { createContext, useEffect } from 'react';
import { IUseWidgetDndData } from './types';
import { WIDGET_DND_INITIAL_STATE } from './WidgetDndContextReducer/WidgetDndContextReducer';
import { INITIAL_WIDGET_DND_CONTEXT_ACTIONS } from './WidgetDndContextReducer/actions';
import { DEFAULT_WIDGET_DND_CONTEXT_HELPERS } from './WidgetDndContextReducer/default';

const WIDGET_CONTEXT_DATA_INITIAL_STATE = {
  widgetContextState: WIDGET_DND_INITIAL_STATE,
  widgetsCatalogLoaded: false,
  /** Context State Updaters */
  widgetContextActions: INITIAL_WIDGET_DND_CONTEXT_ACTIONS,
  /** Helpers */
  widgetContextHelpers: DEFAULT_WIDGET_DND_CONTEXT_HELPERS,
};

export const WidgetDndContextCreator = createContext<IUseWidgetDndData>(
  WIDGET_CONTEXT_DATA_INITIAL_STATE as IUseWidgetDndData
);

import { useContext } from 'react';
import { useWidgetDndData } from './useWidgetDndData';
import LocalStorageHelper from 'src/utils/LocalStorageHelper';
import { useRouter } from 'next/router';

export const useWidgetDndContextData = () => {
  const data = useContext<IUseWidgetDndData>(WidgetDndContextCreator);
  return data;
};

export const WidgetDndContext = ({ children }) => {
  const { query } = useRouter();
  const data = useWidgetDndData();

  useEffect(() => {
    const isBuilder = window.location.pathname?.includes('widgets/dnd');
    if (isBuilder) {
      const oldToken = LocalStorageHelper.get('builder_auth_token');
      if (oldToken && data.authToken && oldToken !== data.authToken) {
        LocalStorageHelper.add('builder_auth_token', query.token);
        LocalStorageHelper.add('builder_domain', query.domain);
      }
    }
  }, []);

  return (
    <WidgetDndContextCreator.Provider value={data}>
      {children}
    </WidgetDndContextCreator.Provider>
  );
};

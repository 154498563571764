/**
 * Additional Pages Related Saga
 */
import { put, takeEvery } from 'redux-saga/effects';
import { setLoaderState, saveAddtionalPage, saveNavigationPagesList } from '../actions';
import {
  GET_NAVIGATION_PAGES_LIST,
  GET_STATIC_WIDGET_DATA,
  GET_ADDITIONAL_PAGE_DATA_BY_SLUG,
} from '../actionTypes';
import { ENDPOINT } from '../../config/endpoints';
import apiCall from '../../services/api';

function* getAdditionalPageDataBySlug(actions) {
  try {
    yield put(setLoaderState(true));
    const pageResponse = yield apiCall({
      method: 'POST',
      url: ENDPOINT.ADDITIONAL_PAGES.GET_ADDITIONAL_PAGE,
      data: actions.data,
      parseToJson: true,
    });

    if (pageResponse && pageResponse.data.status) {
      yield put(saveAddtionalPage(pageResponse.data.page));
    }
  } catch (error) {
  } finally {
    yield put(setLoaderState(false));
  }
}

function* getNavigationPagesList(actions) {
  try {
    yield put(setLoaderState(true));
    const pageResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.ADDITIONAL_PAGES.getNavigationList(
        actions.data.storeId,
        actions.data.isPublished
      ),
      parseToJson: true,
    });
    if (pageResponse && pageResponse.data.status) {
      yield put(saveNavigationPagesList(pageResponse.data.data));
      actions.callback && actions.callback(pageResponse.data.data);
    }
  } catch (error) {
  } finally {
    yield put(setLoaderState(false));
  }
}

function* getStaticWidgetDataById(actions) {
  try {
    yield put(setLoaderState(true));
    const widgetResponse = yield apiCall({
      method: 'GET',
      url: ENDPOINT.ADDITIONAL_PAGES.getStaticWidgetDetails(actions.data),
      parseToJson: true,
    });
    if (widgetResponse && widgetResponse.data.status) {
      // yield put(saveNavigationPagesList(widgetResponse.data.data));
      actions.callback && actions.callback(widgetResponse.data.data);
    }
  } catch (error) {
  } finally {
    yield put(setLoaderState(false));
  }
}

export default function* root() {
  yield takeEvery(GET_ADDITIONAL_PAGE_DATA_BY_SLUG, getAdditionalPageDataBySlug);
  yield takeEvery(GET_NAVIGATION_PAGES_LIST, getNavigationPagesList);
  yield takeEvery(GET_STATIC_WIDGET_DATA, getStaticWidgetDataById);
}

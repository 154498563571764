export const INTERNAL_DOMAINS = [
  'showroom.dot',
  'showroom.stage',
  'showroom-preview1.dot',
  'showroom-preview2.dot',
  'showroom-preview1.stage',
  'showroom-preview2.stage',
  'showroom.digitaldukaan.app',
  'localhost',
];

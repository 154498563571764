import styled from 'styled-components';

export const VideoMediaContainer = styled.div`
  width: 100%;
  overflow: hidden;
  & > video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  & iframe {
    width: 100%;
    height: 100%;
  }
`;

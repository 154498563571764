export function WidgetOutlineAnnouncementBarIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <g clip-path="url(#clip0_1166_2373)">
        <path
          d="M13.5 8.25C13.5 8.7525 13.5 9.2475 13.5 9.75C14.4 9.75 15.57 9.75 16.5 9.75C16.5 9.2475 16.5 8.7525 16.5 8.25C15.57 8.25 14.4 8.25 13.5 8.25Z"
          fill="#222222"
        />
        <path
          d="M12 13.2075C12.72 13.74 13.6575 14.445 14.4 15C14.7 14.6025 15 14.1975 15.3 13.8C14.5575 13.245 13.62 12.54 12.9 12C12.6 12.405 12.3 12.81 12 13.2075Z"
          fill="#222222"
        />
        <path
          d="M15.3 4.2C15 3.8025 14.7 3.3975 14.4 3C13.6575 3.555 12.72 4.26 12 4.8C12.3 5.1975 12.6 5.6025 12.9 6C13.62 5.46 14.5575 4.7625 15.3 4.2Z"
          fill="#222222"
        />
        <path
          d="M3 6.75C2.175 6.75 1.5 7.425 1.5 8.25V9.75C1.5 10.575 2.175 11.25 3 11.25H3.75V14.25H5.25V11.25H6L9.75 13.5V4.5L6 6.75H3ZM6.7725 8.0325L8.25 7.1475V10.8525L6.7725 9.9675L6.4125 9.75H6H3V8.25H6H6.4125L6.7725 8.0325Z"
          fill="#222222"
        />
        <path
          d="M11.625 9.00005C11.625 8.00255 11.19 7.10255 10.5 6.48755V11.505C11.19 10.8975 11.625 9.99755 11.625 9.00005Z"
          fill="#222222"
        />
      </g>
      <defs>
        <clipPath id="clip0_1166_2373">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

/**
 * Required Module
 */
import { get } from 'axios';
import { ENDPOINT } from './../config/endpoints';

/**
 * Function for fetching the single
 * catalog Item
 * @param {number} storeId
 * @param {number} itemId
 * @return {object}
 */
async function getCatalogItemById(storeId, itemId, src) {
  try {
    const response = await get(ENDPOINT.CATALOG.getItemById(storeId, itemId, src), {
      headers: {
        app_os: 'cfe',
      },
    });
    return { error: null, data: response.data };
  } catch (error) {
    return { error };
  }
}

/**
 * Function for fetching the
 * catalog categories
 * @param {number} storeId
 * @return {object}
 */
async function getCatalogCategories(storeId) {
  try {
    const response = await get(ENDPOINT.CATALOG.getCategories(storeId), {
      headers: {
        app_os: 'cfe',
      },
    });
    return { error: null, data: response.data };
  } catch (error) {
    return { error };
  }
}

/**
 * Function for fetching all collections data
 * @param {*} storeId
 * @return {Array}
 */
async function getCollectionData(storeId) {
  try {
    const response = await get(ENDPOINT.CATALOG.getAllCollectionsData(storeId), {
      headers: {
        app_os: 'cfe',
      },
    });
    return { error: null, data: response.data };
  } catch (error) {
    return { error };
  }
}

/**
 * Add Exported Function here
 */
export { getCatalogItemById, getCatalogCategories, getCollectionData };

export const addressRegex = () => /[^\w\@\-\.]/gi;
export const alphaNumericLettersRegex = () => /[^\w\@\-\.]/gi;
export const alphabetsAndSpaceRegex = () => /[^\w\@\-\.]/gi;
export const emailRegex = () => /[^\w\@\-\.]/gi;

export function removeSpecialCharAndNumbers(inputStr) {
  const opStr = inputStr.replace(/[^a-zA-Z ]/g, '');
  return opStr;
}

export function removeSpecialCharacters(inputStr) {
  const opStr = inputStr.replace(/[^a-zA-Z0-9 ]/g, '');
  return opStr;
}

export function allowOnlyNumber(inputStr) {
  const opStr = inputStr.replace(/[^0-9]/g, '');
  return opStr;
}

export function allowRange(inputStr) {
  const opStr = inputStr.replace(/^\d+:\d+$|[A-Z]+:[A-Z]$/g, '');
  return opStr;
}

export function allowAllNumbers(inputStr) {
  const opStr = inputStr.replace(/[^0-9 . -]/g, '');
  return opStr;
}

export function decimalPriceRegex(inputStr, maxDecimalPlaces = 2) {
  let opStr = inputStr.replace(/[^0-9\.]/g, '');
  if (opStr.split('.').length > 2) opStr = opStr.replace(/\.+$/, '');
  const decimalPointIndex = opStr.indexOf('.');
  opStr =
    decimalPointIndex >= 0
      ? opStr.slice(0, decimalPointIndex) +
        opStr.slice(decimalPointIndex, decimalPointIndex + maxDecimalPlaces + 1)
      : opStr;
  return opStr;
}

export function allowEverything(inputStr) {
  return inputStr;
}

export function passwordRegex(inputStr) {
  const opStr = allowOnlyNumber(inputStr).replace(/./g, '*');
  return opStr;
}

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const isValidPhoneNumber = (number) => {
  return /^[6-9]\d{9}$/.test(number);
};

import config from 'src/config';

export const WIDGETS_REGISTRY = `https://cdn.dotnu.co/widgets`;
// export const WIDGETS_REGISTRY = `http://localhost:8010/proxy/widgets`;

export const getWidgetUrl = (name: string) => {
  return `${WIDGETS_REGISTRY}/${name}/index.js`;
}

export const isBrowser = () => {
  if (typeof window !== 'undefined' && typeof document !== 'undefined') {
    return true;
  }
  return false;
}

export const changeApiEndPoint = (env: string) => {
  const api = {
    production: 'https://api.dotshowroom.in/api',
    development: 'https://api.digitaldukaan.app/api',
    staging1: 'https://api.digitaldukaan.app/api',
    staging2: 'https://stage2-api.digitaldukaan.app/api',
  };
  config.API_BASE_URL = api[env];
}

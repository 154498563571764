import { saveAdditionalPageList } from '@/redux/actions';
import { getAdditionalPageData } from './WidgetDnD/widgetDnDApi';
import reduxStore from '@/redux/store';
import { StoreDetails } from '@/redux/reducers';
import store from '@/redux/store';
import { getNavigationList } from '@/services/additionalPages';
import { useSSRSelector } from '@/redux/ssrStore';

type DefaultStore = Record<string, any>;

interface CFEReduxStoreAddress {
  store_id: number;
  address_1: string;
  address_2: string;
  latitude: number;
  longitude: number;
  city: string;
  google_address: string;
  pincode: string;
  landmark: string;
  city_id: number;
  state: string;
  visibility: 1;
}

interface CFEReduxStoreOwner {
  store_id: number;
  user_id: number;
  phone: string;
  role: string;
  verify_phone: number;
  language_id: number;
  first_name: string;
  last_name: string;
  email_id: string;
  sign_in_id: string;
  photo: string;
  permissions: number[];
}

interface CFETheme {
  theme_id: number;
  store_theme_id: number;
  colors: {
    id: number;
    name: string;
    image_url: string;
    primary_color: string;
    secondary_color: '';
  };
  cta_config: {
    banner_cta: string;
    header_cta: string;
    pdp_cta: string;
    postpaid_cart_cta: string;
    my_orders: 'My Orders';
  };
  roundness: number;
  category: string;
  expiry: string;
  is_demo: number;
  theme_class: number;
  createlist_flag: number;
  mb_flag: string;
  custom_theme_flag: number;
  additional_page_flag: number;
  category_aspect_ratio: number;
  product_aspect_ratio: number;
  banner_fixed_ratio: number;
  product_image_quality_flag: number;
  is_quick_view: number;
}

export interface CFEReduxStore {
  storeReducer: {
    store: {
      store_id: number;
      store_name: string;
      domain: string;
      display_phone: string;
      description: string;
      store_url: string;
      logo_image: string;
      reference_store_id: number;
      spotlight_flag: number;
      score: number;
      kyc_status: number;
      gst_status: number;
      gst_number: string;
      premium: number;
      bank_account_id: number;
      subscription_flag: number;
      source: string;
      created_at: string;
      address: CFEReduxStoreAddress;
      owner: CFEReduxStoreOwner;
      services: {
        store_id: number;
        store_flag: number;
        delivery_flag: number;
        pickup_flag: number;
        listing_flag: number;
        partner_delivery_flag: number;
        min_order_value: number;
        mdr_flag: number;
        delivery_charge_type: number;
        free_delivery_above: number;
        delivery_price: number;
        delivery_charge_min: number;
        delivery_charge_max: number;
        delivery_time_approx: string;
        is_setup: number;
        request_callback: number;
        payment_method: number;
        notification_flag: number;
        merchant_cart_notification: number;
        customer_cart_notification: number;
        emi_flag: number;
        store_type_flag: number;
        gst_flag: number;
        gst_activated_flag: 0;
      };
      configs: {
        store_id: number;
        rewards: number;
        promos: number;
        payouts: number;
        bank_update: number;
        catalog: 1;
      };
      domain_info: {
        store_id: number;
        domain: string;
        redirect_to: string;
        domain_type: number;
        expiry_date: string;
        status: number;
        site_id: 46347;
      };
      store_businesses: {
        business_id: number;
        business_name: string;
        image: 'https://cdn.dotpe.in/kiranaStatic/image/Grocery_Dairy05012021.png';
      }[];
      gst_info: {
        store_id: number;
        gst_number: string;
        is_gst_same: number;
        is_gst_included: number;
        default_gst: number;
        default_hsn: string;
        signature: string;
        gst_status: number;
        gst_address: string;
        gst_business_name: string;
        pincode: string;
      };
      store_info: {
        name: string;
        domain: string;
        store_url: string;
        logo_image: string;
        reference_store_id: number;
        description: string;
        merchant_shiprocket_status: number;
        total_credits: number;
        non_premium_ad_url_desktop_vertical: string;
        non_premium_ad_url_desktop_horizontal: string;
        non_premium_ad_url_mobile: '';
      };
      categories: null;
      bank_details: null;
      theme: CFETheme;
      store_premium_theme: CFETheme;
      store_marketing_tags: {
        store_id: number;
        facebook_pixel: string;
        google_analytics: string;
        google_ads: string;
        g_tag: string;
        gmc_verification: string;
        fb_domain_verification: '';
      };
      constants: null;
      is_ecomm_plus_active: true;
    };
  };
  cartReducer: DefaultStore;
  catalogReducer: DefaultStore;
  userReducer: DefaultStore;
  orderReducer: DefaultStore;
  commonReducer: DefaultStore;
  paymentReducer: DefaultStore;
  spotlightReducer: DefaultStore;
  errorReducer: DefaultStore;
  promoReducer: DefaultStore;
  additionalPagesReducer: DefaultStore;
  storePolicyReducer: DefaultStore;
  leadGenReducer: DefaultStore;
  emiReducer: DefaultStore;
  deliveryReducer: DefaultStore;
}

const convertStoreToWidgetData = (store: CFEReduxStore) => {
  if (store) {
    const data = {
      store: store.storeReducer?.store,
      cart: store.cartReducer,
      catalog: store.catalogReducer,
      user: store.userReducer,
      promo: store.promoReducer,
      additionalPage: store.additionalPagesReducer,
    };
    return data;
  }

  return null;
};

export const useConvertStoreToWidgetData = () => {
  const { store, additionalPage, cart, catalog, user, promo } = useSSRSelector(
    (state: any) => ({
      store: state.storeReducer?.store,
      additionalPage: state.additionalPagesReducer || {},
      promo: state.promoReducer || {},
      cart: state.cartReducer || {},
      catalog: state.catalogReducer || {},
      user: state.userReducer || {},
    })
  );

  if (store) {
    const data = {
      store,
      additionalPage,
      promo,
      cart,
      catalog,
      user,
    };
    return data;
  }

  return null;
};

const FILE_EXT_MAP = {
  css: 'css',
  html: 'html',
  tsx: 'typescript',
  json: 'json',
};

export const getFileTypeFromName = (fileName: string) => {
  const fileParts = fileName.split('.');
  const fileExt = fileParts[fileParts.length - 1];

  return FILE_EXT_MAP[fileExt] || fileExt;
};

const fetchAndSaveAdditionalPage = async (slug: string, storeId: number) => {
  const data = await getAdditionalPageData(slug, storeId);
  store.dispatch(saveAdditionalPageList(data));
  return data;
};

// Load initial state data for testimonials and blogs (i.e. additional pages) - This resolves the problem where for testimonials and blogs, we see empty data being passed as props.
export const useWidgetData = () => {
  // const dispatch = useDispatch();
  const store = reduxStore.getState();

  // useEffect(() => {
  //   if (store?.storeReducer?.store?.store_id) {
  //     dispatch(
  //       getNavigationPagesList(
  //         {
  //           storeId: store.storeReducer.store.store_id,
  //           isPublished: true,
  //         },
  //         (response) => {
  //           response.map((additionalPage) => {
  //             if (
  //               additionalPage?.page_type === 'testimonial' ||
  //               additionalPage?.page_type === 'blog'
  //             ) {
  //               fetchAndSaveAdditionalPage(
  //                 additionalPage?.slug_url,
  //                 store.storeReducer.store.store_id
  //               );
  //             }
  //           });
  //         }
  //       )
  //     );
  //   }
  // }, [store.storeReducer.store.store_id]);

  // useEffect(() => {
  //   store?.additionalPagesReducer?.navigationList?.map((additionalPage) => {
  //     if (
  //       additionalPage?.page_type === 'testimonial' ||
  //       additionalPage?.page_type === 'blog'
  //     ) {
  //       fetchAndSaveAdditionalPage(
  //         additionalPage?.slug_url,
  //         store.storeReducer.store.store_id,
  //         dispatch,
  //         (val) => setStoreDataVal(val)
  //       );
  //     }
  //   });
  // }, [store?.additionalPagesReducer?.navigationList]);
  // if (store?.additionalPagesReducer?.navigationList?.isActiveList) {
  //   console.log(storeDataVal);
  // }

  const storeData = convertStoreToWidgetData(store);
  return storeData;
};

// Load initial state data for testimonials and blogs (i.e. additional pages) - This resolves the problem where for testimonials and blogs, we see empty data being passed as props.
export const getAdditionalPagesData = async (
  storeData: StoreDetails = {} as StoreDetails
) => {
  const { store_id } = storeData;
  try {
    const navigationPageList = await getNavigationList(store_id, true);
    const additionalPagesList = {};
    for (const additionalPage of navigationPageList) {
      if (
        additionalPage?.page_type === 'testimonial' ||
        additionalPage?.page_type === 'blog'
      ) {
        const additionalPageResponse = await fetchAndSaveAdditionalPage(
          additionalPage?.slug_url,
          store_id
        );
        additionalPagesList[additionalPageResponse.slug_url] = additionalPageResponse;
      }
    }

    return {
      error: null,
      navigationPageList,
      additionalPagesList,
    };
  } catch (error) {
    return { error };
  }
};

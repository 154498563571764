import { getTextLineHeightBuilder } from '../../Carousel/utils';
import {
  getMappedProductCasing,
  getMappedproductsTextSize,
  getMappedProductTextThickness,
  getTextSizeNum,
} from '../../ListPresentation/components/utils';

const TEXT_SIZE_DESKTOP_KEY = 'textSizeDesktop';
const TEXT_SIZE_MOBILE_KEY = 'textSizeMobile';

export function getAnnouncementBarStyles({
  isMobile,
  cardFrameConfig,
  listPresentationConfig,
}) {
  const { selectedColorSchemeIndex, colorSchemes } = listPresentationConfig?.colors;
  const { mobilePaddingY, paddingY } = listPresentationConfig;
  const { text = '#000000', background = '#ffffff' } =
    colorSchemes[selectedColorSchemeIndex];

  const announcementStyles = {
    ...getMappedproductsTextSize(
      cardFrameConfig?.[!isMobile ? TEXT_SIZE_DESKTOP_KEY : TEXT_SIZE_MOBILE_KEY]
    ),
    ...getMappedProductCasing(cardFrameConfig?.textCase),
    ...getMappedProductTextThickness(cardFrameConfig?.textThickness),
    color: text,
    textAlign: 'center',
  };

  const containersStyles = {
    padding: `${isMobile ? mobilePaddingY : paddingY}px 0px`,
    background,
  };

  return { announcementStyles, containersStyles };
}

export function getMaxAnnouncementHeight({ activeHeaderData, isMobile }) {
  const { cardConfig, listConfig } =
    activeHeaderData?.headerPropsConfig?.announcementConfig || {};
  const { mobilePaddingY = 6, paddingY = 8 } = listConfig || {};

  const { textSizeMobile = 12, textSizeDesktop = 14 } = cardConfig || {};

  const announcementTextHeight = getTextLineHeightBuilder(
    getTextSizeNum(isMobile ? textSizeMobile : textSizeDesktop)
  );

  // announcement text can go upto 3 line only
  const maxAnnouncementHeight = ((isMobile ? mobilePaddingY : paddingY) * 2 +
    3 * announcementTextHeight) as number;

  return { maxAnnouncementHeight };
}

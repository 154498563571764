import { SAVE_STORE_POLICIES, GET_STORE_POLICIES } from '../actionTypes';

export const saveStorePolicies = (data, fromSSR = false) => {
  return {
    type: SAVE_STORE_POLICIES,
    data,
    fromSSR,
  };
};

export const getStorePolicies = (data) => {
  return {
    type: GET_STORE_POLICIES,
    payload: data,
  };
};

import { takeEvery, put } from 'redux-saga/effects';
import { ENDPOINT } from './../../config/endpoints';
import apiCall from './../../services/api';
import { getLeadId, setLeadId } from '../../utils/localStorageFunctions';
import {
  GET_STORE_ACTIVE_LEADGEN_TEMPLATE,
  UPDATE_CUSTOM_LEAD,
} from './../actionTypes';

import { saveLeadGeninfo, setLoaderState } from './../actions';
function* updateCustomerLead(actions) {
  yield put(setLoaderState(true));

  try {
    const response = yield apiCall({
      method: 'POST',
      url: ENDPOINT.LEAD_GENERATION.updateCustomerLead,
      data: { ...actions.data },
    });
    if (response && response.data.status) {
      const data = JSON.parse(response.data.data);
      if (!getLeadId() && setLeadId(data.id)) yield put(setLoaderState(false));
      actions?.callback();
    }
  } catch (err) {
    console.log(err);
    yield put(setLoaderState(false));
  }
}

function* getStoreActiveLeadGenTemplate(actions) {
  try {
    yield put(setLoaderState(true));
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.LEAD_GENERATION.getStoreActiveLeadGenTemplate(
        actions.data.store_id
      ),
      parseToJson: true,
    });
    if (response && response.data.status) {
      yield put(setLoaderState(false));
      yield put(saveLeadGeninfo(response.data.data));
      actions?.callback(response.data.data);
    }
  } catch (err) {
    console.log(err);
    yield put(setLoaderState(false));
  }
}

export default function* root() {
  yield takeEvery(
    GET_STORE_ACTIVE_LEADGEN_TEMPLATE,
    getStoreActiveLeadGenTemplate
  );
  yield takeEvery(UPDATE_CUSTOM_LEAD, updateCustomerLead);
}

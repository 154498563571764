import {
  NEW_STORE_WEBSITE_STATUS,
  NEW_SUBSCRIPTIONS_STATUS,
} from 'src/constants/subscription';
import { StoreDetails } from 'src/redux/reducers';
import store from 'src/redux/store';

export const getStoreSubscriptionConditions = (storeInfo?: StoreDetails) => {
  const storeData = (storeInfo || store?.getState()?.storeReducer?.store) as StoreDetails;
  const isOldSubscriptionActive =
    storeData?.new_subscription_status === NEW_SUBSCRIPTIONS_STATUS.INACTIVE;
  const isSubscriptionTransitionedToNew =
    storeData?.premium &&
    storeData?.new_subscription_status !== NEW_SUBSCRIPTIONS_STATUS.INACTIVE;
  const isNewSubscriptionActive =
    storeData?.new_subscription_status === NEW_SUBSCRIPTIONS_STATUS.ACTIVE;
  const isNewSubscriptionTokenReceived =
    storeData?.new_subscription_status === NEW_SUBSCRIPTIONS_STATUS.TOKEN_AMOUNT_RECEIVED;

  return {
    isOldSubscriptionActive,
    isSubscriptionTransitionedToNew,
    isNewSubscriptionActive,
    isNewSubscriptionTokenReceived,
  };
};

export const getWebsiteLiveStatus = (storeInfo) => {
  const storeData = (storeInfo || store?.getState()?.storeReducer?.store) as StoreDetails;
  const isOldStoreWebsiteActive =
    storeData?.theme?.new_subscription_web_status === NEW_STORE_WEBSITE_STATUS.INACTIVE;
  const isNewStoreWebsiteLive =
    storeData?.theme?.new_subscription_web_status === NEW_STORE_WEBSITE_STATUS.LIVE;
  // In draft-only mode
  const isNewStoreWebsiteInactive =
    storeData?.theme?.new_subscription_web_status ===
    NEW_STORE_WEBSITE_STATUS.ACTIVE_NOT_LIVE;

  return { isOldStoreWebsiteActive, isNewStoreWebsiteLive, isNewStoreWebsiteInactive };
};

import { getDefaultConfig } from 'src/components/WidgetMaker/utils/common';
import { SECTION_TYPE } from './constant';
import { ADMIN_WIDGETS } from 'src/constants/widgets';
import { SUBSCRIPTION_NAMES } from 'src/constants/builderConstants';

export const FOOTER_DATA = {
  isNewAdminFooter: true,
  type: ADMIN_WIDGETS.FOOTER.type,
  footerConfig: {
    colorSchemes: [
      {
        background: '#ffffff',
        text: '#000000',
        isDefault: true,
      },
      {
        background: '#000000',
        text: '#ffffff',
        isDefault: false,
      },
    ],
    selectedColorSchemeIndex: 0,
    showGoToTop: false,
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  layer1: {
    heading: '',
    isSortable: true,
    isAddElement: true,
    actualLayer: 'layer1',
    elementList: [''],
    builder: [
      {
        type: SECTION_TYPE.LOGO_ABOUTUS,
        displayText: 'Store details and utilities',
        sectionHeading: '',
        isSortable: false,
        isDefault: true,
        isVisible: true,
        isEditable: true,
        section: [
          {
            type: 'logo',
            logoUrl: '',
            logoSrc: '',
            aspectRatio: '',
            zoom: '',
            manualUpdate: true,
            desktopSize: '60px',
            mobileSize: '20px',
            hideLogo: false,
            hideStoreName: true,
            showAppDownload: true,
            showSocialMedia: true,
          },
          {
            type: 'aboutUs',
            content: '<p>Tell something about your company here in brief.</p>',
            contentCharCount: 0,
            showMoreLink: true,
            linkText: 'Learn more',
            redirectionType: 'link',
            redirectUrl: '',
            isOpenInNewTab: true,
            linkColor: '#000000',
          },
          {
            type: 'appStore',
            selectedType: 'type1',
            isVisible: false,
            showAppStore: true,
            showGooglePlay: true,
            playStoreLink: '',
            appStoreLink: '',
            newTabPlayStore: true,
            newTabAppStore: true,
            columnType: 'logoAboutUs',
          },
          {
            type: 'social',
            isVisible: true,
            selectedType: 'type1',
            columnType: 'logoAboutUs',
          },
        ],
        style: {
          // width: '22%',
          minWidth: '210px',
          maxWidth: '336px',
        },
      },
      {
        type: SECTION_TYPE.QUICK_LINKS,
        subType: 'info',
        isDefault: true,
        displayText: 'Information',
        content: '<p>Information</p>',
        contentCharCount: 11,
        isSortable: false,
        isVisible: true,
        isEditable: true,
        section: [],
        style: {
          // width: '13%',
          minWidth: '120px',
          maxWidth: '180px',
        },
      },
      {
        type: SECTION_TYPE.QUICK_LINKS,
        displayText: 'Shop',
        subType: 'shop',
        content: '<p>Shop</p>',
        isDefault: true,
        contentCharCount: 4,
        isSortable: false,
        isVisible: true,
        isEditable: true,
        section: [],
        style: {
          // width: '13%',
          minWidth: '120px',
          maxWidth: '180px',
        },
      },
      {
        type: SECTION_TYPE.CONTACTUS_LOCATION,
        subType: 'contactUs',
        displayText: 'Contact us and location',
        contentContact: '<p>Contact us</p>',
        isDefault: true,
        contentLocation: '<p>Location</p>',
        contactCharCount: 10,
        locationCharCount: 8,
        isSortable: false,
        isVisible: true,
        showContact: true,
        showLocation: true,
        isEditable: true,
        showMap: false,
        showAppDownload: false,
        showSocialMedia: false,
        button: [getDefaultConfig('footerButton', '#FFFFFF', '#000000')],
        section: [],
        style: {
          // width: '15%',
          minWidth: '140px',
          maxWidth: '220px',
        },
      },
      {
        type: SECTION_TYPE.NEWS_LETTER,
        subType: 'newsLetter',
        displayText: 'Newsletter',
        contentHeading: '<h2>Subscribe to our newsletter</h2>',
        isDefault: true,
        contentDescription:
          '<h5>Be the first to see our latest products, hottest sales and member exclusive discount codes. Sign up below to get your 10% off coupon.</h5>',
        headingCharCount: 10,
        descriptionCharCount: 8,
        newsLetterType: 'type4',
        isSortable: false,
        isVisible: true,
        isEditable: true,
        placeholder: 'Enter your e-mail id',
        subscribeText: 'Subscribe',
        section: [],
        subscription: [SUBSCRIPTION_NAMES.ELITE],
        style: {
          // width: '24%',
          minWidth: '220px',
          maxWidth: '340px',
        },
      },
    ],
    style: {},
  },
  layer2: {
    heading: '',
    isSortable: false,
    isAddElement: true,
    isStaticSection: true,
    actualLayer: 'layer3',
    elementList: [''],
    builder: [
      {
        type: SECTION_TYPE.COPYRIGHT,
        subType: 'copyright',
        isDefault: true,
        displayText: 'Copyright',
        placeholder: 'Enter copyright info',
        content: `© ${new Date().getFullYear()} — Copyright, All Rights reserved`,
        contentCharCount: 100,
        isSortable: false,
        isVisible: true,
        isEditable: true,
        section: [],
        style: {},
      },
      {
        type: SECTION_TYPE.PAYMENT_MODES,
        subType: 'paymentModes',
        isDefault: true,
        displayText: 'Payment Modes',
        content: '',
        contentCharCount: 100,
        isSortable: false,
        isVisible: true,
        isEditable: true,
        section: [],
        style: {},
      },
    ],
  },
};

export const ADD_ELEMENT_TYPE = {
  quickLinks: {
    type: SECTION_TYPE.QUICK_LINKS,
    displayText: 'Quick link',
    subType: 'quick link',
    content: '<p>Quick link</p>',
    isDefault: false,
    contentCharCount: 10,
    isSortable: false,
    isVisible: true,
    section: [],
    style: {
      // width: '15%',
    },
  },
  categoriesCollections: {
    type: SECTION_TYPE.CATEGORIES_COLLECTIONS,
    displayText: 'Shop store',
    subType: 'categories collections',
    content: '<p>Shop Categories</p>',
    isContentEdited: false,
    placeholder: 'Shop Categories',
    isDefault: false,
    selectedLinkType: '', // initial value set dynamically (categories, collections, categoriesCollections, customLinks)
    linksList: [],
    oldSelectedLinksList: [],
    listSortType: 'default',
    contentCharCount: 100,
    isSortable: false,
    isVisible: true,
    section: [],
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
    style: {
      width: '100%',
    },
  },
  customHTML: {
    type: SECTION_TYPE.CUSTOM_HTML,
    displayText: 'Custom HTML',
    subType: 'custom html',
    content: '',
    contentProps: {
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      border: '',
      margin: '',
      padding: '',
      background: '',
      mobileHidden: false,
      desktopHidden: false,
      height: '',
      openInNewTab: false,
      link: '',
      maxWidth: '',
    },
    isDefault: false,
    contentCharCount: 10000,
    isSortable: false,
    isVisible: true,
    section: [],
    style: {
      width: '100%',
    },
  },
  textGroup: {
    type: SECTION_TYPE.TEXT_GROUP,
    displayText: 'Text group',
    subType: 'custom html',
    content: {
      heading: '<p>About</p>',
      description:
        '<p>Use this text area to share your brand story and vision with your customers here.</p>',
    },
    placeholder: 'Your text goes here....',
    isDefault: false,
    contentCharCount: { heading: 100, description: 1000 },
    isSortable: false,
    isVisible: true,
    section: [],
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
    style: {
      width: '100%',
    },
  },
};

export const NEW_FOOTER_COLOR_SCHEME = {
  background: '#0C1518',
  text: '#ffffff',
  isDefault: false,
};

export const NEW_FOOTER_LAYER_CONFIG = {
  heading: '',
  isSortable: true,
  isAddElement: true,
  actualLayer: 'layer2',
  elementList: [''],
  builder: [],
  style: {},
};

export const FOOTER_LINK_TYPES = {
  CATEGORIES: 'categories',
  COLLECTIONS: 'collections',
  CAT_COLLECTION: 'categoriesCollections',
  CUSTOM_LINKS: 'customLinks',
};

export const FOOTER_LINK_SORT_TYPES = {
  DEFAULT: 'default',
  ALPHABETICAL: 'alphabetical',
  ITEM_COUNT: 'itemCount',
  RANKING: 'ranking',
};

export const COLUMN_WISE_ELEMENTS = [
  {
    title: `Quick links`,
    limit: 2,
    seoBoost: false,
    uid: SECTION_TYPE.QUICK_LINKS,
  },
];
export const ROW_WISE_ELEMENTS = [
  {
    title: `Text Group`,
    limit: 2,
    seoBoost: true,
    uid: SECTION_TYPE.TEXT_GROUP,
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  {
    title: `Categories list/Collections, custom links`,
    limit: 2,
    seoBoost: true,
    uid: SECTION_TYPE.CATEGORIES_COLLECTIONS,
    subscription: [SUBSCRIPTION_NAMES.PRO, SUBSCRIPTION_NAMES.ELITE],
  },
  {
    title: `Custom HTML`,
    limit: 1,
    seoBoost: false,
    uid: SECTION_TYPE.CUSTOM_HTML,
  },
];

export const ADMIN_FOOTERS_LIST = [
  {
    title: ADMIN_WIDGETS.FOOTER.type,
    name: ADMIN_WIDGETS.FOOTER.type,
    imgUrl: `https://cdn.dotpe.in/longtail/themes/8073865/amm30kQR.webp`,
    propsConfig: {
      isAdminFooter: true,
      footerNumber: 1,
      configChanges: [
        {
          subType: SECTION_TYPE.SHOP,
          isVisible: true,
        },
        {
          subType: SECTION_TYPE.NEWS_LETTER,
          isVisible: false,
        },
      ],
    },
  },
  {
    title: ADMIN_WIDGETS.FOOTER.type,
    name: ADMIN_WIDGETS.FOOTER.type,
    imgUrl: `https://cdn.dotpe.in/longtail/themes/8073865/d18bG3Q5.webp`,
    propsConfig: {
      isAdminFooter: true,
      footerNumber: 2,
      configChanges: [
        {
          subType: SECTION_TYPE.SHOP,
          isVisible: false,
        },
        {
          subType: SECTION_TYPE.NEWS_LETTER,
          isVisible: true,
        },
      ],
    },
  },
];

export const PLACEHOLDER_FILED = {
  label: 'Placeholder text',
  placeholder: 'Enter text',
  key: 'placeholder',
};

export const SUBSCRIBE_FIELD = {
  label: 'Text over button',
  placeholder: 'Enter text',
  key: 'subscribeText',
};

export const FOOTER_CHILDREN_SECTION_CONFIGS = {
  [SECTION_TYPE.NEWS_LETTER]: {
    style: {
      // width: '24%',
      minWidth: '238px',
      maxWidth: '340px',
    },
  },
  [SECTION_TYPE.QUICK_LINKS]: {
    style: {
      // width: '13%',
      minWidth: '120px',
      maxWidth: '180px',
    },
  },
  [SECTION_TYPE.CONTACTUS_LOCATION]: {
    style: {
      // width: '15%',
      minWidth: '140px',
      maxWidth: '220px',
    },
  },
  [SECTION_TYPE.LOGO_ABOUTUS]: {
    style: {
      // width: '15%',
      minWidth: '140px',
      maxWidth: '220px',
    },
  },
  [SECTION_TYPE.CATEGORIES_COLLECTIONS]: {
    style: {
      width: '100%',
    },
  },
  [SECTION_TYPE.CUSTOM_HTML]: {
    style: {
      width: '100%',
    },
  },
  [SECTION_TYPE.TEXT_GROUP]: {
    style: {
      width: '100%',
    },
  },
};

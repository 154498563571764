import { checkIfRoutesAreValid } from './404Redirect';
import { PATH } from './routes';

/**
 * @param {import('next').NextPageContext} ctx
 * @returns {{domain: string; isCustomDomain: boolean;}}
 */
export const fetchDomainFromHeaders = (ctx) => {
  let domain = '';
  let isCustomDomain = false;
  const domainFromCtx = ctx.params?.domain || ctx.req.headers.host;

  if (
    ctx.req.headers?.domain ||
    (ctx?.resolvedUrl?.includes(PATH.BUILDER_PREVIEW) && ctx.params?.domain)
  ) {
    domain =
      ctx.req.headers?.domain ||
      (ctx?.resolvedUrl?.includes(PATH.BUILDER_PREVIEW) && ctx.params?.domain);
    isCustomDomain = true;
    checkIfRoutesAreValid(isCustomDomain, ctx);
    return { domain, isCustomDomain };
  }

  /** If request is from 'd-' domain */
  if (domainFromCtx.startsWith('d-')) {
    const isStageEnv =
      domainFromCtx.includes('.stage1.dotnu') || domainFromCtx.includes('.stage2.dotnu');

    let targetIndex = 0;

    if (isStageEnv) {
      targetIndex = domainFromCtx.lastIndexOf('.stage') - 2;
    } else {
      targetIndex = domainFromCtx.lastIndexOf('dot') - 3;
    }

    domain = domainFromCtx.substr(domainFromCtx.indexOf('d-') + 2, targetIndex);
    checkIfRoutesAreValid(isCustomDomain, ctx);
    return { domain, isCustomDomain };
  }
  if (
    /** If request is from showroom related subdomains */
    /** prevew1 is on dashy and preview 2 on app */
    domainFromCtx.includes('showroom.dot') ||
    domainFromCtx.includes('showroom.stage') ||
    domainFromCtx.includes('showroom-preview1.dot') ||
    domainFromCtx.includes('showroom-preview2.dot') ||
    domainFromCtx.includes('showroom-preview1.stage') ||
    domainFromCtx.includes('showroom-preview2.stage') ||
    domainFromCtx.includes('showroom.digitaldukaan.app') ||
    domainFromCtx.includes('localhost')
  ) {
    const urlSplitedArray = ctx.req.url.split('/');
    if (ctx.req.url.includes(PATH.WEBSITE_BUILDER) && ctx.query?.domain) {
      domain = ctx.query.domain;
    } else if (ctx.req.url.includes(PATH.STORE)) {
      domain = urlSplitedArray[urlSplitedArray.length - 1];
    } else {
      domain = urlSplitedArray[1];
    }
  } else {
    /** If request is from custom domain */
    domain = domainFromCtx.split(':')[0];
  }

  /** In case of custom domain */
  if (domain.includes('.')) {
    isCustomDomain = true;
  }
  checkIfRoutesAreValid(isCustomDomain, ctx);

  return {
    domain,
    isCustomDomain,
  };
};

import { VISIBILITY_TYPE } from 'src/components/WidgetMaker/WidgetDnD/WidgetProperties/HeaderBuilder/AnnouncementConfig';
import { checkIfHomePage } from 'src/utils/checkIfHomePage';
import { NewAnnouncementBar } from '../../../NewAnnouncementBar';

export function AnnouncementBarHeader({
  isBuilder,
  listConfig,
  cardConfig,
  showAnnouncementOnTop,
  seperatorLineStyles,
  isScroll = false,
  canHideHeaderAnnouncement = false,
}) {
  const isHomePage = checkIfHomePage();
  const isOnlyVisibleOnHomePage =
    listConfig?.visibilty?.type === VISIBILITY_TYPE.HOME_PAGE;

  const canShowAnnouncementBar = !isOnlyVisibleOnHomePage || isHomePage || isBuilder;

  return (
    canShowAnnouncementBar && (
      <>
        <NewAnnouncementBar
          fromHeader={true}
          widgetOptions={{
            // listPresentation: getAnnouncementAdjustedConfig(),
            listPresentation: listConfig,
            cardFrame: cardConfig,
          }}
          isPositionTop={showAnnouncementOnTop}
          isScroll={isScroll}
          canHideHeaderAnnouncement={canHideHeaderAnnouncement}
        />
        {showAnnouncementOnTop && (
          <div style={seperatorLineStyles} className=" tw-w-[100%]"></div>
        )}
      </>
    )
  );
}

import config from 'src/config';
import { checkForWrongDomain } from '@/utils/checkForWrongDomain';
import { fetchDomainFromHeaders } from '@/utils/fetchDomainFromHeaders';

export const getShowroomUrlForStore = (domain = '', storeUrl) => {
  if (domain.startsWith('d-temporary-')) {
    return `${config.APP_BASE_URL}/${domain.split('-')[2]}/?source=pwa`;
  } else if (domain.startsWith('d-')) {
    return `${config.APP_BASE_URL}/${domain.split('-')[1]}/?source=pwa`;
  } else {
    return `${storeUrl}?source=pwa`;
  }
};

let domainFromCtx = '';
export const setDSDomainFromCtx = (ctx) => {
  if (checkForWrongDomain(ctx)) {
    domainFromCtx = undefined;
    return;
  }
  if (ctx.query.domain) {
    domainFromCtx = ctx.query.domain;
    return;
  }
  if (ctx.req) {
    const { domain } = fetchDomainFromHeaders(ctx);
    domainFromCtx = domain;
  }
  const searchParams = new URLSearchParams(ctx.req.url);
  if (searchParams.get('domain')) {
    domainFromCtx = searchParams.get('domain');
  }
  return;
};

export const getDSDomain = () => {
  let domain = '';
  if (domainFromCtx) {
    domain = domainFromCtx;
  } else if (typeof window !== 'undefined') {
    const hostname = window.location.hostname || '';
    const storeName = hostname.split('.')[0];

    if (storeName.startsWith('d-')) {
      domain = storeName.replace('d-', '');
    } else if (storeName.startsWith('showroom') || storeName === 'localhost') {
      domain = window.location.pathname.split('/')[1];
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('domain')) {
      domain = urlParams.get('domain');
    }
  }
  if (domain === 'health' || domain === '_next') {
    return;
  }

  return domain;
};

import { getCustomHeaderTextColor } from '@/utils/getCustomHeaderTextColor';
import React from 'react';

export default function CommonNavbarText(props) {
  return (
    <span
      className={`
      nav-name 
      ${getCustomHeaderTextColor(props?.bgColor)} ${props.classes}`}
      style={props.style || {}}
    >
      {props?.page?.page_name}
    </span>
  );
}

import { IS_SERVER } from './checkRenderEnv';

const LocalStorageHelper = {
  add: (key, value) => {
    if (!key || IS_SERVER) {
      return false;
    }
    localStorage?.setItem(key, JSON.stringify(value));
    return true;
  },
  get: (key, defaultVal = null) => {
    if (!key || IS_SERVER) {
      return defaultVal;
    }
    let data = localStorage?.getItem(key);
    try {
      data = JSON.parse(data);
    } catch (e) {
      console.log(e);
    }
    return data ? data : defaultVal;
  },
  delete: (key) => {
    if (!key || IS_SERVER) {
      return false;
    }
    localStorage.removeItem(key);
    return true;
  },
  clear: () => {
    if (IS_SERVER) return;
    localStorage.clear();
  },
};

export default LocalStorageHelper;

import { ADMIN_WIDGETS } from 'src/constants/widgets';
import { SCROLL_TYPE, SEPERATOR_TYPE } from '../Constants';

export const DEFAULT_COLOR_CONFIG = {
  background: '#ffffff',
  text: '#111C36',
  isDefault: true,
};

export const NEW_ANNOUNCEMENT_BAR_PRESENTATION_CONFIG = {
  layoutName: 'layout1',
  viewLabel: ADMIN_WIDGETS.NEW_ANNOUNCEMENT_BAR.label,
  widgetName: ADMIN_WIDGETS.NEW_ANNOUNCEMENT_BAR.name,
  layoutType: 'carousel',
  heading: '',
  descriptionPosition: 'below',
  textAlign: 'center',
  headingCharCount: 0,
  description: '',
  descriptionCharCount: 0,
  type: ADMIN_WIDGETS.NEW_ANNOUNCEMENT_BAR.type,
  colors: {
    selectedColorSchemeIndex: 0,
    colorSchemes: [
      {
        background: '#ffffff',
        text: '#111C36',
        isDefault: true,
      },
      {
        background: '#000000',
        text: '#ffffff',
        isDefault: false,
      },
    ],
  },
  appearance: 'standard',
  backgroundColor: '#FFFFFF',
  borderColor: '#E2E4E7',
  numColumnsDesktop: 1,
  numColumnsMobile: 1,
  mobilePaddingY: 6,
  paddingY: 8,
  marginTop: 0,
  marginBottom: 0,
  autoPlayConfig: {
    type: SCROLL_TYPE.AUTO_ROTATE,
    autoplayTimer: 30,
    autoRotateTimer: 4,
  },
  seperator: SEPERATOR_TYPE.DOT,
  colGap: 24,
  colGapMobile: 20,
  showArrow: true,
};

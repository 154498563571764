import {
  GET_ITEMS_DELIVERY_CHARGE,
  GET_SERVICEABLE_ADDRESS,
  SET_ITEMS_DELIVERY_CHARGE,
  SET_SERVICEABLE_ADDRESS,
  GET_ESTIMATED_DELVIVERY_TIME,
} from '../actionTypes';

/**
 * get Delivery Charges Data of items inside the Cart Object.
 * @param {*} data
 * @returns
 */

export const getItemDeliveryCharges = (data) => {
  return {
    type: GET_ITEMS_DELIVERY_CHARGE,
    data: data,
  };
};

/**
 * Set Delivery Charges Data of items inside the Cart Object.
 * The Response Data is Set Inside deliveryReducer.
 * @param {*} data
 * @returns
 */

export const setItemDeliveyCharges = (data) => {
  return {
    type: SET_ITEMS_DELIVERY_CHARGE,
    data,
  };
};

/**
 * gets List of Address Id's That are Servicable , Unservicable and Pincode Required.
 * @param {*} data
 * @returns
 */

export const getServiceableAddressId = (data) => {
  return {
    type: GET_SERVICEABLE_ADDRESS,
    data,
  };
};

/**
 * sets List of Address Id's That are Servicable , Unservicable and Pincode Required.
 * @param {*} data
 * @returns
 */

export const setServiceableAddressId = (data) => {
  return {
    type: SET_SERVICEABLE_ADDRESS,
    data,
  };
};

export const getEstimatedDeliveryTime = (data, callback) => {
  return {
    type: GET_ESTIMATED_DELVIVERY_TIME,
    data,
    callback,
  };
};

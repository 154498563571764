import {
  SET_CART_VALIDATION_ERROR_MSG_MODAL_VISIBILITY,
  SAVE_CART_VALIDATION_DATA,
  SET_NOT_ACCEPTING_ORDERS_MODAL_VISIBILITY,
} from '../actionTypes';

const initialState = {
  cart: {
    cartValidationData: null,
    cartValidationErrorMsgModal: false,
  },
  common: {
    notAcceptingOrdersPopUp: false,
  },
};

const ErrorReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART_VALIDATION_ERROR_MSG_MODAL_VISIBILITY:
      return {
        ...state,
        cart: {
          ...state.cart,
          cartValidationErrorMsgModal: action.data,
        },
      };
    case SAVE_CART_VALIDATION_DATA:
      return {
        ...state,
        cart: {
          ...state.cart,
          cartValidationData: action.data,
        },
      };
    case SET_NOT_ACCEPTING_ORDERS_MODAL_VISIBILITY:
      return {
        ...state,
        common: {
          ...state.common,
          notAcceptingOrdersPopUp: action.data,
        },
      };
    default:
      return state;
  }
};

export default ErrorReducer;

import {
  SET_ITEMS_DELIVERY_CHARGE,
  SET_SERVICEABLE_ADDRESS,
} from '../actionTypes';

const initialState = {};

const deliveryReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ITEMS_DELIVERY_CHARGE:
      return { ...state, itemData: action.data };
    case SET_SERVICEABLE_ADDRESS:
      return { ...state, addressData: action.data };
    default:
      return state;
  }
};

export default deliveryReducer;

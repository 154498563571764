import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { useGetDeviceType as useDeviceTypeActual } from 'src/hooks/useGetDeviceType';

export function useDeviceType() {
  const {
    widgetContextState: { isBuilder, previewDevice },
  } = useWidgetDndContextData();
  const { deviceType: deviceTypeActual } = useDeviceTypeActual();

  const deviceTypeToCheck = isBuilder ? previewDevice : deviceTypeActual;

  const isMobile = deviceTypeToCheck === 'mobile';

  return { isMobile };
}

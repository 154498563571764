const SliderArrowForward = ({ color = '#000000', height = 12, width = 7 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.7071 0.292886C1.31659 -0.0976287 0.683402 -0.0976287 0.292886 0.292886C-0.0976288 0.683401 -0.0976288 1.31659 0.292886 1.7071L4.58579 6L0.292886 10.2929C-0.0976288 10.6834 -0.0976288 11.3166 0.292886 11.7071C0.683402 12.0976 1.31659 12.0976 1.7071 11.7071L6.70711 6.70711C7.09763 6.31659 7.09763 5.68341 6.70711 5.29289L1.7071 0.292886Z"
        fill={color}
      />
    </svg>
  );
};

export default SliderArrowForward;

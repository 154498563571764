import { takeEvery, put } from 'redux-saga/effects';
import { ENDPOINT } from '../../config/endpoints';
import apiCall from '../../services/api';
import {
  FETCH_SPOTLIGHT_CITIES,
  FETCH_STORES_LIST,
  FETCH_BUSSINESS_TYPES,
  SEARCH_SPOTLIGHT_STORES,
} from '../actionTypes';
import {
  emptySpotStoresList,
  saveBussinessTypes,
  saveSpotAllCities,
  saveSpotDefaultCity,
  saveSpotSelectedCity,
  saveSpotStoresList,
  setLoaderState,
} from '../actions';
import { toastNotifyError } from '../../components/Common/Toast';

function* fetchCities(actions) {
  try {
    yield put(setLoaderState(true));
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.SPOTLIGHT.cityList(
        actions?.data?.latitude || '',
        actions?.data?.longitude || ''
      ),
    });
    if (response && response.data && response.data.status) {
      yield put(setLoaderState(false));
      yield put(saveSpotAllCities(response.data.city_list));
      if (response.data.selected_city.id) {
        const data = {
          id: response.data.selected_city.id,
          name: response.data.selected_city.name,
          url: response.data.selected_city.url,
        };
        if (actions.data && actions.data.latitude) {
          data.latitude = actions.data.latitude;
          data.longitude = actions.data.longitude;
          yield put(saveSpotSelectedCity(data));
        }
        yield put(saveSpotDefaultCity(data));
        if (actions.callback) {
          actions.callback(response.data.selected_city);
        }
      }
    } else {
      yield put(setLoaderState(false));
      actions.history && actions.history.push('/stores/city/search');
    }
  } catch (err) {
    yield put(setLoaderState(false));
    toastNotifyError('Something went bad!');
  }
}

function* fetchStoresList(actions) {
  try {
    yield put(setLoaderState(true));
    if (actions.data.page === 1) {
      yield put(emptySpotStoresList());
    }
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.SPOTLIGHT.storesList(
        actions?.data?.latitude || '',
        actions?.data?.longitude || '',
        actions?.data?.city_id || '',
        actions?.data?.page,
        actions?.data?.category_id || ''
      ),
    });
    if (response && response.data && response.data.status) {
      yield put(setLoaderState(false));
      yield put(
        saveSpotStoresList({
          stores: response.data.stores || [],
          isNext: response.data.next_page,
        })
      );
    }
  } catch (err) {
    yield put(setLoaderState(false));
    toastNotifyError('Something went bad!');
  }
}

function* fetchBussinessTypes(actions) {
  try {
    yield put(setLoaderState(true));
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.SPOTLIGHT.BUSINESS_LIST,
    });
    if (response && response.data && response.data.status) {
      yield put(setLoaderState(false));
      yield put(saveBussinessTypes(response.data.businesses || []));
    }
  } catch (err) {
    yield put(setLoaderState(false));
    toastNotifyError('Something went bad!');
  }
}

function* searchStoreList(actions) {
  try {
    yield put(setLoaderState(true));
    const response = yield apiCall({
      method: 'GET',
      url: ENDPOINT.SPOTLIGHT.storeSearch(
        actions.data?.latitude || '',
        actions.data?.longitude || '',
        actions.data?.city_id,
        actions.data.page,
        encodeURIComponent(actions.data.text)
      ),
    });
    if (response && response.data && response.data.status) {
      yield put(setLoaderState(false));
      yield put(
        saveSpotStoresList({
          stores: response.data.stores || [],
          isNext: response.data.next_page,
        })
      );
    }
  } catch (err) {
    yield put(setLoaderState(false));
    toastNotifyError('Something went bad!');
  }
}

export default function* root() {
  yield takeEvery(FETCH_SPOTLIGHT_CITIES, fetchCities);
  yield takeEvery(FETCH_STORES_LIST, fetchStoresList);
  yield takeEvery(FETCH_BUSSINESS_TYPES, fetchBussinessTypes);
  yield takeEvery(SEARCH_SPOTLIGHT_STORES, searchStoreList);
}

import { OS_ENUMS, getMobileOperatingSystem } from '@/utils/operatingSystem.ts';
import { IS_WEBVIEW } from '@/utils/checkRenderEnv';
import {
  ANDROID_GOOGLE_PAY_INTENT_LINK,
  ANDROID_PAYTM_INTENT_LINK,
  ANDROID_PHONEPE_INTENT_LINK,
  ANDROID_WHATSAPP_INTENT_LINK,
  IOS_GOOGLE_PAY_INTENT_LINK,
  IOS_PAYTM_INTENT_LINK,
  IOS_PHONEPE_INTENT_LINK,
  IOS_WHATSAPP_INTENT_LINK,
} from '../config/paymentLinks';

const TEMPLATE_TYPE = {
  ANDROID: 1, // specific to android
  IOS_WEBVIEW: 2, // specific to iOS and react native webview for cfe apps on playstore and appstore
};

export default function getIntentLink(paymentMethod) {
  const os = getMobileOperatingSystem();
  if (os === OS_ENUMS.ios || IS_WEBVIEW) {
    return getDeeplinkForOSType(TEMPLATE_TYPE.IOS_WEBVIEW, paymentMethod);
  } else {
    return getDeeplinkForOSType(TEMPLATE_TYPE.ANDROID, paymentMethod);
  }
}

function getDeeplinkForOSType(type, paymentMethod) {
  switch (paymentMethod) {
    case 'phonepe':
      return type === TEMPLATE_TYPE.ANDROID
        ? ANDROID_PHONEPE_INTENT_LINK
        : IOS_PHONEPE_INTENT_LINK;
    case 'googlepay':
      return type === TEMPLATE_TYPE.ANDROID
        ? ANDROID_GOOGLE_PAY_INTENT_LINK
        : IOS_GOOGLE_PAY_INTENT_LINK;
    case 'paytmupi':
      return type === TEMPLATE_TYPE.ANDROID
        ? ANDROID_PAYTM_INTENT_LINK
        : IOS_PAYTM_INTENT_LINK;
    case 'whatsapp':
      return type === TEMPLATE_TYPE.ANDROID
        ? ANDROID_WHATSAPP_INTENT_LINK
        : IOS_WHATSAPP_INTENT_LINK;
    default:
      return '';
  }
}

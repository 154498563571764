import { SCROLL_TYPE } from '../components/Testimonials/Constants';

export const useAutoScrollOrRotation = ({ listPresentationConfig }) => {
  const isAutoRotateEnabled =
    listPresentationConfig?.autoPlayConfig?.type === SCROLL_TYPE.AUTO_ROTATE;
  const autoRotateTimer = listPresentationConfig?.autoPlayConfig?.autoRotateTimer || 5;

  const isAutoScrollEnabled =
    listPresentationConfig?.autoPlayConfig?.type === SCROLL_TYPE.AUTO_SCROLL;
  const autoScrollTimer = listPresentationConfig?.autoPlayConfig?.autoplayTimer || 5;

  return {
    isAutoRotateEnabled,
    autoRotateTimer,
    isAutoScrollEnabled,
    autoScrollTimer,
  };
};

import { useEffect, useState, useRef } from 'react';
import { BlurContainer, ImageEnlargeModal } from './ImageEnlarge.styled';
import { Tab3LeftArrow } from '@/assets/svgExports/Tab3LeftArrow';
import { Tab3RightArrow } from '@/assets/svgExports/Tab3RightArrow';
import { CancelIcon } from '../../utils/SvgExport';
const ImageEnlarge = (props) => {
  const { media, selectedIndex, closeModal } = props;
  const [currentImageIndex, setCurrentImageIndex] = useState(selectedIndex);
  const imageEnlargeRef = useRef(null);
  useEffect(() => {
    // Add overflow hidden to the body when the modal is open
    document.body.style.overflow = 'hidden';
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Remove overflow hidden when the modal is closed
      document.body.style.overflow = 'unset';
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handlePrev = () => {
    const newIndex = (currentImageIndex - 1 + media.length) % media.length;
    setCurrentImageIndex(newIndex);
  };
  const handleNext = () => {
    const newIndex = (currentImageIndex + 1) % media.length;
    setCurrentImageIndex(newIndex);
  };
  const handleClickOutside = (event) => {
    if (imageEnlargeRef.current && !imageEnlargeRef.current.contains(event.target)) {
      // Click occurred outside the modal, so close it
      closeModal();
    }
  };
  return (
    <>
      <BlurContainer />
      <ImageEnlargeModal className="modal" ref={imageEnlargeRef}>
        <button className="prev" onClick={handlePrev}>
          <Tab3LeftArrow />
        </button>
        <div className="modal-content">
          <button className="close" onClick={closeModal}>
            <CancelIcon />
          </button>
          <div>
            <img
              className="enlarged-image"
              src={media?.[currentImageIndex]?.url}
              alt={`Enlarged Image ${currentImageIndex}`}
            />
          </div>
        </div>
        <button className="next" onClick={handleNext}>
          <Tab3RightArrow />
        </button>
      </ImageEnlargeModal>
    </>
  );
};

export default ImageEnlarge;

import { get } from 'axios';
import { ENDPOINT } from './../config/endpoints';

/**
 * Function for getting Catalog List by storeId & pageNo
 * @param {string} hash
 * @param {number} storeId
 */
async function getOrderDetailsByHash(hash, storeId) {
  try {
    const response = await get(ENDPOINT.ORDER.getOrderFromHash(hash, storeId), {
      headers: {
        app_os: 'cfe',
      },
    });
    return { error: null, data: response.data };
  } catch (error) {
    return { error };
  }
}

async function getStoreOrderByHash(hash) {
  try {
    const response = await get(ENDPOINT.ORDER.getStoreOrder(hash), {
      headers: {
        app_os: 'cfe',
      },
    });
    return { error: null, data: response.data };
  } catch (error) {
    return { error };
  }
}

/**
 * Add Exported Function here
 */
export { getOrderDetailsByHash, getStoreOrderByHash };

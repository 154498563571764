import { getStoreType } from '@/utils/getStoreType';
import store from '../redux/store';
import { ORDER_TYPE, STORE_CUSTOMIZATION } from './constants';

/**
 * This function will return the current order_type. If the current order type present in cart is still associated with store services then
 * return the order type as it is else we need to toggle the order type
 * @param {*} currentOrderType
 * @return {number}
 */
export const getCurrentOrderType = (currentOrderType) => {
  const { store: storeData } = store.getState().storeReducer;
  if (getStoreType() === STORE_CUSTOMIZATION.B2B) {
    return ORDER_TYPE.B2B;
  }
  if (getStoreType() != STORE_CUSTOMIZATION.ORDER_BASED) return ORDER_TYPE.SERVICE;
  if (
    (currentOrderType === ORDER_TYPE.DELIVERY && storeData.services?.delivery_flag) ||
    (currentOrderType === ORDER_TYPE.PICKUP && storeData.services?.pickup_flag)
  )
    return currentOrderType;
  else return currentOrderType === 1 ? 2 : 1;
};

import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './../reducers';
import sagas from './../sagas';
import { loadState, saveState } from '@/utils/persistState';
import { CATALOG_REDUCER_INIT_STATE } from '../reducers/catalog';

const sagaMiddleware = createSagaMiddleware();
const logger = createLogger({
  stateTransformer: (state) => {
    if (
      (process.env.ENV === `DEV` || process.env.NODE_ENV === `development`) &&
      typeof window !== 'undefined'
    ) {
      window.store = state;
    }
    return state;
  },
});

const persistedState = loadState();

const middlewaresArr = [sagaMiddleware];

if (
  process.env.NEXT_PUBLIC_CUSTOM_ENV === 'DEV' ||
  process.env.NEXT_PUBLIC_CUSTOM_ENV === 'STAGE-1' ||
  process.env.NEXT_PUBLIC_CUSTOM_ENV === 'STAGE-2'
) {
  middlewaresArr.push(logger);
}
const enhancers = [applyMiddleware(...middlewaresArr)];

const composedEnhancers = composeWithDevTools(...enhancers);

const store = createStore(rootReducer, persistedState, composedEnhancers);

store.subscribe(() => {
  const globalStore = store.getState() || {};

  const storeReducer = globalStore.storeReducer;
  // const currentRoute = globalStore.userReducer?.routeChanges?.current;
  // const nextRoute = globalStore.userReducer?.routeChanges?.next;

  const state = {
    cartReducer: globalStore.cartReducer,
    orderReducer: globalStore.orderReducer,
    userReducer: globalStore.userReducer,
    // Added for stopping unnecessaey API calls, & cleared after a set interval of time useReduxStorageInvalidation
    storePolicyReducer: globalStore.storePolicyReducer,
    promoReducer: globalStore.promoReducer,
  };

  const isCustomStore = !storeReducer?.store?.domain_info?.domain?.includes('d-');

  const isValidComponent = !!storeReducer?.store?.theme?.components?.Body?.find(
    (comp) => comp.name === 'MiddleBody5' || comp.name === 'MiddleBody6'
  );

  if (isCustomStore && isValidComponent) {
    state.catalogReducer = globalStore.catalogReducer;
  } else {
    state.catalogReducer = CATALOG_REDUCER_INIT_STATE;
  }

  saveState(state);
});

export default store;
sagaMiddleware.run(sagas);

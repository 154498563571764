export type InputFieldData = {
  inputLabel: string;
  is_mandatory: boolean;
  id: number;
  is_field_selected: boolean;
  key: string;
  payload_key: string;
};

export const INPUT_NOT_VISIBLE = 0;
export const OPTIONAL_INPUT = 1;
export const MANDATORY_INPUT = 2;

export const inputFieldsData: InputFieldData[] = [
  {
    inputLabel: "Name",
    is_mandatory: true,
    id: 1,
    is_field_selected: true,
    key: "name_flag",
    payload_key: "name",
  },
  {
    inputLabel: "Phone Number",
    is_mandatory: true,
    id: 3,
    is_field_selected: true,
    key: "phone_flag",
    payload_key: "phone",
  },
  {
    inputLabel: "Message Box",
    is_mandatory: false,
    id: 2,
    is_field_selected: false,
    key: "custom_message_flag",
    payload_key: "custom_message",
  },
  {
    inputLabel: "Quantity",
    is_mandatory: false,
    id: 4,
    is_field_selected: false,
    key: "quantity_flag",
    payload_key: "quantity",
  },
  {
    inputLabel: "Email Id",
    is_mandatory: false,
    id: 5,
    is_field_selected: false,
    key: "email_flag",
    payload_key: "email",
  },
  {
    inputLabel: "Date and Time",
    is_mandatory: false,
    id: 7,
    is_field_selected: false,
    key: "date_time_flag",
    payload_key: "date_and_time",
  },
  {
    inputLabel: "Address",
    is_mandatory: false,
    id: 6,
    is_field_selected: false,
    key: "address1_flag",
    payload_key: "address",
  },
  {
    inputLabel: "GST",
    is_mandatory: false,
    id: 8,
    is_field_selected: false,
    key: "gst_flag",
    payload_key: "gst",
  }
];

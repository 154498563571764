import { REDIRECTION_TYPES } from '@/components/WidgetMaker/WidgetsComponents/CMSConstants';

const redirectToNewLink = (link, newTab) => {
  if (!link) return;
  if (newTab) {
    window.open(link, '_blank');
  } else {
    window.location.href = link;
  }
};
const downloadButton = (link) => {
  if (!link) return;
  const downloadLink = document.createElement('a');
  downloadLink.href = `${link}?download=true`;
  downloadLink.target = '_blank';
  downloadLink.rel = 'noopener noreferrer';
  downloadLink.download = 'file';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};
const scrollToElement = (id) => {
  if (!id) return;

  const elementContainer = document.getElementById(id);
  if (elementContainer) {
    window.scrollTo({
      behavior: 'smooth',
      top:
        elementContainer.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        5,
    });
  }
};

const renderFormComponent = (handler, callback) => {
  callback && callback();
};

export const btnActionHandler = ({ type, handler, newTab }, callback = () => {}) => {
  switch (type) {
    case 1:
    case REDIRECTION_TYPES.link:
      redirectToNewLink(handler, newTab);
      break;
    case 2:
      renderFormComponent(handler, callback);
      break;
    case 3:
    case REDIRECTION_TYPES.scrollto:
      scrollToElement(handler);
      break;
    case 4:
      downloadButton(handler);
      break;
    case 5:
      return;
  }
};

import router from 'next/router';
import store from '../redux/store';
import { getRoute, PATH } from './routes';

export const routeToCollectionPage = (collection, openInNewTab = false) => {
  const storeData = store.getState().storeReducer?.store;
  const link = getRoute(
    PATH.collectionCategories(collection?.id),
    storeData?.store_info?.domain
  );

  if (openInNewTab) {
    window.open(
      window.location.origin + (!link?.startsWith('/') ? '/' + link : link),
      '_blank'
    );
    return;
  }
  router.push(link, undefined, { shallow: true, scroll: true });
};

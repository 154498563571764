import { combineReducers } from 'redux';
import { storeReducer } from './store';
import cartReducer from './cart';
import catalogReducer from './catalog';
import userReducer from './user';
import orderReducer from './order';
import commonReducer from './common';
import paymentReducer from './payment';
import spotlightReducer from './spotlight';
import errorReducer from './error';
import promoReducer from './promo';
import additionalPagesReducer from './additionalPages';
import storePolicyReducer from './storePolicies';
import leadGenReducer from './leadgen';
import emiReducer from './emi';
import deliveryReducer from './delivery';
import widgetsReducer from './widgets';
import jsonWidgetsReducer from './jsonWidgets';
import { useSelector as reduxUseSelector } from 'react-redux';

export type { StoreDetails } from './store';

const rootReducer = combineReducers({
  storeReducer,
  cartReducer,
  catalogReducer,
  userReducer,
  orderReducer,
  commonReducer,
  paymentReducer,
  spotlightReducer,
  errorReducer,
  promoReducer,
  additionalPagesReducer,
  storePolicyReducer,
  leadGenReducer,
  emiReducer,
  deliveryReducer,
  widgetsReducer,
  jsonWidgetsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export function useSelector<TState = RootState, TSelected = unknown>(
  selector: (state: TState) => TSelected,
  equalityFn?: (left: TSelected, right: TSelected) => boolean
): TSelected {
  return reduxUseSelector<TState, TSelected>(selector, equalityFn);
}

export default rootReducer;

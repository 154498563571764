const convertSnakeToCamel = (str) =>
  str.toLowerCase().replace(/[-_][a-z0-9]/g, (group) => group.slice(-1).toUpperCase());

// Works only for obejcts currently
export function transformBEDataToFE(data, convertNestedKeys = true) {
  const isDataArrayType = Array.isArray(data);
  const transformedData = isDataArrayType ? [] : {};
  if (isDataArrayType) {
    for (const item of data) {
      transformedData.push(transformBEDataToFE(item));
    }
  } else {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const transformedKey = convertSnakeToCamel(key);
        const value = data[key];
        if (convertNestedKeys && typeof value === 'object') {
          transformedData[transformedKey] = transformBEDataToFE(value);
        } else transformedData[transformedKey] = value;
      }
    }
  }
  return transformedData;
}

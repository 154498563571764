export const normalizeFooterConfig = (config) => {
  const currentConfig = Object.keys(config).reduce((all, keyName) => {
    if (keyName?.includes('layer') && config[keyName]?.data) {
      all[keyName] = {
        ...config[keyName],
        builder: config[keyName]?.data,
      };
      delete all[keyName].data;
    } else all[keyName] = config[keyName];

    return all;
  }, {});

  return currentConfig;
};

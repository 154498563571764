import { FunctionComponent, MouseEventHandler, PropsWithChildren } from 'react';
import styles from './widgetFrame.module.scss';
import cn from 'classnames';
import {
  ArrowDownward,
  ArrowUpward,
  DeleteForeverOutlined,
  EditOutlined,
} from '@mui/icons-material';

interface WidgetFrameProps {
  name?: string;
  widgetId?: string;
  alwaysVisible?: boolean;
  onDelete?: MouseEventHandler<HTMLSpanElement>;
  onSelect?: MouseEventHandler<HTMLSpanElement>;
  onMove?: (widgetId: string, direction: string) => void;
  siblingCount?: number;
  index?: number;
  widget?: any;
  isHeaderFocused?: boolean;
}
export const WidgetFrame: FunctionComponent<PropsWithChildren<WidgetFrameProps>> = (
  props
) => {
  const { widget } = props;

  const shouldDisableWidget = () => {
    return props.name === 'Header' || props.name === 'Footer';
  };
  return (
    <div
      className={cn(styles.widgetFrameContainer, {
        [styles.alwaysVisible]: props.alwaysVisible,
      })}
    >
      <div
        className={'tw-text-sm ' + cn(styles.widgetHandle, styles.centerHandle)}
        style={{ opacity: 1, background: '#2f80ed' }}
      >
        {/* {props.name && <div className={styles.widgetName} onClick={props.onSelect}>{props.name}</div>} */}
        {/* <WidgetIcon title="Move" cursor="move" className={cn(styles.widgetHandleIcon, styles.dragIndicator, 'drag-handle')} name="drag_indicator" /> */}
        <span
          className={cn(styles.widgetHandleIcon, {
            [styles.disabled]: props.index === 0 || shouldDisableWidget(),
          })}
          onClick={() => props.onMove(props.widgetId, 'up')}
        >
          <ArrowUpward fontSize="inherit" />
        </span>
        <span
          className={cn(styles.widgetHandleIcon, {
            [styles.disabled]:
              props.index === props.siblingCount - 1 || shouldDisableWidget(),
          })}
          onClick={() => props.onMove(props.widgetId, 'down')}
        >
          <ArrowDownward fontSize="inherit" />
        </span>
        <span className={styles.widgetHandleIcon} onClick={() => props.onSelect(widget)}>
          <EditOutlined fontSize="inherit" />
        </span>
        <span
          className={cn(
            styles.widgetHandleIcon,
            {
              [styles.disabled]: shouldDisableWidget(),
            },
            styles.delete
          )}
          onClick={() => props.onDelete(widget)}
        >
          <DeleteForeverOutlined fontSize="inherit" />
        </span>
      </div>
    </div>
  );
};
